// Library Imports
import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage, FieldArray, getIn } from "formik";
import * as Yup from "yup";
import * as moment from "moment";
import FileBase64 from "react-file-base64";
import ReactPlayer from "react-player";
import Modal from "react-responsive-modal";
import $ from "jquery";
import loadable from '@loadable/component';
// Service Imports
import { RegisterUserService } from "../../services/registration.service";
import { LoginService } from "../../services/login.service";
import { RegionManagementService } from "../../services/regionManagement.service";
import { ReattemptTestService } from "../../services/reattemptTest.service";
import { getExperienceList } from "../../services/distributor.service";
import { fetchProcessType, getAMCMaster } from "../../services/fetchLearners";
import { LookUpService } from "../../services/lookupService";
// Other Imports
// import UserProfileComplitionBarCommon from "../userprofile/userProfileComplitionBarCommon";
import {
  success,
  error,
  successNotification,
  errorNotification,
  warning,
  warningNotification,
  info,
  infoNotification,
  success1,successNotification1,
} from "../notification/notifications";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import { FirstMessage, SecondMessage, ThirdMessage } from "../learner-dashboard/NotificationMessage";
import LoadingFallback from "../loadingFallBack";
// Asset Imports
import "react-datepicker/dist/react-datepicker.css";
require("moment-precise-range-plugin");
const mapping = require("../../assets/pdf/EUIN Mapping form.pdf");
const fresh = require("../../assets/pdf/EUIN Fresh Form.pdf");
const renewal = require("../../assets/pdf/EUIN Renewal Form.pdf");
const UserProfileComplitionBarCommon = loadable(() => import("../userprofile/userProfileComplitionBarCommon"), {
  fallback: <LoadingFallback />,
});

//! Unused Imports
// import mapping from "../../assets/pdf/EUIN Mapping form.pdf";
// import fresh from "../../assets/pdf/EUIN Fresh Form.pdf";
// import renewal from "../../assets/pdf/EUIN Renewal Form.pdf";
// import Select, { createFilter } from "react-select";
// import {
//   camelCase,
//   capitalCase,
//   constantCase,
//   dotCase,
//   headerCase,
//   noCase,
//   paramCase,
//   pascalCase,
//   pathCase,
//   sentenceCase,
//   snakeCase,
// } from "change-case";
// import DatePicker from "react-datepicker";
// import { Warning } from "@material-ui/icons";

export const TextValidationMesg = "you must enter the text";
export let validateStatus = "DRAFTED";
const currProf = [
  {
    codeValueId: 1071,
    codeValue: "Financial Distributor",
  },
  {
    codeValueId: 1072,
    codeValue: "Information Technology",
  },
  {
    codeValueId: 1073,
    codeValue: "Medicine",
  },
  {
    codeValueId: 1074,
    codeValue: "Engineering",
  },
  {
    codeValueId: 1075,
    codeValue: "Education",
  },
  {
    codeValueId: 1076,
    codeValue: "Govt. Employee",
  },
  {
    codeValueId: 1077,
    codeValue: "Other",
  },
];

class UserProfile extends Component {
  constructor(props) {
    debugger;
    super(props);
    let suspendStartDt = this.props?.personnelData?.dob ? this.props?.personnelData?.dob?.split(" ") : "";
    let suspendStartDate = suspendStartDt !== "" ? suspendStartDt[0]?.split("/") : "";

    this.suspendStartDate2 = suspendStartDate !== "" ?
      suspendStartDate[2] +
      "-" +
      suspendStartDate[1] +
      "-" +
      suspendStartDate[0] : "";
    if (this.props.personnelData.certificateValidUpto != undefined) {
      let nismDt = this.props.personnelData.certificateValidUpto.split(" ");
      let nismDate = nismDt[0].split("/");

      this.nismDate2 = nismDate[2] + "-" + nismDate[1] + "-" + nismDate[0];
    }

    if (this.props.personnelData.enrollmentDate != undefined) {
      let enrollDt = this.props.personnelData.enrollmentDate.split(" ");
      let enrollDate = enrollDt[0].split("/");

      this.enrollDate2 =
        enrollDate[2] + "-" + enrollDate[1] + "-" + enrollDate[0];
    }

    if (this.props.personnelData.euinValidUpto != undefined) {
      let euinDt = this.props.personnelData.euinValidUpto.split(" ");
      let euinDate = euinDt[0].split("/");
      this.euinDate2 = euinDate[2] + "-" + euinDate[1] + "-" + euinDate[0];
    }

    if (this.props.personnelData.learnerProfessionalQualification) {
      let learnerProfessionalQualification = this.props.personnelData
        .learnerProfessionalQualification;
      learnerProfessionalQualification.map((ele, index) => {
        // let fromdate = ele.fromDate.split(" ");
        // let fromdate_Temp = fromdate[0].split("/");
        // ele.fromDate =  fromdate_Temp[2] +
        // "-" +
        // fromdate_Temp[1] +
        // "-" +
        // fromdate_Temp[0];
        // let toDate = ele.toDate.split(" ");
        // let toDate_Temp = toDate[0].split("/");
        // ele.toDate =  toDate_Temp[2] +
        // "-" +
        // toDate_Temp[1] +
        // "-" +
        // toDate_Temp[0];
        // ele.fromdate = moment(ele.fromdate).format('YYYY-MM-DD');
        // ele.toDate = moment(ele.toDate).format('YYYY-MM-DD');
        // alert(ele.todate)
        // alert(new Date(moment(ele.todate).format('YYYY-MM-DD')))
        // ele.fromDate2=new Date(moment(ele.fromdate).format('YYYY-MM-DD'))
        // ele.toDate2=new Date(moment(ele.fromdate).format('YYYY-MM-DD'))
      });
    }

    // // father birth date spilt and bind
    // if (this.props.personnelData.learnerFatherDOB) {
    //   let suspendStartDateFatherBirthDt = (
    //     (this.props.personnelData || "").learnerFatherDOB || ""
    //   ).split(" ");
    //   let suspendStartDateFatherBirthDate = (
    //     suspendStartDateFatherBirthDt[0] || ""
    //   ).split("/");
    //   this.suspendStartDateFatherBirthDate2 =
    //     suspendStartDateFatherBirthDate[2] +
    //     "-" +
    //     suspendStartDateFatherBirthDate[1] +
    //     "-" +
    //     suspendStartDateFatherBirthDate[0];
    // }

    // // mother birth date spilt and bind
    // if (this.props.personnelData.learnerMotherDOB) {
    //   let suspendStartDateMotherBirthDt = (
    //     (this.props.personnelData || "").learnerMotherDOB || ""
    //   ).split(" ");
    //   let suspendStartDateMotherBirthDate = (
    //     suspendStartDateMotherBirthDt[0] || ""
    //   ).split("/");
    //   this.suspendStartDateMotherBirthDate2 =
    //     suspendStartDateMotherBirthDate[2] +
    //     "-" +
    //     suspendStartDateMotherBirthDate[1] +
    //     "-" +
    //     suspendStartDateMotherBirthDate[0];
    // }

    //seperate the incoming address to the permanr address and correspondence address
    let peraddress;
    // let corsAddress;
    this.props.addressData.forEach(function (addr) {
      if (addr.addressType == "permanent") {
        peraddress = addr;
      }
    });
    // this.props.addressData.forEach(function (addr) {
    //   if (addr.addressType == "correspondence") {
    //     corsAddress = addr;
    //   }
    // });
    let addressFlag = false;
    //      if(peraddress.street!=="" && peraddress.street2!=="" && peraddress.street3 !=="" &&peraddress.postalCode!=="")
    // {
    //     if(peraddress.street==corsAddress.street && peraddress.street2===corsAddress.street2 && peraddress.street3 ===corsAddress.street3 &&peraddress.postalCode==corsAddress.postalCode
    //       && peraddress.cityId==corsAddress.cityId &&peraddress.stateId===corsAddress.stateId)
    //       {
    //         // this.setState({
    //         //   addressFlag:true
    //         // })
    //         addressFlag=true
    //       }
    //     }
    //       else{
    //         // this.setState({
    //         //   addressFlag:false
    //         // })
    //         addressFlag=false
    //       }

    var todaydate = new Date();
    this.state = {
      
      today: moment(todaydate).format("YYYY-MM-DD"),
      show: false,
      isEditable: false,
      dob: this.suspendStartDate2,
      aadharNumber: this.props.personnelData.aadharNumber,
      perAddressType: peraddress ? peraddress.addressType : "",
      perAddressID: peraddress ? peraddress.addressId : "",
      perStreet: peraddress ? peraddress.street : "",
      perStreet2: peraddress ? peraddress.street2 : "",
      perStreet3: peraddress ? peraddress.street3 : "",
      perPostalCode: peraddress ? peraddress.postalCode : "",
      percityId: peraddress ? peraddress.cityId : "",
      perCityName: peraddress ? peraddress.city : "",
      perstateId: peraddress ? peraddress.stateId : "",
      perStateName: peraddress ? peraddress.state : "",
      percountryId: peraddress !== undefined ? peraddress.countryId : 1,
      perCountryName: peraddress ? peraddress.country : "",
      // corAddressType: corsAddress ? corsAddress.addressType : "",
      // corAddressID: corsAddress ? corsAddress.addressId : "",
      // corStreet: corsAddress ? corsAddress.street : "",
      // corStreet2: corsAddress ? corsAddress.street2 : "",
      // corStreet3: peraddress ? corsAddress.street3 : "",
      // corPostalCode: corsAddress ? corsAddress.postalCode : "",
      // corcityId: corsAddress ? corsAddress.cityId : "",

      // corCityName: corsAddress ? corsAddress.city : "",
      // corstateId: corsAddress ? corsAddress.stateId : "",
      // corStateName: corsAddress ? corsAddress.state : "",
      // corcountryId: corsAddress ? corsAddress.countryId : 1,
      // corCountryName: corsAddress ? corsAddress.coutnry : "",
      learnerFatherDOB: this.suspendStartDateFatherBirthDate2,
      learnerMotherDOB: this.suspendStartDateMotherBirthDate2,
      resumeDocumentName: "",
      countryList: [],
      // countryListForCors: [],
      // stateListForCor: [],
      // cityListForCor: [],
      stateListForPer: [],
      cityListForPer: [],
      employeTypeForParents: [],
      checkAddressFlag: "",
      listOfYear: [],
      addressFlag: addressFlag,
      dob_2_validate_message: "",
      dob_3_validate_message: "",
      processType: "",
      newLearnerDetails: "",
      whoareu: [],
      occupation: [],
      year: "",
      month: "",
      days: "",
      ValidationState: "DRAFTED",
      documents: [],
      educationalQualificationDocument: [],
      corAddressProof: "",
      perAddressProof: "",
      currentDate: "",
      panCardData: "",
      adharCardData: "",
      ProfilePhotoData: "",
      addressPoofCardData: "",
      videoResumeData: "",
      ResumeDocumentData: "",
      pancardTempURL: "",
      newAdharCardUrl: "",
      newAddressProof: "",
      AuualCome: [],
      nameOfMutual: [],
      //ARNEUINNO:'',
      currentProfession: [],
      loading1: "none",
      loading: false,

      listOfYear1: [],
      listOfMonth: [],
      listOfDay: [],
      listOfYear2: [],

      certificateYear: [],
      isCertificate: this.props.personnelData.isCertificate,
      certificateNumber: this.props.personnelData.nismRegistrationNo
        ? this.props.personnelData.nismRegistrationNo
        : "",
      certificateValidUpto: this.nismDate2,
      enrollmentNumber: this.props.personnelData.enrollmentNumber
        ? this.props.personnelData.enrollmentNumber
        : "",
      enrollmentDate: this.enrollDate2,
      euinNumber: this.props.personnelData.euinNumber,
      euinValidUpto: this.euinDate2,
      //certificateNumber: "",
      //certificateValidUpto: "",
      // euinNumber: "",
      //euinValidUpto: "",
      companyList: [],
      userDetailList: [],
      companyNameOther: "",
      selectedCompanyId: parseInt(this.props.personnelData.companyName),
      otherCompanyName: this.props.personnelData.otherCompanyName,
      euinyear1: "",
      orgCompanyName: "",
      isMandateUser: this.props.personnelData.isMandateUser,
      assignedUserMandateName: "",
      userId: "",
      assignedUserMandateName: localStorage.getItem("assignedUserMandateName"),
      getExperienceList:[],
      saveDraftBtnDisabled: false,
      submitBtnDisabled: false,
      submitHDFCBtnDisabled: false,
      saveDraftHDFCBtnDisabled: false,
    };
  }

  submitHandler(e) {
    e.preventDefault();
  }
  titleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  goto(url) {
    // window.location=url;
    var e = document.getElementById(url);
    e.scrollIntoView(true);
  }
  replaceAll(str, find, replace) {
    var escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    // alert(str.replace(new RegExp(escapedFind, 'g'), replace))
    return str.replace(new RegExp(escapedFind, "g"), replace);
  }
  getMandateName=()=>{
    var PersonnelData;
    if(["",null,undefined,"null","undefined"].includes(localStorage.getItem("PersonnelData"))){
      PersonnelData = this.props.personnelData
    }else{
      PersonnelData = JSON.parse(localStorage.getItem("PersonnelData"))
    }

    let dataValidate = {
      companyName: PersonnelData?.responseObject?.companyName || PersonnelData?.companyName,
      otherCompanyName: PersonnelData?.responseObject?.mandateId || PersonnelData?.mandateId,
    };
    RegionManagementService.checkMandateDetails(dataValidate,otherRes => {
      if(otherRes.status === "success"){
        this.setState({
          preferredMandateName: otherRes?.responseObject?.mandateEntity[0].mandateName
        })
      }else{
        warning(otherRes.reasonText,warningNotification)
        this.setState({ preferredMandateName: "" })
      }
    })
    if((PersonnelData?.responseObject?.executionTracker && PersonnelData?.responseObject?.executionTracker.length > 0) ||
        PersonnelData?.executionTracker && PersonnelData?.executionTracker.length > 0
      ){
    let stateId = ["",null,undefined].includes(PersonnelData?.responseObject?.executionTracker[0]?.additionalDetails?.stateId) ? "" : PersonnelData?.responseObject?.executionTracker[0]?.additionalDetails?.stateId 
     || ["",null,undefined].includes(PersonnelData?.executionTracker[0]?.additionalDetails?.stateId) ? "" : PersonnelData?.executionTracker[0]?.additionalDetails?.stateId
    if(!["",null,undefined].includes(stateId)){
      RegionManagementService.getCenter(
        "stateId=" + stateId,
        (res) => {
          if(res.status === "success"){
            this.setState({ preferredExamCenterList: res.responseListObject });
          }else{
            warning(res.reasonText,warningNotification)
          }
        }
      );
    }
    }
  }
  componentDidMount() {
    this.getMandateName();
    window.$('#Hdfc-Mandate').modal('hide')
    setTimeout(()=>{
      console.log("componentdidmount-userprofilelandingpage")
    
    debugger;
    // if (this.state.perStreet === "" || this.state.perStreet === undefined) {
    //   this.setState({
    //     perStreet: "",
    //     perStreet2: "",
    //     perStreet3: "",
    //     perPostalCode: "",
    //     percityId: "",
    //     perstateId: "",
    //     percountryId: "",
    //     checkAddressFlag: "",
    //   });
    // } else {
    // }

    RegionManagementService.getCompanyList((res) => {
      let company = [];
      // console.log ('getCompanyList::', res.responseListObject);
      for (let i = 0; i < res.responseListObject.length; i++) {
        let obj = {};
        obj["value"] = res.responseListObject[i].companyId;
        obj["label"] = res.responseListObject[i].companyName;
        company.push(obj);
      }
      // this.setState({
      //   companyList:company,
      // });
      this.setState({
        companyList: res.responseListObject,
      });
    });

    // RegionManagementService.getCompanyWithId (
    //   'companyId=' + this.state.selectedCompanyId,
    //   res2 => {
    //     this.setState ({
    //       otherCompanyName: res2.responseObject.companyName,
    //     });
    //   }
    // );

    // RegionManagementService.getUserDetails("pan="+this.props.personnelData.panNumber+"&mobileNo="+this.props.personnelData.mobileNo+"&emailId="+this.props.personnelData.emailID,(res) => {
    //   console.log("userDetailList::", res.userDetailList)
    //   let nismDt = ""
    //   let euinDt = ""
    //   let nismCertificate = ""
    //   let euin =""
    //   for (let i = 0; i < res.responseListObject.length; i++) {
    //      nismDt = res.responseListObject[i].registrationDate.split(" ");
    //      euinDt = res.responseListObject[i].euinValidity.split(" ");
    //      nismCertificate = res.responseListObject[i].nismCertificateNo;
    //      euin = res.responseListObject[i].euin;
    //   }

    //       let nismDate = nismDt[0].split("/");
    //       let euinDate = euinDt[0].split("/")
    //       this.euinDate2 =
    //       euinDate[2] +
    //       "-" +
    //       euinDate[1] +
    //       "-" +
    //       euinDate[0];

    //       this.nismDate2 =
    //       nismDate[2] +
    //       "-" +
    //       nismDate[1] +
    //       "-" +
    //       nismDate[0];

    // this.setState({ euinNumber: euin });
    // this.setState({ euinValidUpto: this.euinDate2 });
    // this.setState({ certificateNumber: nismCertificate });
    // this.setState({ certificateValidUpto: this.nismDate2  });

    // alert("Sneha"+this.state.certificateNumber)

    //})

    var year = new Date().getFullYear();
    year = year - 18;
    var listOfYear = [];
    for (var i = 0; i < 100; i++) {
      var current = year - i;
      listOfYear.push(current);
    }

    var yearcerti = new Date().getFullYear();
    //yearcerti = yearcerti
    yearcerti = 2010;
    var listOfCertificateYear = [];
    for (var i = 0; i < 100; i++) {
      var current = yearcerti + i;
      listOfCertificateYear.push(current);
    }

    var year1 = new Date().getFullYear();
    year1 = year1;
    var listOfYear2 = [];
    for (var i = 0; i < 100; i++) {
      var current1 = year1 - i;
      listOfYear2.push(current1);
    }
    var listOfMonth = [];
    // for (var i = 1; i <= 12; i++) {
    //   // var current = year - i;
    //   listOfMonth.push(i);

    // }

    var listOfMonth = [
      { key: 1, value: "Jan" },
      { key: 2, value: "Feb" },
      { key: 3, value: "Mar" },
      { key: 4, value: "Apr" },
      { key: 5, value: "May" },
      { key: 6, value: "Jun" },
      { key: 7, value: "Jul" },
      { key: 8, value: "Aug" },
      { key: 9, value: "Sep" },
      { key: 10, value: "Oct" },
      { key: 11, value: "Nov" },
      { key: 12, value: "Dec" },
    ];

    var listOfDay = [];
    for (var i = 1; i <= 31; i++) {
      //    var current = year - i;
      listOfDay.push(i);
    }
    this.setState({ listOfMonth: listOfMonth });
    this.setState({ listOfYear1: listOfYear });
    this.setState({ listOfDay: listOfDay });
    this.setState({ listOfYear2: listOfYear2 });
    this.setState({ certificateYear: listOfCertificateYear });

    //alert()
    this.setState({ loading1: "block" });
    this.setState({ loading: true });
    window.addEventListener("scroll", (e) => onScroll(e), true);

    LookUpService.getOccuption("codeTypeId=101&sortOrder=ASC", (res) => {
      this.setState({
        whoareu: res.responseListObject,
        // countryListForPer: res.responseListObject
      });
      this.setState({ loading: false });
      // this.setState({ loading1: "none" });
      this.setState({ loading1: "none" });
    });

    LookUpService.getWhoareu("codeTypeId=102&sortOrder=ASC", (res) => {
      this.setState({
        occupation: res.responseListObject,

        // countryListForPer: res.responseListObject
      });
      this.setState({ loading: false });
      this.setState({ loading1: "none" });
    });

    LookUpService.getWhoareu("codeTypeId=105&sortOrder=ASC", (res) => {
      this.setState({
        AuualCome: res.responseListObject,
        // countryListForPer: res.responseListObject
      });
      this.setState({ loading: false });
      this.setState({ loading1: "none" });
    });

    LookUpService.getWhoareu("codeTypeId=106&sortOrder=ASC", (res) => {
      this.setState({
        AddressProof: res.responseListObject,
        // countryListForPer: res.responseListObject
      });
      this.setState({ loading: false });
      this.setState({ loading1: "none" });
    });
    LookUpService.getLookingFor("codeTypeId=107&sortOrder=ASC", (res) => {
      this.setState({
        currentProfession: res.responseListObject,
        // countryListForPer: res.responseListObject
      });
      this.setState({ loading: false });
      this.setState({ loading1: "none" });
    });
    getExperienceList((res)=>{
      if(res.status === "success"){
        this.setState({getExperienceList:res.responseListObject})
      }else{
        error(res.reasonText,errorNotification)
      }
    })
    function onScroll(event) {
      if ($("section#create-user").offset() !== undefined) {
        var scrollPos = $("section#create-user").offset().top;
        ////console.log("hiiiiiiiii" + scrollPos)
        $("section.page-section").each(function () {
          var scrollid = $(this).attr("id");
          var scrollPos = $("section#" + scrollid).offset().top;
          if (scrollPos > 100 && scrollPos < 300) {
            ////console.log("Scroll position :" + scrollid + scrollPos)
            $("#topheader .tab li").removeClass("active");
            $("a[href='#" + [scrollid] + "']")
              .parent("li")
              .addClass("active");
          }
        });
      }
      // LookUpService.getWhoareu(
      //     "codeTypeId=102",
      //     res => {

      //       this.setState({
      //         whoareu: res.responseListObject,
      //        // countryListForPer: res.responseListObject
      //       });
      //     }
      //   );
      // $('#topheader .tab a').each(function () {
      //     var currLink = $(this);
      //     var refElement = $(currLink.attr("href"));
      //     if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
      //         $('#topheader .tab li a').removeClass("active"); //added to remove active class from all a elements
      //         currLink.addClass("active");
      //     }
      //     else{
      //         currLink.removeClass("active");
      //     }
      // });
    }

    //qulification year dropdown bind start  here
    var year = new Date().getFullYear();

    var listOfYear = [];
    for (var i = 0; i < 100; i++) {
      var current = year - i;
      listOfYear.push(current);
      this.setState({ listOfYear: listOfYear });
    }
    //qulification year dropdown bind ends  here

    LoginService.TokenUser((res) => {
      localStorage.setItem("jwt-token", res.responseObject);
    });

    RegionManagementService.getCountry((res) => {
      this.setState({
        countryList: res.responseListObject,
      });
      RegionManagementService.getState(
        "countryId=1",
        //+ this.state.corcountryId,
        (res) => {
          this.setState({
            stateListForCor: res.responseListObject,
            preferredExamLocationList: res.responseListObject,
          });
          this.setState({ loading: false });
          this.setState({ loading1: "none" });
        }
      );
      // RegionManagementService.getCity(
      //   "stateId=" + this.state.corstateId,
      //   (res) => {
      //     this.setState({
      //       cityListForCor: res.responseListObject,
      //     });
      //     this.setState({ loading: false });
      //     this.setState({ loading1: "none" });
      //   }
      // );
      // RegionManagementService.getCity(
      //   "stateId=" + this.state.corstateId,
      //   (res) => {
      //     this.setState({
      //       cityListForCor: res.responseListObject,
      //     });
      //     this.setState({ loading: false });
      //     this.setState({ loading1: "none" });
      //   }
      // );
      RegionManagementService.getState(
        "countryId=1",
        // + this.state.percountryId,
        (res) => {
          this.setState({
            stateListForPer: res.responseListObject,
          });
          this.setState({ loading: false });
          this.setState({ loading1: "none" });
        }
      );
      RegionManagementService.getCity(
        "stateId=" + this.state.perstateId,
        (res) => {
          this.setState({
            cityListForPer: res.responseListObject,
          });
          this.setState({ loading: false });
          this.setState({ loading1: "none" });
        }
      );
    });
    RegisterUserService.getEmployeType((res) => {
      this.setState({
        employeTypeForParents: res.responseListObject,
      });
      this.setState({ loading: false });
      this.setState({ loading1: "none" });
    });
    fetchProcessType(
      "learnerPartyID=" + localStorage.getItem("userid-Token"),
      (res) => {
        if(res.status==="success"){
          this.setState({
            processType: res.responseObject.lookingFor,
            newLearnerDetails: res.responseObject,
          });
          this.setState({ loading: false });
          this.setState({ loading1: "none" });
        }
      }
    );

    getAMCMaster((res) => {
      this.setState({
        nameOfMutual: res.responseListObject,
        // res.responseObject.learnerType
      });
      this.setState({ loading: false });
      this.setState({ loading1: "none" });
    });

    RegionManagementService.getLearnerCompany(
      "learnerID=" + this.props.personnelData.learnerID,
      (companyresponse) => {
        this.setState({
          orgCompanyName: companyresponse.responseObject.companyName,
        });
        if (
          this.state.orgCompanyName === "Other" ||
          this.state.orgCompanyName == "OTHER" ||
          this.state.orgCompanyName === "other"
        ) {
          this.props.personnelData.arn = "";
        } else {
          this.props.personnelData.arn = this.props.personnelData.arn;
        }
      }
    );

    RegionManagementService.learnerMandateDetails(
      "learnerID=" +
        this.props.personnelData.learnerID +
        "&learnerPartyID=" +
        this.props.personnelData.learnerPartyID +
        "&mandateUserId=" +
        "&learnerMandateId=",
      (resM) => {
        let mandateId = "";
        let mandateUserId = "";
        let assignedUserMandateName = "";
        if (resM.status === "success") {
          for (let i = 0; i < resM.responseListObject.length; i++) {
            mandateUserId = resM.responseListObject[i].mandateUserId;
            mandateId = resM.responseListObject[i].mandateId;
          }
          this.setState({ mandateId: mandateId });
          this.setState({ mandateUserId: mandateUserId });
          if (
            mandateId !== "" ||
            mandateId !== null ||
            mandateId !== undefined
          ) {
            RegionManagementService.getUserMandateById(
              "mandateId=" + mandateId,
              (mandateres) => {
                if (mandateres.status === "success") {
                  assignedUserMandateName =
                    mandateres.responseObject.mandateName;
                  this.setState({
                    assignedUserMandateName: assignedUserMandateName,
                  });

                  //alert("asdghj::: " + this.state.assignedUserMandateName)
                }
              }
            );
          }
        }
      }
    );

    RegionManagementService.getUserId(
      "searchText=" + this.props.personnelData.emailID,
      (userResponse) => {
        let userId = "";
        for (let i = 0; i < userResponse.responseListObject.length; i++) {
          userId = userResponse.responseListObject[i].userId;
        }
        this.setState({ userId: userId.toString() });
        if (
          this.props.personnelData.learnerEuinOption ===
            // "Fresh EUIN"
            "EUIN Renewal and mapping" ||
          this.props.personnelData.learnerEuinOption === "Only Mapping"
        ) {
          window.$("#opt12").modal("show");
        }
      }
    );
    console.log(
      "personnelData.learnerEuinOption",
      this.props.personnelData.learnerEuinOption
    );
    // this.getMandateName();


  },100)}

  onChangeParameter(selected, setFieldValue) {
    // console.log ('selected::', selected.value);

    setFieldValue("companyId", selected.value);
    this.setState({ companyNameOther: selected.label });
  }

  getWords(monthCount) {
    function getPlural(number, word) {
      return (number === 1 && word.one) || word.other;
    }

    var months = { one: "month", other: "months" },
      years = { one: "year", other: "years" },
      m = monthCount % 12,
      y = Math.floor(monthCount / 12),
      result = [];

    // y && result.push(y + ' ' + getPlural(y, years));
    m && result.push(m);
    return result;
  }
  diff_years(date, toDate) {
    //  var diff =(dt2.getTime() - dt1.getTime()) / 1000;
    //   diff /= (60 * 60 * 24);

    //  return Math.abs(Math.round(diff/365.25));

    // var date='2020-11-01'
    date = date.split("-");
    toDate = toDate.split("-");
    var today = new Date(toDate[0], toDate[1], toDate[2]);
    var year = today.getFullYear();
    var month = today.getMonth();
    var day = today.getDate();
    var yy = parseInt(date[0]);
    var mm = parseInt(date[1]);
    var dd = parseInt(date[2]);
    var years, months, days;
    // months
    months = month - mm;
    if (day < dd) {
      months = months - 1;
    }
    // years
    years = year - yy;
    if (month * 100 + day < mm * 100 + dd) {
      years = years - 1;
      months = months + 12;
    }
    // days
    days = Math.floor(
      (today.getTime() - new Date(yy + years, mm + months - 1, dd).getTime()) /
        (24 * 60 * 60 * 1000)
    );
    console.log(years, months);
    return years + "." + months;
  }
  diff_months(dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60 * 24 * 7 * 4;
    return Math.abs(Math.round(diff));
  }

  checkName(name) {
    name = name.split(".");
    var regexp1 = new RegExp("^[0-9A-Za-z- ]+$");

    //    var url = "http://www.website.com/page.php?data=" + document.getElementById("url").value;

    if (!regexp1.test(name[0])) {
      //  alert("Only numbers, letters, hypens, periods, spaces and underscores ");
      return false;
    } else {
      return true;
    }
  }
  removeByAttr = (arr, attr, value) => {
    var i = arr.length;
    while (i--) {
      if (arr[i] && arr[i][attr] === value) {
        arr.splice(i, 1);
      }
    }
  };
  WhyTheFillForm = () => {
    //  this.setState({ linkModalloading :true, showWhyTheFillForm: true });

    window.$("#roadmap1").modal("show");
  };

  onWhyTheFillFormCloseModal = () => {
    this.setState({ linkModalloading: false, showWhyTheFillForm: false });
  };

  isValidDate = (fromDate, toDate) => {
    if (fromDate !== "") {
      let fromDate1 = fromDate.split("-");
      if (fromDate1[1] == 2 && fromDate1[2] > 29) {
        warning("Please select valid Date", warningNotification);
        return false;
      }
      // else if(((fromDate1[0] % 4==0) && (fromDate1[0] % 100!=0) || (fromDate1[0]==0 ))&& (fromDate1[2] > 28)) )
      else if (
        ! (
           (fromDate1[0] % 4 == 0 && fromDate1[0] % 100 != 0) ||
           fromDate1[0] % 400 == 0
         )&&fromDate1[1] == 2 &&
         fromDate1[2] > 28
       ) {
        warning("Please select valid Date", warningNotification);
        return ;
      } else if (
        fromDate1[1] == 4 ||
        fromDate1[1] == 6 ||
        fromDate1[1] == 9 ||
        fromDate1[1] == 11
      ) {
        if (fromDate1[2] > 30) {
          warning("Please select valid Date", warningNotification);
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }

    if (toDate !== "") {
      let toDate1 = fromDate.split("-");
      if (toDate1[1] == 2 && toDate1[2] > 29) {
        warning("Please select valid Date", warningNotification);
        return false;
      } else if (
        !(
          (toDate1[0] % 4 == 0 && toDate1[0] % 100 != 0) ||
          toDate1[0] % 400 == 0
        ) &&
        toDate1[2] > 28
      ) {
        warning("Please select valid Date", warningNotification);
        return ;
      } else if (
        toDate1[1] == 4 ||
        toDate1[1] == 6 ||
        toDate1[1] == 9 ||
        toDate1[1] == 11
      ) {
        if (toDate1[2] > 30) {
          warning("Please select valid Date", warningNotification);
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  };

  // calculateExp = (fromYear, fromMonth, fromDay, toYear, toMonth, toDay) => {
  //   let fromDate1 = "";
  //   if (
  //     fromYear !== undefined &&
  //     fromYear !== "" &&
  //     fromYear !== "0" &&
  //     fromMonth !== undefined &&
  //     fromMonth !== "" &&
  //     fromMonth !== "0" &&
  //     fromDay !== undefined &&
  //     fromDay !== "" &&
  //     fromDay !== "0"
  //   ) {
  //     fromDate1 = fromYear + "-" + fromMonth + "-" + fromDay;
  //     let flag = this.isValidDate(fromDate1, "");
  //   } else {
  //     fromDate1 = "";
  //   }

  //   let toDate1 = "";
  //   if (
  //     toYear !== undefined &&
  //     toYear !== "" &&
  //     toYear !== "0" &&
  //     toMonth !== undefined &&
  //     toMonth !== "" &&
  //     toMonth !== "0" &&
  //     toDay !== undefined &&
  //     toDay !== "" &&
  //     toDay !== "0"
  //   ) {
  //     toDate1 = toYear + "-" + toMonth + "-" + toDay;
  //     let flag = this.isValidDate("", toDate1);
  //   } else {
  //     toDate1 = "";
  //   }

  //   if (fromDate1 != "" && toDate1 != "") {
  //     if (fromDate1 > toDate1) {
  //       // warning("To Date should be greater than from date ",warningNotification)
  //       return false;
  //     } else {
  //       let yearsDiff = this.diff_years(fromDate1, toDate1);
  //       this.updateCommonWeightage("professionalQualificationfromDate", fromDate1);
  //       this.updateCommonWeightage("professionalQualificationtoDate", toDate1);
  //       this.updateCommonWeightage(
  //         "professionalQualificationannualIncome",
  //         yearsDiff
  //       );

  //       return yearsDiff;
  //     }
  //     //alert(yearsDiff)
  //   }

  //   // alert(yearsDiff)
  //   // if(yearsDiff=="NaN.NaN"){
  //   // setFieldValue(
  //   // (values.professionalQualification[index].totalExperience = 0.0)
  //   // );
  //   // }
  //   //  }
  // };

 




  updateCommonWeightage=async (key,value,values,section)=>{
    let userProfileCompletedData={
      empNo:values?.empNo,
      panNumber: values?.panNumber,
      mobileNo: values?.mobileNo,
      firstName: values?.firstName,
      lastName: values?.lastName,
      middleName: values?.middleName,
      fatherName: values?.fatherName,
      dob: values?.dob,
      gender: values?.gender,
      emailID: values?.emailID,
      ARNNO:values.ARNNO,
      orgCompanyName:this.props?.personnelData?.companyName,
      profilePhotoURL: values?.profilePhotoURL,
    }
    let userProfileAddressData = {
    
    };
    let EducationalDetails={
      learnerQualificationpassingYear: (
        values?.learnerQualification?.[0] || ""
      )?.passingYear,
      learnerQualificationqualificationType: (
        values?.learnerQualification?.[0] || ""
      )?.qualificationType,
      learnerQualificationinstituteName: (
        values?.learnerQualification?.[0] || ""
      )?.instituteName,
    }
    let NISMandEnrollDetails={}
    let EmploymentDetails={}
    let EUINDetails={}
    if((this.state?.newLearnerDetails||this.props||this.props.personnelData)?.learnerEuinOption==="Fresh EUIN"
    ||(this.state?.newLearnerDetails||this.props||this.props.personnelData)?.learnerEuinOption==="Only EUIN Renewal"){
      if((this.state?.newLearnerDetails||this.props||this.props.personnelData)?.learnerEuinOption==="Only EUIN Renewal"){
        userProfileCompletedData={
          ...userProfileCompletedData,   
          panCardURL: values?.panCardURL,
        };
        EducationalDetails={
          ...EducationalDetails,
          learnerQualificationpercentage:(
            values?.learnerQualification?.[0] || ""
          )?.percentage,
        }
        const occupationValue =  (values?.professionalQualification?.[0] || "")?.occupation;

        const isStudent = [1021, "1021"].includes(occupationValue)?true:false
       if(isStudent){
         EmploymentDetails={
          ...EmploymentDetails,
          professionalQualificationoccupation: (
            values?.professionalQualification?.[0] || ""
          )?.occupation,
        }
      }
         else{
          EmploymentDetails={
          ...EmploymentDetails,
          professionalQualificationoccupation: (
            values?.professionalQualification?.[0] || ""
          )?.occupation,
          professionalQualificationcompanyName: (
            values?.professionalQualification?.[0] || ""
          )?.companyName,
          professionalQualificationdesignation: (
            values?.professionalQualification?.[0] || ""
          )?.designation,
          professionalQualificationtotalExperience: (
            values?.professionalQualification?.[0] || ""
          )?.totalExperience,
        }
         }
      }
      let isPersonalDetailsDisabled=(this.state?.newLearnerDetails||this.props||this.props.personnelData)?.learnerEuinOption==="Fresh EUIN"||(this.state?.newLearnerDetails||this.props||this.props.personnelData)?.learnerEuinOption ==="Only EUIN Renewal" ||values.disabledForCielops? true:false
      if ((isPersonalDetailsDisabled&&values?.salutation)||!isPersonalDetailsDisabled) {
       userProfileCompletedData={
         ...userProfileCompletedData,   
         salutation:values?.salutation,
       };
     }
     if ((isPersonalDetailsDisabled&&values?.maritalStatus)||!isPersonalDetailsDisabled) {
       userProfileCompletedData={
         ...userProfileCompletedData,   
         maritalStatus:values?.maritalStatus,
       };
     }
     if ((isPersonalDetailsDisabled&&values?.secondaryEmailID)||!isPersonalDetailsDisabled) {
        userProfileCompletedData={
         ...userProfileCompletedData,   
         secondaryEmailID:values?.secondaryEmailID,
       };
     }
     let isAddressHide=(this.state?.newLearnerDetails||this.props||this.props.personnelData)?.learnerEuinOption==="Fresh EUIN"||(this.state?.newLearnerDetails||this.props||this.props.personnelData)?.learnerEuinOption==="Only EUIN Renewal"?true:false
     if ((isAddressHide&&values?.perStreet)||!isAddressHide) {
       userProfileAddressData={
        ...userProfileAddressData,   
        perStreet:values?.perStreet,
      };
    }   
    if ((isAddressHide&&values?.perStreet2)||!isAddressHide) {
       userProfileAddressData={
        ...userProfileAddressData,   
        perStreet2:values?.perStreet2,
      };
    }   
      
    if ((isAddressHide&&values?.perStreet3)||!isAddressHide) {
       userProfileAddressData={
        ...userProfileAddressData,   
        perStreet3:values?.perStreet3,
      };
    }   
    if((this.state?.newLearnerDetails||this.props||this.props.personnelData)?.learnerEuinOption==="Fresh EUIN"){ 
    if ((isAddressHide&&values?.percityId)||!isAddressHide) {
       userProfileAddressData={
        ...userProfileAddressData,   
        percityId:values?.percityId,
      };
    }   
      
    if ((isAddressHide&&values?.perstateId)||!isAddressHide) {
       userProfileAddressData={
        ...userProfileAddressData,   
        perstateId:values?.perstateId,
      };
    }   
      
    if ((isAddressHide&&values?.percountryId)||!isAddressHide) {
       userProfileAddressData={
        ...userProfileAddressData,   
        percountryId:values?.percountryId,
      };
    }   
  }
        
    if ((isAddressHide&&values?.perPostalCode)||!isAddressHide) {
      userProfileAddressData={
        ...userProfileAddressData,   
        perPostalCode:values?.perPostalCode,
      };
    
    }  
        
    if ((isAddressHide&&values?.addressTypeMark)||!isAddressHide) {
       userProfileAddressData={
        ...userProfileAddressData,   
        addressTypeMark:values?.addressTypeMark,
      };
    }   
    let CertificateValidUptoDisabled=values.disabledForCielops === true ? false : (this.state.certificateValidUpto === "" || this.state.certificateValidUpto === undefined ? false : true)
    let EnrollmentDateDisabled =values.disabledForCielops === true ? false : (this.state.enrollmentDate === "" || this.state.enrollmentDate === undefined ? false || (this.state?.newLearnerDetails||this.props||this.props.personnelData)?.learnerEuinOption ==="Fresh EUIN" ||(this.state?.newLearnerDetails||this.props||this.props.personnelData)?.learnerEuinOption === "Only EUIN Renewal": true)
    let EnrollmentNumberDisabled =values.disabledForCielops === true ? false : (this.state.enrollmentNumber === "" || this.state.enrollmentNumber === undefined ? false ||  (this.state?.newLearnerDetails||this.props||this.props.personnelData)?.learnerEuinOption ==="Fresh EUIN" ||(this.state?.newLearnerDetails||this.props||this.props.personnelData)?.learnerEuinOption === "Only EUIN Renewal" : true)
    let CertificateNumberDisabled =values.disabledForCielops === true ? false : (this.state.certificateNumber === "" || this.state.certificateNumber === undefined ? false : true)
   if ((CertificateValidUptoDisabled&&values?.certificateValidUpto)||!CertificateValidUptoDisabled) {
      NISMandEnrollDetails = {
        ...NISMandEnrollDetails,
        certificateValidUpto: values.certificateValidUpto,
      };
    }
    if ((CertificateNumberDisabled&&values?.certificateNumber)||!CertificateNumberDisabled) {
      NISMandEnrollDetails = {
        ...NISMandEnrollDetails,
        nismRegistrationNo: values.certificateNumber,
      };
    }
    if ((EnrollmentNumberDisabled&&values?.enrollmentNumber)||!EnrollmentNumberDisabled) {
      NISMandEnrollDetails = {
        ...NISMandEnrollDetails,
        enrollmentNumber: values.enrollmentNumber,
      };
    }
    if ((EnrollmentDateDisabled&&values?.enrollmentDate)||!EnrollmentDateDisabled) {
      NISMandEnrollDetails = {
        ...NISMandEnrollDetails,
        enrollmentDate: values.enrollmentDate,
      };
    }
    let isEUINDisabled= values.disabledForCielops === true? false:this.state.euinNumber ===""||this.state.euinNumber ===undefined? false:true
    if ((isEUINDisabled&&values?.euinNumber)||!isEUINDisabled) {
        if(["Only EUIN Renewal"].includes((this.state?.newLearnerDetails||this.props||this.props.personnelData)?.learnerEuinOption)){
        EUINDetails = {
          ...EUINDetails,
          euinNumber: values.euinNumber,
        };
      
    }
      }   
    }
    if(!((this.state?.newLearnerDetails||this.props||this.props.personnelData)?.learnerEuinOption==="Fresh EUIN"
    ||(this.state?.newLearnerDetails||this.props||this.props.personnelData)?.learnerEuinOption==="Only EUIN Renewal")){
      userProfileCompletedData={
        ...userProfileCompletedData,
        salutation:values?.salutation,
        maritalStatus:values?.maritalStatus,
        secondaryEmailID:values?.secondaryEmailID,
      }
      userProfileAddressData = {
        ...userProfileAddressData,
      perStreet:  values.perStreet? values.perStreet : '',
      perStreet2:  values.perStreet2? values.perStreet2 : '',
      perStreet3:  values.perStreet3? values.perStreet3 : '',
      percityId:  values.percityId? values.percityId : '',
      perstateId:  values.perstateId? values.perstateId : '',
      percountryId:  values.percountryId? values.percountryId : '',
      perPostalCode:  values.perPostalCode? values.perPostalCode : '',
      addressTypeMark:values.addressTypeMark?values.addressTypeMark:'',
      addressProofURL:values?.addressProofCardURL,
      peraddressproof1: values?.peraddressproof1,
     }  
      EducationalDetails={
        ...EducationalDetails,
        learnerQualificationpercentage:(
          values?.learnerQualification&&values?.learnerQualification?.[0] || ""
        )?.percentage,
      }
      const occupationValue =  (values?.professionalQualification?.[0] || "")?.occupation;

      const isStudent = [1021, "1021"].includes(occupationValue)?true:false
     if(isStudent){
       EmploymentDetails={
        ...EmploymentDetails,
        professionalQualificationoccupation: (
          values?.professionalQualification?.[0] || ""
        )?.occupation,
      }
    }
       else{
        EmploymentDetails={
        ...EmploymentDetails,
        professionalQualificationoccupation: (
          values?.professionalQualification?.[0] || ""
        )?.occupation,
        professionalQualificationcompanyName: (
          values?.professionalQualification?.[0] || ""
        )?.companyName,
        professionalQualificationdesignation: (
          values?.professionalQualification?.[0] || ""
        )?.designation,
        professionalQualificationtotalExperience: (
          values?.professionalQualification?.[0] || ""
        )?.totalExperience,
      }
       }
      userProfileCompletedData={
        ...userProfileCompletedData,
        panCardURL: values.panCardURL,
      }
      if(["Only Mapping","EUIN Renewal and mapping"].includes((this.state?.newLearnerDetails||this.props||this.props.personnelData)?.learnerEuinOption)){
      let CertificateValidUptoDisabled=values.disabledForCielops === true ? false : (this.state.certificateValidUpto === "" || this.state.certificateValidUpto === undefined ? false : true)
      let EnrollmentDateDisabled =values.disabledForCielops === true ? false : (this.state.enrollmentDate === "" || this.state.enrollmentDate === undefined ? false || (this.state?.newLearnerDetails||this.props||this.props.personnelData)?.learnerEuinOption ==="Fresh EUIN" ||(this.state?.newLearnerDetails||this.props||this.props.personnelData)?.learnerEuinOption === "Only EUIN Renewal": true)
      let EnrollmentNumberDisabled =values.disabledForCielops === true ? false : (this.state.enrollmentNumber === "" || this.state.enrollmentNumber === undefined ? false ||  (this.state?.newLearnerDetails||this.props||this.props.personnelData)?.learnerEuinOption ==="Fresh EUIN" ||(this.state?.newLearnerDetails||this.props||this.props.personnelData)?.learnerEuinOption === "Only EUIN Renewal" : true)
      let CertificateNumberDisabled =values.disabledForCielops === true ? false : (this.state.certificateNumber === "" || this.state.certificateNumber === undefined ? false : true)
    
      if ((CertificateValidUptoDisabled&&values?.certificateValidUpto)||!CertificateValidUptoDisabled) {
        NISMandEnrollDetails = {
          ...NISMandEnrollDetails,
          certificateValidUpto: values.certificateValidUpto,
        };
      }
      if ((CertificateNumberDisabled&&values?.certificateNumber)||!CertificateNumberDisabled) {
        NISMandEnrollDetails = {
          ...NISMandEnrollDetails,
          nismRegistrationNo: values.certificateNumber,
        };
      }
      if ((EnrollmentNumberDisabled&&values?.enrollmentNumber)||!EnrollmentNumberDisabled) {
        NISMandEnrollDetails = {
          ...NISMandEnrollDetails,
          enrollmentNumber: values.enrollmentNumber,
        };
      }
      if ((EnrollmentDateDisabled&&values?.enrollmentDate)||!EnrollmentDateDisabled) {
        NISMandEnrollDetails = {
          ...NISMandEnrollDetails,
          enrollmentDate: values.enrollmentDate,
        };
      }  
      let isEUINDisabled= values.disabledForCielops === true? false:this.state.euinNumber ===""||this.state.euinNumber ===undefined? false:true
      if ((isEUINDisabled&&values?.euinNumber)||!isEUINDisabled) {
                EUINDetails = {
          ...EUINDetails,
          euinNumber: values.euinNumber,
        };
      }
      }
    }
    if(values.preferredMandateName === "NISM" || ["", null].includes(values.learnerEuinOption)) {
      userProfileCompletedData = {
        ...userProfileCompletedData,
        preferredExamLocation: values.preferredExamLocation,
        preferredExamCenter: values.preferredExamCenter,
      }
    }
    var obj1 = userProfileCompletedData;
    var keys1 = await Object?.keys(obj1);
    var filtered1 =await keys1?.filter(function (key) {
      var value = obj1[key]||obj1.key;
      return value !== null && value !== undefined && value !== "" && !(Array.isArray(value) && value.length === 0);
    });
    var obj2 = userProfileAddressData;
    var keys2 =await Object?.keys(obj2);
    var filtered2 = await keys2?.filter(function (key) {
      var value = obj2[key]||obj2.key;
      return value !== null && value !== undefined && value !== "" && !(Array.isArray(value) && value.length === 0);
    });
    var obj3 = EducationalDetails;
    var keys3 = await Object?.keys(obj3);
    var filtered3 = await keys3?.filter(function (key) {
      var value=obj3[key]||obj3.key;
      return value !== null && value !== undefined && value !== "" && !(Array.isArray(value) && value.length === 0);
    });
    var obj4 = EmploymentDetails;
    var keys4 = await Object?.keys(obj4);
    var filtered4 = await keys4?.filter(function (key) {
      var value = obj4[key]||obj4.key;
      return value !== null && value !== undefined && value !== "" && !(Array.isArray(value) && value.length === 0);
    });
    var obj5 = NISMandEnrollDetails;
    var keys5 = await Object?.keys(obj5);
    var filtered5 = await keys5?.filter(function (key) {
      var value = obj5[key]||obj5.key;
      return value !== null && value !== undefined && value !== "" && !(Array.isArray(value) && value.length === 0);
    });
    var obj6 = EUINDetails;
    var keys6 = Object.keys(obj6);
    var filtered6 = keys6?.filter(function (key) {
      var value = obj6[key]||obj6.key;
      return value !== null && value !== undefined && value !== "" && !(Array.isArray(value) && value.length === 0);
    });
  let numer,denom;
  let personalN=await filtered1?Object.keys(filtered1)?.length:0;
  let addressN=await filtered2?Object.keys(filtered2)?.length:0;
  let educationN=await filtered3?Object.keys(filtered3)?.length:0;
  let employmentN=await filtered4?Object.keys(filtered4)?.length:0;
  let NISMandEnrollN=await filtered5?Object.keys(filtered5)?.length:0;
  let EUINDetailsN=await filtered6?Object.keys(filtered6)?.length:0;
  numer=personalN+addressN+educationN+employmentN+NISMandEnrollN+EUINDetailsN;
  let personalD,addressD,educationD,employmentD,NISMandEnrollD,EUINDetailsD;
  if(
    this.props?.learnerEuinOption==="Fresh EUIN"
    ||this.props?.learnerEuinOption==="Only EUIN Renewal"){
       personalD=await obj1?Object.keys(obj1)?.length:16;
       addressD=await obj2?Object.keys(obj2)?.length:8;
       educationD=await obj3?Object.keys(obj3)?.length:3;
       employmentD=await obj4?Object.keys(obj4)?.length:0;
       NISMandEnrollD=await obj5?Object.keys(obj5)?.length:4;
       EUINDetailsD=obj6?Object.keys(obj6)?.length:0
    }
    else{
       personalD=await obj1?Object.keys(obj1)?.length:17;
       addressD=await obj2?Object.keys(obj2)?.length:10;
       educationD=await obj3?Object.keys(obj3)?.length:4;
       employmentD=await obj4?Object.keys(obj4)?.length:4;
       NISMandEnrollD=await obj5?Object.keys(obj5)?.length:0;
       EUINDetailsD=obj6?Object.keys(obj6)?.length:0
    }
  denom=personalD+addressD+educationD+employmentD+NISMandEnrollD+EUINDetailsD;
  let combinedper = Math.round((numer / denom) * 100);
    console.log("vkd-this.props",this.props.personnelData,this.props.addressData);
    console.log("vkd-personal",key,userProfileCompletedData,obj1,keys1);
    console.log("vkd-address",key,userProfileAddressData,obj2,keys2);
    console.log("vkd-eduction",key,EducationalDetails,obj3,keys3);
    console.log("vkd-employment",key,EmploymentDetails,obj4,keys4);
    console.log("vkd-nism",key,NISMandEnrollDetails,obj5,keys5);
    console.log("vkd euin",key,EUINDetails,obj6,keys6)
    console.log("vkd-numerator",key,personalN,addressN,educationN,employmentN,NISMandEnrollN,EUINDetailsN,numer);
    console.log("vkd-denominator",key,personalD,addressD,educationD,employmentD,NISMandEnrollD,EUINDetailsD,denom);
    console.log("vkd-percentage", key,combinedper);
    let profileComplitionPercent=["",null,undefined,NaN,"NaN"]?.includes(combinedper)?0:combinedper>100?100:combinedper;
  
    await localStorage.setItem("ProfilePercent", profileComplitionPercent); 
  
    await localStorage.setItem("profilecomlitionvalue",profileComplitionPercent); 
    // return null;  
}


  // //same as address
  // assignPerAddressSameAsCores = (event) => {
  //   if (event.target.checked) {
  //     this.setState({
  //       perStreet: this.state.corStreet,
  //       perStreet2: this.state.corStreet2,
  //       perStreet3: this.state.corStreet3,
  //       perPostalCode: this.state.corPostalCode,
  //       percityId: this.state.corcityId,
  //       perstateId: this.state.corstateId,
  //       percountryId: this.state.corcountryId,
  //       checkAddressFlag: "checked",
  //     });
  //   } else {
  //     this.setState({
  //       perStreet: "",
  //       perStreet2: "",
  //       perStreet3: "",
  //       perPostalCode: "",
  //       percityId: "",
  //       perstateId: "",
  //       percountryId: "",
  //       checkAddressFlag: "",
  //     });
  //   }
  // };
  handleClose = () =>{
    window.$('#Hdfc-Mandate').modal('hide')
  }

  render() {
    console.log("asdf",this.state);
    console.log("propssss",this.props);

    // alert(this.state.dob.split("-")[2])
    let title =
      "1.Fresher: In case you are a Fresher, then the rest of the fields in the row will be disabled. 2.Employed: Mention name of the current company. 3.Self Employed: Mention legal entity name under which you are conducting business.In case no name registered, mention “Self”. 4.Not Employed: Mention name of the last company you were employed";
    var roledata = this.props.userRoleAccess;

    let dob_2_validate_message = "";
    let dob_3_validate_message = "";

    return (
      <Formik
        enableReinitialize={true}
        //   initialValues={{
        //     learnerPartyID: localStorage.getItem("userid-Token"), //4919,
        //     learnerID: this.props.personnelData.learnerID,

        //     peraddressproof1:this.props.personnelData.proofOfAddress,

        //     learnerQualification: this.props.personnelData.learnerQualification,
        //     mobileNo: this.props.personnelData.mobileNo,
        //     emailID: this.props.personnelData.emailID,
        //     gender: this.props.personnelData.gender,
        //     secondaryEmailID: this.props.personnelData.secondaryEmailID,
        //     dob: this.state.dob,
        //     firstName: this.props.personnelData.firstName,
        //     middleName: this.props.personnelData.middleName,
        //     lastName: this.props.personnelData.lastName,
        //     contactNo: this.props.personnelData.contactNo,
        //     panNumber: this.props.personnelData.panNumber,
        //     aadharNumber: this.props.personnelData.aadharNumber,
        //     perAddressType: this.state.perAddressType,
        //     perAddressID: this.state.perAddressID,
        //     perStreet: this.state.perStreet,
        //     perStreet2: this.state.perStreet2,
        //     perStreet3: this.state.perStreet3,
        //     perPostalCode: this.state.perPostalCode,
        //    // percityId: this.state.percityId,
        //  //   perstateId: this.state.perstateId,
        //    percityId: this.props.addressData[1].cityId,
        //      perstateId: this.props.addressData[1].stateId,
        //     percountryId: 1,
        //     //this.state.percountryId,
        //     corAddressType: this.state.corAddressType,
        //     corAddressID: this.state.corAddressID,
        //     corStreet: this.state.corStreet,
        //     corStreet2: this.state.corStreet2,
        //     corStreet3: this.state.corStreet3,
        //     corPostalCode: this.state.corPostalCode,
        //     corcityId: this.props.addressData[0].cityId,
        //     corstateId: this.props.addressData[0].stateId,
        //     corcountryId:1,
        //     // this.state.corcountryId,
        //     resumeDocumentURL: this.props.personnelData.resumeDocumentURL,
        //     resumeDocumentNameDuringEdit: this.props.personnelData.resumeDocumentURL?
        //      ( this.props.personnelData.resumeDocumentURL || ""
        //     ).substring(
        //       (this.props.personnelData.resumeDocumentURL || "").lastIndexOf(
        //         "/"
        //       ) + 1,
        //       (this.props.personnelData.resumeDocumentURL || "").length
        //     ):'',

        //     resumeDocumentNameDuringEditName:this.props.personnelData.resumeDocumentURL? (
        //       this.props.personnelData.resumeDocumentURL.split("?")[0]).substring(
        //         (this.props.personnelData.resumeDocumentURL || "").lastIndexOf(
        //           "/"
        //         ) + 1).replaceAll("%20"," ").replaceAll("%28","(").replaceAll("%29",")"):'',
        //     resumeVideoURL: this.props.personnelData.resumeVideoURL,
        //     profilePhotoURL: this.props.personnelData.profilePhotoURL,
        //     aadharCardURL: this.props.personnelData.aadharCardURL,
        //     BackaadharCardBackURL:this.props.personnelData.aadharCardBackURL,
        //     panCardURL: this.props.personnelData.panCardURL,

        //     panpdfName:this.props.personnelData.panCardURL? (
        //       this.props.personnelData.panCardURL.split("?")[0]).substring(
        //         (this.props.personnelData.panCardURL || "").lastIndexOf(
        //           "/"
        //         ) + 1).replaceAll("%20"," ").replaceAll("%28","(").replaceAll("%29",")"):'',
        //         addressProofName1:this.props.personnelData.proofOfAddressURL? (
        //           this.props.personnelData.proofOfAddressURL.split("?")[0]).substring(
        //             (this.props.personnelData.proofOfAddressURL || "").lastIndexOf(
        //               "/"
        //             ) + 1).replaceAll("%20"," ").replaceAll("%28","(").replaceAll("%29",")"):'',
        //         aadharpdfName:this.props.personnelData.aadharCardURL? (
        //           this.props.personnelData.aadharCardURL.split("?")[0]).substring(
        //             (this.props.personnelData.aadharCardURL || "").lastIndexOf(
        //               "/"
        //             ) + 1).replaceAll("%20"," ").replaceAll("%28","(").replaceAll("%29",")"):'',
        //             backaadharpdfName:this.props.personnelData.aadharCardBackURL? (
        //               this.props.personnelData.aadharCardBackURL.split("?")[0]).substring(
        //                 (this.props.personnelData.aadharCardBackURL || "").lastIndexOf(
        //                   "/"
        //                 ) + 1).replaceAll("%20"," ").replaceAll("%28","(").replaceAll("%29",")"):'',

        //                 aadharCardBackURL:this.props.personnelData.aadharCardBackURL,
        //     learnerFatherName: this.props.personnelData.learnerFatherName,
        //     learnerFatherDOB: this.state.learnerFatherDOB,
        //     learnerFatherEmployementType: this.props.personnelData
        //       .learnerFatherEmployementType,
        //     learnerFatherCompanyName: this.props.personnelData
        //       .learnerFatherCompanyName,
        //     learnerMotherName: this.props.personnelData.learnerMotherName,
        //     learnerMotherDOB: this.state.learnerMotherDOB,
        //     learnerMotherEmployementType: this.props.personnelData
        //       .learnerMotherEmployementType,
        //     learnerMotherCompanyName: this.props.personnelData
        //       .learnerMotherCompanyName,
        //     checkAddressFlag: this.state.checkAddressFlag,
        //     document: [],
        //     resumeDocumentName: "",
        //     panCardDocumentName: "",
        //     aadharCardDocumentName: "",
        //     documentSizeCheckForProfileCheck: false,
        //     documentTypeCheckForProfileImage: false,
        //     documentTypeCheckForResume: false,
        //     documentSizeCheckForResume: false,
        //     documentTypeCheckForAadharImage: false,
        //     documentSizeCheckForVideoCheck: false,
        //     documentSizeCheckForPanCard: false,
        //     documentSizeCheckForAadharCard: false,
        //     documentTypeCheckForPANImage: false,
        //     documentTypeCheckForVideo: false,
        //     countryList: this.state.countryList,
        //     countryListForCors: this.state.countryListForCors,
        //     stateListForCor: this.state.stateListForCor,
        //     cityListForCor: this.state.cityListForCor,
        //     stateListForPer: this.state.stateListForPer,
        //     cityListForPer: this.state.cityListForPer,
        //     addressFlag: this.state.addressFlag,
        //     downloadResumeAfterSelecting: "",
        //     whoareu: this.props.personnelData.learnerType,
        //     bankName: this.props.personnelData.bankName,
        //     branch: this.props.personnelData.branchName,
        //     branchAddress: this.props.personnelData.branchAddress,
        //     accountType: this.props.personnelData.accountType,
        //     acccountNumber: this.props.personnelData.accountNumber,

        //     ARNNO:this.props.personnelData.arn,
        //     NameOfARNHolder: this.props.personnelData.nameOfARNHolder,

        //     passportNumber: this.props.personnelData.passportNumber,

        //     fatherName: this.props.personnelData.learnerFatherName,
        //     salutation: this.props.personnelData.salutation,

        //     professionalQualification: this.props.personnelData.learnerProfessionalQualification,
        //     ARNuserFeilds: this.props.personnelData.nameOfMutualFundEmpanelled,
        //     CPEDetails: this.props.personnelData.learnerCpeDetails,
        //     addressProofCardURL:this.props.personnelData.proofOfAddressURL,
        //     ifscCode:this.props.personnelData.ifscCode,
        //     //this.props.personnelData.proofOfAddressURL,
        //     //this.props.personnelData.proofOfAddressURL,
        //     submitStatus:'',
        //     profileCompletion: "26",
        //     edudocument:[],
        //     PanValidation:false,
        //     euinNumber: this.props.personnelData.euinNumber,
        //     applicationStatus:this.props.personnelData.applicationStatus,
        //     currentProfession:this.props.personnelData.learnerProfession,
        //     sameasAboveCount:0,
        //     disabledForCielops:localStorage.getItem('roleId')==6 || localStorage.getItem('roleId')==7?true:false,
        //     currProfessionList:this.props.personnelData.learnerType=="1013" ||this.props.personnelData.learnerType=="1015"?currProf: [],
        //     disablecurreProf:this.props.personnelData.learnerType=="1013" ||this.props.personnelData.learnerType=="1015"?false: true,
        //     nameOfCurrProf:this.props.personnelData.learnerType=="1015"?"Last Profession":"Current Profession",
        //     disabledprofDetails:false
        //    // event.target.value=="1013" || event.target.value=="1015"
        //   }}

        initialValues={{
          condition1:  this.state?.newLearnerDetails?.learnerEuinOption ===
          "Fresh EUIN" ||
        this.state?.newLearnerDetails?.learnerEuinOption ===
          "Only EUIN Renewal" ||
          localStorage.getItem("roleId") == 6 ||
          localStorage.getItem("roleId") == 7,
        condition2:this.props.personnelData
        .learnerEuinOption === "Fresh EUIN" ||
      this.props.personnelData
        .learnerEuinOption ===
        "Only EUIN Renewal",

          empNo: this.props.personnelData.empNo
            ? this.props.personnelData.empNo
            : "",
          learnerPartyID: localStorage.getItem("userid-Token"), //4919,
          learnerID: this.props.personnelData.learnerID,
          peraddressproof1: this.props.personnelData.proofOfAddress
            ? this.props.personnelData.proofOfAddress
            : "",

          learnerQualification: this.props.personnelData.learnerQualification,
          mobileNo: this.props.personnelData.mobileNo,
          emailID: this.props.personnelData.emailID,
          gender: this.props.personnelData.gender,
          maritalStatus: this.props.personnelData.maritalStatus,
          secondaryEmailID: this.props.personnelData.secondaryEmailID,
          dob: this.state.dob,
          year: this.state.dob.split("-")[0],
          day: parseInt(this.state.dob.split("-")[2], 10),
          month: parseInt(this.state.dob.split("-")[1], 10),
          firstName: this.props.personnelData.firstName,
          middleName: this.props.personnelData.middleName,
          lastName: this.props.personnelData.lastName,
          contactNo: this.props.personnelData.contactNo,
          panNumber: this.props.personnelData.panNumber,
          aadharNumber: this.props.personnelData.aadharNumber,
          perAddressType: this.state.perAddressType,
          perAddressID: this.state.perAddressID,
          perStreet: this.state.perStreet,
          perStreet2: this.state.perStreet2,
          perStreet3: this.state.perStreet3,
          perPostalCode: this.state.perPostalCode,

          isCertificate: this.props.personnelData.isCertificate,
          certificateNumber: this.state.certificateNumber,
          certificateValidUpto: this.state.certificateValidUpto,
          enrollmentNumber: this.state.enrollmentNumber,
          enrollmentDate: this.state.enrollmentDate,
          nismyear: this.state.certificateValidUpto
            ? this.state.certificateValidUpto.split("-")[0]
            : "",
          nismday: this.state.certificateValidUpto
            ? parseInt(this.state.certificateValidUpto.split("-")[2], 10)
            : "",
          nismmonth: this.state.certificateValidUpto
            ? parseInt(this.state.certificateValidUpto.split("-")[1], 10)
            : "",
          enrollmentyear: this.state.enrollmentDate
            ? this.state.enrollmentDate.split("-")[0]
            : "",
          enrollmentday: this.state.enrollmentDate
            ? parseInt(this.state.enrollmentDate.split("-")[2], 10)
            : "",
          enrollmentmonth: this.state.enrollmentDate
            ? parseInt(this.state.enrollmentDate.split("-")[1], 10)
            : "",
          selectedCompanyId: parseInt(this.props.personnelData.companyName),
          otherCompanyName: this.state.otherCompanyName,
          orgCompanyName: this.state.orgCompanyName,

          euinValidUpto: this.state.euinValidUpto,
          // euinyear:this.state.euinValidUpto.split("-")[0],
          euinyear: this.state.euinValidUpto
            ? this.state.euinValidUpto.split("-")[0]
            : "",
          euinday: this.state.euinValidUpto
            ? parseInt(this.state.euinValidUpto.split("-")[2], 10)
            : "",
          euinmonth: this.state.euinValidUpto
            ? parseInt(this.state.euinValidUpto.split("-")[1], 10)
            : "",

          // percityId: this.state.percityId,
          //   perstateId: this.state.perstateId,
          //  percityId: this.props.addressData[1]?this.props.addressData[1].cityId:'',
          //   perstateId: this.props.addressData[1]?this.props.addressData[1].stateId:'',
          // percityId:
          //   this.state.perStreet === "" || this.state.perStreet === undefined
          //     ? ""
          //     :
          //      this.props.addressData[0].cityId,
          // perstateId:
          //   this.state.perStreet === "" || this.state.perStreet === undefined
          //     ? ""
          //     :
          //      this.props.addressData[0].stateId,
             percityId: [0,"0","",null,undefined].includes(this.props?.addressData[0]?.cityId) ? "" : this.props?.addressData[0]?.cityId,
             perstateId: [0,"0","",null,undefined].includes(this.props?.addressData[0]?.stateId) ? "" : this.props?.addressData[0]?.stateId,
   
          percountryId: 1,
          //this.state.percountryId,
          // corAddressType: this.state.corAddressType,
          // corAddressID: this.state.corAddressID,
          // corStreet: this.state.corStreet,
          // corStreet2: this.state.corStreet2,
          // corStreet3: this.state.corStreet3,
          // corPostalCode: this.state.corPostalCode,
          // corcityId: this.props.addressData[0]
          //   ? this.props.addressData[0].cityId
          //   : "",
          // corstateId: this.props.addressData[0]
          //   ? this.props.addressData[0].stateId
          //   : "",
          // corcountryId: 1,
          // this.state.corcountryId,
          resumeDocumentURL: this.props.personnelData.resumeDocumentURL,
          resumeDocumentNameDuringEdit: this.props.personnelData
            .resumeDocumentURL
            ? (this.props.personnelData.resumeDocumentURL || "").substring(
                (this.props.personnelData.resumeDocumentURL || "").lastIndexOf(
                  "/"
                ) + 1,
                (this.props.personnelData.resumeDocumentURL || "").length
              )
            : "",

          resumeDocumentNameDuringEditName: this.replaceAll(
            this.replaceAll(
              this.replaceAll(
                this.props.personnelData.resumeDocumentURL
                  ? this.props.personnelData.resumeDocumentURL
                      .split("?")[0]
                      .substring(
                        (
                          this.props.personnelData.resumeDocumentURL || ""
                        ).lastIndexOf("/") + 1
                      )
                  : "",
                "%29",
                ""
              ),
              "%28",
              ""
            ),
            "%20",
            ""
          ),
          // this.props.personnelData.resumeDocumentURL? (
          //   this.props.personnelData.resumeDocumentURL.split("?")[0]).substring(
          //     (this.props.personnelData.resumeDocumentURL || "").lastIndexOf(
          //       "/"
          //     ) + 1)
          //     // .replaceAll("%20"," ").replaceAll("%28","(").replaceAll("%29",")")
          //     :'',
          resumeVideoURL: this.props.personnelData.resumeVideoURL,
          profilePhotoURL: this.props.personnelData.profilePhotoURL,
          chequePhotoURL: this.props.personnelData.chequePhotoURL,
          aadharCardURL: this.props.personnelData.aadharCardURL,
          BackaadharCardBackURL: this.props.personnelData.aadharCardBackURL,
          panCardURL: this.props.personnelData.panCardURL,

          panpdfName: this.replaceAll(
            this.replaceAll(
              this.replaceAll(
                this.props.personnelData.panCardURL
                  ? this.props.personnelData.panCardURL
                      .split("?")[0]
                      .substring(
                        (this.props.personnelData.panCardURL || "").lastIndexOf(
                          "/"
                        ) + 1
                      )
                  : "",
                "%29",
                ""
              ),
              "%28",
              ""
            ),
            "%20",
            ""
          ),
          // this.props.personnelData.panCardURL? (
          //   this.props.personnelData.panCardURL.split("?")[0]).substring(
          //     (this.props.personnelData.panCardURL || "").lastIndexOf(
          //       "/"
          //     ) + 1)
          //     // .replaceAll("%20"," ").replaceAll("%28","(").replaceAll("%29",")")
          //     :'',
          addressProofName1: this.replaceAll(
            this.replaceAll(
              this.replaceAll(
                this.props.personnelData.proofOfAddressURL
                  ? this.props.personnelData.proofOfAddressURL
                      .split("?")[0]
                      .substring(
                        (
                          this.props.personnelData.proofOfAddressURL || ""
                        ).lastIndexOf("/") + 1
                      )
                  : "",
                "%29",
                ""
              ),
              "%28",
              ""
            ),
            "%20",
            ""
          ),
          // this.props.personnelData.proofOfAddressURL? (
          //   this.props.personnelData.proofOfAddressURL.split("?")[0]).substring(
          //     (this.props.personnelData.proofOfAddressURL || "").lastIndexOf(
          //       "/"
          //     ) + 1)
          //     // .replaceAll("%20"," ").replaceAll("%28","(").replaceAll("%29",")")
          //     :'',
          aadharpdfName: this.replaceAll(
            this.replaceAll(
              this.replaceAll(
                this.props.personnelData.aadharCardURL
                  ? this.props.personnelData.aadharCardURL
                      .split("?")[0]
                      .substring(
                        (
                          this.props.personnelData.aadharCardURL || ""
                        ).lastIndexOf("/") + 1
                      )
                  : "",
                "%29",
                ""
              ),
              "%28",
              ""
            ),
            "%20",
            ""
          ),
          // aadharpdfName:this.props.personnelData.aadharCardURL?(this.props.personnelData.aadharCardURL.split("?")[0]).substring(
          //   (this.props.personnelData.aadharCardURL || "").lastIndexOf(
          //     "/"
          //   ) + 1):'',

          // this.props.personnelData.aadharCardURL? (
          //   this.props.personnelData.aadharCardURL.split("?")[0]).substring(
          //     (this.props.personnelData.aadharCardURL || "").lastIndexOf(
          //       "/"
          //     ) + 1)
          //     // .replaceAll("%20"," ").replaceAll("%28","(").replaceAll("%29",")")
          //     :'',
          backaadharpdfName: this.replaceAll(
            this.replaceAll(
              this.replaceAll(
                this.props.personnelData.aadharCardBackURL
                  ? this.props.personnelData.aadharCardBackURL
                      .split("?")[0]
                      .substring(
                        (
                          this.props.personnelData.aadharCardBackURL || ""
                        ).lastIndexOf("/") + 1
                      )
                  : "",
                "%29",
                ""
              ),
              "%28",
              ""
            ),
            "%20",
            ""
          ),
          // this.props.personnelData.aadharCardBackURL? (
          //   this.props.personnelData.aadharCardBackURL.split("?")[0]).substring(
          //     (this.props.personnelData.aadharCardBackURL || "").lastIndexOf(
          //       "/"
          //     ) + 1)
          //     // .replaceAll("%20"," ").replaceAll("%28","(").replaceAll("%29",")")
          //     :'',

          aadharCardBackURL: this.props.personnelData.aadharCardBackURL,
          learnerFatherName: this.props.personnelData.learnerFatherName,
          learnerFatherDOB: this.state.learnerFatherDOB,
          learnerFatherEmployementType: this.props.personnelData
            .learnerFatherEmployementType,
          learnerFatherCompanyName: this.props.personnelData
            .learnerFatherCompanyName,
          learnerMotherName: this.props.personnelData.learnerMotherName,
          learnerMotherDOB: this.state.learnerMotherDOB,
          learnerMotherEmployementType: this.props.personnelData
            .learnerMotherEmployementType,
          learnerMotherCompanyName: this.props.personnelData
            .learnerMotherCompanyName,
          checkAddressFlag: this.state.checkAddressFlag,
          document: [],
          resumeDocumentName: "",
          panCardDocumentName: "",
          aadharCardDocumentName: "",
          documentSizeCheckForProfileCheck: false,
          documentTypeCheckForProfileImage: false,
          documentSizeCheckForChequeCheck: false,
          documentTypeCheckForChequeImage: false,
          documentTypeCheckForResume: false,
          documentSizeCheckForResume: false,
          documentTypeCheckForAadharImage: false,
          documentSizeCheckForVideoCheck: false,
          documentSizeCheckForPanCard: false,
          documentSizeCheckForAadharCard: false,
          documentTypeCheckForPANImage: false,
          documentTypeCheckForVideo: false,
          countryList: this.state.countryList,
          // countryListForCors: this.state.countryListForCors,
          // stateListForCor: this.state.stateListForCor,
          // cityListForCor: this.state.cityListForCor,
          stateListForPer: this.state.stateListForPer,
          cityListForPer: this.state.cityListForPer,
          addressFlag: this.state.addressFlag,
          downloadResumeAfterSelecting: "",
          whoareu: this.props.personnelData.learnerType,
          bankName: this.props.personnelData.bankName,
          branch: this.props.personnelData.branchName,
          branchAddress: this.props.personnelData.branchAddress,
          accountType: this.props.personnelData.accountType,
          acccountNumber: this.props.personnelData.accountNumber,

          ARNNO: this.props.personnelData.arn||this.props?.arnNo,
          NameOfARNHolder: this.props.personnelData.nameOfARNHolder,

          passportNumber: this.props.personnelData.passportNumber,

          fatherName: this.props.personnelData.learnerFatherName,
          salutation: this.props.personnelData.salutation,
          applicantSpouseFather: this.props.personnelData.applicantSpouseFather,
          professionalQualification: this.props.personnelData
            .learnerProfessionalQualification,
          ARNuserFeilds: this.props.personnelData.nameOfMutualFundEmpanelled,
          CPEDetails: this.props.personnelData.learnerCpeDetails,
          addressProofCardURL: this.props.personnelData.proofOfAddressURL,
          ifscCode: this.props.personnelData.ifscCode,
          //this.props.personnelData.proofOfAddressURL,
          //this.props.personnelData.proofOfAddressURL,
          submitStatus: "",
          profileCompletion: "26",
          edudocument: [],
          PanValidation: false,
          euinNumber: this.props.personnelData.euinNumber,
          applicationStatus: this.props.personnelData.applicationStatus,
          currentProfession: this.props.personnelData.learnerProfession,
          sameasAboveCount: 0,
          disabledForCielops:
            localStorage.getItem("roleId") == 6 ||
            localStorage.getItem("roleId") == 7
              ? true
              : false,
          currProfessionList:
            this.props.personnelData.learnerType == "1013" ||
            this.props.personnelData.learnerType == "1015"
              ? currProf
              : [],
          disablecurreProf:
            this.props.personnelData.learnerType == "1013" ||
            this.props.personnelData.learnerType == "1015"
              ? false
              : true,
          nameOfCurrProf:
            this.props.personnelData.learnerType == "1015"
              ? "Last Profession"
              : "Current Profession",
          disabledprofDetails: false,
          listOfMonth: this.state.listOfMonth,
          listOfDay: this.state.listOfDay,
          tolistOfDay: this.state.listOfDay,
          tolistOfMonth: this.state.listOfMonth,

          fromlistOfDay: this.state.listOfDay,
          fromlistOfMonth: this.state.listOfMonth,
          addressTypeMark:this.props.addressData&&this.props.addressData[0]?.addressTypeMark==="permanent"?true:false,
          preferredExamLocation: this.props.personnelData?.executionTracker[0]?.additionalDetails?.stateId || "",
          preferredExamLocationState: "",
          preferredExamCenter: this.props.personnelData?.executionTracker[0]?.additionalDetails?.center || "",
          preferredExamLocationList: this.state.preferredExamLocationList,
          preferredExamCenterList: this.state.preferredExamCenterList || [],
          preferredMandateName: this.state.preferredMandateName,
          learnerEuinOption: this.props?.learnerEuinOption || localStorage.getItem("learnerEuinOption1"),
          // event.target.value=="1013" || event.target.value=="1015"
        }}
        validationSchema={() =>
          Yup.lazy((values) => {
            const isCPF = values.submitStatus === "SUBMITTED";
            return isCPF
              ? Yup.object().shape({
                  whoareu: Yup.string().required(
                    "You must select a who are you field"
                  ),
                  empNo:Yup.string()
                  .required("You must specify a Employee Number"),

                  mobileNo: Yup.string()
                    .required("You must specify a Mobile Number")
                    .matches(
                      /^[6-9]\d{9}$/,
                      "Please enter the valid mobile number"
                    ),
                  gender: Yup.string().required("You must specify a Gender"),

                  // applicantSpouseFather: Yup.string ()
                  //   .matches (
                  //     /^[a-zA-Z ]{1,50}$/,
                  //     TextValidationMesg + ' and upto 50 letter'
                  //   )
                  //   .required (
                  //     "You must specify a Applicant Spouse / Father's Name"
                  //   ),

                  // ifscCode: Yup.string ()
                  //   .matches (
                  //     /^[A-Z]{4}0[A-Z0-9]{6}$/,
                  //     'You must enter valid IFSC code with capital letters'
                  //   ),
                  //  .required ('You must specify a IFSC code'),
                  // .matches(/^[a-zA-Z ]{1,15}$/, TextValidationMesg+' and upto 15 letter'),

                  firstName: Yup.string()
                    .required("You must specify a First Name")
                   
                    .matches(
                      // /^[a-zA-Z.']{1}[a-zA-Z.' ]{2,50}$/,
                      /^\S([a-zA-Z .']{1,50})?$/,
                      "Characters, dot(.), and apostrophe are allowed."
                    )
                    ,
                  middleName: Yup.string().matches(
                    /^\S([a-zA-Z .']{1,50})?$/,
                    // /^[a-zA-Z.']{1}[a-zA-Z.' ]{2,50}$/,
                    "Characters, dot(.), and apostrophe are allowed."
                  ),
                  lastName: Yup.string()
                    .required("You must specify a Last Name")
                    .matches(
                      /^\S([a-zA-Z .']{1,50})?$/,
                      // /^[a-zA-Z.']{1}[a-zA-Z.' ]{2,50}$/,
                      "Characters, dot(.), and apostrophe are allowed."
                    ),
                  fatherName: Yup.string()
                    .required("You must specify a Father's Name")
                    .matches(
                      // /^([a-zA-Z.']){1}?([a-zA-Z.' ]){2,50}?$/,
                      /^\S([a-zA-Z .']{1,50})?$/,
                      // TextValidationMesg + " and upto 50 letter"
                      "Characters, dot(.), and apostrophe are allowed."
                    ),

                  // maritalStatus: Yup.string().required(
                  //   "You must specify a Marital Status"
                  // ),
                  maritalStatus:Yup.string().when("condition2", {
                    is: (condition2) =>(condition2?true:false),
                    // => (discountType === "3" ? true : false),
                    then: Yup.string(),
                    otherwise: Yup.string()
                    .required("You must specify Marital Status")
                   
                  }),
                  secondaryEmailID: Yup.string().when("condition1",{
                    is:(condition1)=>(condition1?true:false),
                    then:Yup.string(),
                    otherwise:Yup.string()
                    .required("You must specify Official Email ID")
                    .email("Please enter the valid official email ID")
                  }),
                  emailID: Yup.string()
                    .required("You must specify a Email ID")
                    .email("Please enter the valid email ID"),

                  panNumber: Yup.string()
                    .required("You must specify a Pan Number")
                    .matches(
                      /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                      "Please enter the valid PAN number with capital letters"
                    ),

                  // .when("PanValidation", {
                  //   is: true,
                  //   then: Yup.string().required("You must specify a Pan Number")
                  // })
                  salutation: Yup.string(),
                  //   .required("You must select a salutation"),
                  aadharNumber: Yup.string()
                    .required("You must specify a Aadhaar number")
                    .matches(
                      /^\d{12}$/,
                      "Please enter the valid Aadhaar number"
                    ),
                  // dob: Yup.string()
                  //   .required("Date of Birth is required")
                  //   .test(
                  //     "dob",
                  //     "Birth Date should be greater then 18 years",
                  //     value => {
                  //       let isvalid = moment().diff(moment(value), "years") >= 18;
                  //       return isvalid;
                  //     }
                  //   ),

                  // learnerFatherDOB: Yup.lazy((key) => {
                  //   return Yup.string()
                  //     .default(() => "")
                  //     .when("dob", (dob, schema) => {
                  //       return schema.test({
                  //         test: (EndIntensity) => !!dob && EndIntensity < dob,
                  //         message:
                  //           "Father birth date should be greater than learner birth date",
                  //       });
                  //     });
                  // }),
                  // learnerMotherDOB: Yup.lazy((key) => {
                  //   return Yup.string()
                  //     .default(() => "")
                  //     .when("dob", (dob, schema) => {
                  //       return schema.test({
                  //         test: (EndIntensity) => !!dob && EndIntensity < dob,
                  //         message:
                  //           "Mother birth date should be greater than learner birth date",
                  //       });
                  //     });
                  // }),

                  // contactNo: Yup.string().matches(
                  //   /^[6-9]\d{9}$/,
                  //   "Please enter the valid mobile number"
                  // ),

                  // corStreet: Yup.string()
                  //   .required("You must specify a Address")
                  //   .matches(
                  //     /^\S([a-zA-Z0-9 ,.-]{1,40})?$/,
                  //     // /^[a-zA-Z0-9,\-.]{1}[a-zA-Z0-9,\-. ]{2,40}$/,
                  //     "Characters, Number, comma(,), dot(.), hypen(-) and space are allowed."
                  //   ),
                  // corStreet2: Yup.string().matches(
                  //   /^\S([a-zA-Z0-9 ,.-]{1,40})?$/,
                  //   // /^[a-zA-Z0-9,\-.]{1}[a-zA-Z0-9,\-. ]{2,40}$/,
                  //   "Characters, Number, comma(,), dot(.), hypen(-) and space are allowed."
                  // ),
                  // corStreet3: Yup.string().matches(
                  //   /^\S([a-zA-Z0-9 ,.-]{1,40})?$/,
                  //   // /^[a-zA-Z0-9,\-.]{1}[a-zA-Z0-9,\-. ]{2,40}$/,
                  //   "Characters, Number, comma(,), dot(.), hypen(-) and space are allowed."
                  // ),
                  // corcityId: Yup.string().required("You must specify a City"),
                  // corstateId: Yup.string().required("You must specify a State"),
                  // corcountryId: Yup.string().required(
                  //   "You must specify a Country"
                  // ),
                  // corPostalCode: Yup.string()
                  //   .required("You must specify a PIN Code")
                  //   .matches(/^[0-9]\d{5}$/, "Please enter the valid PIN code"),
                  perStreet: Yup.lazy((values) => {
                    const isfresh =
                      this.props.personnelData.learnerEuinOption ===
                        "Fresh EUIN" ||
                      this.props.personnelData.learnerEuinOption ===
                        "Only EUIN Renewal";
                    return isfresh
                      ? Yup.string()
                      : Yup.string()
                          .required("You must specify a Address")
                          .matches(
                            /^\S([a-zA-Z0-9 ,.-]{1,40})?$/,
                            // /^[a-zA-Z0-9,\-.]{1}[a-zA-Z0-9,\-. ]{2,40}$/,
                            "Characters, Number, comma(,), dot(.), hypen(-) and space are allowed."
                          );
                  }),

                  perStreet2: Yup.lazy((values) => {
                    const isfresh =
                      this.props.personnelData.learnerEuinOption ===
                        "Fresh EUIN" ||
                      this.props.personnelData.learnerEuinOption ===
                        "Only EUIN Renewal";
                    return isfresh
                      ? Yup.string()
                      : Yup.string().matches(
                          /^\S([a-zA-Z0-9 ,.-]{1,40})?$/,
                          // /^[a-zA-Z0-9,\-.]{1}[a-zA-Z0-9,\-. ]{2,40}$/,
                          "Characters, Number, comma(,), dot(.), hypen(-) and space are allowed."
                        );
                  }),

                  perStreet3: Yup.lazy((values) => {
                    const isfresh =
                      this.props.personnelData.learnerEuinOption ===
                        "Fresh EUIN" ||
                      this.props.personnelData.learnerEuinOption ===
                        "Only EUIN Renewal";
                    return isfresh
                      ? Yup.string()
                      : Yup.string().matches(
                          /^\S([a-zA-Z0-9 ,.-]{1,40})?$/,
                          // /^[a-zA-Z0-9,\-.]{1}[a-zA-Z0-9,\-. ]{2,40}$/,
                          "Characters, Number, comma(,), dot(.), hypen(-) and space are allowedi."
                        );
                  }),
//[^-\s][a-zA-Z0-9-_\\s]+$
                  percountryId: Yup.lazy((values) => {
                    const isfresh =
                      this.props.personnelData.learnerEuinOption ===
                        "Fresh EUIN" ||
                      this.props.personnelData.learnerEuinOption ===
                        "Only EUIN Renewal";
                    return isfresh
                      ? Yup.string()
                      : Yup.string().required("You must specify a Country");
                  }),

                  percityId: Yup.lazy((values) => {
                    const isfresh =
                      this.props.personnelData.learnerEuinOption ===
                        "Fresh EUIN" ;
                      //   ||
                      // this.props.personnelData.learnerEuinOption ===
                      //   "Only EUIN Renewal";
                    return isfresh
                      ? Yup.string()
                      : Yup.string().required("You must specify a City");
                  }),

                  perstateId: Yup.lazy((values) => {
                    const isfresh =
                      this.props.personnelData.learnerEuinOption ===
                        "Fresh EUIN" ;
                      //   ||
                      // this.props.personnelData.learnerEuinOption ===
                      //   "Only EUIN Renewal";
                    return isfresh
                      ? Yup.string()
                      : Yup.string().required("You must specify a State");
                  }),

                  perPostalCode: Yup.lazy((values) => {
                    const isfresh =
                      this.props.personnelData.learnerEuinOption ===
                        "Fresh EUIN" ||
                      this.props.personnelData.learnerEuinOption ===
                        "Only EUIN Renewal";
                    return isfresh
                      ? Yup.string()
                      : Yup.string()

                          .required("You must specify a PIN Code")
                          .matches(
                            /^[0-9]\d{5}$/,
                            "Please enter the valid PIN code"
                          );
                  }),

                  // passportNumber: Yup.string().matches(
                  //   /^(?!^0+$)[a-zA-Z0-9]{6,9}$/,
                  //   "Please enter the valid passport number"
                  // ),
                  // branch: Yup.string ().required ('You must specify a branch'),
                  // branchAddress: Yup.string ().required (
                  //   'You must specify a branch address'
                  // ),
                  // accountType: Yup.string ().required (
                  //   'You must specify a account type'
                  // ),
                  // acccountNumber: Yup.string ()
                  //   .matches (
                  //     /^[0-9]{9,18}$/,
                  //     'Please enter valid account Number'
                  //   )
                  //   .required ('You must specify a acccount number'),
                  // bankName: Yup.string ().required (
                  //   'You must specify a bank name'
                  // ),
                  // .matches(/^[a-zA-Z ]{1,180}$/, TextValidationMesg),
                  peraddressproof1: Yup.lazy((values) => {
                    const isfresh =
                      this.props.personnelData.learnerEuinOption ===
                        "Fresh EUIN" ||
                      this.props.personnelData.learnerEuinOption ===
                        "Only EUIN Renewal";
                    return isfresh
                      ? Yup.string()
                      : Yup.string().required(
                          "You must specify a address proof"
                        );
                  }),

                  euinNumber: Yup.string().matches(
                    /^[a-zA-Z0-9-]{4,20}$/,
                    "Please enter the valid EUIN Number"
                  ),

                  // professionalQualification: Yup.array()
                  //   .of(
                  //     Yup.object().shape({
                  //       occupation: Yup.string().required('Required'),
                  //       companyName: Yup.string().required('Required')
                  //         .matches(/^[a-zA-Z ]{1,180}$/, TextValidationMesg),
                  //       annualIncome: Yup.string().required('Required'),

                  //     })
                  //  ),
                  // CPEDetails: Yup.array()
                  //   .of(
                  //     Yup.object().shape({
                  //       passingCertificateNo: Yup.string()
                  //         .matches(/^[a-zA-Z0-9 ]{1,180}$/, "Passing Certificate Number should be alphanumric"),
                  //       dateOfPassingTest: Yup.string(),
                  //       //  .matches(/^[a-zA-Z ]{1,180}$/,TextValidationMesg),
                  //       cpeCertificateNo: Yup.string()
                  //         .matches(/^[a-zA-Z0-9 ]{1,180}$/, "CPE Certificate Number should be alphanumric"),
                  //       dateOfCpe: Yup.string(),

                  //     })
                  // ),
                  preferredExamLocation: Yup.string().when("preferredMandateName", {
                    is: (preferredMandateName) => preferredMandateName === "NISM" ? true : false,
                    then:Yup.string().required("You must specify a Preferred Exam Location"),
                    otherwise:Yup.string()
                  }),
                  preferredExamCenter: Yup.string().when("preferredMandateName", {
                    is: (preferredMandateName) => preferredMandateName === "NISM" ? true : false,
                    then:Yup.string().required("You must specify a Preferred Exam Center"),
                    otherwise:Yup.string()
                  }),        
                  ARNNO: Yup.string().matches(
                    /^[a-zA-Z0-9-]{4,20}$/,
                    "Please enter the valid ARN Number"
                  ),
                  certificateNumber: Yup.string().matches(
                    /^[a-zA-Z0-9-]{4,20}$/,
                    "Please enter the valid NISM Registration Number"
                  ),
                  enrollmentNumber: Yup.string().matches(
                    /^[a-zA-Z0-9-]{4,20}$/,
                    "Please enter valid enrollment Number"
                  ),
                  NameOfARNHolder: Yup.string().matches(
                    /^[a-zA-Z ]{1,15}$/,
                    TextValidationMesg
                  ),
                  // euinNumber:Yup.string()
                  // .matches(^[a-zA-Z]-[0-9-]{15,20}$/, "Only alphanumeric and hypen allowed")
                })
              : Yup.object().shape({
                  // ifscCode: Yup.string ().matches (
                  //   /^[A-Z]{4}0[A-Z0-9]{6}$/,
                  //   'You must enter valid IFSC code with capital letters'
                  // ),
                  NameOfARNHolder: Yup.string().matches(
                    /^[a-zA-Z ]{1,15}$/,
                    TextValidationMesg
                  ),
                  euinNumber: Yup.string().matches(
                    /^[a-zA-Z0-9-]{4,20}$/,
                    "Please enter the valid EUIN Number"
                  ),

                  ARNNO: Yup.string().matches(
                    /^[a-zA-Z0-9-]{4,20}$/,
                    "Please enter the valid ARN Number"
                  ),
                  certificateNumber: Yup.string().matches(
                    /^[a-zA-Z0-9-]{4,20}$/,
                    "Please enter the valid NISM Registration Number"
                  ),
                  enrollmentNumber: Yup.string().matches(
                    /^[a-zA-Z0-9-]{4,20}$/,
                    "Please enter valid enrollment Number"
                  ),
                  professionalQualification: Yup.array().of(
                    Yup.object().shape({
                      occupation: Yup.string(),
                      companyName: Yup.string().matches(
                        /^[a-zA-Z ]{1,180}$/,
                        TextValidationMesg
                      ),
                      // annualIncome: Yup.string(),
                      // these constraints take precedence
                      //salary: Yup.string().min(3, 'cmon').required('Required'), // these constraints take precedence
                    })
                  ),
                  // CPEDetails: Yup.array()
                  //   .of(
                  //     Yup.object().shape({
                  //       passingCertificateNo: Yup.string()
                  //         .matches(/^[a-zA-Z0-9 ]{1,180}$/, "Passing Certificate Number should be alphanumric"),
                  //       dateOfPassingTest: Yup.string(),
                  //       //  .matches(/^[a-zA-Z ]{1,180}$/,TextValidationMesg),
                  //       cpeCertificateNo: Yup.string()
                  //         .matches(/^[a-zA-Z0-9 ]{1,180}$/, "CPE Certificate Number should be alphanumric"),
                  //       dateOfCpe: Yup.string(),
                  //       // .matches(/^[a-zA-Z ]{1,180}$/,TextValidationMesg),
                  //       // these constraints take precedence
                  //       //salary: Yup.string().min(3, 'cmon').required('Required'), // these constraints take precedence
                  //     })
                  //   ),
                  empNo:Yup.string(),
                  // .required("You must specify a Employee Number"),
                  // contactNo: Yup.string().matches(
                  //   /^[6-9]\d{9}$/,
                  //   "Please enter the valid mobile number"
                  // ),
                  gender: Yup.string(),
                  firstName: Yup.string()
                  .matches(
                    /^\S([a-zA-Z .']{1,50})?$/,
                    // /^[a-zA-Z.']{1}[a-zA-Z.' ]{2,50}$/,
                    "Characters, dot(.), and apostrophe are allowed."
                  )
                  ,
                  middleName: Yup.string().matches(
                    /^\S([a-zA-Z .']{1,50})?$/,
                    // /^[a-zA-Z.']{1}[a-zA-Z.' ]{2,50}$/,
                    "Characters, dot(.), and apostrophe are allowed."
                  ),

                  // applicantSpouseFather: Yup.string ().matches (
                  //   /^[a-zA-Z ]{1,50}$/,
                  //   TextValidationMesg + ' and upto 50 letter'
                  // ),

                  lastName: Yup.string()
                    // .required("You must specify a Last Name")
                    .matches(
                      /^\S([a-zA-Z .']{1,50})?$/,
                      // /^[a-zA-Z.']{1}[a-zA-Z.' ]{2,50}$/,
                      "Characters, dot(.), and apostrophe are allowed."
                    ),
                  fatherName: Yup.string()
                    // .required("You must specify a Father's Name")
                    .matches(
                      /^\S([a-zA-Z .']{1,50})?$/,
                      // /^[a-zA-Z.']{1}[a-zA-Z.' ]{2,50}$/,
                      // TextValidationMesg + " and upto 50 letter"
                      "Characters, dot(.), and apostrophe are allowed."
                    ),
                  maritalStatus: Yup.string(),

                  secondaryEmailID: Yup.lazy((values) => {
                    const isfresh =
                      this.props.personnelData.learnerEuinOption ===
                        "Fresh EUIN" ||
                      this.props.personnelData.learnerEuinOption ===
                        "Only EUIN Renewal";
                    return isfresh
                      ? Yup.string()
                      : Yup.string()
                          // .required("You must specify Official Email ID")
                          .email("Please enter the valid official email ID");
                  }),

                  emailID: Yup.string().email(
                    "Please enter the valid email ID"
                  ),

                  mobileNo: Yup.string().matches(
                    /^[6-9]\d{9}$/,
                    "Please enter the valid mobile number"
                  ),
                  panNumber: Yup.string().matches(
                    /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                    "Please enter the valid PAN number with capital letters"
                  ),
                  aadharNumber: Yup.string().matches(
                    /^\d{12}$/,
                    "Please enter the valid Aadhaar number"
                  ),
                  // dob: Yup.string()

                  //   .test(
                  //     "dob",
                  //     "Birth Date should be greater then 18 years",
                  //     value => {
                  //       let isvalid = moment().diff(moment(value), "years") >= 18;
                  //       return isvalid;
                  //     }
                  //   ),

                  // learnerFatherDOB: Yup.lazy((key) => {
                  //   return Yup.string()
                  //     .default(() => "")
                  //     .when("dob", (dob, schema) => {
                  //       return schema.test({
                  //         test: (EndIntensity) => !!dob && EndIntensity < dob,
                  //         message:
                  //           "Father birth date should be greater than learner birth date",
                  //       });
                  //     });
                  // }),
                  // learnerMotherDOB: Yup.lazy((key) => {
                  //   return Yup.string()
                  //     .default(() => "")
                  //     .when("dob", (dob, schema) => {
                  //       return schema.test({
                  //         test: (EndIntensity) => !!dob && EndIntensity < dob,
                  //         message:
                  //           "Mother birth date should be greater than learner birth date",
                  //       });
                  //     });
                  // }),

                  // secondaryEmailID:
                  //  Yup.string()
                  //   .required("You must specify Official Email ID")
                  //   .email("Please enter the valid official email ID"),
                  // corStreet: Yup.string(),
                  // corStreet2: Yup.string(),
                  // corcityId: Yup.string(),
                  // corstateId: Yup.string(),
                  // corcountryId: Yup.string(),
                  corPostalCode: Yup.string().matches(
                    /^[0-9]\d{5}$/,
                    "Please enter the valid PIN code"
                  ),
                  // perStreet: Yup.string(),
                  // perStreet2: Yup.string(),
                  // percityId: Yup.string(),
                  // perstateId: Yup.string(),
                  // percountryId: Yup.string(),
                  perPostalCode: Yup.string().matches(
                    /^[0-9]\d{5}$/,
                    "Please enter the valid PIN code"
                  ),
                  // passportNumber: Yup.string().matches(
                  //   /^(?!^0+$)[a-zA-Z0-9]{6,9}$/,
                  //   "Please enter the valid passport number"
                  // ),
                  // branch: Yup.string (),

                  // branchAddress: Yup.string (),
                  // accountType: Yup.string (),

                  // acccountNumber: Yup.string ().matches (
                  //   /^[0-9]{9,18}$/,
                  //   'Please enter valid account Number'
                  // ),
                  // bankName: Yup.string (),

                  // .matches(/^[a-zA-Z ]{1,180}$/, TextValidationMesg)
                });
          })
        }
        onSubmit={async (values) => {
          window.$('#Hdfc-Mandate').modal('hide')
          let NISMDATE = "";
          let EUINDATE = "";
          let ENROLLMENTDATE = "";
          let arncount = 0;
          let nismcount = 0;
          let enrollcount = 0;
          let euincount = 0;
          let nismDateMessage = "";
          let enrollmentDateMessage = "";
          let euinDateMessage = "";
          let nismCertificateDateMessage = "";
          let enrollmentNumberDateMessage = "";
          let euinCertificateDateMessage = "";
          let empNoMessage = "";
          let DOB =
            values["day"] +
            "/" +
            values["month"] +
            "/" +
            values["year"] +
            " " +
            "00:00:00";
console.log("dateofb",DOB);
          if (
            isNaN(parseInt(values["nismday"])) ||
            isNaN(parseInt(values["nismmonth"])) ||
            isNaN(parseInt(values["nismyear"]))
          ) {
            NISMDATE = "";
          } else {
            var today = new Date();

            let nismDate2 =
              values["nismyear"] +
              "-" +
              values["nismmonth"] +
              "-" +
              values["nismday"];
            let mydate = new Date(nismDate2);

            var diff = mydate.getTime() - today.getTime();

            var daydiff = diff / (1000 * 60 * 60 * 24);

            if (daydiff > 1461) {
              nismDateMessage =
                "NISM Certificate expiry date entered is incorrect.  Please enter valid NISM certificate expiry date.";
            } else {
              NISMDATE =
                values["nismday"] +
                "/" +
                values["nismmonth"] +
                "/" +
                values["nismyear"] +
                " " +
                "00:00:00";
            }
          }
          if (
            isNaN(parseInt(values["nismday"])) ||
            isNaN(parseInt(values["nismmonth"])) ||
            isNaN(parseInt(values["nismyear"]))
          ) {
            NISMDATE = "";
          } else {
            var today = new Date();

            let nismDate2 =
              values["nismyear"] +
              "-" +
              values["nismmonth"] +
              "-" +
              values["nismday"];
            let mydate = new Date(nismDate2);

            var diff = mydate.getTime() - today.getTime();

            var daydiff = diff / (1000 * 60 * 60 * 24);

            if (daydiff > 1461) {
              nismDateMessage =
                "NISM Certificate expiry date entered is incorrect.  Please enter valid NISM certificate expiry date.";
            } else {
              NISMDATE =
                values["nismday"] +
                "/" +
                values["nismmonth"] +
                "/" +
                values["nismyear"] +
                " " +
                "00:00:00";
            }
          }
          if (
            isNaN(parseInt(values["enrollmentday"])) ||
            isNaN(parseInt(values["enrollmentmonth"])) ||
            isNaN(parseInt(values["enrollmentyear"]))
          ) {
            ENROLLMENTDATE = "";
          } else {
            var today = new Date();

            let enrollDate2 =
              values["enrollmentyear"] +
              "-" +
              values["enrollmentmonth"] +
              "-" +
              values["enrollmentday"];
            let mydate = new Date(enrollDate2);

            var diff = mydate.getTime() - today.getTime();

            var daydiff = diff / (1000 * 60 * 60 * 24);

            // if (daydiff > 1095.75) {
            //   enrollmentDateMessage = "Please enter valid enrollment date.";
            // } else {
            ENROLLMENTDATE =
              values["enrollmentday"] +
              "/" +
              values["enrollmentmonth"] +
              "/" +
              values["enrollmentyear"] +
              " " +
              "00:00:00";
            //}
          }
          if (
            isNaN(parseInt(values["enrollmentday"])) ||
            isNaN(parseInt(values["enrollmentmonth"])) ||
            isNaN(parseInt(values["enrollmentyear"]))
          ) {
            ENROLLMENTDATE = "";
          } else {
            var today = new Date();

            let enrollDate2 =
              values["enrollmentyear"] +
              "-" +
              values["enrollmentmonth"] +
              "-" +
              values["enrollmentday"];
            let mydate = new Date(enrollDate2);

            var diff = mydate.getTime() - today.getTime();

            var daydiff = diff / (1000 * 60 * 60 * 24);

            // if (daydiff > 1095.75) {
            //   enrollmentDateMessage = "Please enter valid Enrollment date.";
            // } else {
            ENROLLMENTDATE =
              values["enrollmentday"] +
              "/" +
              values["enrollmentmonth"] +
              "/" +
              values["enrollmentyear"] +
              " " +
              "00:00:00";
            //}
          }
          if (
            isNaN(parseInt(values["euinday"])) ||
            isNaN(parseInt(values["euinmonth"])) ||
            isNaN(parseInt(values["euinyear"]))
          ) {
            EUINDATE = "";
          } else {
            var today = new Date();
            let euinDate2 =
              values["euinyear"] +
              "-" +
              values["euinmonth"] +
              "-" +
              values["euinday"];
            let mydate = new Date(euinDate2);

            var diff = mydate.getTime() - today.getTime();

            var daydiff = diff / (1000 * 60 * 60 * 24);

            // if (daydiff > 1095.75) {
            //   euinDateMessage =
            //     "EUIN validity date entered is incorrect. Please enter valid EUIN validity date.";
            // } else {
            EUINDATE =
              values["euinday"] +
              "/" +
              values["euinmonth"] +
              "/" +
              values["euinyear"] +
              " " +
              "00:00:00";
            // }
          }

          if (values.euinNumber !== "" && values.euinNumber != undefined) {
            euincount = (values.euinNumber.match(/-/g) || []).length;
          }
          if (
            values.certificateNumber !== "" &&
            values.certificateNumber != undefined
          ) {
            nismcount = (values.certificateNumber.match(/-/g) || []).length;
          }
          if (
            values.enrollmentNumber !== "" &&
            values.enrollmentNumber != undefined
          ) {
            enrollcount = (values.enrollmentNumber.match(/-/g) || []).length;
          }
          if (values.ARNNO !== "" && values.ARNNO != undefined) {
            arncount = (values.ARNNO.match(/-/g) || []).length;
          }

          if (
            values.certificateNumber !== "" &&
            values.certificateNumber !== undefined
          ) {
            if (NISMDATE === "") {
              nismCertificateDateMessage =
                "If you entered a NISM Certificate Number, you must also submit a NISM Validity Date, and vice versa.";
            }
          }

          if (NISMDATE !== "") {
            if (
              values.certificateNumber === "" ||
              values.certificateNumber === undefined
            ) {
              nismCertificateDateMessage =
                "If you entered a NISM Certificate Validity Date, you must also submit a NISM Certificate Number, and vice versa.";
            }
          }

          if (
            values.enrollmentNumber !== "" &&
            values.enrollmentNumber !== undefined
          ) {
            if (ENROLLMENTDATE === "") {
              enrollmentNumberDateMessage =
                "If you entered a Enrollment Number, you must also submit a Enrollment Date, and vice versa.";
            }
          }

          if (ENROLLMENTDATE !== "") {
            if (
              values.enrollmentNumber === "" ||
              values.enrollmentNumber === undefined
            ) {
              enrollmentNumberDateMessage =
                "If you entered a Enrollment Date, you must also submit a Enrollment Number, and vice versa.";
            }
          }

          // if (values.euinNumber !== "" && values.euinNumber !== undefined) {
          //   if (EUINDATE === "") {
          //     euinCertificateDateMessage =
          //       "If you entered a EUIN Number, you must also submit a EUIN Validity Date, and vice versa.";
          //   }
          // }

          // if (EUINDATE !== "") {
          //   if (values.euinNumber === "" || values.euinNumber === undefined) {
          //     euinCertificateDateMessage =
          //       "If you entered a EUIN Validity Date, you must also submit a EUIN Number, and vice versa.";
          //   }
          // }
          if (
            this.state.isMandateUser === 1 &&
            (values.empNo === "" || values.empNo === undefined)
          ) {
            empNoMessage = "Please provide Employee No.";
          }
          // this.state?.newLearnerDetails?.learnerEuinOption === "Fresh EUIN"
          //   ||this.state?.newLearnerDetails?.learnerEuinOption ==="Only EUIN Renewal"
          // if (this.state?.newLearnerDetails?.learnerEuinOption === "Fresh EUIN" ||this.state?.newLearnerDetails?.learnerEuinOption ==="Only EUIN Renewal") {
          //   empNoMessage = "Please provide Employee No.";
          // }

          let DOB1 =
            values["year"] + "-" + values["month"] + "-" + values["day"];
          let fromDate1 = "";
          if (
            values["year"] === undefined ||
            values["year"] === "" ||
            values["month"] === undefined ||
            values["month"] === "" ||
            values["day"] === undefined ||
            values["day"] === ""
          ) {
            // warning("Please Select valid date", warningNotification);
            warning("Please select Birth Date.", warningNotification);
          } else if (nismcount > 4) {
            error(
              "Please provide valid NISM Registration Date",
              errorNotification
            );
          } else if (enrollcount > 4) {
            error(
              "Please provide valid NISM Registration Date",
              errorNotification
            );
          } else if (euincount > 4) {
            error("Please provide valid EUIN Number", errorNotification);
          } else if (arncount > 4) {
            error("Please provide valid ARN Number", errorNotification);
          } else if (nismDateMessage !== "") {
            error(nismDateMessage, errorNotification);
          } else if (enrollmentDateMessage !== "") {
            error(enrollmentDateMessage, errorNotification);
          } else if (euinDateMessage !== "") {
            error(euinDateMessage, errorNotification);
          } else if (nismCertificateDateMessage !== "") {
            error(nismCertificateDateMessage, errorNotification);
          } else if (enrollmentNumberDateMessage !== "") {
            error(enrollmentNumberDateMessage, errorNotification);
          } else if (euinCertificateDateMessage !== "") {
            error(euinCertificateDateMessage, errorNotification);
          } else if (empNoMessage !== "") {
            error(empNoMessage, errorNotification);
          } else {
            // values.professionalQualification.map((ele) => {
            //   let fromDate1 = "";
            //   if (
            //     ele.fromYear !== undefined &&
            //     ele.fromYear !== "0" &&
            //     ele.fromMonth !== undefined &&
            //     ele.fromMonth !== "0" &&
            //     ele.fromDay !== undefined &&
            //     ele.fromDay !== "0"
            //   ) {
            //     fromDate1 =
            //       ele.fromDay +
            //       "/" +
            //       ele.fromMonth +
            //       "/" +
            //       ele.fromYear +
            //       " " +
            //       "00:00:00";
            //   } else {
            //     fromDate1 = "";
            //   }

            //   let toDate1 = "";
            //   if (
            //     ele.toyear !== undefined &&
            //     ele.toyear !== "0" &&
            //     ele.toMonth !== undefined &&
            //     ele.toMonth !== "0" &&
            //     ele.toDay !== undefined &&
            //     ele.toDay !== "0"
            //   ) {
            //     toDate1 =
            //       ele.toDay +
            //       "/" +
            //       ele.toMonth +
            //       "/" +
            //       ele.toyear +
            //       " " +
            //       "00:00:00";
            //   } else {
            //     toDate1 = "";
            //   }

            //   if (fromDate1 !== "" && toDate1 !== "") {
            //     let fromDate = fromDate1;
            //     let toDate = toDate1;
            //     ele.fromDate = fromDate;
            //     ele.toDate = toDate;
            //   } else {
            //     ele.fromDate = ele.fromDate;
            //     ele.toDate = ele.toDate;
            //   }
            // });
            let isValidDOB = this.isValidDate(DOB1, "");

            if (isValidDOB) {
              // this.setState({ loading1: "block" });
              let valData = [];
              let docData = [];
              let docData1 = [];
              let docData2 = [];
              let docData3 = [];
              let doc_temp = [];
              let doc_temp1 = [];
              let doc_temp2 = [];
              let doc_temp3 = [];
              let doc_temp4 = [];
              let eduQualificationvalData = [];

              let isValidflag = true;
              if (values.panNumber !== undefined) {
                LoginService.TokenUser((res) => {
                  localStorage.setItem("jwt-token", res.responseObject);
                });
                // RegisterUserService.checkPAN(
                //   "learnerID=" +
                //   this.props.personnelData
                //     .learnerID +
                //   "&panNumber=" +
                // values.panNumber +
                //   "&aadharNumber="+"&learnerPartyID="+localStorage.getItem("userid-Token"),
                //   res => {
                //     if (res.status === "fail") {

                //       error(res.reasonText, {
                //         ...errorNotification
                //        // container: "top-center"
                //       });
                //       isValidflag=false

                //     }

                // }

                // );
              }
              if (values.aadharNumber !== undefined) {
                // RegisterUserService.checkPAN(
                //   "learnerID=" +
                //   this.props.personnelData
                //     .learnerID +
                //   "&panNumber=" +
                //   "&aadharNumber=" +
                //   values.aadharNumber+"&learnerPartyID="+localStorage.getItem("userid-Token"),
                //   res => {
                //     if (res.status === "fail") {
                //       error(res.reasonText, {
                //         ...errorNotification
                //      //   container: "top-center"
                //       });
                //       isValidflag=false
                //     }
                //   }
                // );
              }
              if (isValidflag) {
                if (
                  values.submitStatus === "SUBMITTED" &&
                  values.hasOwnProperty("professionalQualification") &&
                  values.professionalQualification !== "" &&
                  values.professionalQualification !== 0
                ) {
                  if (values.professionalQualification.length > 0) {
              
                    values.professionalQualification.map((ele) => {
                      if (ele.occupation == "1021") {
                        valData.push(ele.occupation);
                        valData.push("test");
                        valData.push("test");
                        valData.push("test");
                        // valData.push("test");
                        // valData.push("test");
                      } else {
                        //  alert(ele.totalExperience)
                        valData.push(ele.occupation);
                        valData.push(ele.companyName);
                        // valData.push(
                        //   ele.annualIncome !== "" ? ele.annualIncome : "0"
                        // );
                        valData.push(ele.totalExperience);
                        // valData.push(ele.fromDate);
                        // valData.push(ele.toDate);
                        // valData.push(ele.fromDay);
                        // valData.push(ele.fromMonth);
                        // valData.push(ele.fromYear);
                        // valData.push(ele.toDay);
                        // valData.push(ele.toMonth);
                        // valData.push(ele.toyear);
                      }
                    });
                  } else {
                    valData.push("");
                    valData.push("");
                    valData.push("");
                    valData.push("");
                    // valData.push("");
                    // valData.push("");
                  }
                }

                if (
                  values.submitStatus === "SUBMITTED" &&
                  values.hasOwnProperty("learnerQualification") &&
                  values.learnerQualification !== "" &&
                  values.learnerQualification !== 0
                ) {
                  if (values.learnerQualification.length > 0) {
                    values.learnerQualification.map((ele) => {
                      //let doc=ele.hasOwnProperty('document') && ele.document!=null ?ele.document[0].documentHash:ele.educationQualificationDocumentURL
                      //      eduQualificationvalData.push(doc)
                      eduQualificationvalData.push(ele.instituteName);
                    
                      eduQualificationvalData.push(ele.passingYear);
                      if(ele.percentage<=100){
                      eduQualificationvalData.push(ele.percentage);
                      }
                      else{
                        eduQualificationvalData.push("InvalidPercentage");
                      }
                      eduQualificationvalData.push(ele.qualificationType);
                    });
                  } else {
                    eduQualificationvalData.push("");
                    eduQualificationvalData.push("");
                    eduQualificationvalData.push("");
                    eduQualificationvalData.push("");
                  }
                }
                // return;
                if (
                  values.submitStatus === "SUBMITTED" &&
                  (values.addressProofCardURL === undefined ||
                    values.panCardURL === undefined ||
                    values.aadharCardURL === undefined ||
                    values.aadharCardBackURL === undefined)
                ) {
                  // && values.whoareu!=="1011"
                  if (values.document.length > 0) {
                    for (let i = 0; i < values.document.length; i++) {
                      if (values.document[i].documentType == "AddressProof") {
                        doc_temp3.push(values.document[i]);
                      }
                      //  else{
                      //   docData.push('requiredAddressProof')
                      //  }
                      if (values.document[i].documentType == "PanCard") {
                        doc_temp.push(values.document[i]);
                      }

                      if (values.document[i].documentType == "AadharCard") {
                        doc_temp1.push(values.document[i]);
                      }
                      if (
                        values.document[i].documentType == "AadharCardBackSide"
                      ) {
                        doc_temp2.push(values.document[i]);
                      }

                      if (values.document[i].documentType == "ProfilePhoto") {
                        doc_temp4.push(values.document[i]);
                      }

                      // else{
                      //   docData.push('requiredPanCard')
                      // }
                    }
                  }
                  // else{
                  //   doc_temp.push(values.document[{}])
                  // }
                }

                if (doc_temp.length == 0 && values.panCardURL == undefined) {
                  docData3.push("requiredPanCard");
                }
                if (doc_temp1.length == 0) {
                  docData1.push("requiredAadharCard");
                }
                if (
                  doc_temp3.length == 0 &&
                  values.addressProofCardURL == undefined
                ) {
                  docData.push("requiredAddressProof");
                }

                if (doc_temp2.length == 0) {
                  docData1.push("requiredAadharCardBackSide");
                }
                if (
                  doc_temp4.length == 0 &&
                  values.profilePhotoURL == undefined
                ) {
                  docData2.push("requiredProilePhoto");
                }

                let pan =
                  values.panCardURL === undefined && values.whoareu !== 1011
                    ? true
                    : false;
                console.log("valData:::", valData);
            
                if (
                  valData.includes(undefined) === true ||
                  valData.includes("") == true
                ) {
                  warning(
                    "Please select mandatory Fields of Professsional Qualification",
                    warningNotification
                  );
                  return;
                 
                }

                if (
                  values.submitStatus === "SUBMITTED" &&
                  values.addressProofCardURL === undefined &&
                  docData.length > 0
                ) {
                  if (docData.length > 0) {
                    if(values.condition2){

                    }
                    else{
                    if (docData.includes("requiredAddressProof")) {
                      // warning("Please upload Aadhaar back part and Aadhaar front part documents", warningNotification);
                      warning(
                        "Please upload all required documents-Address Proof",
                        warningNotification
                      );
                      return;
                    }
                  }
                  } else {
                    warning(
                      "Please upload all required documents-Address Proof",
                      warningNotification
                    );
                    return;
                  }
              
                }
                         if (
                  values.submitStatus === "SUBMITTED" &&
                  values.panCardURL === undefined &&
                  docData3.length > 0
                ) {
                  if (docData3.length > 0) {
                    if (docData3.includes("requiredPanCard")) {
                      // warning("Please upload Aadhaar back part and Aadhaar front part documents", warningNotification);
                      warning(
                        "Please upload all required documents-Pan Card",
                        warningNotification
                      );
                      return;
                    }
                  } else {
                    warning(
                      "Please upload all required documents-Pan Card",
                      warningNotification
                    );
                    return;
                  }
                }
                //aadhar upload code commented
                // else if (
                //   values.submitStatus === 'SUBMITTED' &&
                //   (values.aadharCardURL === undefined ||
                //     values.aadharCardBackURL === undefined) &&
                //   docData1.length > 0
                // ) {
                //   if (docData1.length > 0) {
                //     if (
                //       docData1.includes ('requiredAadharCardBackSide') &&
                //       docData1.includes ('requiredAadharCard')
                //     ) {
                //       warning (
                //         'Please upload all required documents aadhaar',
                //         warningNotification
                //       );
                //     } else if (
                //       docData1.includes ('requiredAadharCardBackSide')
                //     ) {
                //       warning (
                //         'Please upload all required documents',
                //         warningNotification
                //       );
                //     } else if (docData1.includes ('requiredAadharCard')) {
                //       warning (
                //         'Please upload all required documents',
                //         warningNotification
                //       );
                //     }

                //   } else {
                //     warning (
                //       'Please upload all required documents',
                //       warningNotification
                //     );
                //   }
                // }

                if (
                  values.submitStatus === "SUBMITTED" &&
                  values.profilePhotoURL === undefined &&
                  docData2.length > 0
                ) {
                  if (docData2.length > 0) {
                    if (docData2.includes("requiredProilePhoto")) {
                      // warning("Please upload Aadhaar back part and Aadhaar front part documents", warningNotification);
                      warning(
                        "Please upload all required documents-Profile Photo",
                        warningNotification
                      );
                 
                      return;
                    }
                  } else {
                    warning(
                      "Please upload all required documents-Profile Photo",
                      warningNotification
                    );
                    return;
                  }
                }
                if (
                  eduQualificationvalData.includes(undefined) === true ||
                  eduQualificationvalData.includes("") == true ||
                  eduQualificationvalData.includes(null) == true
                ) {
                  warning(
                    "please fill mandatory Fields of Educational Qualification",
                    warningNotification
                  );
             
                  return;
                } 
                else if ( eduQualificationvalData.includes("InvalidPercentage")===true){
                  warning(
                    "please Enter Valid Percentage",
                    warningNotification
                  );
             
                  return; 
                }
                  else {
                  if (
                    values.hasOwnProperty("learnerQualification") &&
                    values.learnerQualification !== ""
                  ) {
                    values.learnerQualification.map((ele) => {
                      delete ele.learnerQualificationID;
                      // delete ele.educationQualificationDocumentId
                    });
                  }
                  // if (values.edudocument) {
                  //   if (values.hasOwnProperty('learnerQualification') && values.learnerQualification !== "" && values.learnerQualification.length !=0) {
                  //     values.learnerQualification.map((ele, index) => {
                  //       let document=[]
                  //       document.push(values.edudocument[index])

                  //     ele.document = document

                  //     });
                  //   }
                  // }
                  // if (
                  //   values.hasOwnProperty("professionalQualification") &&
                  //   values.professionalQualification !== "" &&
                  //   values.professionalQualification !== 0
                  // ) {
                  //   values.professionalQualification.map((ele) => {
                  //     if (
                  //       moment(ele.fromDate, "YYYY-MM-DD", true).isValid() ==
                  //         true ||
                  //       moment(ele.toDate, "YYYY-MM-DD", true).isValid() == true
                  //     ) {
                  //       ele.fromDate = moment(ele.fromDate).format(
                  //         "DD/MM/YYYY HH:mm:ss"
                  //       );
                  //       ele.toDate = moment(ele.toDate).format(
                  //         "DD/MM/YYYY HH:mm:ss"
                  //       );
                  //     }
                  //   });
                  // }

                  if (
                    values.hasOwnProperty("CPEDetails") &&
                    values.CPEDetails !== "" &&
                    values.CPEDetails !== 0
                  ) {
                    values.CPEDetails.map((ele) => {
                      if (
                        moment(
                          ele.dateOfPassingTest,
                          "YYYY-MM-DD",
                          true
                        ).isValid() == true ||
                        moment(ele.dateOfCpe, "YYYY-MM-DD", true).isValid() ==
                          true
                      ) {
                        ele.dateOfPassingTest = moment(
                          ele.dateOfPassingTest
                        ).format("DD/MM/YYYY HH:mm:ss");
                        ele.dateOfCpe = moment(ele.dateOfCpe).format(
                          "DD/MM/YYYY HH:mm:ss"
                        );
                      }
                    });
                  }
                  // this.setState({ loading: true });
                  let address = [];
                  let perAddress = {
                    addressType: "permanent",
                    // addressType:values.addressTypeMark?"permanent":"",
                    addressTypeMark:values.addressTypeMark?"permanent":"",
                    addressId: values.perAddressID,
                    street: values.perStreet,
                    street2: values.perStreet2,
                    street3: values.perStreet3,
                    cityId: values.percityId,
                    stateId: values.perstateId,
                    countryId: values.percountryId,
                    postalCode: values.perPostalCode,
                  };
                  // let corAddress = {
                  //   addressType: "correspondence",
                  //   addressId: values.corAddressID,
                  //   street: values.corStreet,
                  //   street2: values.corStreet2,
                  //   street3: values.corStreet3,
                  //   cityId: values.corcityId,
                  //   stateId: values.corstateId,
                  //   countryId: values.corcountryId,
                  //   postalCode: values.corPostalCode,
                  // };
                  address.push(perAddress);
                  // address.push(corAddress);

                  // let percetentage = localStorage.getItem(
                  //   "profilecomlitionvalue"
                  // );
                  values.certificateValidUpto=NISMDATE
                  values.enrollmentDate=ENROLLMENTDATE
                  // setTimeout(async ()=>{
                  await this.updateCommonWeightage("","",values);
                  let updatedPercentage =await localStorage.getItem(
                    "profilecomlitionvalue"
                  );
                  values.professionalQualification&&values.professionalQualification.map((ele) => {
                    if(ele.hasOwnProperty("learneProfessionalrQualificationID")){
                      delete ele.learneProfessionalrQualificationID;
                    }
                  })
                  const { learnerEuinOption, submitStatus, preferredExamLocation, preferredExamCenter } = values;
                  const isEmpty = (val) => ["", null].includes(val);

                  if (isEmpty(learnerEuinOption)) {
                    if (submitStatus === "SUBMITTED") {
                      if (isEmpty(preferredExamLocation)) {
                        warning("You must specify Preferred Exam Location", warningNotification);
                        return;
                      }
                      if (isEmpty(preferredExamCenter)) {
                        warning("You must specify Preferred Exam Center", warningNotification);
                        return;
                      }
                    }
                    //  else if (submitStatus === "DRAFTED") {
                    //   if (!isEmpty(preferredExamLocation) && isEmpty(preferredExamCenter)) {
                    //     warning("If you have selected Preferred Exam Location then you must specify Preferred Exam Center Also", warningNotification);
                    //     return;
                    //   }
                    // }
                  }
                      let data = {
                      learnerEuinOption:this.state?.newLearnerDetails?.learnerEuinOption,
                        // userID: localStorage.getItem('userid'),
                        userID: this.state.userId,
                        partyID: localStorage.getItem("userid-Token"),
                        learnerPartyID: values.learnerPartyID,
                        learnerID: values.learnerID,
                        roleID: localStorage.roleId,
                        mobileNo: values.mobileNo,
                        emailID: values.emailID,
                        gender: values.gender,
                        maritalStatus: values.maritalStatus,
                        firstName: values.firstName,
                        middleName: values.middleName,
                        lastName: values.lastName,
                        // contactNo: values.contactNo,
                        contactNo:"",
                        secondaryEmailID: values.secondaryEmailID,
                        dob: DOB,
                        isMandateUser: this.props.personnelData.isMandateUser,
                        mandateId: this.props.personnelData.mandateId,
                        empNo: values.empNo,
                        // values.dob
                        //   ? moment(values.dob).format("DD/MM/YYYY HH:mm:ss")
                        //   : values.dob,
                        panNumber: values.panNumber,
                        aadharNumber: values.aadharNumber,
                        learnerFatherName: values.learnerFatherName
                          ? values.learnerFatherName
                          : " ",
                        learnerFatherDOB: values.learnerFatherDOB
                          ? moment(values.learnerFatherDOB).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : " ",
                        learnerFatherEmployementType: values.learnerFatherEmployementType
                          ? values.learnerFatherEmployementType
                          : 0,
                        learnerFatherCompanyName: values.learnerFatherCompanyName
                          ? values.learnerFatherCompanyName
                          : " ",
                        learnerMotherName: values.learnerMotherName
                          ? values.learnerMotherName
                          : " ",
                        learnerMotherDOB: values.learnerMotherDOB
                          ? moment(values.learnerMotherDOB).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : " ",
                        learnerMotherEmployementType: values.learnerMotherEmployementType
                          ? values.learnerMotherEmployementType
                          : 0,
                        learnerMotherCompanyName: values.learnerMotherCompanyName
                          ? values.learnerMotherCompanyName
                          : " ",
                        learnerQualification:
                          values.learnerQualification.length != 0
                            ? values.learnerQualification.filter((x) => !!x)
                            : [],
                        address,
                        isActive: 1,
                        document: values.document,
                        resumeDocumentURL: values.resumeDocumentURL,
                        // profilePhotoURL: values.profilePhotoURL,
                        // chequePhotoURL: values.chequePhotoURL,
                        resumeVideoURL: values.resumeVideoURL,
                        // passportNumber: values.passportNumber,
                        passportNumber:"",
                        // bankName: values.bankName,
                        // branchName: values.branch,
                        // branchAddress: values.branchAddress,
                        // accountType: values.accountType,
                        // accountNumber: values.acccountNumber,

                        bankName: "Default Bank",
                        branchName: "Default Branch",
                        branchAddress: "Default Address",
                        accountType: "1",
                        accountNumber: "00000000000",

                        arn: values.ARNNO,
                        nameOfARNHolder: values.NameOfARNHolder,
                        nameOfMutualFundEmpanelled:
                          values.ARNuserFeilds !== undefined
                            ? values.ARNuserFeilds.filter((x) => !!x)
                            : null,

                        postalCode: values.perPostalCode,
                        salutation: values.salutation,
                        learnerProfessionalQualification:
                          values.professionalQualification !== "" &&
                          values.professionalQualification !== 0
                            ? values.professionalQualification.filter((x) => !!x)
                            : null,
                        learnerFatherName: values.fatherName,
                        // applicantSpouseFather: values.applicantSpouseFather,
                        applicantSpouseFather: "",
                        learnerCpeDetails:
                          values.CPEDetails !== "" && values.CPEDetails !== 0
                            ? values.CPEDetails.filter((x) => !!x)
                            : null,
                        proofOfAddress: values.peraddressproof1,
                        applicationStatus: values.submitStatus,
                        learnerType: values.whoareu,
                        // profileCompletion:
                        //   localStorage.getItem("profilecomlitionvalue") < 60
                        //     ? 61
                        //     : localStorage.getItem("profilecomlitionvalue"),
                        profileCompletion:updatedPercentage,
                        isTourCompleted: 1,
                        //euinNumber:values.euinNumber,
                        isAddressSame: true,
                        learnerProfession: values.currentProfession,
                        workflowName:
                          this.state.processType === "1001" ? "EUIN" : "EUIN",
                        //ifscCode: values.ifscCode,
                        ifscCode: "Default0000",
                        isCertificate: values.isCertificate,
                        //isCertificate: this.state.isCertificate,
                        nismRegistrationNo: this.state?.newLearnerDetails?.learnerEuinOption && 
                        ![null,undefined,"null","undefined"].includes(this.state?.newLearnerDetails?.learnerEuinOption) &&
                        this.state?.newLearnerDetails?.learnerEuinOption !== "NISM" ?
                          values.certificateNumber : "",
                        enrollmentNumber: this.state?.newLearnerDetails?.learnerEuinOption && 
                        ![null,undefined,"null","undefined"].includes(this.state?.newLearnerDetails?.learnerEuinOption) &&
                        this.state?.newLearnerDetails?.learnerEuinOption !== "NISM" ? 
                        values.enrollmentNumber : "" ,
                        euinNumber: this.state?.newLearnerDetails?.learnerEuinOption && 
                        ![null,undefined,"null","undefined"].includes(this.state?.newLearnerDetails?.learnerEuinOption) &&
                        this.state?.newLearnerDetails?.learnerEuinOption !== "NISM" ? 
                        values.euinNumber : "",
                        certificateValidUpto: this.state?.newLearnerDetails?.learnerEuinOption && 
                        ![null,undefined,"null","undefined"].includes(this.state?.newLearnerDetails?.learnerEuinOption) &&
                        this.state?.newLearnerDetails?.learnerEuinOption !== "NISM" ? 
                        NISMDATE : "",
                        enrollmentDate: this.state?.newLearnerDetails?.learnerEuinOption && 
                        ![null,undefined,"null","undefined"].includes(this.state?.newLearnerDetails?.learnerEuinOption) &&
                        this.state?.newLearnerDetails?.learnerEuinOption !== "NISM" ? 
                        ENROLLMENTDATE : "",
                        euinValidUpto: EUINDATE,
                        companyName: values.companyId,
                        otherCompanyName: values.otherCompanyName,
                      };
                      console.log("vkd updateUserProfile:::: ", data);
                      console.log('vkd values vvvv',values);
                      if(values.orgCompanyName==="HDFC Bank Limited" && values.submitStatus === "SUBMITTED"){
                        this.setState({ submitHDFCBtnDisabled: true });
                      }else if(values.orgCompanyName==="HDFC Bank Limited" && values.submitStatus === "DRAFTED"){
                        this.setState({ saveDraftHDFCBtnDisabled: true });
                      }if(values.submitStatus === "SUBMITTED"){
                        this.setState({ submitBtnDisabled: true });
                      }else if(values.submitStatus === "DRAFTED"){
                        this.setState({ saveDraftBtnDisabled: true });
                      }
                      
                      // if (
                      //   docData3.length === 0 &&
                      //   docData.length === 0 &&
                      //   docData2.length === 0
                      // ) {
                        this.setState({ loading: true });
                        let mandateDetails = JSON.parse(localStorage.getItem("mandateDetails"));
                        RegisterUserService.updateUserProfile(data, (res) => {
                          console.log(data.applicationStatus);
                          if (res.status === "success") {
                          
                            console.log("updateResponse", res);
                            this.setState({ loading: false });
                            this.setState({ loading1: "none" });

                            localStorage.setItem("reload", "true");
                            if (localStorage.getItem("roleId") == 5) {
                              if (data.applicationStatus === "SUBMITTED") {
                                // success (
                                //   'Your profile has been successfully submitted. Please proceed to make payment of Rs.1500/- towards your NISM Examination fees.',
                                //   successNotification
                                // );
                                if(values.preferredMandateName === "NISM" || ["", null].includes(values.learnerEuinOption)){
                                  if(!["",null,undefined].includes(mandateDetails)){
                                    const { isSponsored, hasTraining } = mandateDetails;
                                    const isIncluded = (val, arr) => arr.includes(val);
                                    
                                    if (isIncluded(isSponsored, [1, "1"])) {
                                      if (isIncluded(hasTraining, [1, "1"])) {
                                    success1(<FirstMessage />,successNotification1);
                                      } else if (isIncluded(hasTraining, [0, "0"])) {
                                    success1(<SecondMessage />,successNotification1);
                                      }
                                    } else if (isIncluded(isSponsored, [0, "0"])) {
                                    success1(<ThirdMessage />,successNotification1);
                                    }else{
                                      success("Your profile has been successfully submitted.",successNotification);  
                                    }
                                  }else{
                                    success("Your profile has been successfully submitted.",successNotification);
                                  }
                                }else{
                                  success(
                                    "Your profile has been successfully submitted.",
                                    successNotification
                                    );
                                  }
                                if(values.preferredMandateName === "NISM" || ["", null].includes(values.learnerEuinOption)){
                                  var data2 = localStorage.getItem("PersonnelData");
                                  let data = [
                                    {
                                      action: "PROFILE COMPLETE",
                                      workflowId: JSON.parse(data2).responseObject.learnerID,
                                      additionalDetails: {
                                        stateId: values.preferredExamLocation,
                                        stateName: values.preferredExamLocationState,
                                        center: values.preferredExamCenter,
                                        centerName: values.preferredExamCenter,
                                        requestedDate: "2000-1-1",
                                        flag: false,
                                        isconfirmbycilopps: false,
                                      },
                                    },
                                  ];
                                ReattemptTestService.UpdateWorkflow(data, (res) => {})}
                                setTimeout(() => {
                                  this.props.history.push("/learnerDashboard");
                                }, 5000);
                              } else {
                                const { preferredMandateName, learnerEuinOption, preferredExamLocation, preferredExamCenter } = values;

                                if (
                                  (preferredMandateName === "NISM" || ["", null].includes(learnerEuinOption)) &&
                                  ![null, "", undefined].includes(preferredExamLocation) &&
                                  ![null, "", undefined].includes(preferredExamCenter)
                                ) {
                                  info("If you want to save the details of Preferred Exam Location & Center then you must click on Submit Button. On Click of Save Draft Button the details will not save.",infoNotification);
                                }

                                setTimeout(() => {
                                  success(
                                    "Welcome to CIELs Regulatory Platform. Your profile is created and saved as draft. Fill in all mandatory details to submit your profile successfully.",
                                    successNotification
                                    );
                                },2000);
                                if(values.preferredMandateName === "NISM" || ["", null].includes(values.learnerEuinOption)){
                                  if(!["",null,undefined].includes(values.preferredExamLocation) &&
                                  !["",null,undefined].includes(values.preferredExamCenter)){
                                  var data2 = localStorage.getItem("PersonnelData");
                                  let data = [
                                    {
                                      action: "PROFILE COMPLETE",
                                      workflowId: JSON.parse(data2).responseObject.learnerID,
                                      additionalDetails: {
                                        stateId: values.preferredExamLocation,
                                        stateName: values.preferredExamLocationState,
                                        center: values.preferredExamCenter,
                                        centerName: values.preferredExamCenter,
                                        requestedDate: "2000-1-1",
                                        flag: false,
                                        isconfirmbycilopps: false,
                                      },
                                    },
                                  ];
                                ReattemptTestService.UpdateWorkflow(data, (res) => {})}}
                                setTimeout(() => {
                                  window.location.reload();
                                }, 5000);
                              }
                              //  this.props.history.push("/learnerDashboard");
                            } else {
                              success(
                                "Your profile has been successfully submitted.",
                                successNotification
                              );
                              setTimeout(() => {
                                window.location.reload();
                                //this.props.history.push('/dashboard');
                              }, 5000);
                            }
                          } else {
                            // this.setState({ loading1: "none" });
                            this.setState({ loading: false, 
                              submitBtnDisabled: false, saveDraftBtnDisabled: false,
                              submitHDFCBtnDisabled: false, saveDraftHDFCBtnDisabled: false
                             });
                            error(res.reasonText, {
                              ...errorNotification,
                              // container: "top-center"
                            });
                            // else {
                            //   if (res.status === "fail") {
                            //     this.setState({ loading: false });
                            //     success("Error", errorNotification);
                            //     this.props.history.push("/learnerDashboard");
                            //   }
                          }
                        });
                      
                    // },400)
                    // }
                    // else if(values.submitStatus==="DRAFTED"){
                    //   let data = {
                    //     userID: localStorage.getItem("userid"),
                    //     partyID: localStorage.getItem("userid-Token"),
                    //     learnerPartyID: values.learnerPartyID,
                    //     learnerID: values.learnerID,
                    //     roleID: localStorage.roleId,
                    //     mobileNo: values.mobileNo,
                    //     emailID: values.emailID,
                    //     gender: values.gender,
                    //     firstName: values.firstName,
                    //     middleName: values.middleName,
                    //     lastName: values.lastName,
                    //     contactNo: values.contactNo,
                    //     secondaryEmailID: values.secondaryEmailID,
                    //     dob: DOB,
                    //     // values.dob
                    //     //   ? moment(values.dob).format("DD/MM/YYYY HH:mm:ss")
                    //     //   : values.dob,
                    //     panNumber: values.panNumber,
                    //     aadharNumber: values.aadharNumber,
                    //     learnerFatherName: values.learnerFatherName
                    //       ? values.learnerFatherName
                    //       : " ",
                    //     learnerFatherDOB: values.learnerFatherDOB
                    //       ? moment(values.learnerFatherDOB).format("DD/MM/YYYY HH:mm:ss")
                    //       : " ",
                    //     learnerFatherEmployementType: values.learnerFatherEmployementType
                    //       ? values.learnerFatherEmployementType
                    //       : 0,
                    //     learnerFatherCompanyName: values.learnerFatherCompanyName
                    //       ? values.learnerFatherCompanyName
                    //       : " ",
                    //     learnerMotherName: values.learnerMotherName
                    //       ? values.learnerMotherName
                    //       : " ",
                    //     learnerMotherDOB: values.learnerMotherDOB
                    //       ? moment(values.learnerMotherDOB).format("DD/MM/YYYY HH:mm:ss")
                    //       : " ",
                    //     learnerMotherEmployementType: values.learnerMotherEmployementType
                    //       ? values.learnerMotherEmployementType
                    //       : 0,
                    //     learnerMotherCompanyName: values.learnerMotherCompanyName
                    //       ? values.learnerMotherCompanyName
                    //       : " ",
                    //     learnerQualification: values.learnerQualification.length!=0?values.learnerQualification.filter(x => !!x):[],
                    //     address,
                    //     isActive: 1,
                    //     document: values.document,
                    //     resumeDocumentURL: values.resumeDocumentURL,
                    //     profilePhotoURL: values.profilePhotoURL,
                    //     resumeVideoURL: values.resumeVideoURL,
                    //     passportNumber: values.passportNumber,
                    //       bankName: values.bankName,
                    //       branchName: values.branch,
                    //       branchAddress: values.branchAddress,
                    //       accountType: values.accountType,
                    //       accountNumber: values.acccountNumber,

                    //       arn: values.ARNNO,
                    //       nameOfARNHolder: values.NameOfARNHolder,
                    //       nameOfMutualFundEmpanelled: values.ARNuserFeilds !==undefined ? values.ARNuserFeilds.filter(x => !!x) : null,

                    //       postalCode: values.perPostalCode,
                    //       salutation: values.salutation,
                    //       learnerProfessionalQualification: values.professionalQualification !== "" && values.professionalQualification !== 0 ? values.professionalQualification.filter(x => !!x) : null,
                    //       learnerFatherName: values.fatherName,
                    //       learnerCpeDetails: values.CPEDetails !== "" && values.CPEDetails !== 0 ? values.CPEDetails.filter(x => !!x) : null,
                    //       proofOfAddress: values.peraddressproof1,
                    //       applicationStatus: values.submitStatus,
                    //       learnerType: values.whoareu,
                    //       profileCompletion: localStorage.getItem('profilecomlitionvalue'),
                    //       isTourCompleted:1,
                    //       euinNumber:values.euinNumber,
                    //       isAddressSame:true,
                    //       learnerProfession:values.currentProfession,
                    //       workflowName:this.state.processType === "1001"?"ARN":"ARN",
                    //       ifscCode:values.ifscCode

                    //   };
                    //   //console.log("update", data);
                    //   RegisterUserService.updateUserProfile(data, res => {
                    //     console.log(data.applicationStatus)
                    //     if (res.status === "success") {
                    //       this.setState({ loading: false });
                    //       this.setState({ loading1: "none" });

                    //       localStorage.setItem("reload", "true");
                    //       if(localStorage.getItem('roleId')==5)
                    //       {
                    //         if(data.applicationStatus==="SUBMITTED")
                    //         {

                    //           success(
                    //             'Your profile has been "Submitted". Please click on "Initiate Course" below FEAT to proceed.',
                    //             successNotification
                    //           );
                    //           setTimeout(() => {
                    //             this.props.history.push("/learnerDashboard");
                    //                             }, 5000)

                    //         }
                    //         else{
                    //           success(
                    //             'Your profile has been "Saved as a Draft". Please provide all mandatory information to "Submit" your profile and undertake FEAT.',
                    //             successNotification
                    //           );
                    //           setTimeout(() => {
                    //             window.location.reload();
                    //                             }, 5000)
                    //         }
                    //     //  this.props.history.push("/learnerDashboard");
                    //       }
                    //       else{

                    //         setTimeout(() => {
                    //           this.props.history.push("/dashboard");
                    //                           }, 5000)

                    //       }
                    //     } else {
                    //       // this.setState({ loading1: "none" });
                    //       this.setState({ loading: false });
                    //       error(res.reasonText, {
                    //         ...errorNotification
                    //        // container: "top-center"
                    //       });
                    //       // else {
                    //       //   if (res.status === "fail") {
                    //       //     this.setState({ loading: false });
                    //       //     success("Error", errorNotification);
                    //       //     this.props.history.push("/learnerDashboard");
                    //       //   }
                    //     }
                    //   });
                    // }
                    // else{
                    //   this.setState({ loading: false });
                    //   warning("You have either not filled all mandatory fields or not uploaded all document for NISM or your profile might be less than 60%. In case you don't have your documents ready, please save your profile as 'Save Draft'. Add the documents to move to the next section",warningNotification)
                    // }

                    // }else{
                    //   if(docData.length > 0){
                    //     warning("Upload the Address Proof Again ",warningNotification)
                    //   }
                  // }
                }
              }
            }
          }
        }}
        render={({
          errors,
          touched,
          handleSubmit,
          handleChange,
          isValid,
          values,
          setFieldValue,
          formProps,
          validateField,
          handleBlur,
          form,
          ...formikProps
        }) => (
          <React.Fragment>
            {/* <div
              className=""
              id="roadmap1"
             tabindex="-1" role="dialog" aria-labelledby="modalLabel"    data-keyboard="true" aria-hidden="true"
            > */}
            {/* {this.state.learnerEuinOption==="Frest EUIN"? */}
            {/* <pre>{JSON.stringify(errors,null,2)}</pre> */}
            {/* <pre>{JSON.stringify(values.percityId,null,2)}</pre>
            <pre>{JSON.stringify(this.props.addressData[0].cityId,null,2)}</pre>
            <pre>{JSON.stringify(this.props.addressData[0],null,2)}</pre>
             */}
            <div
              className="modal fade"
              id="opt12"
              tabindex="-1"
              role="dialog"
              aria-labelledby="modalLabel"
              data-backdrop="static"
              data-keyboard="false"
              aria-hidden="true"
              // closeOnOverlayClick={false}
            >
              
              <div className="modal-dialog modal-md">
                <div className="modal-content">
                  <div className="modal-header"></div>
                  <div className="modal-body">
                    <h4>
                      This process is currently done by submitting hard copies
                      of the application with required documents.
                    </h4>
                    <br />
                    <br />
                    <i className="fa fa-dot-circle-o"></i> Download the
                    application and fill in all details. <br />
                    <br />
                    <div>
                      <a href={fresh} download>
                        <i className="fa fa-download"></i> EUIN Fresh
                        Application
                      </a>{" "}
                      <br />
                      <a href={renewal} download>
                        <i className="fa fa-download"></i> EUIN renewal
                        Application
                      </a>{" "}
                      <br />
                      <a href={mapping} download>
                        <i className="fa fa-download"></i> EUIN Mapping
                        Application
                      </a>{" "}
                    </div>
                    <br />
                    <i className="fa fa-dot-circle-o"></i> Attach the documents
                    required mentioned in the application. <br />
                    <br />
                    <i className="fa fa-dot-circle-o"></i> Ensure to mention
                    your EMP id on the form at the top right-hand corner. <br />
                    <br />
                    <i className="fa fa-dot-circle-o"></i>Ensure to mention your
                    organization ARN no. correctly to avoid any confusion and
                    delay in processing. <br />
                    <br />
                    <i className="fa fa-dot-circle-o"></i> Application completed
                    in all respect with documents must be sent to CIEL at the
                    following address – <br />
                    <br />
                    <span style={{ color: "blue" }}>
                      Centre for Investment Education and Learning Unit no. 320,
                      Plot No. 105, Champaklal Industrial Estate, Next to Sion
                      Telephone Exchange (situated at Sion Koliwada Road), Sion
                      East, Mumbai 400022
                    </span>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn-5 mt-40"
                      name="btnNo"
                      onClick={(e) => {
                        // alert("are you")
                        // setTimeout( ()=>{this.props.history.push('/learnerdashboard')},2000)

                        window.location.href = "/learnerdashboard";
                      }}
                    >
                      Go Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* :""

} */}

            <div
              className="modal fade"
              id="roadmap1"
              tabindex="-1"
              role="dialog"
              aria-labelledby="modalLabel"
              data-keyboard="true"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <div className="scr-board">
                      <h2>Why Fill The Form? </h2>
                      <div className="bottom-line" />
                    </div>
                    {/* <h4 className="modal-title">FinX Core Login</h4> */}
                  </div>
                  <div className="modal-body">
                    {/* The Profile Form is a means of gathering your information which would help us in serving you more efficiently through your journey towards becoming a Mutual Fund Advisor. */}
                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <p>
                        <b>Welcome to the RCP Platform!</b>
                      </p>{" "}
                      <br />
                      <p>
                        The platform will take you through your journey of NISM
                        certification/Training and EUIN or as mandated by your
                        employer.
                      </p>
                      <p>
                        We will be collecting the following information from you
                        to create your NISM Profile and undertake your enrolment
                        for the NISM VA MF Certification Examination. Fill all
                        the “Compulsory Fields”, which have been marked with a
                        red asterisk (*). In case you are only coming for EUIN,
                        you will still need to fill the profile and put in your
                        certificate number and validity. This information will
                        be passed on to CAMS seamlessly for generating your
                        EUIN.
                      </p>
                      <ul>
                        <li>
                          <b>
                            The profile details are required by National
                            Institute of Securities Markets (NISM) for your
                            Profile Creation & NISM VA Exam Registration
                          </b>
                        </li>
                        <li>
                          <b>Please Keep the following documents ready </b>
                          <ul>
                            <li>
                              Scanned PAN{" "}
                              <span className="validation">
                                (File size less than 1 MB)
                              </span>
                            </li>
                            <li>
                              Scanned Proof of Address - Electricity Bill /
                              Ration Card / Passport / Voter ID
                              <span className="validation">
                                (File size less than 1 MB)
                              </span>
                            </li>
                            <li>
                              Profile Image{" "}
                              <span className="validation">
                                (only JPEG/JPG/PNG formats accepted)
                              </span>
                              <br />
                              <span className="validation">
                                Please note that the file you upload should NOT{" "}
                                have ANY special character in the name of the
                                attachment file.
                              </span>
                            </li>
                          </ul>
                        </li>
                        <li>
                          Click on “<b>Submit</b>” after filling all the
                          compulsory fields. Click on “<b>Save Draft</b>” if you{" "}
                          intend on updating your profile at a later date.
                          Kindly note that “<b>Submitting your Profile</b>” is a{" "}
                          <i>mandatory requirement</i> to continue with the RCP
                          Journey
                        </li>
                        <li>
                          <b>
                            Please note you will be able to submit the profile
                            details & move to next section ONLY after filing all
                            mandatory information (Marked as{" "}
                            <span className="validation">*</span>, including upload
                            of scanned documents)
                          </b>
                        </li>
                        <li>
                          <b>
                            The form may take 15 mins of your precious time.
                            Please be patient
                          </b>
                        </li>
                        <li>
                          NISM Certificate Details: Please fill this only if you
                          have a valid NISM certificate. Once you fill your
                          valid certificate details, and complete your profile,
                          the platform will take you for your EUIN completion.
                          These details are mandatory if you have come to
                          complete the EUIN journey on RCP.
                        </li>
                        <li>
                          EUIN Details: In case you are apply for a EUIN
                          renewal, you need to give us your EUIN details – EUIN
                          number and validity. Also you need to necessarily
                          update your NISM certificate details above with valid
                          certificate number
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="modal-footer">
                    {/* <button
                      type="button"
                      className="btn-5 mt-40 mr-20"
                      name="btnYes"
                    onClick={this.getPaymentLink}
                    >
                      Yes
                    </button> */}
                    <button
                      type="button"
                      className="btn-5 mt-40"
                      name="btnNo"
                      onClick={(e) => {
                        window.$("#roadmap1").modal("hide");
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {this.state.loading && (
              <PopUpPortal
                HidePopup={this.state.loading}
                IsVisible={this.state.loading}
              />
            )}
            <section className="dashboard-tab user-profile">
              <div className="container">
                <div className="row">
                  {/* <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                  <span>
                  <h2>User Profile
                  <a onClick={this.WhyTheFillForm} ><i className="fa fa-question-circle-o" aria-hidden="true" style={{fontSize:"24px", paddingLeft: "5px"}}></i></a>
                  </h2>  </span>
                  </div> */}
                  {/* <div id="loading-bar-spinner" class="spinner" style={{ display: this.state.loading1 }}>
                        <div class="spinner-icon">
                        </div>
                      </div> */}
                  <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <div className="profile-left-sidebar">
                      <div className="profile-sidebar">
                        <div className="table-header">
                          <div className="container-fluid">
                            <div id="topheader">
                              <nav>
                                <ul className="tab">
                                  <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12 hidden-xs hidden-sm pl pr">
                                    <div className="container-fluid">
                                      <div id="topheader">
                                        <nav>
                                          {/* {values.mobileNo} */}

                                          <ul className="tab">
                                            <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12 hidden-xs hidden-sm pl pr">
                                              <li className="active">
                                                <a
                                                  onClick={(e) => {
                                                    this.goto(
                                                      "distributor-profile"
                                                    );
                                                  }}

                                                  //href="#distributor-profile"
                                                >
                                                  {/* Distributor Profile */}
                                                  Profile Instructions
                                                </a>
                                              </li>
                                              <li className="">
                                                <a
                                                  onClick={(e) => {
                                                    this.goto("create-user");
                                                  }}

                                                  // href="#create-user"
                                                >
                                                  Personal Details{" "}
                                                </a>

                                                {
                                                  values.mobileNo &&
                                                  // values.contactNo &&
                                                  values.emailID &&
                                                  values.gender &&
                                                  values.maritalStatus &&
                                                  values.secondaryEmailID &&
                                                  values.firstName &&
                                                  values.middleName &&
                                                  values.lastName &&
                                                  values.dob &&
                                                  values.panNumber &&
                                                  values.aadharNumber &&
                                                  values.empNo
                                                    ? ""
                                                    : // <span className="pull-right">
                                                      //   {" "}
                                                      //   <i
                                                      //     className="fa fa-check-circle"
                                                      //     aria-hidden="true"
                                                      //   ></i>{" "}
                                                      // </span>
                                                      ""
                                                  // <span className="pull-right">
                                                  //   {" "}
                                                  //   <i
                                                  //     className="fa fa-times-circle red"
                                                  //     aria-hidden="true"
                                                  //   ></i>
                                                  // </span>
                                                }
                                              </li>
                                              <li>
                                                <a
                                                  //href="#create-address"

                                                  onClick={(e) => {
                                                    this.goto("create-address");
                                                  }}
                                                >
                                                  Address Details{" "}
                                                </a>

                                                {
                                                  values.perStreet &&
                                                  values.perStreet2 &&
                                                  values.perPostalCode &&
                                                  values.percityId &&
                                                  values.perstateId &&
                                                  values.percountryId
                                                    ? ""
                                                    : // <span className="pull-right">
                                                    //   {" "}
                                                    //   <i
                                                    //     className="fa fa-check-circle"
                                                    //     aria-hidden="true"
                                                    //   ></i>{" "}
                                                    // </span>
                                                    "" &&
                                                      // <span className="pull-right">
                                                      //   {" "}
                                                      //   <i
                                                      //     className="fa fa-times-circle red"
                                                      //     aria-hidden="true"
                                                      //   ></i>
                                                      // </span>
                                                      values.corStreet &&
                                                      values.corStreet2 &&
                                                      values.corPostalCode &&
                                                      values.corcityId &&
                                                      values.corstateId &&
                                                      values.corcountryId
                                                    ? ""
                                                    : // <span className="pull-right">
                                                      //   {" "}
                                                      //   <i
                                                      //     className="fa fa-check-circle"
                                                      //     aria-hidden="true"
                                                      //   ></i>{" "}
                                                      // </span>
                                                      ""
                                                  // <span className="pull-right">
                                                  //   {" "}
                                                  //   <i
                                                  //     className="fa fa-times-circle red"
                                                  //     aria-hidden="true"
                                                  //   ></i>
                                                  // </span>
                                                }
                                              </li>

                                              {(values.learnerFatherEmployementType ===
                                                "6" ||
                                                values.learnerFatherEmployementType ===
                                                  6 ||
                                                values.learnerFatherEmployementType ===
                                                  "8" ||
                                                values.learnerFatherEmployementType ===
                                                  8) &&
                                              (values.learnerMotherEmployementType ===
                                                "6" ||
                                                values.learnerMotherEmployementType ===
                                                  6 ||
                                                values.learnerMotherEmployementType ===
                                                  "8" ||
                                                values.learnerMotherEmployementType ===
                                                  8) ? (
                                                <li>
                                                  <a
                                                    onClick={(e) => {
                                                      this.goto(
                                                        "educational-details"
                                                      );
                                                    }}
                                                    //href="#educational-details"
                                                  >
                                                    Educational Details{" "}
                                                  </a>
                                                </li>
                                              ) : (values.learnerFatherEmployementType ===
                                                  "6" ||
                                                  values.learnerFatherEmployementType ===
                                                    6 ||
                                                  values.learnerFatherEmployementType ===
                                                    "8" ||
                                                  values.learnerFatherEmployementType ===
                                                    8) &&
                                                (values.learnerMotherEmployementType !==
                                                  "6" ||
                                                  values.learnerMotherEmployementType !==
                                                    6 ||
                                                  values.learnerMotherEmployementType !==
                                                    "8" ||
                                                  values.learnerMotherEmployementType !==
                                                    8) ? (
                                                <li>
                                                  <a href="#create-parent">
                                                    Professional Details{" "}
                                                  </a>
                                                  {
                                                    values.learnerFatherName &&
                                                    values.learnerFatherDOB &&
                                                    values.learnerFatherEmployementType &&
                                                    values.learnerMotherName &&
                                                    values.learnerMotherDOB &&
                                                    values.learnerMotherEmployementType &&
                                                    values.learnerMotherCompanyName
                                                      ? ""
                                                      : // <span className="pull-right">
                                                        //   {" "}
                                                        //   <i
                                                        //     className="fa fa-check-circle"
                                                        //     aria-hidden="true"
                                                        //   ></i>{" "}
                                                        // </span>
                                                        ""
                                                    // <span className="pull-right">
                                                    //   {" "}
                                                    //   <i
                                                    //     className="fa fa-times-circle red"
                                                    //     aria-hidden="true"
                                                    //   ></i>
                                                    // </span>
                                                  }
                                                </li>
                                              ) : (values.learnerFatherEmployementType !==
                                                  "6" ||
                                                  values.learnerFatherEmployementType !==
                                                    6 ||
                                                  values.learnerFatherEmployementType !==
                                                    "8" ||
                                                  values.learnerFatherEmployementType !==
                                                    8) &&
                                                (values.learnerMotherEmployementType ===
                                                  "6" ||
                                                  values.learnerMotherEmployementType ===
                                                    6 ||
                                                  values.learnerMotherEmployementType ===
                                                    "8" ||
                                                  values.learnerMotherEmployementType ===
                                                    8) ? (
                                                <li>
                                                  <a
                                                    // href="#educational-details"

                                                    onClick={(e) => {
                                                      this.goto(
                                                        "educational-details"
                                                      );
                                                    }}
                                                  >
                                                    Educational Details{" "}
                                                  </a>
                                                </li>
                                              ) : (
                                                <li>
                                                  <a
                                                    //href="#educational-details"
                                                    onClick={(e) => {
                                                      this.goto(
                                                        "educational-details"
                                                      );
                                                    }}
                                                  >
                                                    Educational Details{" "}
                                                  </a>
                                                  {
                                                    values.learnerFatherName &&
                                                    values.learnerFatherDOB &&
                                                    values.learnerFatherEmployementType &&
                                                    values.learnerMotherName &&
                                                    values.learnerMotherDOB &&
                                                    values.learnerMotherEmployementType &&
                                                    values.learnerFatherCompanyName &&
                                                    values.learnerMotherCompanyName
                                                      ? ""
                                                      : // <span className="pull-right">
                                                        //   {" "}
                                                        //   <i
                                                        //     className="fa fa-check-circle"
                                                        //     aria-hidden="true"
                                                        //   ></i>{" "}
                                                        // </span>
                                                        ""
                                                    // <span className="pull-right">
                                                    //   {" "}
                                                    //   <i
                                                    //     className="fa fa-times-circle red"
                                                    //     aria-hidden="true"
                                                    //   ></i>
                                                    // </span>
                                                  }
                                                </li>
                                              )}

                                              {(
                                                values
                                                  .learnerQualification[0] || ""
                                              ).passingYear ? (
                                                <li>
                                                  <a
                                                    onClick={(e) => {
                                                      this.goto(
                                                        "create-qualification"
                                                      );
                                                    }}

                                                    //href="#create-qualification"
                                                  >
                                                    Professional Details
                                                  </a>
                                                </li>
                                              ) : (
                                                <li>
                                                  <a
                                                    // href="#create-qualification"
                                                    onClick={(e) => {
                                                      this.goto(
                                                        "create-qualification"
                                                      );
                                                    }}
                                                  >
                                                    Professional Details
                                                  </a>
                                                </li>
                                              )}
                                              {/* <li className="">
                                                <a
                                                  onClick={e => {
                                                    this.goto (
                                                      'banking-details'
                                                    );
                                                  }}
                                                >
                                                  Banking Details
                                                </a>
                                              </li> */}

                                              {this.state
                                                .assignedUserMandateName !==
                                                undefined &&
                                              this.state
                                                .assignedUserMandateName !==
                                                "NISM" ? (
                                                <li className="">
                                                  <a
                                                    onClick={(e) => {
                                                      this.goto(
                                                        "NISMCertificate"
                                                      );
                                                    }}
                                                  >
                                                    {" "}
                                                    NISM Certificate Details{" "}
                                                  </a>
                                                  {values.isCertificate &&
                                                  values.certificateNumber &&
                                                  values.certificateValidUpto
                                                    ? ""
                                                    : ""}
                                                </li>
                                              ) : (
                                                ""
                                              )}
                                              {this.state
                                                .assignedUserMandateName !==
                                                undefined &&
                                              this.state
                                                .assignedUserMandateName !==
                                                "NISM" ? (
                                                <li>
                                                  <a
                                                    onClick={(e) => {
                                                      this.goto("EUINDetails");
                                                    }}
                                                  >
                                                    {" "}
                                                    EUIN Details
                                                  </a>
                                                </li>
                                              ) : (
                                                ""
                                              )}
                                              {(values.preferredMandateName === "NISM" 
                                              || ["", null].includes(values.learnerEuinOption))
                                              && 
                                              (<li>
                                                <a
                                                  onClick={(e) => {
                                                    this.goto("Preferred-NISM-Exam-Location");
                                                  }}
                                                >
                                                  {" "}Preferred NISM Exam Location
                                                </a>
                                              </li>)}
                                              <li>
                                                <a
                                                  onClick={(e) => {
                                                    this.goto("ARNDetails");
                                                  }}
                                                >
                                                  {" "}
                                                  ARN Details
                                                </a>
                                                {values.companyName ? "" : ""}
                                              </li>

                                              <li>
                                                <a
                                                  onClick={(e) => {
                                                    this.goto("create-profile");
                                                  }}
                                                >
                                                  Profile Details
                                                </a>
                                                {(values.resumeDocumentURL ||
                                                  values.resumeDocumentName) &&
                                                values.profilePhotoURL &&
                                                values.resumeVideoURL
                                                  ? ""
                                                  : ""}
                                              </li>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                              <UserProfileComplitionBarCommon
                                                personnelProfileData={
                                                  this.props.personnelData
                                                }
                                                personnelAddressData={
                                                  this.props.addressData
                                                }
                                                learnerEuinOption={this.props.learnerEuinOption}
                                              />
                                            </div>
                                            <li>
                                              <a onClick={this.WhyTheFillForm}>
                                                {" "}
                                                User Profile
                                                <i
                                                  className="fa fa-question-circle-o"
                                                  aria-hidden="true"
                                                  style={{
                                                    fontSize: "20px",
                                                    paddingLeft: "5px",
                                                    paddingright: "8px",
                                                  }}
                                                />
                                              </a>
                                            </li>
                                          </ul>
                                        </nav>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <UserProfileComplitiomBar
          personnelProfileData={
            this.props.personnelData
          }
          personnelAddressData={
            this.props.addressData
          }
        />
      </div>
      <li>
   <a onClick={this.WhyTheFillForm} > User Profile
     <i className="fa fa-question-circle-o" aria-hidden="true"
     style={{fontSize:"20px", paddingLeft: "5px", paddingright: "8px"}}></i></a>
    </li>  */}
                                </ul>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9 col-lg-9 col-sm-12 col-xs-12">
                    <main className="content-pos-fixed">
                      <Form
                        onSubmit={handleSubmit}
                        onKeyDown={(onKeyDown) => {
                          if (
                            (onKeyDown.charCode || onKeyDown.keyCode) === 13 &&
                            onKeyDown.target.name != "corcountryId" &&
                            onKeyDown.target.name != "corstateId" &&
                            onKeyDown.target.name != "corcityId" &&
                            onKeyDown.target.name != "percountryId" &&
                            onKeyDown.target.name != "perstateId" &&
                            onKeyDown.target.name != "percityId" &&
                            onKeyDown.target.name !=
                              "learnerFatherEmployementType" &&
                            onKeyDown.target.name !=
                              "learnerMotherEmployementType" &&
                            onKeyDown.target.name != "submitForm" &&
                            onKeyDown.target.name != "gender" &&
                            onKeyDown.target.name != "maritalStatus"
                          ) {
                            onKeyDown.preventDefault();
                          }
                        }}
                        className="form-group"
                      >
                        <div className="bg-box">
                          <section
                            className="create-user page-section profile-box"
                            id="distributor-profile"
                          >
                            <div className="row">
                              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                <h2 className="sub-header">
                                  Profile Instructions
                                </h2>
                               
                                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                  <p>
                                    <b>Welcome to the RCP Platform!</b>
                                  </p>{" "}
                                  <br />
                                  <p>
                                  The platform will take you through your journey of NISM certification/Training and EUIN as mandated by your employer.
                                  </p>
                                  <p>
                                  We will be collecting the following information from you to create your NISM Profile and undertake your enrolment for the NISM VA MF Certification Examination.
                                  </p>
                                  <ul>
                                   
                                   <li>
                                     <b>
                                     Please Keep the following documents ready<span className="validation">((File size less than 1 MB))</span> 	
                                     </b>
                                     <ul>
                                       <li>
                                        Scanned PAN                   
                                       </li>
                                       <li>
                                       Scanned Proof of Address                          
                                       </li>
                                       <li>   
                                       Photo     
                                       </li>  
                                     <span className="validation">
                                     Only JPEG/JPG/PNG formats accepted. Please note that the file you upload should NOT have ANY special character in the name of the attachment file.	
                                     </span>
                                   
                                     </ul>
                                    </li>
                                   
                                  </ul>
                                </div>
                                <div
                                  className="form-group"
                                  style={{
                                    display: "none",
                                  }}
                                >
                                  <div className="row">
                                    <div
                                      className="col-md-12 col-sm-12 col-xs-12"
                                      style={{ display: "none" }}
                                    >
                                      <label>Process Type </label>
                                      <div className="displayFlex">
                                        <div>
                                          <Field
                                            className="form-check-input"
                                            type="checkbox"
                                            name="ARN"
                                            checked={
                                              this.state.processType === "1001"
                                                ? true
                                                : false
                                            }
                                            disabled={true}
                                            style={{ marginRight: "10px" }}
                                          />
                                          <label
                                            className="form-check-label"
                                            for="gridCheck"
                                            style={{ marginRight: "15px" }}
                                          >
                                            ARN
                                          </label>
                                        </div>
                                        <div>
                                          <Field
                                            className="form-check-input"
                                            type="checkbox"
                                            name="CPE"
                                            checked={
                                              this.state.processType === "1003"
                                                ? true
                                                : false
                                            }
                                            disabled={true}
                                            style={{ marginRight: "10px" }}
                                          />
                                          <label
                                            className="form-check-label"
                                            for="gridCheck"
                                            style={{ marginRight: "15px" }}
                                          >
                                            NISM Certification Details
                                          </label>
                                        </div>
                                        <div>
                                          <Field
                                            className="form-check-input"
                                            type="checkbox"
                                            name="EUIN"
                                            checked={
                                              this.state.processType === "1002"
                                                ? true
                                                : false
                                            }
                                            disabled={true}
                                            style={{ marginRight: "10px" }}
                                          />
                                          <label
                                            className="form-check-label"
                                            for="gridCheck"
                                            style={{ marginRight: "15px" }}
                                          >
                                            EUIN
                                          </label>
                                        </div>
                                        <div>
                                          <Field
                                            className="form-check-input"
                                            type="checkbox"
                                            name="nismCertificate"
                                            checked={
                                              this.state.processType === "1004"
                                                ? true
                                                : false
                                            }
                                            disabled={true}
                                            style={{ marginRight: "10px" }}
                                          />
                                          <label
                                            className="form-check-label"
                                            for="gridCheck"
                                            style={{ marginRight: "15px" }}
                                          >
                                            NISM Certification
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="col-md-4 col-sm-4 col-xs-12"
                                      style={{ display: "none" }}
                                    >
                                      <label>
                                        Who Are You?
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        as="select"
                                        name="whoareu"
                                        id="whoareu"
                                        disabled={values.disabledForCielops}
                                        onChange={(event) => {
                                          setFieldValue(
                                            (values.whoareu =
                                              event.target.value)
                                          );
                                          if (event.target.value == "1011") {
                                            setFieldValue(
                                              (values.PanValidation = false)
                                            );
                                          } else {
                                            setFieldValue(
                                              (values.PanValidation = true)
                                            );
                                          }
                                          if (event.target.value == "1015") {
                                            setFieldValue(
                                              (values.nameOfCurrProf =
                                                "Last Profession")
                                            );
                                          }
                                          if (
                                            event.target.value == "1011" ||
                                            event.target.value == "1012"
                                          ) {
                                            setFieldValue(
                                              (values.currProfessionList = [])
                                            );
                                            setFieldValue(
                                              (values.disablecurreProf = true)
                                            );
                                          } else if (
                                            event.target.value == "1013" ||
                                            event.target.value == "1015"
                                          ) {
                                            console.log(
                                              this.state.currentProfession
                                            );
                                            setFieldValue(
                                              (values.disablecurreProf = false)
                                            );
                                            setFieldValue(
                                              (values.currProfessionList = currProf)
                                            );
                                          } else {
                                            setFieldValue(
                                              (values.currProfessionList = [])
                                            );
                                            setFieldValue(
                                              (values.disablecurreProf = true)
                                            );
                                          }
                                        }}
                                        onBlur={(event) => {
                                          this.updateCommonWeightage(
                                            event.target.name,
                                            event.target.value,
                                          values);
                                        }}
                                        className={
                                          "form-control" +
                                          (errors.whoareu && touched.whoareu
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        {/* <option>--Select--</option>
                                            {(this.state.whoareu || []).map(
                                              team => (
                                                <option
                                                  key={team.codeValueId}
                                                  value={team.codeValueId}
                                                >
                                                  {(team.codeValue)}
                                                </option>
                                              )
                                            )} */}
                                        <option value="1011">Student</option>
                                        <option value="1012">
                                          Entrepreneur
                                        </option>
                                        <option value="1013">
                                          Professional
                                        </option>
                                        <option value="1014">Home Maker</option>
                                        <option value="1015">Retired</option>
                                      </Field>
                                      <ErrorMessage
                                        name="whoareu"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        {/* current profession{" "} */}
                                        {values.nameOfCurrProf}{" "}
                                        {/* <span className="validation"> *</span> */}
                                      </label>

                                      <div>
                                        <Field
                                          as="select"
                                          name="currentProfession"
                                          id="currentProfession"
                                          onChange={handleChange}
                                          disabled={
                                            values.disabledForCielops ||
                                            values.disablecurreProf
                                          }
                                          onBlur={(event) => {
                                            this.updateCommonWeightage(
                                              event.target.name,
                                              event.target.value,
                                              values
                                            );
                                          }}
                                          className={
                                            "form-control" +
                                            (errors.lookingFor &&
                                            touched.lookingFor
                                              ? " is-invalid"
                                              : "")
                                          }
                                        >
                                          <option value="">--Select--</option>
                                          {(
                                            values.currProfessionList || []
                                          ).map((team) => (
                                            <option
                                              key={team.codeValueId}
                                              value={team.codeValueId}
                                            >
                                              {team.codeValue}
                                            </option>
                                          ))}
                                        </Field>
                                        <ErrorMessage
                                          name="currentProfession"
                                          component="div"
                                          className="validation"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>ARN/EUIN Number</label>
                                      <Field
                                        type="text"
                                        // name="euinNumbers"
                                        placeholder="ARN/EUIN Number"
                                        className="form-control"
                                        disabled={values.disabledForCielops}
                                        onBlur={(event) => {
                                          this.updateCommonWeightage(
                                            event.target.name,
                                            event.target.value,
                                            values
                                          );
                                        }}
                                      />
                                      {/* { errors.euinNumbers && <div  className="validation">{errors.euinNumbers}</div>} */}

                                      {/* <ErrorMessage
                                            name="euinNumber"
                                            component="div"
                                            className="validation"
                                          /> */}
                                    </div>
                                  </div>
                                  <div className="row" />
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="create-user page-section profile-box"
                            id="create-user"
                          >
                            <div className="row">
                              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                <h2 className="sub-header">Personal Details</h2>
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Employee ID{" "}
                                        <span className="validation"> *</span>{" "}
                                      </label>
                                      {/* <Field
                                        type="text"
                                        name="learnerPartyID"
                                        disabled
                                        className="form-control"
                                      /> */}
                                      <Field
                                        type="text"
                                        name="empNo"
                                        // disabled
                                        onChange={handleChange}
                                        onBlur={(e) => {
                                          this.updateCommonWeightage(
                                            e.target.name,
                                            e.target.value,
                                            values
                                          )}}
                                        id="empNo"
                                        className="form-control"
                                      />
                                      <div className="col-md-12 col-sm-12">
                                        <span className="file-size-limit">
                                        If from Institution/Bank mentioned your Employee ID or else put Mobile number
                                        </span>
                                      </div>
                                         <ErrorMessage
                                        name="empNo"
                                        className="validation"
                                        component="div"
                                      />

                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Mobile No{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        type="text"
                                        name="mobileNo"
                                        id="mobileNo"
                                        onChange={(e)=>{
                                          if(this.state.isEditable){
                                            handleChange(e);
                                          }
                                        }}
                                        // onChange={handleChange}
                                        // onChange={(e)=>{if(!( this.state?.newLearnerDetails?
                                        //   .learnerEuinOption ===
                                        //   "Fresh EUIN" ||
                                        // this.state?.newLearnerDetails?
                                        //   .learnerEuinOption ===
                                        //   "Only EUIN Renewal")){handleChange(e);}}}
                                        disabled={
                                          this.state?.newLearnerDetails?.learnerEuinOption ===
                                            "Fresh EUIN" ||
                                          this.state?.newLearnerDetails?.learnerEuinOption ===
                                            "Only EUIN Renewal"
                                            ? // || values.mobileNo
                                              false
                                            : true
                                          // values.disabledForCielops
                                        }
                                        // disabled={
                                        //   values.mobileNo ? true : false
                                        // }
                                        // id="mobileNo"
                                        autoComplete="mobileNo"
                                        className={
                                          "form-control" +
                                          (errors.mobileNo && touched.mobileNo
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Mobile Number"
                                      />
                                      <ErrorMessage
                                        name="mobileNo"
                                        className="validation"
                                        component="div"
                                      />
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Personal Email Id
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        type="text"
                                        name="emailID"
                                        id="emailID"
                                        // onChange={handleChange}
                                        // onChange={
                                        //   (e)=>{
                                        //     if(!( this.state?.newLearnerDetails?
                                        //       .learnerEuinOption ===
                                        //       "Fresh EUIN" ||
                                        //     this.state?.newLearnerDetails?
                                        //       .learnerEuinOption ===
                                        //       "Only EUIN Renewal")){
                                        //         handleChange(e);
                                        //       }
                                        //   }
                                        // }
                                        onChange={(e)=>{
                                          if(this.state.isEditable){
                                            handleChange(e);
                                          }
                                        }}
                                        disabled={
                                          this.state?.newLearnerDetails?.learnerEuinOption ===
                                            "Fresh EUIN" ||
                                          this.state?.newLearnerDetails?.learnerEuinOption ===
                                            "Only EUIN Renewal"
                                            ? // || values.mobileNo
                                              false
                                            : true
                                          // values.disabledForCielops
                                        }
                                        // disabled={values.emailID ? true : false}
                                        autoComplete="emailID"
                                        className={
                                          "form-control" +
                                          (errors.emailID && touched.emailID
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Email ID"
                                      />
                                      <ErrorMessage
                                        name="emailID"
                                        className="validation"
                                        component="div"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12 col-sm-12 col-xs-12" />
                                  <div className="row">
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Gender
                                        {this.props.personnelData
                                          .learnerEuinOption === "Fresh EUIN" ? (
                                          // ||
                                        // this.props.personnelData
                                        //   .learnerEuinOption ===
                                        //   "Only EUIN Renewal" ? (
                                          ""
                                        ) : (
                                          <span className="validation"> *</span>
                                        )}
                                      </label>
                                      <Field
                                        as="select"
                                        name="gender"
                                        id="gender"
                                        disabled={
                                          // values.disabledForCielops
                                          // ||
                                          this.state?.newLearnerDetails?.learnerEuinOption ===
                                            "Fresh EUIN" ||
                                          // this.state?.newLearnerDetails?.learnerEuinOption ===
                                          //   "Only EUIN Renewal" ||
                                          values.disabledForCielops
                                            ? true
                                            : false
                                        }
                                        onChange={handleChange}
                                        onBlur={(e) => {
                                          this.updateCommonWeightage(
                                            e.target.name,
                                            e.target.value,
                                            values
                                          );
                                        }}
                                        className={
                                          "form-control" +
                                          (errors.gender && touched.gender
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option value="">Select Gender</option>
                                        <option value="1">Male</option>
                                        <option value="2">Female</option>
                                        <option value="3">Transgender</option>
                                      </Field>
                                      {errors.gender && (
                                        <div className="validation">
                                          {errors.gender}
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Select Salutation
                                        {/* <span className="validation"> *</span> */}
                                      </label>
                                      <Field
                                        as="select"
                                        name="salutation"
                                        id="salutation"
                                        disabled={
                                          this.state?.newLearnerDetails?.learnerEuinOption ===
                                            "Fresh EUIN" ||
                                          this.state?.newLearnerDetails?.learnerEuinOption ===
                                            "Only EUIN Renewal" ||
                                          values.disabledForCielops
                                            ? true
                                            : false
                                          // values.disabledForCielops
                                        }
                                        onChange={handleChange}
                                        onBlur={(e) => {
                                          this.updateCommonWeightage(
                                            e.target.name,
                                            e.target.value,
                                            values
                                          );
                                        }}
                                        className={
                                          "form-control" +
                                          (errors.salutation &&
                                          touched.salutation
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option value="">
                                          Select Salutation
                                        </option>
                                        <option value="1">Mr.</option>
                                        <option value="2">Mrs.</option>
                                        <option value="3">Miss.</option>
                                      </Field>
                                      {/* <ErrorMessage
                                            name="salutation"
                                            component="div"
                                            className="validation"
                                          /> */}
                                      {errors.salutation && (
                                        <div className="validation">
                                          {errors.salutation}
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        First Name{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        type="text"
                                        name="firstName"
                                        id="firstName"
                                        disabled={
                                          // this.state?.newLearnerDetails?.learnerEuinOption === "Fresh EUIN"
                                          // ||this.state?.newLearnerDetails?.learnerEuinOption ==="Only EUIN Renewal"
                                          // || values.disabledForCielops ? true : false
                                          values.disabledForCielops
                                        }
                                        onChange={handleChange}
                                        autoComplete="firstName"
                                        onBlur={(e) => {
                                          this.updateCommonWeightage(
                                            e.target.name,
                                            e.target.value,
                                            values
                                          );
                                        }}
                                        className={
                                          "form-control" +
                                          (errors.firstName && touched.firstName
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="First Name"
                                      />
                                      {errors.firstName && (
                                        <div className="validation">
                                          {errors.firstName}
                                        </div>
                                      )}

                                      {/* <ErrorMessage
                                            name="firstName"
                                            className="validation "
                                            component="div"
                                          /> */}
                                    </div>
                                    <div className="col-md-12 col-sm-12 col-xs-12" />
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>Middle Name</label>
                                      <Field
                                        type="text"
                                        name="middleName"
                                        id="middleName"
                                        disabled={
                                          // this.state?.newLearnerDetails?.learnerEuinOption === "Fresh EUIN"
                                          // ||this.state?.newLearnerDetails?.learnerEuinOption ==="Only EUIN Renewal"
                                          // || values.disabledForCielops ? true : false
                                          values.disabledForCielops
                                        }
                                        onChange={handleChange}
                                        onBlur={
                                          //   formProps.handleBlur,
                                          (e) => {
                                            this.updateCommonWeightage(
                                              e.target.name,
                                              e.target.value,
                                              values
                                            );
                                          }
                                        }
                                        autoComplete="middleName"
                                        className="form-control"
                                        placeholder="Middle Name"
                                      />
                                      {errors.middleName && (
                                        <div className="validation">
                                          {errors.middleName}
                                        </div>
                                      )}

                                      {/* <ErrorMessage
                                            name="middleName"
                                            className="validation"
                                            component="div"
                                          /> */}
                                    </div>

                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Last Name
                                        <span className="validation">
                                          {" "}
                                          *
                                        </span>{" "}
                                      </label>
                                      <Field
                                        type="text"
                                        name="lastName"
                                        id="lastName"
                                        disabled={
                                          // this.state?.newLearnerDetails?.learnerEuinOption === "Fresh EUIN"
                                          // ||this.state?.newLearnerDetails?.learnerEuinOption ==="Only EUIN Renewal"
                                          // || values.disabledForCielops ? true : false
                                          values.disabledForCielops
                                        }
                                        onChange={handleChange}
                                        onBlur={(e) => {
                                          this.updateCommonWeightage(
                                            e.target.name,
                                            e.target.value,
                                            values
                                          );
                                        }}
                                        autoComplete="lastName"
                                        className={
                                          "form-control" +
                                          (errors.lastName && touched.lastName
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Last Name"
                                      />
                                      {errors.lastName && (
                                        <div className="validation">
                                          {errors.lastName}
                                        </div>
                                      )}
                                      {/* <ErrorMessage
                                            name="lastName"
                                            className="validation"
                                            component="div"
                                          /> */}
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Father's Name
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        type="text"
                                        name="fatherName"
                                        id="fatherName"
                                        disabled={
                                          // this.state?.newLearnerDetails?.learnerEuinOption === "Fresh EUIN"
                                          // ||this.state?.newLearnerDetails?.learnerEuinOption ==="Only EUIN Renewal"
                                          // || values.disabledForCielops ? true : false
                                          values.disabledForCielops
                                        }
                                        onChange={handleChange}
                                        onBlur={(e) => {
                                          console.log(errors);
                                          //  formProps.setTouched({...form.touched,[values.fatherName]: true });
                                          //

                                          //  validateField('fatherName')
                                          this.updateCommonWeightage(
                                            e.target.name,
                                            e.target.value,
                                            values
                                          );
                                        }}
                                        autoComplete="fatherName"
                                        className="form-control"
                                        placeholder="Father Name"
                                        // maxLength={50}
                                      />
                                      {errors.fatherName && (
                                        <div className="validation">
                                          {errors.fatherName}
                                        </div>
                                      )}

                                      {/* <ErrorMessage
                                            name="fatherName"
                                            className="validation"
                                            component="div"
                                          /> */}
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Marital Status
                                        {this.props.personnelData
                                          .learnerEuinOption === "Fresh EUIN" ||
                                        this.props.personnelData
                                          .learnerEuinOption ===
                                          "Only EUIN Renewal" ? (
                                          ""
                                        ) : (
                                          <span className="validation"> *</span>
                                        )}
                                      </label>
                                      <Field
                                        as="select"
                                        name="maritalStatus"
                                        id="maritalStatus"
                                        disabled={
                                          // props.personnelData.learnerEuinOption
                                          this.state?.newLearnerDetails?.learnerEuinOption ===
                                            "Fresh EUIN" ||
                                          this.state?.newLearnerDetails?.learnerEuinOption ===
                                            "Only EUIN Renewal" ||
                                          values.disabledForCielops
                                            ? true
                                            : false
                                          // values.disabledForCielops
                                        }
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.maritalStatus &&
                                          touched.maritalStatus
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option value="">
                                          Select Marital Status
                                        </option>
                                        <option value="11020">Married</option>
                                        <option value="11021">Unmarried</option>
                                      </Field>
                                      {errors.maritalStatus && (
                                        <div className="validation">
                                          {errors.maritalStatus}
                                        </div>
                                      )}
                                    </div>

                                    {/* <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Applicant Spouse */}
                                        {/* <span className="validation"> *</span> */}
                                      {/* </label>
                                      <Field
                                        type="text"
                                        name="applicantSpouseFather"
                                        id="applicantSpouseFather"
                                        disabled={
                                          // this.state?.newLearnerDetails?.learnerEuinOption === "Fresh EUIN"
                                          // ||this.state?.newLearnerDetails?.learnerEuinOption ==="Only EUIN Renewal"
                                          // || values.disabledForCielops ? true : false
                                          values.disabledForCielops
                                        }
                                        onChange={handleChange}
                                        autoComplete="applicantSpouseFather"
                                        className="form-control"
                                        placeholder="Spouse Name"
                                      /> */}
                                      {/* {errors.applicantSpouseFather &&
                                        <div className="validation">
                                          {errors.applicantSpouseFather}
                                        </div>} */}
                                    {/* </div> */}
                                  </div>
                                  <div className="col-md-12 col-sm-12 col-xs-12" />
                                  <div className="row">
                                    {/* <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>Secondary Contact No</label>
                                      <Field
                                        type="text"
                                        name="contactNo"
                                        maxlength="10"
                                        onChange={handleChange}
                                        autoComplete="contactNo"
                                        disabled={
                                          this.state?.newLearnerDetails?
                                            .learnerEuinOption ===
                                            "Fresh EUIN" ||
                                          this.state?.newLearnerDetails?
                                            .learnerEuinOption ===
                                            "Only EUIN Renewal" ||
                                          values.disabledForCielops
                                            ? true
                                            : false
                                          // values.disabledForCielops
                                        }
                                        onBlur={(e) => {
                                          this.updateCommonWeightage(
                                            e.target.name,
                                            e.target.value
                                          );
                                        }}
                                        className={
                                          "form-control" +
                                          (errors.contactNo && touched.contactNo
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Mobile Number"
                                      />
                                      {errors.contactNo && (
                                        <div className="validation">
                                          {errors.contactNo}
                                        </div>
                                      )} */}
                                      {/* <ErrorMessage
                                            name="contactNo"
                                            className="validation"
                                            component="div"
                                          /> */}
                                    {/* </div> */}
                                 
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>Official Email ID</label>
                                      {this.props.personnelData
                                        .learnerEuinOption === "Fresh EUIN" ||
                                      this.props.personnelData
                                        .learnerEuinOption ===
                                        "Only EUIN Renewal" ? (
                                        ""
                                      ) : (
                                        <span className="validation"> *</span>
                                      )}
                                      <Field
                                        type="text"
                                        name="secondaryEmailID"
                                        id="secondaryEmailID"
                                        onChange={handleChange}
                                        autoComplete="secondaryEmailID"
                                        disabled={
                                          this.state?.newLearnerDetails?.learnerEuinOption ===
                                            "Fresh EUIN" ||
                                          this.state?.newLearnerDetails?.learnerEuinOption ===
                                            "Only EUIN Renewal" ||
                                          values.disabledForCielops
                                            ? true
                                            : false
                                          // values.disabledForCielops
                                        }
                                        onBlur={(e) => {
                                          this.updateCommonWeightage(
                                            e.target.name,
                                            e.target.value,
                                            values
                                          );
                                        }}
                                        className={
                                          "form-control" +
                                          (errors.secondaryEmailID &&
                                          touched.secondaryEmailID
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Email ID"
                                      />
                                      {errors.secondaryEmailID && (
                                        <div className="validation">
                                          {errors.secondaryEmailID}
                                        </div>
                                      )}
                                      {/* <ErrorMessage
                                            name="secondaryEmailID"
                                            className="validation"
                                            component="div"
                                      /> */}
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Birth Date
                                        <span className="validation"> *</span>
                                      </label>

                                      {/* <Field
                                            type="date"
                                            onChange={handleChange}
                                            name="dob"
                                            max={this.state.today}
                                            disabled={values.disabledForCielops}
                                            onBlur={
                                              e=>{
                                                this.updateCommonWeightage(e.target.name,e.target.value)
                                      
                                              }
                                            }
                                            id="dob"
                                            className={
                                              "form-control" +
                                              (errors.dob && touched.dob
                                                ? " is-invalid"
                                                : "")
                                            }
                                          /> */}

                                      {/* 
<DatePicker selected={values.dob!==""?new Date(values.dob):''}
  disabled={values.disabledForCielops}
                                                                autoComplete="off"
                                                                placeholderText="dd-mm-yyyy"
                                                                dateFormat="dd-MM-yyyy"
                                                                maxLength="50"


                                                                value={values.dob!==""?new Date(values.dob):""}
                                                                onChangeRaw={event => {

                                                                    let temp = event.target.value

                                                                    if (temp !== undefined) {
                                                                        if (temp.includes("/")) {
                                                                            setFieldValue(values.dob = "")
                                                                            setFieldValue(values.errmsg1 = "Date should be in dd-mm-yyyy format.")

                                                                        }
                                                                        else {
                                                                       //   setFieldValue(values.dob = "")

                                                                            // setFieldValue(values.toDate = date)
                                                                            setFieldValue(values.errmsg1 = "")

                                                                        }
                                                                    }
                                                                }
                                                                }
                                                                onKeyDown={
                                                                  e=>{
                                                                    if(e.key=="Tab"){
                                                                     // ("#panNumber").focus();
                                                                document.getElementById("panNumber").focus();

                                                                    }
                                                                    
                                                                  console.log(e.target.value)
                                                                  }
                                                                }
                                                                // onBlur={
                                                                //   e=>{
                                                                //     this.updateCommonWeightage("dob",e.target.value)
                                                          
                                                                //   }
                                                                // }
                                                                //   maxDate={new Date()}
                                                                // disabled={true}
                                                                //value={this.state.acceptData.length > 0 ? new Date(this.state.acceptData[0].executionTracker[0].additionalDetails.requestedDate) : ''}
                                                                onChange={date => {
                                                                  
                                                                  if(date==null)
                                                                  {
                                                                    setFieldValue(values.dob = "")
                                                                  }
                                                                    else{
                                                                      setFieldValue(values.dob = date)

                                                                    }   

                                                                        // if (moment(date).format("YYYY-MM-DD") !== "Invalid date") {
                                                                        //   setFieldValue(values.dateOfBirth = moment(date).format("YYYY-MM-DD"))
                                                                        // }
                                                                        // else {
                                                                        //   setFieldValue(values.dateOfBirth = "")
                                                                        // }
                                                                      //  setFieldValue(values.dob = moment(date).format("YYYY-MM-DD"))

                                                                    

                                                                    // setFieldValue(values.dateOfBirth = date)
                                                                }}
                                                                className={
                                                                    "form-control" +
                                                                    (errors.dob &&
                                                                        touched.dob
                                                                        ? " is-invalid"
                                                                        : "")
                                                                }

                                                            /> */}

                                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                        <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                          <Field
                                            as="select"
                                            name="day"
                                            id="day"
                                            // onChange={handleChange}
                                            //  disabled={this.state.isDisabled}
                                            //  disabled
                                            disabled={
                                              this.state?.newLearnerDetails?.learnerEuinOption ===
                                                "Fresh EUIN" ||
                                              // this.state?.newLearnerDetails?.learnerEuinOption ===
                                              //   "Only EUIN Renewal" ||
                                              values.disabledForCielops
                                                ? true
                                                : false
                                              // values.disabledForCielops
                                            }
                                            onChange={(e) => {
                                              var listOfMonth = [];
                                              setFieldValue(
                                                (values.day = e.target.value)
                                              );
                                              if (e.target.value == 31) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },

                                                  { key: 3, value: "Mar" },

                                                  { key: 5, value: "May" },

                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },

                                                  { key: 10, value: "Oct" },

                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else if (e.target.value == 30) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },

                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else if (
                                                e.target.value == 28 ||
                                                e.target.value == 29
                                              ) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 2, value: "Feb" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 2, value: "Feb" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              }
                                              setFieldValue(
                                                (values.listOfMonth = listOfMonth)
                                              );

                                              // this.setState({
                                              //   listOfMonth: listOfMonth
                                              // })
                                            }}
                                            className={
                                              "form-control" +
                                              (errors.day && touched.day
                                                ? " is-invalid"
                                                : "")
                                            }
                                            // className="form-control"
                                          >
                                            <option value="">DD</option>
                                            {(values.listOfDay || []).map(
                                              (d) => (
                                                <option value={d}>{d}</option>
                                              )
                                            )}
                                          </Field>
                                        </div>
                                        <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                          <Field
                                            as="select"
                                            name="month"
                                            id="month"
                                            //   onChange={handleChange}
                                            //     disabled={this.state.isDisabled}
                                            //  disabled
                                            onChange={(e) => {
                                              setFieldValue(
                                                (values.month = e.target.value)
                                              );
                                              if (e.target.value == 2) {
                                                var listOfDay = [];
                                                for (var i = 1; i <= 29; i++) {
                                                  //    var current = year - i;
                                                  listOfDay.push(i);
                                                  // this.setState({ listOfDay: listOfDay });
                                                }
                                              } else if (
                                                e.target.value == 1 ||
                                                e.target.value == 3 ||
                                                e.target.value == 5 ||
                                                e.target.value == 7 ||
                                                e.target.value == 8 ||
                                                e.target.value == 10 ||
                                                e.target.value == 12
                                              ) {
                                                var listOfDay = [];
                                                for (var i = 1; i <= 31; i++) {
                                                  //    var current = year - i;
                                                  listOfDay.push(i);
                                                  //  this.setState({ listOfDay: listOfDay });
                                                }
                                              } else {
                                                var listOfDay = [];
                                                for (var i = 1; i <= 30; i++) {
                                                  //    var current = year - i;
                                                  listOfDay.push(i);
                                                  //  this.setState({ listOfDay: listOfDay });
                                                }
                                              }
                                              setFieldValue(
                                                (values.listOfDay = listOfDay)
                                              );
                                            }}
                                            className={
                                              "form-control" +
                                              (errors.month && touched.month
                                                ? " is-invalid"
                                                : "")
                                            }
                                            disabled={
                                              this.state?.newLearnerDetails?.learnerEuinOption ===
                                                "Fresh EUIN" ||
                                              // this.state?.newLearnerDetails?.learnerEuinOption ===
                                              //   "Only EUIN Renewal" ||
                                              values.disabledForCielops
                                                ? true
                                                : false
                                              // values.disabledForCielops
                                            }
                                            // className="form-control"
                                          >
                                            <option value="">MM</option>
                                            {(values.listOfMonth || []).map(
                                              (m) => (
                                                <option value={m.key}>
                                                  {m.value}
                                                </option>
                                              )
                                            )}
                                          </Field>
                                        </div>
                                        <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                          <Field
                                            as="select"
                                            name="year"
                                            id="year"
                                            // onChange={handleChange}
                                            //    disabled={this.state.isDisabled}
                                            //  disabled
                                            className={
                                              "form-control" +
                                              (errors.year && touched.year
                                                ? " is-invalid"
                                                : "")
                                            }
                                            disabled={
                                              this.state?.newLearnerDetails?.learnerEuinOption ===
                                                "Fresh EUIN" ||
                                              // this.state?.newLearnerDetails?.learnerEuinOption ===
                                              //   "Only EUIN Renewal" ||
                                              values.disabledForCielops
                                                ? true
                                                : false
                                              // values.disabledForCielops
                                            }
                                            // className="form-control"
                                          >
                                            <option value="">YYYY</option>
                                            {(this.state.listOfYear1 || []).map(
                                              (year) => (
                                                <option value={year}>
                                                  {year}
                                                </option>
                                              )
                                            )}
                                          </Field>
                                        </div>
                                      </div>

                                      {errors.dob && (
                                        <div className="validation">
                                          {errors.dob}
                                        </div>
                                      )}
                                      {/* <ErrorMessage
                                            name="dob"
                                            className="validation"
                                            component="div"
                                          /> */}
                                    </div>
                                  </div>
                                  <div className="col-md-12 col-sm-12 col-xs-12" />
                                  <div className="row">
                                    <div
                                      className="col-md-4 col-sm-4 col-xs-12"
                                      id="pan"
                                    >
                                      <label>
                                        PAN NO
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        type="text"
                                        name="panNumber"
                                        id="panNumber"
                                        maxlength="10"
                                        // disabled={values.disabledForCielops}
                                        disabled={
                                          this.state?.newLearnerDetails?.learnerEuinOption ===
                                            "Fresh EUIN" ||
                                          this.state?.newLearnerDetails?.learnerEuinOption ===
                                            "Only EUIN Renewal"
                                            ? false
                                            : true
                                          // values.disabledForCielops
                                        }
                                        // onChange={handleChange}
                                        onChange={(event) => {
                                          handleChange(event);
                                          let pan = event.target.value;
                                          pan = pan.toUpperCase();
                                          setFieldValue(
                                            (values.panNumber = pan)
                                          );
                                        }}
                                        onBlur={(event) => {
                                          //alert(event.target.value.length)
                                          // if(event.target.value.length>9){
                                          this.updateCommonWeightage(
                                            event.target.name,
                                            event.target.value,
                                            values
                                          );

                                          LoginService.TokenUser((res) => {
                                            localStorage.setItem(
                                              "jwt-token",
                                              res.responseObject
                                            );
                                          });
                                          // RegisterUserService.checkPAN(
                                          //   "learnerID=" +
                                          //   this.props.personnelData
                                          //     .learnerID +
                                          //   "&panNumber=" +
                                          //   event.target.value +
                                          //   "&aadharNumber="+"&learnerPartyID="+localStorage.getItem("userid-Token"),
                                          //   res => {
                                          //     if (res.status === "fail") {
                                          //       setFieldValue(
                                          //         (values.panNumber = "")
                                          //       );
                                          //       error(res.reasonText, {
                                          //         ...errorNotification
                                          //        // container: "top-center"
                                          //       });
                                          //     }
                                          //   }
                                          // );
                                        }}
                                        className="form-control"
                                        placeholder="PAN NO"
                                      />

                                      <div className="col-md-12 col-sm-12">
                                        <span className="file-size-limit">
                                          PAN is compulsory for NISM
                                          Registration
                                        </span>
                                      </div>
                                      {errors.panNumber && (
                                        <div className="validation">
                                          {errors.panNumber}
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      className="col-md-4 col-sm-4 col-xs-12"
                                      style={{ display: "none" }}
                                    >
                                      <label>
                                        Aadhaar No{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        type="text"
                                        name="aadharNumber"
                                        id="aadharNumber"
                                        maxlength="12"
                                        disabled
                                        //disabled={values.disabledForCielops}
                                        onChange={handleChange}
                                        onBlur={(event) => {
                                          this.updateCommonWeightage(
                                            event.target.name,
                                            event.target.value,
                                            values
                                          );

                                          LoginService.TokenUser((res) => {
                                            localStorage.setItem(
                                              "jwt-token",
                                              res.responseObject
                                            );
                                          });
                                          // RegisterUserService.checkPAN(
                                          //   "learnerID=" +
                                          //   this.props.personnelData
                                          //     .learnerID +
                                          //   "&panNumber=" +
                                          //   "&aadharNumber=" +
                                          //   event.target.value+"&learnerPartyID="+localStorage.getItem("userid-Token"),
                                          //   res => {
                                          //     if (res.status === "fail") {
                                          //       setFieldValue(
                                          //         (values.aadharNumber = "")
                                          //       );
                                          //       error(res.reasonText, {
                                          //         ...errorNotification
                                          //        // container: "top-center"
                                          //       });
                                          //     }
                                          //   }
                                          // );
                                        }}
                                        className={
                                          "form-control" +
                                          (errors.aadharNumber &&
                                          touched.aadharNumber
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Aadhaar NO"
                                      />
                                      {errors.aadharNumber && (
                                        <div className="validation">
                                          {errors.aadharNumber}
                                        </div>
                                      )}
                                      {/* <ErrorMessage
                                            name="aadharNumber"
                                            className="validation"
                                            component="div"
                                          /> */}
                                    </div>
                                    {/* <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>Passport No </label>
                                      <Field
                                        type="text"
                                        name="passportNumber"
                                        id="passportNumber"
                                        maxlength="9"
                                        disabled={
                                          this.state?.newLearnerDetails?
                                            .learnerEuinOption ===
                                            "Fresh EUIN" ||
                                          this.state?.newLearnerDetails?
                                            .learnerEuinOption ===
                                            "Only EUIN Renewal" ||
                                          values.disabledForCielops
                                            ? true
                                            : false
                                          // values.disabledForCielops
                                        }
                                        // disabled={values.disabledForCielops}
                                        onChange={handleChange}
                                        onBlur={(event) => {
                                          this.updateCommonWeightage(
                                            event.target.name,
                                            event.target.value
                                          );
                                        }}
                                        className={
                                          "form-control" +
                                          (errors.passportNumber &&
                                          touched.passportNumber
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Passport NO"
                                      /> */}
                                      {/* <ErrorMessage
                                            name="passportNumber"
                                            className="validation"
                                            component="div"
                                          /> */}
                                      {/* {errors.passportNumber && (
                                        <div className="validation">
                                          {errors.passportNumber}
                                        </div>
                                      )} */}
                                    {/* </div> */}
                                    <div className="col-md-12 col-sm-12 col-xs-12" />
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Upload PAN
                                        {/* <span className="validation"> *</span> */}
                                        <span className="validation"> *</span>
                                      </label>
                                      <div className="profile-detail">
                                        <div className="circle">
                                          <img
                                            className="profile-pic"
                                            src={values.panCardURL}
                                          />
                                          <div className="profile">
                                            <i
                                              className="fa fa-user"
                                              aria-hidden="true"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="profile-add">
                                        <a
                                        // style={
                                        //   values.disabledForCielops
                                        //     ? {
                                        //         pointerEvents: 'none',
                                        //         cursor: 'default',
                                        //       }
                                        //     : {}
                                        // }
                                        >
                                          <span className="icon btn-file text-center">
                                            <i
                                              className="fa fa-plus-circle"
                                              aria-hidden="true"
                                            />
                                            <FileBase64
                                              multiple={true}
                                              name="panCardURL"
                                    
                                              onChange={handleChange}
                                              onDone={(event) => {
                                                // str.lastIndexOf()
                                                // const checkType = event[0].name.split(
                                                //   "."
                                                // );
                                                const checkType = event[0].name.split(
                                                  /\.(?=[^\.]+$)/
                                                );
                                                if (
                                                  [
                                                    "jpg",
                                                    "JPG",
                                                    "jpeg",
                                                    "JPEG",
                                                    "png",
                                                    "PNG",
                                                    // "pdf",
                                                  ].indexOf(checkType[1]) < 0
                                                ) {
                                                  setFieldValue(
                                                    (values.documentTypeCheckForPANImage = true)
                                                  );
                                                } else if (
                                                  event[0].file.size <= 1000000
                                                ) {
                                                  if (
                                                    this.checkName(
                                                      event[0].name
                                                    )
                                                  ) {
                                                    values.document.push({
                                                      documentHash:
                                                        event[0].base64,
                                                      documentType: "PanCard",
                                                      documentName:
                                                        event[0].name,
                                                    });
                                                    this.updateCommonWeightage(
                                                      "panCardURL",
                                                      event[0].name,
                                                      values
                                                    );
                                                    setFieldValue(
                                                      (values.documentTypeCheckForPANImage = false)
                                                    );
                                                    setFieldValue(
                                                      (values.panCardName =
                                                        event[0].name)
                                                    );
                                                    setFieldValue(
                                                      (values.documentSizeCheckForPanCard = false)
                                                    );
                                                    setFieldValue(
                                                      (values.documentNameValidation = false)
                                                    );
                                                    setFieldValue(
                                                      (values.panCardURL =
                                                        event[0].base64)
                                                    );
                                                  } else {
                                                    setFieldValue(
                                                      (values.documentNameValidation = true)
                                                    );

                                                    // warning("File/Document name can only contain number, letter, hyphens, space.", warningNotification);
                                                  }
                                                } else {
                                                  setFieldValue(
                                                    (values.documentNameValidation = false)
                                                  );
                                                  setFieldValue(
                                                    (values.documentSizeCheckForPanCard = true)
                                                  );
                                                }
                                              }}
                                            />
                                            <h4>Add PAN</h4>
                                          </span>
                                        </a>
                                        <h4 style={{ wordBreak: "break-all" }}>
                                          {values.panCardName
                                            ? ""
                                            : values.panpdfName
                                            ? values.panpdfName.split(".")[1] ==
                                              "pdf"
                                              ? values.panpdfName
                                              : ""
                                            : ""}
                                          {values.panCardName &&
                                          values.panCardName.split(".")[1] ==
                                            "pdf"
                                            ? "(" + values.panCardName + ")"
                                            : ""}
                                        </h4>
                                        {/* <span className="icon">
                                              <i
                                                className="fa fa-times-circle"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                            <h4>Remove PAN</h4> */}
                                        <div className="col-md-12 col-sm-12">
                                          <span className="file-size-limit">
                                            The file should be in PNG/JPG
                                            format, with file-size less than
                                            1MB, with no special characters in
                                            the file-name
                                            {/* Supported Formats: .jpg, .jpeg,pdf,
                                              .png only.  <br />
                                                File Size: Max 1 MB */}
                                          </span>
                                        </div>
                                        {values.documentSizeCheckForPanCard ? (
                                          <p style={{ color: "red" }}>
                                            File Size is too large(Maximum File
                                            Size:1MB).
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                        {values.documentTypeCheckForPANImage ? (
                                          <p style={{ color: "red" }}>
                                            Invalid image format
                                          </p>
                                        ) : (
                                          ""
                                        )}

                                        {values.documentNameValidation ? (
                                          <p style={{ color: "red" }}>
                                            File/Document name can only contain
                                            number, letter, hyphens, space.
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>

                                    <div
                                      className="col-md-4 col-sm-4 col-xs-12"
                                      style={{ display: "none" }}
                                    >
                                      <label>
                                        Upload Aadhaar
                                        <span className="validation"> </span>
                                      </label>
                                      <div className="profile-detail">
                                        <div className="circle">
                                          <img
                                            className="profile-pic"
                                            src={values.aadharCardURL}
                                          />
                                          <div className="profile">
                                            <i
                                              className="fa fa-user"
                                              aria-hidden="true"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="profile-add">
                                        <a
                                          style={{
                                            pointerEvents: "none",
                                            cursor: "default",
                                          }}
                                          // style={
                                          //   values.disabledForCielops
                                          //     ? {
                                          //         pointerEvents: 'none',
                                          //         cursor: 'default',
                                          //       }
                                          //     : {}
                                          // }
                                        >
                                          <span className="icon btn-file text-center">
                                            <i
                                              className="fa fa-plus-circle"
                                              aria-hidden="true"
                                            />
                                            <FileBase64
                                              multiple={true}
                                              name="aadharCardURL"
                                              onChange={handleChange}
                                              onDone={(event) => {
                                                // const checkType = event[0].name.split(
                                                //   "."
                                                // );
                                                const checkType = event[0].name.split(
                                                  /\.(?=[^\.]+$)/
                                                );

                                                if (
                                                  [
                                                    "jpg",
                                                    "JPG",
                                                    "jpeg",
                                                    "JPEG",
                                                    "png",
                                                    "PNG",
                                                    "pdf",
                                                  ].indexOf(checkType[1]) < 0
                                                ) {
                                                  setFieldValue(
                                                    (values.documentTypeCheckForAadharImage = true)
                                                  );
                                                } else if (
                                                  event[0].file.size <= 1000000
                                                ) {
                                                  if (
                                                    this.checkName(
                                                      event[0].name
                                                    )
                                                  ) {
                                                    values.document.push({
                                                      documentHash:
                                                        event[0].base64,
                                                      documentType:
                                                        "AadharCard",
                                                      documentName:
                                                        event[0].name,
                                                    });
                                                    this.updateCommonWeightage(
                                                      "aadharCardURL",
                                                      event[0].name,
                                                      values
                                                    );
                                                    setFieldValue(
                                                      (values.documentTypeCheckForAadharImage = false)
                                                    );
                                                    setFieldValue(
                                                      (values.documentSizeCheckForAadharCard = false)
                                                    );
                                                    setFieldValue(
                                                      (values.adharDocumentNameValidation = false)
                                                    );
                                                    setFieldValue(
                                                      (values.aadharCardDocumentName =
                                                        event[0].name)
                                                    );
                                                    setFieldValue(
                                                      (values.aadharCardURL =
                                                        event[0].base64)
                                                    );
                                                  } else {
                                                    // warning("File/Document name can only contain number, letter, hyphens, space.", warningNotification);
                                                    setFieldValue(
                                                      (values.adharDocumentNameValidation = true)
                                                    );
                                                  }
                                                } else {
                                                  setFieldValue(
                                                    (values.adharDocumentNameValidation = false)
                                                  );
                                                  setFieldValue(
                                                    (values.documentSizeCheckForAadharCard = true)
                                                  );
                                                }
                                              }}
                                            />
                                            <h4 style={{ color: "black" }}>
                                              Add Aadhaar Front Part
                                            </h4>
                                          </span>
                                        </a>

                                        <h4 style={{ wordBreak: "break-all" }}>
                                          {values.aadharCardDocumentName
                                            ? ""
                                            : values.aadharpdfName
                                            ? values.aadharpdfName.split(
                                                "."
                                              )[1] == "pdf"
                                              ? values.aadharpdfName
                                              : ""
                                            : ""}
                                          {values.aadharCardDocumentName &&
                                          values.aadharCardDocumentName.split(
                                            "."
                                          )[1] == "pdf"
                                            ? "(" +
                                              values.aadharCardDocumentName +
                                              ")"
                                            : ""}
                                        </h4>

                                        <div className="col-md-12 col-sm-12">
                                          <span className="file-size-limit">
                                            The file should be in PNG/JPG/PDF
                                            format, with file-size less than
                                            1MB, with no special characters in
                                            the file-name
                                            {/* Supported Formats: .jpg, .jpeg,pdf,
                                              .png only. <br />
                                                File Size: Max 1 MB */}
                                          </span>
                                        </div>
                                        {values.documentSizeCheckForAadharCard ? (
                                          <p style={{ color: "red" }}>
                                            File Size is too large(Maximum File
                                            Size:1MB).
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                        {values.documentTypeCheckForAadharImage ? (
                                          <p style={{ color: "red" }}>
                                            Invalid image format
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                        {values.adharDocumentNameValidation ? (
                                          <p style={{ color: "red" }}>
                                            File/Document name can only contain
                                            number, letter, hyphens, space.
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>

                                    <div
                                      className="col-md-4 col-sm-4 col-xs-12"
                                      style={{ display: "none" }}
                                    >
                                      <label>
                                        Upload Aadhaar
                                        <span className="validation"> </span>
                                      </label>
                                      <div className="profile-detail">
                                        <div className="circle">
                                          <img
                                            className="profile-pic"
                                            src={values.BackaadharCardBackURL}
                                          />
                                          <div className="profile">
                                            <i
                                              className="fa fa-user"
                                              aria-hidden="true"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="profile-add">
                                        <a
                                          style={{
                                            pointerEvents: "none",
                                            cursor: "default",
                                          }}

                                          // style={
                                          //   values.disabledForCielops
                                          //     ? {
                                          //         pointerEvents: 'none',
                                          //         cursor: 'default',
                                          //       }
                                          //     : {}
                                          // }
                                        >
                                          <span className="icon btn-file text-center">
                                            <i
                                              className="fa fa-plus-circle"
                                              aria-hidden="true"
                                            />
                                            <FileBase64
                                              multiple={true}
                                              name="AadharCardBackSide"
                                              onChange={handleChange}
                                              onDone={(event) => {
                                                // const checkType = event[0].name.split(
                                                //   "."
                                                // );
                                                const checkType = event[0].name.split(
                                                  /\.(?=[^\.]+$)/
                                                );

                                                if (
                                                  [
                                                    "jpg",
                                                    "JPG",
                                                    "jpeg",
                                                    "JPEG",
                                                    "png",
                                                    "PNG",
                                                    "pdf",
                                                  ].indexOf(checkType[1]) < 0
                                                ) {
                                                  setFieldValue(
                                                    (values.documentTypeCheckForBackAadharImage = true)
                                                  );
                                                } else if (
                                                  event[0].file.size <= 1000000
                                                ) {
                                                  if (
                                                    this.checkName(
                                                      event[0].name
                                                    )
                                                  ) {
                                                    values.document.push({
                                                      documentHash:
                                                        event[0].base64,
                                                      documentType:
                                                        "AadharCardBackSide",
                                                      documentName:
                                                        event[0].name,
                                                    });
                                                    this.updateCommonWeightage(
                                                      "BackaadharCardBackURL",
                                                      event[0].name,
                                                      values
                                                    );
                                                    setFieldValue(
                                                      (values.documentTypeCheckForBackAadharImage = false)
                                                    );
                                                    setFieldValue(
                                                      (values.documentSizeCheckForBackAadharCard = false)
                                                    );
                                                    setFieldValue(
                                                      (values.BackadharDocumentNameValidation = false)
                                                    );
                                                    setFieldValue(
                                                      (values.BackaadharCardDocumentName =
                                                        event[0].name)
                                                    );
                                                    setFieldValue(
                                                      (values.BackaadharCardBackURL =
                                                        event[0].base64)
                                                    );
                                                  } else {
                                                    // warning("File/Document name can only contain number, letter, hyphens, space.", warningNotification);
                                                    setFieldValue(
                                                      (values.BackadharDocumentNameValidation = true)
                                                    );
                                                  }
                                                } else {
                                                  setFieldValue(
                                                    (values.BackadharDocumentNameValidation = false)
                                                  );
                                                  setFieldValue(
                                                    (values.BackdocumentSizeCheckForAadharCard = true)
                                                  );
                                                }
                                              }}
                                            />
                                            <h4 style={{ color: "black" }}>
                                              Add Aadhaar Back Part
                                            </h4>
                                          </span>
                                        </a>
                                        <h4 style={{ wordBreak: "break-all" }}>
                                          {values.BackaadharCardDocumentName
                                            ? ""
                                            : values.backaadharpdfName
                                            ? values.backaadharpdfName.split(
                                                "."
                                              )[1] == "pdf"
                                              ? values.backaadharpdfName
                                              : ""
                                            : ""}
                                          {values.BackaadharCardDocumentName &&
                                          values.BackaadharCardDocumentName.split(
                                            "."
                                          )[1] == "pdf"
                                            ? "(" +
                                              values.BackaadharCardDocumentName +
                                              ")"
                                            : ""}
                                        </h4>

                                        <div className="col-md-12 col-sm-12">
                                          <span className="file-size-limit">
                                            The file should be in PNG/JPG/PDF
                                            format, with file-size less than
                                            1MB, with no special characters in
                                            the file-name
                                            {/* Supported Formats: .jpg, .jpeg,pdf,
                                              .png only. <br />
                                                File Size: Max 1 MB */}
                                          </span>
                                        </div>
                                        {values.documentSizeCheckForBackAadharCard ? (
                                          <p style={{ color: "red" }}>
                                            File Size is too large(Maximum File
                                            Size:1MB).
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                        {values.documentTypeCheckForBackAadharImage ? (
                                          <p style={{ color: "red" }}>
                                            Invalid image format
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                        {values.BackadharDocumentNameValidation ? (
                                          <p style={{ color: "red" }}>
                                            File/Document name can only contain
                                            number, letter, hyphens, space.
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>

                          {/* <UserAddressDetails /> */}
                          <section
                            className="create-address page-section profile-box"
                            style={
                              this.props.personnelData.learnerEuinOption ===
                                "Fresh EUIN" ||
                              this.props.personnelData.learnerEuinOption ===
                                "Only EUIN Renewal"
                                ? { display: "none" }
                                : { display: "block" }
                            }
                            id="create-address"
                          >
                            <h2 className="sub-header">Address Details</h2>
                            {/* <div className="address-border">
                              <div className="form-group">
                                <div className="row">
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                    <h5 className="address-heading">
                                      Correspondence Address
                                    </h5>
                                  </div>
                                  <div className="col-md-4 col-sm-4 col-xs-12">
                                    <label>
                                      Address Line 1
                                      <span className="validation"> *</span>
                                    </label>
                                    <Field
                                      type="text"
                                      id="corStreet"
                                      name="corStreet"
                                      maxLength="40"
                                      onChange={handleChange}
                                      disabled={values.disabledForCielops}
                                      autoComplete="corStreet"
                                      onBlur={(event) => {
                                        this.updateCommonWeightage(
                                          event.target.name,
                                          event.target.value
                                        );
                                      }}
                                      className={
                                        "form-control" +
                                        (errors.corStreet && touched.corStreet
                                          ? " is-invalid"
                                          : "")
                                      }
                                      placeholder="Address"
                                    />
                                    {errors.corStreet && (
                                      <div className="validation">
                                        {errors.corStreet}
                                      </div>
                                    )} */}
                                    {/* <ErrorMessage
                                          name="corStreet"
                                          className="validation"
                                          component="div"
                                        /> */}
                                  {/* </div>
                                  <div className="col-md-4 col-sm-4 col-xs-12">
                                    <label>Address Line 2</label>
                                    <Field
                                      type="text"
                                      name="corStreet2"
                                      maxLength="40"
                                      onChange={handleChange}
                                      disabled={values.disabledForCielops}
                                      onBlur={(event) => {
                                        this.updateCommonWeightage(
                                          event.target.name,
                                          event.target.value
                                        );
                                      }}
                                      autoComplete="corStreet2"
                                      className={
                                        "form-control" +
                                        (errors.corStreet2 && touched.corStreet2
                                          ? " is-invalid"
                                          : "")
                                      }
                                      placeholder="Address"
                                    />
                                    {errors.corStreet2 && (
                                      <div className="validation">
                                        {errors.corStreet2}
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-4 col-sm-4 col-xs-12">
                                    <label>Address Line 3</label>
                                    <Field
                                      type="text"
                                      name="corStreet3"
                                      maxLength="40"
                                      onChange={handleChange}
                                      disabled={values.disabledForCielops}
                                      onBlur={(event) => {
                                        this.updateCommonWeightage(
                                          event.target.name,
                                          event.target.value
                                        );
                                      }}
                                      autoComplete="corStreet3"
                                      className={
                                        "form-control" +
                                        (errors.corStreet3 && touched.corStreet3
                                          ? " is-invalid"
                                          : "")
                                      }
                                      placeholder="Address"
                                    />
                                    {errors.corStreet3 && (
                                      <div className="validation">
                                        {errors.corStreet3}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12" />
                                <div className="row">
                                  <div className="col-md-4 col-sm-4 col-xs-12">
                                    <label>
                                      Country
                                      <span className="validation"> *</span>
                                    </label>
                                    <Field
                                      as="select"
                                      name="corcountryId"
                                      id="corcountryId"
                                      disabled={values.disabledForCielops}
                                      onChange={(event) => {
                                        LoginService.TokenUser((res) => {
                                          localStorage.setItem(
                                            "jwt-token",
                                            res.responseObject
                                          );
                                        });
                                        if (
                                          event.target.value !== undefined &&
                                          event.target.value != "0"
                                        ) {
                                          setFieldValue(
                                            (values.corcountryId =
                                              event.target.value)
                                          );
                                          values.stateListForCor = [];
                                          values.cityListForCor = [];
                                          RegionManagementService.getState(
                                            "countryId=" + event.target.value,
                                            (res) => {
                                              setFieldValue(
                                                (values.stateListForCor =
                                                  res.responseListObject)
                                              );
                                            }
                                          );
                                        } else {
                                          setFieldValue(
                                            (values.corcountryId = "")
                                          );
                                          setFieldValue(
                                            (values.corcityId = "")
                                          );
                                          setFieldValue(
                                            (values.corstateId = "")
                                          );
                                          setFieldValue(
                                            (values.stateListForCor = [])
                                          );
                                          setFieldValue(
                                            (values.cityListForCor = [])
                                          );
                                        }
                                      }}
                                      onBlur={(event) => {
                                        this.updateCommonWeightage(
                                          event.target.name,
                                          event.target.value
                                        );
                                      }}
                                      className={
                                        "form-control" +
                                        (errors.corcountryId &&
                                        touched.corcountryId
                                          ? " is-invalid"
                                          : "")
                                      }
                                    >
                                      <option value="0">---Select----</option>
                                      {values.countryList.map((team) =>
                                        team.isActive ? (
                                          <option
                                            key={team.countryId}
                                            value={team.countryId}
                                          >
                                            {this.titleCase(team.countryName)}
                                          </option>
                                        ) : (
                                          ""
                                        )
                                      )}
                                    </Field> */}
                                    {/* <ErrorMessage
                                          name="corcountryId"
                                          component="div"
                                          className="validation"
                                        /> */}
                                    {/* {errors.corcountryId && (
                                      <div className="validation">
                                        {errors.corcountryId}
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-4 col-sm-4 col-xs-12">
                                    <label>
                                      State
                                      <span className="validation"> *</span>
                                    </label>
                                    <Field
                                      as="select"
                                      name="corstateId"
                                      disabled={values.disabledForCielops}
                                      id="corstateId"
                                      onChange={(event) => {
                                        console.log(event.target.value);
                                        if (event.target.value == "") {
                                          setFieldValue(
                                            (values.corcityId = "")
                                          );
                                          setFieldValue(
                                            (values.corstateId = "")
                                          );
                                        } else {
                                          LoginService.TokenUser((res) => {
                                            localStorage.setItem(
                                              "jwt-token",
                                              res.responseObject
                                            );
                                          });
                                          if (
                                            event.target.value !== undefined
                                          ) {
                                            setFieldValue(
                                              (values.corstateId =
                                                event.target.value)
                                            );
                                            RegionManagementService.getCity(
                                              "stateId=" + event.target.value,
                                              (res) => {
                                                setFieldValue(
                                                  (values.cityListForCor =
                                                    res.responseListObject)
                                                );
                                              }
                                            );
                                          }
                                        }
                                      }}
                                      onBlur={(event) => {
                                        this.updateCommonWeightage(
                                          event.target.name,
                                          event.target.value
                                        );
                                      }}
                                      className={
                                        "form-control" +
                                        (errors.corstateId && touched.corstateId
                                          ? " is-invalid"
                                          : "")
                                      }
                                    >
                                      <option value="">---Select----</option>
                                      {(values.stateListForCor || []).map(
                                        (team) => (
                                          <option
                                            key={team.stateId}
                                            value={team.stateId}
                                          >
                                            {this.titleCase(team.stateName)}
                                          </option>
                                        )
                                      )}
                                    </Field> */}
                                    {/* <ErrorMessage
                                          name="corstateId"
                                          component="div"
                                          className="validation"
                                        /> */}
                                    {/* {errors.corstateId && (
                                      <div className="validation">
                                        {errors.corstateId}
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-4 col-sm-4 col-xs-12">
                                    <label>
                                      City
                                      <span className="validation"> *</span>
                                    </label>
                                    <Field
                                      as="select"
                                      name="corcityId"
                                      id="corcityId"
                                      disabled={values.disabledForCielops}
                                      onChange={(event) => {
                                        if (event.target.value !== undefined) {
                                          setFieldValue(
                                            (values.corcityId =
                                              event.target.value)
                                          );
                                        }
                                      }}
                                      className={
                                        "form-control" +
                                        (errors.corcityId && touched.corcityId
                                          ? " is-invalid"
                                          : "")
                                      }
                                    >
                                      <option value="">---Select----</option>
                                      {(values.cityListForCor || []).map(
                                        (team) => (
                                          <option
                                            key={team.cityId}
                                            value={team.cityId}
                                          >
                                            {this.titleCase(team.cityName)}
                                          </option>
                                        )
                                      )}
                                      onBlur=
                                      {(event) => {
                                        this.updateCommonWeightage(
                                          event.target.name,
                                          event.target.value
                                        );
                                      }}
                                    </Field> */}
                                    {/* <ErrorMessage
                                          name="corcityId"
                                          component="div"
                                          className="validation"
                                        /> */}
                                    {/* {errors.corcityId && (
                                      <div className="validation">
                                        {errors.corcityId}
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12" />
                                  <div className="col-md-4 col-sm-4 col-xs-12">
                                    <label>
                                      pin Code
                                      <span className="validation"> *</span>
                                    </label>
                                    <Field
                                      type="text"
                                      name="corPostalCode"
                                      id="corPostalCode"
                                      maxlength="6"
                                      disabled={values.disabledForCielops}
                                      onChange={handleChange}
                                      onBlur={(event) => {
                                        fetch(
                                          "https://api.postalpincode.in/pincode/" +
                                            event.target.value
                                        )
                                          .then((response) => response.json())
                                          .then((records) => {
                                            console.log(records);
                                            if (
                                              records[0].Status === "Success"
                                            ) {
                                              setFieldValue(
                                                (values.corPostalCode =
                                                  event.target.value)
                                              );
                                              this.updateCommonWeightage(
                                                "corPostalCode",
                                                event.target.value
                                              );
                                            } else {
                                              setFieldValue(
                                                (values.corPostalCode = "")
                                              );
                                              this.updateCommonWeightage(
                                                "corPostalCode",
                                                ""
                                              );
                                            }
                                          })
                                          .catch((error) => {});
                                      }}
                                      autoComplete="corPostalCode"
                                      // onBlur={event => {
                                      //   this.updateCommonWeightage(event.target.name,event.target.value)
                                      // }}
                                      className={
                                        "form-control" +
                                        (errors.corPostalCode &&
                                        touched.corPostalCode
                                          ? " is-invalid"
                                          : "")
                                      }
                                      placeholder="PIN"
                                    /> */}
                                    {/* <ErrorMessage
                                          name="corPostalCode"
                                          className="validation"
                                          component="div"
                                        /> */}
                                    {/* {errors.corPostalCode && (
                                      <div className="validation">
                                        {errors.corPostalCode}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>*/}
                            <div 
                              className="address-border"
                              style={
                                this.props.personnelData.learnerEuinOption ===
                                  "Fresh EUIN" ||
                                this.props.personnelData.learnerEuinOption ===
                                  "Only EUIN Renewal"
                                  ? { display: "none" }
                                  : { display: "block" }
                              }
                            >
                              <div className="form-group">
                                <div className="row">
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                    <div className="row row-no-margin">
                                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                      <Field
                                              className="form-check-input"
                                              type="checkbox"
                                              name="addressTypeMark"
                                              style={{ marginRight: "10px" }}
                                              checked={values.addressTypeMark}
                                              onClick={(event) => {
                                                if (event.target.checked) {
                                                setFieldValue("addressTypeMark",true)
                                                }
                                                else{
                                                  setFieldValue("addressTypeMark",false)

                                                }
                                              }}
                                              />
                                                <label
                                                className="form-check-label"
                                                for="gridCheck"
                                              >
                                             Mark, if this is your permanent address
                                              </label>

                                        {/* <div class="col-md-9 col-lg-9 col-sm-9 col-xs-12 pl">
                                          <h5 class="address-heading">
                                            Permanent Address{" "}
                                          </h5>
                                        </div>
                                       */}
{/*                          
                                        <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                          <div class="form-check pull-right">
                                            <Field
                                              className="form-check-input"
                                              type="checkbox"
                                              name="sameAsAbove"
                                              style={{ marginRight: "10px" }}
                                              checked={values.addressFlag}
                                              onClick={(event) => {
                                                if (event.target.checked) {
                                                  let count = 0;

                                                  if (
                                                    values.corStreet !== "" &&
                                                    values.corStreet !==
                                                      undefined
                                                  ) {
                                                    this.updateCommonWeightage(
                                                      "perStreet",
                                                      "perStreet"
                                                    );
                                                    count = count + 1;
                                                  }
                                                  if (
                                                    values.corStreet2 !== "" &&
                                                    values.corStreet2 !==
                                                      undefined
                                                  ) {
                                                    this.updateCommonWeightage(
                                                      "perStreet2",
                                                      "perStreet2"
                                                    );
                                                    count = count + 1;
                                                  }
                                                  if (
                                                    values.corStreet3 !== "" &&
                                                    values.corStreet3 !==
                                                      undefined
                                                  ) {
                                                    this.updateCommonWeightage(
                                                      "perStreet3",
                                                      "perStreet3"
                                                    );
                                                    count = count + 1;
                                                  }
                                                  if (
                                                    values.corcountryId !==
                                                      "" &&
                                                    values.corcountryId !==
                                                      undefined
                                                  ) {
                                                    this.updateCommonWeightage(
                                                      "percountryId",
                                                      "percountryId"
                                                    );
                                                    count = count + 1;
                                                  }
                                                  if (
                                                    values.corstateId !== "" &&
                                                    values.corstateId !==
                                                      undefined
                                                  ) {
                                                    this.updateCommonWeightage(
                                                      "perstateId",
                                                      "perstateId"
                                                    );
                                                    count = count + 1;
                                                  }
                                                  if (
                                                    values.corcityId !== "" &&
                                                    values.corcityId !==
                                                      undefined
                                                  ) {
                                                    this.updateCommonWeightage(
                                                      "percityId",
                                                      "percityId"
                                                    );
                                                    count = count + 1;
                                                  }
                                                  if (
                                                    values.corPostalCode !==
                                                      "" &&
                                                    values.corPostalCode !==
                                                      undefined
                                                  ) {
                                                    this.updateCommonWeightage(
                                                      "perPostalCode",
                                                      "percityId"
                                                    );
                                                    count = count + 1;
                                                  }

                                                  // setFieldValue(
                                                  //   (values.sameasAboveCount =
                                                  //    count)
                                                  // );

                                                  //  this.updateCommonWeightage("sameasabove",count)

                                                  values.stateListForPer =
                                                    values.stateListForCor;
                                                  values.cityListForPer =
                                                    values.cityListForCor;
                                                  values.perStreet =
                                                    values.corStreet;
                                                  values.perStreet2 =
                                                    values.corStreet2;
                                                  values.perStreet3 =
                                                    values.corStreet3;
                                                  values.percountryId =
                                                    values.corcountryId;
                                                  values.perstateId =
                                                    values.corstateId;
                                                  values.percityId =
                                                    values.corcityId;
                                                  values.perPostalCode =
                                                    values.corPostalCode;
                                                  values.addressFlag =
                                                    "checked";
                                                  //  "checked";
                                                } else {
                                                  //this.updateCommonWeightage("unCheckSameasabove",values.sameasAboveCount)

                                                  values.perStreet = "";
                                                  values.perStreet2 = "";
                                                  values.perStreet3 = "";
                                                  values.percountryId = "";
                                                  values.perstateId = "";
                                                  values.percityId = "";
                                                  values.perPostalCode = "";
                                                  values.addressFlag = "";
                                                  values.stateListForPer = [];
                                                  values.cityListForPer = [];
                                                }
                                              }}
                                            />
                                            <label
                                              class="form-check-label"
                                              for="gridCheck"
                                            >
                                              Same as Above
                                            </label>
                                          </div>
                                        </div>
                          */}
                                      </div>
                                    </div>{" "}
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-4 col-sm-4 col-xs-12">
                                    <label>
                                      Address Line 1{" "}
                                      <span className="validation"> *</span>
                                    </label>
                                    <Field
                                      type="text"
                                      name="perStreet"
                                      id="perStreet"
                                      maxLength="40"
                                      autoComplete="perStreet"
                                      disabled={values.disabledForCielops}
                                      onChange={handleChange}
                                      onBlur={(event) => {
                                        this.updateCommonWeightage(
                                          event.target.name,
                                          event.target.value,
                                          values
                                        );
                                      }}
                                      className={
                                        "form-control" +
                                        (errors.perStreet && touched.perStreet
                                          ? " is-invalid"
                                          : "")
                                      }
                                      placeholder="Address"
                                    />
                                    {/* <ErrorMessage
                                          name="perStreet"
                                          className="validation"
                                          component="div"
                                        /> */}
                                    {errors.perStreet && (
                                      <div className="validation">
                                        {errors.perStreet}
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-4 col-sm-4 col-xs-12">
                                    <label>Address Line 2</label>
                                    <Field
                                      type="text"
                                      name="perStreet2"
                                      id="perStreet2"
                                      maxLength="40"
                                      onChange={handleChange}
                                      disabled={values.disabledForCielops}
                                      onBlur={(event) => {
                                        this.updateCommonWeightage(
                                          event.target.name,
                                          event.target.value,
                                          values
                                        );
                                      }}
                                      autoComplete="perStreet2"
                                      className={
                                        "form-control" +
                                        (errors.perStreet2 && touched.perStreet2
                                          ? " is-invalid"
                                          : "")
                                      }
                                      placeholder="Address"
                                    />
                                    {errors.perStreet2 && (
                                      <div className="validation">
                                        {errors.perStreet2}
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-4 col-sm-4 col-xs-12">
                                    <label>Address Line 3</label>
                                    <Field
                                      type="text"
                                      name="perStreet3"
                                      maxLength="40"
                                      id="perStreet3"
                                      onChange={handleChange}
                                      disabled={values.disabledForCielops}
                                      onBlur={(event) => {
                                        this.updateCommonWeightage(
                                          event.target.name,
                                          event.target.value,
                                          values
                                        );
                                      }}
                                      autoComplete="perStreet3"
                                      className={
                                        "form-control" +
                                        (errors.perStreet3 && touched.perStreet3
                                          ? " is-invalid"
                                          : "")
                                      }
                                      placeholder="Address"
                                    />
                                    {errors.perStreet3 && (
                                      <div className="validation">
                                        {errors.perStreet3}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12" />
                                <div className="row">
                                  <div className="col-md-4 col-sm-4 col-xs-12">
                                    <label>
                                      Country
                                      <span className="validation"> *</span>
                                    </label>
                                    <Field
                                      as="select"
                                      name="percountryId"
                                      id="percountryId"
                                      disabled={values.disabledForCielops}
                                      onChange={(event) => {
                                        LoginService.TokenUser((res) => {
                                          localStorage.setItem(
                                            "jwt-token",
                                            res.responseObject
                                          );
                                        });
                                        if (
                                          event.target.value !== undefined &&
                                          event.target.value !== "0"
                                        ) {
                                          setFieldValue(
                                            (values.percountryId =
                                              event.target.value)
                                          );
                                          values.stateListForPer = [];
                                          values.cityListForPer = [];

                                          RegionManagementService.getState(
                                            "countryId=" + event.target.value,
                                            (res) => {
                                              setFieldValue(
                                                (values.stateListForPer =
                                                  res.responseListObject)
                                              );
                                            }
                                          );
                                        } else {
                                          setFieldValue(
                                            (values.percountryId = "")
                                          );
                                          setFieldValue(
                                            (values.percityId = "")
                                          );
                                          setFieldValue(
                                            (values.perstateId = "")
                                          );

                                          setFieldValue(
                                            (values.cityListForPer = [])
                                          );
                                          setFieldValue(
                                            (values.stateListForPer = [])
                                          );
                                        }
                                      }}
                                      onBlur={(event) => {
                                        this.updateCommonWeightage(
                                          event.target.name,
                                          event.target.value,
                                          values
                                        );
                                      }}
                                      className={
                                        "form-control" +
                                        (errors.percountryId &&
                                        touched.percountryId
                                          ? " is-invalid"
                                          : "")
                                      }
                                    >
                                      <option value="0">---Select----</option>
                                      {values.countryList.map((team) =>
                                        team.isActive ? (
                                          <option
                                            key={team.countryId}
                                            value={team.countryId}
                                          >
                                            {this.titleCase(team.countryName)}
                                          </option>
                                        ) : (
                                          ""
                                        )
                                      )}
                                    </Field>
                                    {errors.percountryId && (
                                      <div className="validation">
                                        {errors.percountryId}
                                      </div>
                                    )}
                                    {/* <ErrorMessage
                                          name="percountryId"
                                          component="div"
                                          className="validation"
                                        /> */}
                                  </div>
                                  <div className="col-md-4 col-sm-4 col-xs-12">
                                    <label>
                                      State
                                      <span className="validation"> *</span>
                                    </label>
                                    <Field
                                      as="select"
                                      name="perstateId"
                                      id="perstateId"
                                      value={values.perstateId}
                                      disabled={values.disabledForCielops}
                                      onChange={(event) => {
                                        if (event.target.value == "") {
                                          setFieldValue(
                                            (values.percityId = "")
                                          );
                                          setFieldValue(
                                            (values.perstateId = "")
                                          );
                                        } else {
                                          LoginService.TokenUser((res) => {
                                            localStorage.setItem(
                                              "jwt-token",
                                              res.responseObject
                                            );
                                          });
                                          if (
                                            event.target.value !== undefined
                                          ) {
                                            setFieldValue(
                                              (values.perstateId =
                                                event.target.value)
                                            );

                                            RegionManagementService.getCity(
                                              "stateId=" + event.target.value,
                                              (res) => {
                                                setFieldValue(
                                                  (values.cityListForPer =
                                                    res.responseListObject)
                                                );
                                              }
                                            );
                                          }
                                        }
                                      }}
                                      onBlur={(event) => {
                                        this.updateCommonWeightage(
                                          event.target.name,
                                          event.target.value,
                                          values
                                        );
                                      }}
                                      className={
                                        "form-control" +
                                        (errors.perstateId && touched.perstateId
                                          ? " is-invalid"
                                          : "")
                                      }
                                    >
                                      <option value="">---Select----</option>
                                      {(values.stateListForPer || []).map(
                                        (team) => (
                                          <option
                                            key={team.stateId}
                                            value={team.stateId}
                                          >
                                            {this.titleCase(team.stateName)}
                                          </option>
                                        )
                                      )}
                                    </Field>
                                    {errors.perstateId && (
                                      <div className="validation">
                                        {errors.perstateId}
                                      </div>
                                    )}
                                    {/* <ErrorMessage
                                          name="perstateId"
                                          component="div"
                                          className="validation"
                                        /> */}
                                  </div>
                                  <div className="col-md-4 col-sm-4 col-xs-12">
                                    <label>
                                      City
                                      <span className="validation"> *</span>
                                    </label>
                                    <Field
                                      as="select"
                                      name="percityId"
                                      id="percityId"
                                      value={values.percityId}
                                      disabled={values.disabledForCielops}
                                      onChange={(event) => {
                                        if (event.target.value !== undefined)
                                          setFieldValue(
                                            (values.percityId =
                                              event.target.value)
                                          );
                                      }}
                                      onBlur={(event) => {
                                        this.updateCommonWeightage(
                                          event.target.name,
                                          event.target.value,
                                          values
                                        );
                                      }}
                                      className={
                                        "form-control" +
                                        (errors.percityId && touched.percityId
                                          ? " is-invalid"
                                          : "")
                                      }
                                    >
                                      <option value="">---Select----</option>
                                      {(values.cityListForPer || []).map(
                                        (team) => (
                                          <option
                                            key={team.cityId}
                                            value={team.cityId}
                                          >
                                            {this.titleCase(team.cityName)}
                                          </option>
                                        )
                                      )}
                                    </Field>
                                    {/* <ErrorMessage
                                          name="percityId"
                                          component="div"
                                          className="validation"
                                        /> */}
                                    {errors.percityId && (
                                      <div className="validation">
                                        {errors.percityId}
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12" />
                                  <div className="col-md-4 col-sm-4 col-xs-12">
                                    <label>
                                      pin Code
                                      <span className="validation"> *</span>
                                    </label>
                                    <Field
                                      type="text"
                                      name="perPostalCode"
                                      id="perPostalCode"
                                      disabled={values.disabledForCielops}
                                      maxlength="6"
                                      onChange={handleChange}
                                      // onBlur={event => {
                                      //   this.updateCommonWeightage(event.target.name,event.target.value)
                                      // }}
                                      onBlur={(event) => {
                                        fetch(
                                          "https://api.postalpincode.in/pincode/" +
                                            event.target.value
                                        )
                                          .then((response) => response.json())
                                          .then((records) => {
                                            console.log(records);
                                            if (
                                              records[0].Status === "Success"
                                            ) {
                                              setFieldValue(
                                                (values.perPostalCode =
                                                  event.target.value)
                                              );
                                              this.updateCommonWeightage(
                                                 event.target.name,
                                                event.target.value,
                                                values
                                              );
                                            }
                                            // else {
                                            //   setFieldValue (
                                            //     (values.perPostalCode = '')
                                            //   );
                                            //   this.updateCommonWeightage (
                                            //     'perPostalCode',
                                            //     ''
                                            //   );
                                            // }
                                          })
                                          .catch((error) => {});
                                      }}
                                      autoComplete="perPostalCode"
                                      className={
                                        "form-control" +
                                        (errors.perPostalCode &&
                                        touched.perPostalCode
                                          ? " is-invalid"
                                          : "")
                                      }
                                      placeholder="PIN"
                                    />
                                    {/* <ErrorMessage
                                          name="perPostalCode"
                                          className="validation"
                                          component="div"
                                        /> */}
                                    {errors.perPostalCode && (
                                      <div className="validation">
                                        {errors.perPostalCode}
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-4 col-sm-4 col-xs-12">
                                    {" "}
                                    <label>
                                      Proof Of Address
                                      <span className="validation"> *</span>
                                    </label>
                                    <Field
                                      as="select"
                                      name="peraddressproof1"
                                      id="peraddressproof1"
                                      // disabled={values.disabledForCielops}
                                      onChange={handleChange}
                                      onBlur={(event) => {
                                        this.updateCommonWeightage(
                                          event.target.name,
                                          event.target.value,
                                          values
                                        );
                                      }}
                                      className={
                                        "form-control" +
                                        (errors.peraddressproof1 &&
                                        touched.peraddressproof1
                                          ? " is-invalid"
                                          : "")
                                      }
                                    >
                                      <option value="">---Select----</option>
                                      {(this.state.AddressProof || []).map(
                                        (team) => (
                                          <option
                                            key={team.codeValueId}
                                            value={team.codeValueId}
                                          >
                                            {team.codeValue}
                                          </option>
                                        )
                                      )}
                                    </Field>
                                    <ErrorMessage
                                      name="peraddressproof1"
                                      component="div"
                                      className="validation"
                                    />
                                    <span className="file-size-limit">
                                      {" "}
                                      Please choose one of the documents from
                                      the dropdown and attach a scanned image of
                                      the same{" "}
                                    </span>
                                  </div>
                                  <div className="col-md-4 col-sm-4 col-xs-12">
                                    <label>
                                      Upload Address Proof
                                      <span className="validation"> *</span>
                                    </label>
                                    <div className="profile-detail">
                                      <div className="circle">
                                        <img
                                          className="profile-pic"
                                          src={values.addressProofCardURL}
                                        />
                                        <div className="profile">
                                          <i
                                            className="fa fa-user"
                                            aria-hidden="true"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="profile-add">
                                      <a>
                                        <span className="icon btn-file text-center">
                                          <i
                                            className="fa fa-plus-circle"
                                            aria-hidden="true"
                                          />
                                          <FileBase64
                                            multiple={true}
                                            // id="addressProofURL"
                                            name="addressProofCardURL"
                                            onChange={handleChange}
                                            onDone={(event) => {
                                              // const checkType = event[0].name.split(
                                              //   "."
                                              // );
                                              const checkType = event[0].name.split(
                                                /\.(?=[^\.]+$)/
                                              );

                                              if (
                                                [
                                                  "jpg",
                                                  "JPG",
                                                  "jpeg",
                                                  "JPEG",
                                                  "png",
                                                  "PNG",
                                                  // "pdf",
                                                ].indexOf(checkType[1]) < 0
                                              ) {
                                                setFieldValue(
                                                  (values.documentTypeCheckForaddressProofCardURLImage = true)
                                                );
                                              } else if (
                                                event[0].file.size <= 1000000
                                              ) {
                                                if (
                                                  this.checkName(event[0].name)
                                                ) {
                                                  values.document.push({
                                                    documentHash:
                                                      event[0].base64,
                                                    documentType:
                                                      "AddressProof",
                                                    documentName: event[0].name,
                                                  });
                                                  this.updateCommonWeightage(
                                                    "addressProofCardURL",
                                                    event[0].name,
                                                    values
                                                  );
                                                  setFieldValue(
                                                    (values.documentTypeCheckForaddressProofCardURLImage = false)
                                                  );
                                                  setFieldValue(
                                                    (values.documentSizeCheckForaddressProofCardURLCard = false)
                                                  );
                                                  setFieldValue(
                                                    (values.AressProofDocumentNameValidation = false)
                                                  );

                                                  //setFieldValue((values.panCardDocumentName = event[0].name));
                                                  setFieldValue(
                                                    (values.addressProofCardURL =
                                                      event[0].base64)
                                                  );
                                                  setFieldValue(
                                                    (values.addressProofName =
                                                      event[0].name)
                                                  );
                                                } else {
                                                  setFieldValue(
                                                    (values.AressProofDocumentNameValidation = true)
                                                  );

                                                  //  warning("File/Document name can only contain number, letter, hyphens, space.", warningNotification);
                                                }
                                              } else {
                                                setFieldValue(
                                                  (values.AressProofDocumentNameValidation = false)
                                                );

                                                setFieldValue(
                                                  (values.documentSizeCheckForaddressProofCardURL = true)
                                                );
                                              }
                                            }}
                                          />
                                          <h4>Add Address Proof</h4>
                                          <h4
                                            style={{ wordBreak: "break-all" }}
                                          >
                                            {values.addressProofName
                                              ? ""
                                              : values.addressProofName1
                                              ? values.addressProofName1.split(
                                                  "."
                                                )[1] == "pdf"
                                                ? values.addressProofName1
                                                : ""
                                              : ""}
                                            {values.addressProofName &&
                                            values.addressProofName.split(
                                              "."
                                            )[1] == "pdf"
                                              ? "(" +
                                                values.addressProofName +
                                                ")"
                                              : ""}
                                          </h4>
                                        </span>
                                      </a>
                                      <div className="col-md-12 col-sm-12">
                                        <span className="file-size-limit">
                                          The file should be in PNG/JPG format,
                                          with file-size less than 1MB, with no
                                          special characters in the file-name
                                          {/* Supported Formats: .jpg, .jpeg,pdf,
                                              .png only. <br />
                                                File Size: Max 1 MB */}
                                        </span>
                                      </div>
                                      {values.documentSizeCheckForaddressProofCardURL ? (
                                        <p style={{ color: "red" }}>
                                          File Size is too large(Maximum File
                                          Size:1MB).
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                      {values.documentTypeCheckForaddressProofCardURLImage ? (
                                        <p style={{ color: "red" }}>
                                          Invalid image format
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                      {values.AressProofDocumentNameValidation ? (
                                        <p style={{ color: "red" }}>
                                          File/Document name can only contain
                                          number, letter, hyphens, space.
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>

                          {/* <section
                                className="create-parent page-section"
                                id="create-parent"
                              >
                                <div className="row">
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                    <h2 className="sub-header">Parent Details</h2>
                                    <div className="address-border">
                                      <div className="form-group">
                                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                          <h5 className="address-heading">
                                            Father Details
                                        </h5>
                                        </div>
                                        <div className="form-group">
                                          <div className="row">
                                            <div className="col-md-4 col-sm-4 col-xs-12">
                                              <label>Father Name </label>
                                              <Field
                                                type="text"
                                                name="learnerFatherName"
                                                id="learnerFatherName"
                                                onChange={handleChange}
                                                autoComplete="learnerFatherName"
                                                className="form-control"
                                                placeholder="Father Name"
                                              />
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-xs-12">
                                              <label>Father Birth Date</label>
                                              <Field
                                                type="date"
                                                onChange={handleChange}
                                                max={this.state.today}
                                                name="learnerFatherDOB"
                                                id="learnerFatherDOB"
                                                className="form-control"
                                                placeholder=""
                                              />
                                              <ErrorMessage
                                                name="learnerFatherDOB"
                                                component="div"
                                                className="validation"
                                              />
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-xs-12">
                                              <label>Occupation Type</label>
                                              <Field
                                                as="select"
                                                name="learnerFatherEmployementType"
                                                onChange={event => {
                                                  setFieldValue(
                                                    (values.learnerFatherEmployementType =
                                                      event.target.value)
                                                  );
                                                  if (
                                                    event.target.value === "6" ||
                                                    event.target.value === "8"
                                                  ) {
                                                    setFieldValue(
                                                      (values.learnerFatherCompanyName =
                                                        "")
                                                    );
                                                  }
                                                }}
                                                id="state"
                                                className={
                                                  "form-control" +
                                                  (errors.learnerFatherEmployementType &&
                                                    touched.learnerFatherEmployementType
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                              >
                                                <option value="">
                                                  ---Select----
                                              </option>
                                                {(
                                                  this.state
                                                    .employeTypeForParents || []
                                                ).map(team => (
                                                  <option
                                                    key={team.codeValueId}
                                                    value={team.codeValueId}
                                                  >
                                                    {team.codeValue}
                                                  </option>
                                                ))}
                                              </Field>
                                              <ErrorMessage
                                                name="learnerFatherEmployementType"
                                                component="div"
                                                className="validation"
                                              />
                                            </div>
                                          </div>
                                          <div className="row">
                                            {values.learnerFatherEmployementType ===
                                              "6" ||
                                              values.learnerFatherEmployementType ===
                                              "8" ||
                                              values.learnerFatherEmployementType ===
                                              6 ||
                                              values.learnerFatherEmployementType ===
                                              8 ? (
                                                ""
                                              ) : (
                                                <div className="col-md-4 col-sm-4 col-xs-12">
                                                  <label>Company Name </label>
                                                  <Field
                                                    type="text"
                                                    name="learnerFatherCompanyName"
                                                    onChange={handleChange}
                                                    autoComplete="learnerFatherCompanyName"
                                                    className="form-control"
                                                    placeholder="Company Name"
                                                  />
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="address-border">
                                      <div className="form-group">
                                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                          <h5 className="address-heading">
                                            Mother Details
                                        </h5>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-4 col-sm-4 col-xs-12">
                                            <label>Mother Name </label>
                                            <Field
                                              type="text"
                                              name="learnerMotherName"
                                              onChange={handleChange}
                                              autoComplete="learnerMotherName"
                                              className="form-control"
                                              placeholder="Full Name"
                                            />
                                          </div>
                                          <div className="col-md-4 col-sm-4 col-xs-12">
                                            <label>Mother Birth Date</label>

                                            <Field
                                              type="date"
                                              max={this.state.today}
                                              name="learnerMotherDOB"
                                              id="learnerMotherDOB"
                                              onChange={handleChange}
                                              className="form-control"
                                              placeholder=""
                                            />
                                            <ErrorMessage
                                              name="learnerMotherDOB"
                                              component="div"
                                              className="validation"
                                            />
                                          </div>
                                          <div className="col-md-4 col-sm-4 col-xs-12">
                                            <label>Occupation Type</label>
                                            <Field
                                              as="select"
                                              name="learnerMotherEmployementType"
                                              onChange={event => {
                                                setFieldValue(
                                                  (values.learnerMotherEmployementType =
                                                    event.target.value)
                                                );
                                                if (
                                                  event.target.value === "6" ||
                                                  event.target.value === "8"
                                                ) {
                                                  setFieldValue(
                                                    (values.learnerMotherCompanyName =
                                                      "")
                                                  );
                                                }
                                              }}
                                              id="state"
                                              className={
                                                "form-control" +
                                                (errors.learnerMotherEmployementType &&
                                                  touched.learnerMotherEmployementType
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                            >
                                              <option value="">
                                                ---Select----
                                            </option>
                                              {(
                                                this.state
                                                  .employeTypeForParents || []
                                              ).map(team => (
                                                <option
                                                  key={team.codeValueId}
                                                  value={team.codeValueId}
                                                >
                                                  {team.codeValue}
                                                </option>
                                              ))}
                                            </Field>
                                            <ErrorMessage
                                              name="learnerMotherEmployementType"
                                              component="div"
                                              className="validation"
                                            />
                                          </div>
                                        </div>
                                        <div className="row">
                                          {values.learnerMotherEmployementType ===
                                            "6" ||
                                            values.learnerMotherEmployementType ===
                                            "8" ||
                                            values.learnerMotherEmployementType ===
                                            6 ||
                                            values.learnerMotherEmployementType ===
                                            8 ? (
                                              ""
                                            ) : (
                                              <div className="col-md-4 col-sm-4 col-xs-12">
                                                <label>Company Name </label>
                                                <Field
                                                  type="text"
                                                  name="learnerMotherCompanyName"
                                                  onChange={handleChange}
                                                  autoComplete="learnerMotherCompanyName"
                                                  className="form-control"
                                                  placeholder="Company Name"
                                                />
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section> */}
                          <section
                            className="create-qualification page-section profile-box"
                            id="educational-details"
                          >
                            <div className="row">
                              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                <h2 className="sub-header">
                                  Educational Qualification Details
                                  <span className="validation">*</span> <br />
                                  <span
                                    className="file-size-limit"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    Please put the latest Education
                                    Qualification first
                                  </span>
                                  {/* <a href="#" id="tooltip-icon" data-toggle="tooltip" lable="" title="Please put the latest Education Qualification 
                                      first">
                                        <i class="fa fa-info-circle" aria-hidden="true" style="font-size: 14px; padding-left: 5px;" /> 
                                       </a> */}
                                </h2>
                                {/* <span>General Instruction:  </span> */}
                                <div className="">
                                  <FieldArray
                                  id="educationalQualification"
                                    name="learnerQualification"
                                    onChange={handleChange}
                                    render={(arrayHelpers) => (
                                      <div>
                                        {values.learnerQualification &&
                                        values.learnerQualification.length >
                                          0 ? (
                                          <div className="table-row">
                                            <table
                                              className="table table-bordered table-hover"
                                              id="tab_logic"
                                            >
                                              <thead>
                                                <tr>
                                                  <th className="text-center">
                                                    {" "}
                                                    University/College Name
                                                    <span className="validation">
                                                      {" "}
                                                      *
                                                    </span>
                                                  </th>
                                                  {/* <th className="text-center">
                                                          {" "}
                                                          Qualification Category{" "}
                                                        </th> */}
                                                  <th className="text-center">
                                                    {" "}
                                                    Degree
                                                    <span className="validation">
                                                      {" "}
                                                      *
                                                    </span>{" "}
                                                  </th>
                                                  <th className="text-center">
                                                    {" "}
                                                    Passing Year
                                                    <span className="validation">
                                                      {" "}
                                                      *
                                                    </span>
                                                  </th>
                                                  {/* <th className="text-center">
                                                          {" "}
                                                          Pursuing Year{" "}
                                                        </th> */}
                                                  <th className="text-center">
                                                    {" "}
                                                    CGPA / Percentage{" "}
                                                    <span className="validation">
                                                      {" "}
                                                      *
                                                    </span>{" "}
                                                  </th>
                                                  {/* <th className="text-center">
                                                    {" "}
                                                    Educational Qualification
                                                    Document{" "} */}
                                                    {/* <span className="validation"> *</span>  {" "} */}
                                                  {/* </th> */}
                                                  <th className="text-center">
                                                    {" "}
                                                    Action{" "}
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {values.learnerQualification.map(
                                                  (friend, index) => (
                                                    <tr key={index}>
                                                      <td>
                                                        <Field
                                                          name={`learnerQualification.${index}.instituteName`}
                                                          className="form-control"
                                                          onChange={
                                                            handleChange
                                                          }
                                                          disabled={
                                                            values.disabledForCielops
                                                          }
                                                          value={
                                                            friend.instituteName
                                                              ? friend.instituteName
                                                              : ""
                                                          }
                                                          onBlur={(event) => {
                                                            this.updateCommonWeightage(
                                                              "learnerQualificationinstituteName",
                                                              event.target.value,
                                                              values
                                                            );
                                                          }}
                                                        />
                                                      </td>

                                                      <td>
                                                        <Field
                                                          as="select"
                                                          name={`learnerQualification.${index}.qualificationType`}
                                                          className="form-control"
                                                          disabled={
                                                            values.disabledForCielops
                                                          }
                                                          onBlur={(event) => {
                                                            this.updateCommonWeightage(
                                                              "learnerQualificationqualificationType",
                                                              event.target.value,
                                                              values
                                                            );
                                                          }}
                                                          onChange={(event) => {
                                                            //alert(event.target.value)
                                                            setFieldValue(
                                                              (friend.qualificationType =
                                                                event.target.value)
                                                            );
                                                            if (
                                                              event.target
                                                                .value ===
                                                                "Under Graduate" ||
                                                              event.target
                                                                .value ===
                                                                "under Graduate"
                                                            ) {
                                                              setFieldValue(
                                                                (friend.passingYear =
                                                                  "")
                                                              );
                                                            } else {
                                                              setFieldValue(
                                                                (friend.persuingYear =
                                                                  "")
                                                              );
                                                            }
                                                          }}
                                                          // value={
                                                          //   friend.qualificationType
                                                          // }
                                                        >
                                                          <option value="">
                                                            Select Education
                                                          </option>
                                                          <option value="Below Higher Secondary">
                                                            Below Higher
                                                            Secondary
                                                          </option>
                                                          <option value="Higher Secondary/diploma">
                                                            Higher
                                                            Secondary/diploma{" "}
                                                          </option>
                                                          <option value="Graduate">
                                                            Graduate
                                                          </option>
                                                          <option value="Post Graduate">
                                                            Post Graduate
                                                          </option>
                                                        </Field>
                                                      </td>
                                                      <td>
                                                        <Field
                                                          as="select"
                                                          className="form-control"
                                                          name={`learnerQualification.${index}.passingYear`}
                                                          type="number"
                                                          disabled={
                                                            values.disabledForCielops
                                                              ? true
                                                              : friend.qualificationType ==
                                                                  "Under Graduate" ||
                                                                friend.qualificationType ==
                                                                  "under Graduate"
                                                              ? true
                                                              : false
                                                          }
                                                          value={
                                                            friend.qualificationType ==
                                                              "Under Graduate" ||
                                                            friend.qualificationType ==
                                                              "under Graduate"
                                                              ? ""
                                                              : friend.passingYear
                                                              ? friend.passingYear
                                                              : ""
                                                          }
                                                          onBlur={(event) => {
                                                            this.updateCommonWeightage(
                                                              "learnerQualificationpassingYear",
                                                              event.target.value,
                                                              values
                                                            );
                                                          }}
                                                        >
                                                          <option value="">
                                                            Select
                                                          </option>
                                                          {(
                                                            this.state
                                                              .listOfYear || []
                                                          ).map((year) => (
                                                            <option
                                                              value={year}
                                                            >
                                                              {year}
                                                            </option>
                                                          ))}
                                                        </Field>
                                                      </td>

                                                      <td>
                                                        <Field
                                                        type="number"
                                                          className="form-control"
                                                          disabled={
                                                            values.disabledForCielops
                                                          }
                                                          name={`learnerQualification.${index}.percentage`}
                                                          //value={friend.percentage}
                                                          value={
                                                            friend.percentage
                                                              ? friend.percentage
                                                              : ""
                                                          }
                                                          onBlur={(event) => {
                                                            this.updateCommonWeightage(
                                                              "learnerQualificationpercentage",
                                                              event.target.value,
                                                              values
                                                            );
                                                          }}
                                                        />
                                                      </td>

                                                      {/* <td
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            wordBreak:
                                                              "break-all",
                                                            paddingRight:
                                                              "20px",
                                                          }}
                                                        > */}
                                                          {/* {friend.docName==undefined?
                                                            
                                                            friend.educationQualificationDocumentURL?(
            friend.educationQualificationDocumentURL.split("?")[0]).substring(
              (friend.educationQualificationDocumentURL || "").lastIndexOf(
                "/"
              ) + 1).replaceAll("%20"," ").replaceAll("%28","(").replaceAll("%29",")")
              
              :'':friend.docName} */}
{/* 
                                                          {friend.docName ==
                                                          undefined
                                                            ? friend.educationQualificationDocumentURL
                                                              ? friend.educationQualificationDocumentURL
                                                                  .split("?")[0]
                                                                  .substring(
                                                                    (
                                                                      friend.educationQualificationDocumentURL ||
                                                                      ""
                                                                    ).lastIndexOf(
                                                                      "/"
                                                                    ) + 1
                                                                  )
                                                              : // .replaceAll("%20"," ").replaceAll("%28","(").replaceAll("%29",")")

                                                                ""
                                                            : friend.docName}
                                                        </span>
                                                        <br />

                                                        <a
                                                          className="icon btn-file text-center"
                                                          style={
                                                            values.disabledForCielops
                                                              ? {
                                                                  pointerEvents:
                                                                    "none",
                                                                  cursor:
                                                                    "default",
                                                                }
                                                              : {}
                                                          }
                                                        >
                                                          <i
                                                            className="fa fa-paperclip color_magento"
                                                            aria-hidden="true"
                                                            style={{
                                                              fontSize: "25px",
                                                            }}
                                                          />

                                                          <FileBase64
                                                            multiple={false}
                                                            name={`learnerQualification.${index}.educationalQualification`}
                                                            onChange={
                                                              handleChange
                                                            }
                                                            onDone={(event) => {
                                                              // const checkType = event.name.split(
                                                              //   "."
                                                              // );
                                                              const checkType = event.name.split(
                                                                /\.(?=[^\.]+$)/
                                                              );

                                                              if (
                                                                [
                                                                  "jpg",
                                                                  "JPG",
                                                                  "jpeg",
                                                                  "JPEG",
                                                                  "png",
                                                                  "PNG",
                                                                  "pdf",
                                                                  "docx",
                                                                  "rtf",
                                                                  "doc",
                                                                ].indexOf(
                                                                  checkType[1]
                                                                ) < 0
                                                              ) {
                                                                setFieldValue(
                                                                  (values.documentSizeCheckForEduQualification = true)
                                                                );
                                                              } else if (
                                                                event.file
                                                                  .size <=
                                                                1000000
                                                              ) {
                                                                if (
                                                                  this.checkName(
                                                                    event.name
                                                                  )
                                                                ) {
                                                                  // values.edudocument.push({
                                                                  //   documentHash: event.base64,
                                                                  //   documentType:'PostGraduation',
                                                                  //  //
                                                                  //   documentName: event.name
                                                                  // });
                                                                  this.updateCommonWeightage(
                                                                    "learnerQualificationeducationalQualification",
                                                                    event.name
                                                                  );
                                                                  values.learnerQualification[
                                                                    index
                                                                  ].document = [];
                                                                  values.learnerQualification[
                                                                    index
                                                                  ].document.push(
                                                                    {
                                                                      documentHash:
                                                                        event.base64,
                                                                      documentType:
                                                                        "PostGraduation",
                                                                      //
                                                                      documentName:
                                                                        event.name,
                                                                    }
                                                                  );

                                                                  setFieldValue(
                                                                    (values.learnerQualification[
                                                                      index
                                                                    ].docName =
                                                                      event.name)
                                                                  );

                                                                  setFieldValue(
                                                                    (values.documentSizeCheckForEduQualification = false)
                                                                  );
                                                                  setFieldValue(
                                                                    (values.documentSizeCheckForEduQualification = false)
                                                                  );
                                                                  setFieldValue(
                                                                    (values.learnerQualification[
                                                                      index
                                                                    ].eduQualificationDocumentNameValidation = false)
                                                                  );

                                                                  // setFieldValue(
                                                                  //   (friend.educationQualificationDocumentURL= "")
                                                                  // );
                                                                  // }
                                                                } else {
                                                                  setFieldValue(
                                                                    (values.learnerQualification[
                                                                      index
                                                                    ].eduQualificationDocumentNameValidation = true)
                                                                  );

                                                                  // warning("File/Document name can only contain number, letter, hyphens, space. ", warningNotification);
                                                                }
                                                              } else {
                                                                setFieldValue(
                                                                  (values.learnerQualification[
                                                                    index
                                                                  ].eduQualificationDocumentNameValidation = false)
                                                                );

                                                                setFieldValue(
                                                                  (values.documentSizeCheckForEduQualification = true)
                                                                );
                                                                setFieldValue(values.learnerQualification[index].isInvalidSize=true)
                                                              }
                                                            }}
                                                          />
                                                        </a>
                                                        <span class="file-size-limit">
                                                          The file should be in
                                                          PNG/JPG/PDF format,
                                                          with file-size less
                                                          than 1MB, with no
                                                          special characters in
                                                          the file-name
                                                        </span>
                                                        {(values.documentSizeCheckForEduQualification&&values.learnerQualification[index].isInvalidSize)? (
                                                          <p
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          >
                                                            
                                                            File Size is too
                                                            large(Maximum File
                                                            Size:1MB).
                                                          </p>
                                                        ) : (
                                                          ""
                                                        )}
                                                        {values.documentTypeCheckForEduQualification ? (
                                                          <p
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          >
                                                            Invalid image format
                                                          </p>
                                                        ) : (
                                                          ""
                                                        )}

                                                        {values
                                                          .learnerQualification[
                                                          index
                                                        ]
                                                          .eduQualificationDocumentNameValidation ? (
                                                          <p
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          >
                                                            File/Document name
                                                            can only contain
                                                            number, letter,
                                                            hyphens, space.
                                                          </p>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </td> */}
                                                      <td>
                                                        <button
                                                          type="button"
                                                          disabled={
                                                            values.disabledForCielops
                                                          }
                                                          className="btn-8 ml-5 mr-5 mt-10"
                                                          onClick={() => {
                                                            arrayHelpers.insert(
                                                              index + 1,
                                                              ""
                                                            );
                                                          }}
                                                        >
                                                          <i
                                                            className="fa fa-plus"
                                                            aria-hidden="true"
                                                          />
                                                        </button>
                                                        <button
                                                          type="button"
                                                          disabled={
                                                            values.disabledForCielops
                                                          }
                                                          className="btn-8 ml-5 mr-5 mt-10"
                                                          onClick={() => {
                                                            arrayHelpers.remove(
                                                              index
                                                            );
                                                          }}
                                                        >
                                                          <i
                                                            className="fa fa-minus"
                                                            aria-hidden="true"
                                                          />
                                                        </button>
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        ) : (
                                          <button
                                            type="button"
                                            className="btn-4"
                                            onClick={() => {
                                              arrayHelpers.push("");
                                            }}
                                          >
                                            Add Educational Qualification
                                          </button>
                                        )}
                                      </div>
                                    )}
                                  />
                                </div>
                              </div>
                              <p
                                className="hidden-md hidden-lg"
                                style={{ paddingLeft: "15px" }}
                              >
                                Scroll to the right for more fields.
                              </p>
                            </div>
                          </section>

                          <section
                            className="create-qualification page-section profile-box"
                            id="create-qualification"
                          >
                            <div className="row">
                              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                <h2 className="sub-header">
                                  
                                  Occupation Details
                                  <span className="validation">*</span>
                                </h2>
                                <ErrorMessage
                                  name={`professionalQualification[0].occupation`}
                                  component="div"
                                  className="validation"
                                />
                                <div className="">
                                  <FieldArray
                                  id="professionalQualification"
                                    name="professionalQualification"
                                    onChange={handleChange}
                                    render={(arrayHelpers1) => (
                                      <div>
                                        {values.professionalQualification &&
                                        values.professionalQualification
                                          .length > 0 ? (
                                          <div className="table-row">
                                            <table
                                              className="table table-bordered table-hover"
                                              id="tab_logic"
                                            >
                                              <thead>
                                                <tr>
                                                  <th className="text-center">
                                                    {" "}
                                                    Occupation
                                                    <span className="validation">
                                                      {" "}
                                                      *
                                                    </span>
                                                  </th>
                                                  <th className="text-center">
                                                    {" "}
                                                    Company Name
                                                    <span className="validation">
                                                      {" "}
                                                      *
                                                    </span>
                                                    <a
                                                      href="#"
                                                      id="tooltip-icon"
                                                      data-toggle="tooltip"
                                                      lable="Name of Personal Assessment"
                                                      title=" 1. Fresher: In case you are a Fresher, then the rest of the fields in the row will be disabled. &#13;&#10;
                                                          2. Employed: Mention name of the current company. &#13;&#10;
                                                          3. Self Employed: Mention legal entity name under which you are conducting business.In case no name registered, mention “Self”. &#13;&#10;
                                                          4. Not Employed: Mention name of the last company you were employed"
                                                    >
                                                      <i
                                                        className="fa fa-info-circle"
                                                        aria-hidden="true"
                                                        style={{
                                                          fontSize: "14px",
                                                          paddingLeft: "5px",
                                                          paddingright: "8px",
                                                        }}
                                                      />{" "}
                                                    </a>
                                                  </th>
                                                  <th className="text-center">
                                                    {" "}
                                                    Designation{" "}
                                                  </th>
                                                  {/* <th className="text-center">
                                                    {" "}
                                                    From{" "}
                                                    <span className="validation">
                                                      {" "}
                                                      *
                                                    </span>
                                                  </th>
                                                  <th className="text-center">
                                                    To{" "}
                                                    <span className="validation">
                                                      {" "}
                                                      *
                                                    </span>
                                                  </th> */}
                                                  <th className="text-center">
                                                    {" "}
                                                    Total Experience (Years)
                                                    <span className="validation">
                                                      {" "}
                                                      *
                                                    </span>{" "}
                                                  </th>
                                                  {/* <th className="text-center">
                                                    {" "}
                                                    Annual Income */}
                                                    {/* <span className="validation">
                                                        {' '}*
                                                      </span>
                                                      {' '} */}
                                                  {/* </th> */}
                                                  <th className="text-center">
                                                    {" "}
                                                    Action{" "}
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {values.professionalQualification.map(
                                                  (friend, index) => (
                                                    <tr key={index}>
                                                      <td>
                                                        <Field
                                                          as="select"
                                                          name={`professionalQualification.${index}.occupation`}
                                                          id="occupation"
                                                          className="form-control"
                                                          disabled={
                                                            values.disabledForCielops
                                                          }
                                                          onChange={(e) => {
                                                            if (
                                                              e.target.value ==
                                                              "1021"
                                                            ) {
                                                              if (
                                                                friend == ""
                                                              ) {
                                                                values.professionalQualification.splice(
                                                                  index,
                                                                  1
                                                                );
                                                                let obj = {};
                                                                obj[
                                                                  "occupation"
                                                                ] = "";
                                                                obj[
                                                                  "companyName"
                                                                ] = "";
                                                                obj[
                                                                  "designation"
                                                                ] = "";
                                                                //    obj["fromDate"]=""
                                                                obj[
                                                                  "totalExperience"
                                                                ] = "";
                                                                //   obj["toDate"]=""
                                                                // obj[
                                                                //   "annualIncome"
                                                                // ] = "";
                                                                values.professionalQualification.push(
                                                                  obj
                                                                );
                                                                // setFieldValue(
                                                                //   (values.professionalQualification=obj)    )
                                                              }

                                                              setFieldValue(
                                                                (values.professionalQualification[
                                                                  index
                                                                ].occupation =
                                                                  e.target.value)
                                                              );

                                                              // setFieldValue(
                                                              //   (values.professionalQualification[
                                                              //     index
                                                              //   ].fromDay = "0")
                                                              // );
                                                              // setFieldValue(
                                                              //   (values.professionalQualification[
                                                              //     index
                                                              //   ].fromMonth =
                                                              //     "0")
                                                              // );
                                                              // setFieldValue(
                                                              //   (values.professionalQualification[
                                                              //     index
                                                              //   ].fromYear =
                                                              //     "0")
                                                              // );

                                                              // setFieldValue(
                                                              //   (values.professionalQualification[
                                                              //     index
                                                              //   ].toDay = "0")
                                                              // );
                                                              // setFieldValue(
                                                              //   (values.professionalQualification[
                                                              //     index
                                                              //   ].toMonth = "0")
                                                              // );
                                                              // setFieldValue(
                                                              //   (values.professionalQualification[
                                                              //     index
                                                              //   ].toyear = "0")
                                                              // );
                                                              setFieldValue(
                                                                (values.professionalQualification[
                                                                  index
                                                                ].totalExperience =
                                                                  "0")
                                                              );

                                                              setFieldValue(
                                                                (values.professionalQualification[
                                                                  index
                                                                ].companyName =
                                                                  "")
                                                              );

                                                              setFieldValue(
                                                                (values.professionalQualification[
                                                                  index
                                                                ].designation =
                                                                  "")
                                                              );
                                                              // setFieldValue(
                                                              // (values.professionalQualification[index].fromDate ="")    )

                                                              setFieldValue(
                                                                (values.professionalQualification[
                                                                  index
                                                                ].totalExperience =
                                                                  "")
                                                              );
                                                              // setFieldValue(
                                                              // (values.professionalQualification[index].toDate ="")    )
                                                              // setFieldValue(
                                                              //   (values.professionalQualification[
                                                              //     index
                                                              //   ].annualIncome =
                                                              //     "")
                                                              // );
                                                            } else if (
                                                              e.target.value ==
                                                              "1025"
                                                            ) {
                                                              if (
                                                                friend == ""
                                                              ) {
                                                                values.professionalQualification.splice(
                                                                  index,
                                                                  1
                                                                );
                                                                let obj = {};
                                                                obj[
                                                                  "occupation"
                                                                ] = "";
                                                                obj[
                                                                  "companyName"
                                                                ] = "";
                                                                obj[
                                                                  "designation"
                                                                ] = "";
                                                                //     obj["fromDate"]=""
                                                                obj[
                                                                  "totalExperience"
                                                                ] = "";
                                                                //    obj["toDate"]=""
                                                                // obj[
                                                                //   "annualIncome"
                                                                // ] = "";
                                                                values.professionalQualification.push(
                                                                  obj
                                                                );
                                                                // setFieldValue(
                                                                //   (values.professionalQualification=obj)    )
                                                              }

                                                              setFieldValue(
                                                                (values.professionalQualification[
                                                                  index
                                                                ].occupation =
                                                                  e.target.value)
                                                              );
                                                            } else if (
                                                              e.target.value ==
                                                                "1022" ||
                                                              e.target.value ==
                                                                "1024"
                                                            ) {
                                                              // warning(
                                                              //   'If you opted for a profession as "Employed" or "Self employed" then System will consider todays date as "To Date". However, you can change "To Date" anytime.',
                                                              //   warningNotification
                                                              // );
                                                              if (
                                                                friend == ""
                                                              ) {
                                                                values.professionalQualification.splice(
                                                                  index,
                                                                  1
                                                                );
                                                                let obj = {};
                                                                obj[
                                                                  "occupation"
                                                                ] = "";
                                                                obj[
                                                                  "companyName"
                                                                ] = "";
                                                                obj[
                                                                  "designation"
                                                                ] = "";
                                                                //     obj["fromDate"]=""
                                                                obj[
                                                                  "totalExperience"
                                                                ] = "";
                                                                //    obj["toDate"]=""
                                                                // obj[
                                                                //   "annualIncome"
                                                                // ] = "";
                                                                values.professionalQualification.push(
                                                                  obj
                                                                );
                                                                // setFieldValue(
                                                                //   (values.professionalQualification=obj)    )
                                                              }
                                                              let date = new Date();
                                                              let date1 = moment(
                                                                date
                                                              ).format(
                                                                "D-M-YYYY"
                                                              );
                                                              setFieldValue(
                                                                (values.professionalQualification[
                                                                  index
                                                                ].occupation =
                                                                  e.target.value)
                                                              );
                                                              // setFieldValue(
                                                              //   (values.professionalQualification[
                                                              //     index
                                                              //   ].fromDay = date1.split(
                                                              //     "-"
                                                              //   )[0])
                                                              // );
                                                              // setFieldValue(
                                                              //   (values.professionalQualification[
                                                              //     index
                                                              //   ].fromMonth = date1.split(
                                                              //     "-"
                                                              //   )[1])
                                                              // );
                                                              // setFieldValue(
                                                              //   (values.professionalQualification[
                                                              //     index
                                                              //   ].fromYear = date1.split(
                                                              //     "-"
                                                              //   )[2])
                                                              // );

                                                              // setFieldValue(
                                                              //   (values.professionalQualification[
                                                              //     index
                                                              //   ].toDay = date1.split(
                                                              //     "-"
                                                              //   )[0])
                                                              // );
                                                              // setFieldValue(
                                                              //   (values.professionalQualification[
                                                              //     index
                                                              //   ].toMonth = date1.split(
                                                              //     "-"
                                                              //   )[1])
                                                              // );
                                                              // setFieldValue(
                                                              //   (values.professionalQualification[
                                                              //     index
                                                              //   ].toyear = date1.split(
                                                              //     "-"
                                                              //   )[2])
                                                              // );
                                                              // setFieldValue(
                                                              //   (values.professionalQualification[
                                                              //     index
                                                              //   ].totalExperience = 0.0)
                                                              // );
                                                            } else {
                                                              if (
                                                                values
                                                                  .professionalQualification[
                                                                  index
                                                                ] == ""
                                                              ) {
                                                                values.professionalQualification.splice(
                                                                  index,
                                                                  1
                                                                );
                                                                let obj = {};
                                                                obj[
                                                                  "occupation"
                                                                ] = "";
                                                                obj[
                                                                  "companyName"
                                                                ] = "";
                                                                obj[
                                                                  "designation"
                                                                ] = "";
                                                                //   obj["fromDate"]=""
                                                                obj[
                                                                  "totalExperience"
                                                                ] = "";
                                                                //   obj["toDate"]=""
                                                                // obj[
                                                                //   "annualIncome"
                                                                // ] = "";
                                                                values.professionalQualification.push(
                                                                  obj
                                                                );
                                                                // setFieldValue(
                                                                //   (values.professionalQualification=obj)    )
                                                              }

                                                              setFieldValue(
                                                                (values.professionalQualification[
                                                                  index
                                                                ].occupation =
                                                                  e.target.value)
                                                              );
                                                            }
                                                          }}
                                                          onBlur={(event) => {
                                                            this.updateCommonWeightage(
                                                              "professionalQualificationoccupation",
                                                              event.target.value,
                                                              values
                                                            );
                                                          }}
                                                        >
                                                          <option value="0">
                                                            ---Select----
                                                          </option>
                                                          {/* <option>aaa</option> */}
                                                          {(
                                                            this.state
                                                              .occupation || []
                                                          ).map((team) => (
                                                            <option
                                                              key={
                                                                team.codeValueId
                                                              }
                                                              value={
                                                                team.codeValueId
                                                              }
                                                            >
                                                              {team.codeValue}
                                                            </option>
                                                          ))}
                                                        </Field>
                                                        <ErrorMessage
                                                          name={`professionalQualification.${index}.occupation`}
                                                          component="div"
                                                          className="validation"
                                                        />
                                                      </td>
                                                      <td>
                                                        <Field
                                                          name={`professionalQualification.${index}.companyName`}
                                                          className="form-control"
                                                          //  disabled={values.disabledForCielops || values.disabledprofDetails}
                                                          disabled={
                                                            values.disabledForCielops
                                                              ? true
                                                              : friend.occupation ==
                                                                "1021"
                                                              ? true
                                                              : false
                                                          }
                                                          onBlur={(event) => {
                                                            this.updateCommonWeightage(
                                                              "professionalQualificationcompanyName",
                                                              event.target.value,
                                                              values
                                                            );
                                                          }}
                                                        />
                                                        <ErrorMessage
                                                          name={`professionalQualification.${index}.companyName`}
                                                          component="div"
                                                          className="validation"
                                                        />
                                                      </td>
                                                      <td>
                                                        <Field
                                                          name={`professionalQualification.${index}.designation`}
                                                          className="form-control"
                                                          disabled={
                                                            values.disabledForCielops
                                                              ? true
                                                              : friend.occupation ==
                                                                "1021"
                                                              ? true
                                                              : false
                                                          }
                                                          onBlur={(event) => {
                                                            this.updateCommonWeightage(
                                                              "professionalQualificationdesignation",
                                                              event.target.value,
                                                              values
                                                            );
                                                          }}
                                                        />
                                                      </td>
                                                      {/* <td> */}
                                                        {/* <Field
                                                                type="date"
                                                                name={`professionalQualification.${index}.fromDate`}
                                                                disabled={
                                                                  values.disabledForCielops?true:friend.occupation ==
                                                                  "1021" 
                                                                    ? true
                                                                    : false
                                                                }
                                                                
                                                              
                                                                value={moment(friend.fromDate,"DD/MM/YYYY HH:mm:ss").format('YYYY-MM-DD[T]HH:mm:ss').split('T')[0]}
                                                                max={moment(new Date()).format("YYYY-MM-DD")}

                                                                className="form-control"
                                                                onChange={(e) => {
                                                                  
                                                             let fromDate=   moment(e.target.value,"YYYY-MM-DD").format('DD/MM/YYYY HH:mm:ss')
                                                             //  let a='12/10/2020 11:11:11'
                                                                  setFieldValue(
                                                                    (values.professionalQualification[index].fromDate =fromDate )
                                                                  )

                                                                  var FromDate = e.target.value
                                                                  var Todate = values.professionalQualification[index].toDate
                                                                 
                                                                  if(Todate!=="" && FromDate!=="")
                                                                  {
                                                                  if(Todate !==undefined && FromDate!=="")
                                                                  {
                                                                    Todate=   moment(Todate,"DD/MM/YYYY HH:mm:ss").format('YYYY-MM-DD')

                                                                    //if (Todate !== undefined && FromDate !== undefined) {
                                                                      if ((Date.parse(Todate) <= Date.parse(FromDate))) {
                                                                        //  alert();
                                                                        warning("End date should be greater than Start date", warningNotification);
                                                                        setFieldValue(
                                                                          (values.professionalQualification[index].fromDate = "")
                                                                        )
                                                                        setFieldValue(
                                                                          (values.professionalQualification[index].toDate = "")
                                                                        );
                                                                        setFieldValue(
                                                                          (values.professionalQualification[index].totalExperience = "")
                                                                        );
                                                                        
                                                                      }
                                                                      else{
                                                                                           
                                                                  let yearsDiff=this.diff_years(FromDate,Todate)
   
                                                                  
                                                                 
                                                                  setFieldValue(
                                                                    (values.professionalQualification[index].totalExperience = yearsDiff)
                                                                  );
                                                                  }
                                                                }
                                                                  else{
                                                                    setFieldValue(
                                                                      (values.professionalQualification[index].totalExperience ="")
                                                                    ); 
                                                                  }
                                                                }
                                                              else{
                                                                Todate=   moment(new Date()).format('DD/MM/YYYY HH:mm:ss')

                                                                setFieldValue(
                                                                  (values.professionalQualification[index].toDate =Todate)
                                                                )
                                                              }
                                                              
                                                              }
                                                              }
                                                              onBlur={event => {
                                                                this.updateCommonWeightage("professionalQualificationfromDate",event.target.value)
                                                              }}
                                                             
                                                              /> */}

                                                        {/* moment(e.target.value,"YYYY-MM-DD").format('DD/MM/YYYY HH:mm:ss') 
                                                           moment(Todate,"DD/MM/YYYY HH:mm:ss").format('YYYY-MM-DD')                   */}

                                                        {/* <div>
                                                          {}{" "}
                                                          <div className="">
                                                            <Field
                                                              as="select"
                                                              name={`professionalQualification.${index}.fromDay`}
                                                              value={parseInt(
                                                                friend.fromDay
                                                                  ? friend.fromDay
                                                                  : friend.fromDate ===
                                                                    undefined
                                                                  ? ""
                                                                  : friend.fromDate.split(
                                                                      "/"
                                                                    )[0],
                                                                10
                                                              )}
                                                              onBlur={(e) => {
                                                                //   alert(e.target.value)
                                                                let date =
                                                                  e.target
                                                                    .value;
                                                                // this.calculateExp(0,0,e.target.value,0,0,0)
                                                                let res = this.calculateExp(
                                                                  friend.fromYear,
                                                                  friend.fromMonth,
                                                                  date,
                                                                  friend.toyear,
                                                                  friend.toMonth,
                                                                  friend.toDay
                                                                );
                                                                //   alert(res)
                                                                if (
                                                                  res == false
                                                                ) {
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].fromDay =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].fromMonth =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].fromYear =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].toDay =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].toMonth =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].toyear =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].totalExperience =
                                                                      "")
                                                                  );
                                                                } else if (
                                                                  res !==
                                                                  undefined
                                                                ) {
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].totalExperience = res)
                                                                  );
                                                                }
                                                              }}
                                                              onChange={(e) => {
                                                                if (
                                                                  friend == ""
                                                                ) {
                                                                  values.professionalQualification.splice(
                                                                    index,
                                                                    1
                                                                  );
                                                                  let obj = {};
                                                                  obj[
                                                                    "occupation"
                                                                  ] = "";
                                                                  obj[
                                                                    "companyName"
                                                                  ] = "";
                                                                  obj[
                                                                    "designation"
                                                                  ] = "";
                                                                  obj[
                                                                    "fromDate"
                                                                  ] = "";
                                                                  obj[
                                                                    "totalExperience"
                                                                  ] = "";
                                                                  obj[
                                                                    "toDate"
                                                                  ] = "";
                                                                  obj[
                                                                    "annualIncome"
                                                                  ] = "";
                                                                  values.professionalQualification.push(
                                                                    obj
                                                                  );
                                                                  // setFieldValue(
                                                                  //   (values.professionalQualification=obj)    )
                                                                }

                                                                setFieldValue(
                                                                  (values.professionalQualification[
                                                                    index
                                                                  ].fromDay =
                                                                    e.target.value)
                                                                );
                                                                var listOfMonth = [];

                                                                if (
                                                                  e.target
                                                                    .value == 31
                                                                ) {
                                                                  listOfMonth = [
                                                                    {
                                                                      key: 1,
                                                                      value:
                                                                        "Jan",
                                                                    },

                                                                    {
                                                                      key: 3,
                                                                      value:
                                                                        "March",
                                                                    },

                                                                    {
                                                                      key: 5,
                                                                      value:
                                                                        "May",
                                                                    },

                                                                    {
                                                                      key: 7,
                                                                      value:
                                                                        "July",
                                                                    },
                                                                    {
                                                                      key: 8,
                                                                      value:
                                                                        "Aug",
                                                                    },

                                                                    {
                                                                      key: 10,
                                                                      value:
                                                                        "Oct",
                                                                    },

                                                                    {
                                                                      key: 12,
                                                                      value:
                                                                        "Dec",
                                                                    },
                                                                  ];
                                                                } else if (
                                                                  e.target
                                                                    .value == 30
                                                                ) {
                                                                  listOfMonth = [
                                                                    {
                                                                      key: 1,
                                                                      value:
                                                                        "Jan",
                                                                    },

                                                                    {
                                                                      key: 3,
                                                                      value:
                                                                        "March",
                                                                    },
                                                                    {
                                                                      key: 4,
                                                                      value:
                                                                        "April",
                                                                    },
                                                                    {
                                                                      key: 5,
                                                                      value:
                                                                        "May",
                                                                    },
                                                                    {
                                                                      key: 6,
                                                                      value:
                                                                        "June",
                                                                    },
                                                                    {
                                                                      key: 7,
                                                                      value:
                                                                        "July",
                                                                    },
                                                                    {
                                                                      key: 8,
                                                                      value:
                                                                        "Aug",
                                                                    },
                                                                    {
                                                                      key: 9,
                                                                      value:
                                                                        "Sept",
                                                                    },
                                                                    {
                                                                      key: 10,
                                                                      value:
                                                                        "Oct",
                                                                    },
                                                                    {
                                                                      key: 11,
                                                                      value:
                                                                        "Nov",
                                                                    },
                                                                    {
                                                                      key: 12,
                                                                      value:
                                                                        "Dec",
                                                                    },
                                                                  ];
                                                                } else if (
                                                                  e.target
                                                                    .value ==
                                                                    28 ||
                                                                  e.target
                                                                    .value == 29
                                                                ) {
                                                                  listOfMonth = [
                                                                    {
                                                                      key: 1,
                                                                      value:
                                                                        "Jan",
                                                                    },
                                                                    {
                                                                      key: 2,
                                                                      value:
                                                                        "Feb",
                                                                    },
                                                                    {
                                                                      key: 3,
                                                                      value:
                                                                        "March",
                                                                    },
                                                                    {
                                                                      key: 4,
                                                                      value:
                                                                        "April",
                                                                    },
                                                                    {
                                                                      key: 5,
                                                                      value:
                                                                        "May",
                                                                    },
                                                                    {
                                                                      key: 6,
                                                                      value:
                                                                        "June",
                                                                    },
                                                                    {
                                                                      key: 7,
                                                                      value:
                                                                        "July",
                                                                    },
                                                                    {
                                                                      key: 8,
                                                                      value:
                                                                        "Aug",
                                                                    },
                                                                    {
                                                                      key: 9,
                                                                      value:
                                                                        "Sept",
                                                                    },
                                                                    {
                                                                      key: 10,
                                                                      value:
                                                                        "Oct",
                                                                    },
                                                                    {
                                                                      key: 11,
                                                                      value:
                                                                        "Nov",
                                                                    },
                                                                    {
                                                                      key: 12,
                                                                      value:
                                                                        "Dec",
                                                                    },
                                                                  ];
                                                                } else {
                                                                  listOfMonth = [
                                                                    {
                                                                      key: 1,
                                                                      value:
                                                                        "Jan",
                                                                    },
                                                                    {
                                                                      key: 2,
                                                                      value:
                                                                        "Feb",
                                                                    },
                                                                    {
                                                                      key: 3,
                                                                      value:
                                                                        "March",
                                                                    },
                                                                    {
                                                                      key: 4,
                                                                      value:
                                                                        "April",
                                                                    },
                                                                    {
                                                                      key: 5,
                                                                      value:
                                                                        "May",
                                                                    },
                                                                    {
                                                                      key: 6,
                                                                      value:
                                                                        "June",
                                                                    },
                                                                    {
                                                                      key: 7,
                                                                      value:
                                                                        "July",
                                                                    },
                                                                    {
                                                                      key: 8,
                                                                      value:
                                                                        "Aug",
                                                                    },
                                                                    {
                                                                      key: 9,
                                                                      value:
                                                                        "Sept",
                                                                    },
                                                                    {
                                                                      key: 10,
                                                                      value:
                                                                        "Oct",
                                                                    },
                                                                    {
                                                                      key: 11,
                                                                      value:
                                                                        "Nov",
                                                                    },
                                                                    {
                                                                      key: 12,
                                                                      value:
                                                                        "Dec",
                                                                    },
                                                                  ];
                                                                }
                                                                setFieldValue(
                                                                  (values.professionalQualification[
                                                                    index
                                                                  ].fromlistOfMonth = listOfMonth)
                                                                );

                                                                //setFieldValue(values.fromlistOfMonth= listOfMonth)
                                                              }}
                                                              disabled={
                                                                values.disabledForCielops
                                                                  ? true
                                                                  : friend.occupation ==
                                                                    "1021"
                                                                  ? true
                                                                  : false
                                                              }
                                                              className={
                                                                "form-control" +
                                                                (errors.from &&
                                                                touched.from
                                                                  ? "is-invalid"
                                                                  : "")
                                                              }
                                                              className="form-control"
                                                            >
                                                              <option value="">
                                                                DD
                                                              </option>
                                                              {(friend.fromlistOfDay !==
                                                              undefined
                                                                ? friend.fromlistOfDay
                                                                : values.fromlistOfDay ||
                                                                  []
                                                              ).map((d) => (
                                                                <option
                                                                  value={d}
                                                                >
                                                                  {d}
                                                                </option>
                                                              ))}
                                                            </Field>
                                                          </div>
                                                          <div className="">
                                                            <Field
                                                              as="select"
                                                              name={`professionalQualification.${index}.fromMonth`}
                                                              value={parseInt(
                                                                friend.fromMonth
                                                                  ? friend.fromMonth
                                                                  : friend.fromDate ===
                                                                    undefined
                                                                  ? ""
                                                                  : friend.fromDate.split(
                                                                      "/"
                                                                    )[1],
                                                                10
                                                              )}
                                                              onChange={(e) => {
                                                                if (
                                                                  friend == ""
                                                                ) {
                                                                  values.professionalQualification.splice(
                                                                    index,
                                                                    1
                                                                  );
                                                                  let obj = {};
                                                                  obj[
                                                                    "occupation"
                                                                  ] = "";
                                                                  obj[
                                                                    "companyName"
                                                                  ] = "";
                                                                  obj[
                                                                    "designation"
                                                                  ] = "";
                                                                  obj[
                                                                    "fromDate"
                                                                  ] = "";
                                                                  obj[
                                                                    "totalExperience"
                                                                  ] = "";
                                                                  obj[
                                                                    "toDate"
                                                                  ] = "";
                                                                  obj[
                                                                    "annualIncome"
                                                                  ] = "";
                                                                  values.professionalQualification.push(
                                                                    obj
                                                                  );
                                                                  // setFieldValue(
                                                                  //   (values.professionalQualification=obj)    )
                                                                }

                                                                setFieldValue(
                                                                  (values.professionalQualification[
                                                                    index
                                                                  ].fromMonth =
                                                                    e.target.value)
                                                                );
                                                                if (
                                                                  e.target
                                                                    .value == 2
                                                                ) {
                                                                  var listOfDay = [];
                                                                  for (
                                                                    var i = 1;
                                                                    i <= 29;
                                                                    i++
                                                                  ) {
                                                                    //    var current = year - i;
                                                                    listOfDay.push(
                                                                      i
                                                                    );
                                                                    // this.setState({ listOfDay: listOfDay });
                                                                  }
                                                                } else if (
                                                                  e.target
                                                                    .value ==
                                                                    1 ||
                                                                  e.target
                                                                    .value ==
                                                                    3 ||
                                                                  e.target
                                                                    .value ==
                                                                    5 ||
                                                                  e.target
                                                                    .value ==
                                                                    7 ||
                                                                  e.target
                                                                    .value ==
                                                                    8 ||
                                                                  e.target
                                                                    .value ==
                                                                    10 ||
                                                                  e.target
                                                                    .value == 12
                                                                ) {
                                                                  var listOfDay = [];
                                                                  for (
                                                                    var i = 1;
                                                                    i <= 31;
                                                                    i++
                                                                  ) {
                                                                    //    var current = year - i;
                                                                    listOfDay.push(
                                                                      i
                                                                    );
                                                                    //  this.setState({ listOfDay: listOfDay });
                                                                  }
                                                                } else {
                                                                  var listOfDay = [];
                                                                  for (
                                                                    var i = 1;
                                                                    i <= 30;
                                                                    i++
                                                                  ) {
                                                                    //    var current = year - i;
                                                                    listOfDay.push(
                                                                      i
                                                                    );
                                                                    //  this.setState({ listOfDay: listOfDay });
                                                                  }
                                                                }
                                                                setFieldValue(
                                                                  (values.professionalQualification[
                                                                    index
                                                                  ].fromlistOfDay = listOfDay)
                                                                );

                                                                //setFieldValue(values.fromlistOfDay=listOfDay)
                                                              }}
                                                              className={
                                                                "form-control" +
                                                                (errors.month &&
                                                                touched.month
                                                                  ? "is-invalid"
                                                                  : "")
                                                              }
                                                              className="form-control"
                                                              onBlur={(e) => {
                                                                // this.calculateExp(0,e.target.value,0,0,0,0)
                                                                let res = this.calculateExp(
                                                                  friend.fromYear,
                                                                  e.target
                                                                    .value,
                                                                  friend.fromDay,
                                                                  friend.toyear,
                                                                  friend.toMonth,
                                                                  friend.toDay
                                                                );
                                                                if (
                                                                  res == false
                                                                ) {
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].totalExperience =
                                                                      "")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].fromDay =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].fromMonth =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].fromYear =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].toDay =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].toMonth =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].toyear =
                                                                      "0")
                                                                  );
                                                                } else if (
                                                                  res !==
                                                                  undefined
                                                                ) {
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].totalExperience = res)
                                                                  );
                                                                }
                                                              }}
                                                              disabled={
                                                                values.disabledForCielops
                                                                  ? true
                                                                  : friend.occupation ==
                                                                    "1021"
                                                                  ? true
                                                                  : false
                                                              }
                                                            >
                                                              <option value="">
                                                                MM
                                                              </option>
                                                              {(friend.fromlistOfMonth !==
                                                              undefined
                                                                ? friend.fromlistOfMonth
                                                                : values.fromlistOfMonth ||
                                                                  []
                                                              ).map((m) => (
                                                                <option
                                                                  value={m.key}
                                                                >
                                                                  {m.value}
                                                                </option>
                                                              ))}
                                                            </Field>
                                                          </div>
                                                          <div className="">
                                                            <Field
                                                              as="select"
                                                              name={`professionalQualification.${index}.fromYear`}
                                                              // id="year"
                                                              // onChange={handleChange}
                                                              //    disabled={this.state.isDisabled}
                                                              //  disabled
                                                              // value={
                                                              //   friend.fromYear?friend.fromYear: friend.fromYear===undefined?"":friend.fromDate===undefined?"":
                                                              //   (friend.fromDate.split("/")[2]).split(" ")[0]
                                                              //  }
                                                              //  value={
                                                              //   friend.fromYear?friend.fromYear:friend.fromDate===undefined?'':friend.fromDate===undefined?"":  (friend.fromDate.split("/")[2]).split(" ")[0]
                                                              //  }
                                                              // value={
                                                              // friend.fromYear?friend.fromYear:friend.fromDate===undefined?'':friend.fromDate.split("/")[2]
                                                              //  }

                                                              value={parseInt(
                                                                friend.fromYear
                                                                  ? friend.fromYear
                                                                  : friend.fromDate ===
                                                                    undefined
                                                                  ? ""
                                                                  : friend.fromDate.split(
                                                                      "/"
                                                                    )[2]
                                                              )}
                                                              className={
                                                                "form-control" +
                                                                (errors.year &&
                                                                touched.year
                                                                  ? "is-invalid"
                                                                  : "")
                                                              }
                                                              onBlur={(e) => {
                                                                //this.calculateExp(e.target.value,0,0,0,0,0)
                                                                let res = this.calculateExp(
                                                                  e.target
                                                                    .value,
                                                                  friend.fromMonth,
                                                                  friend.fromDay,
                                                                  friend.toyear,
                                                                  friend.toMonth,
                                                                  friend.toDay
                                                                );
                                                                if (
                                                                  res == false
                                                                ) {
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].totalExperience =
                                                                      "")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].fromDay =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].fromMonth =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].fromYear =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].toDay =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].toMonth =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].toyear =
                                                                      "0")
                                                                  );
                                                                } else if (
                                                                  res !==
                                                                  undefined
                                                                ) {
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].totalExperience = res)
                                                                  );
                                                                }
                                                              }}
                                                              disabled={
                                                                values.disabledForCielops
                                                                  ? true
                                                                  : friend.occupation ==
                                                                    "1021"
                                                                  ? true
                                                                  : false
                                                              }
                                                              className="form-control"
                                                            >
                                                              <option value="">
                                                                YYYY
                                                              </option>
                                                              {(
                                                                this.state
                                                                  .listOfYear2 ||
                                                                []
                                                              ).map((year) => (
                                                                <option
                                                                  value={year}
                                                                >
                                                                  {year}
                                                                </option>
                                                              ))}
                                                            </Field>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td> */}
                                                        {/* {friend.toDate} */}
                                                        {/*                                                             
                                                              <Field
                                                                type="date"
                                                                name={`professionalQualification.${index}.toDate`}
                                                                max={moment(new Date()).format("YYYY-MM-DD")}
                                                               min= {moment(friend.fromDate,"DD/MM/YYYY HH:mm:ss").format('YYYY-MM-DD[T]HH:mm:ss').split('T')[0]}
                                                                value={moment(friend.toDate,"DD/MM/YYYY HH:mm:ss").format('YYYY-MM-DD[T]HH:mm:ss').split('T')[0]}
                                                                className="form-control"
                                                                disabled={
                                                                  values.disabledForCielops?true:friend.occupation ==
                                                                  "1021" 
                                                                    ? true
                                                                    : false
                                                                }
                                                              
                                                              //  min={friend.fromDate}
                                                                onChange={(e) => {
                                                                  
                                                                  let todate=   moment(e.target.value,"YYYY-MM-DD").format('DD/MM/YYYY HH:mm:ss')
                                                               
                                                                  setFieldValue(
                                                                    (values.professionalQualification[index].toDate = todate)
                                                                  )
                                                                  

                                                                  var Todate = e.target.value

                                                                  var FromDate = values.professionalQualification[index].fromDate
                                                                  if (Todate !== undefined && FromDate !== undefined) {
                                                                    if ((Date.parse(Todate) <= Date.parse(FromDate))) {
                                                                      //  alert();
                                                                      warning("End date should be greater than Start date", warningNotification);
                                                                      setFieldValue(
                                                                        (values.professionalQualification[index].fromDate = "")
                                                                      )
                                                                      setFieldValue(
                                                                        (values.professionalQualification[index].toDate = "")
                                                                      );
                                                                    }
                                                                    else {
                                                                      
                                                                      
                                                                      if(Todate !=="" && FromDate!=="")
                                                                      {
                                                                       FromDate=   moment(FromDate,"DD/MM/YYYY HH:mm:ss").format('YYYY-MM-DD')
                                                                    
                                                                     let yearsDiff=this.diff_years(FromDate,Todate)
 
                                                              
                                                                      setFieldValue(
                                                                        (values.professionalQualification[index].totalExperience = yearsDiff)
                                                                      );
                                                                      }
                                                                      else{
                                                                        setFieldValue(
                                                                          (values.professionalQualification[index].totalExperience ="")
                                                                        ); 
                                                                      }
                                                                    
                                                                    }
                                                                  }
                                                                }
                                                                }
                                                                onBlur={event => {
                                                                  this.updateCommonWeightage("professionalQualificationtoDate",event.target.value)
                                                                }}
                                                              /> */}
                                                        {/* <div className="">
                                                          <div className="">
                                                            <Field
                                                              as="select"
                                                              name={`professionalQualification.${index}.toDay`}
                                                              onBlur={(e) => {
                                                                // this.calculateExp(0,0,0,0,0,e.target.value)
                                                                let res = this.calculateExp(
                                                                  friend.fromYear,
                                                                  friend.fromMonth,
                                                                  friend.fromDay,
                                                                  friend.toyear,
                                                                  friend.toMonth,
                                                                  e.target.value
                                                                );
                                                                if (
                                                                  res == false
                                                                ) {
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].totalExperience =
                                                                      "")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].fromDay =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].fromMonth =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].fromYear =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].toDay =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].toMonth =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].toyear =
                                                                      "0")
                                                                  );
                                                                } else if (
                                                                  res !==
                                                                  undefined
                                                                ) {
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].totalExperience = res)
                                                                  );
                                                                }
                                                              }}
                                                              disabled={
                                                                values.disabledForCielops
                                                                  ? true
                                                                  : friend.occupation ==
                                                                    "1021"
                                                                  ? true
                                                                  : false
                                                              }
                                                              id="today"
                                                              value={parseInt(
                                                                friend.toDay
                                                                  ? friend.toDay
                                                                  : friend.toDate ===
                                                                    undefined
                                                                  ? ""
                                                                  : friend.toDate.split(
                                                                      "/"
                                                                    )[0],
                                                                10
                                                              )}
                                                              // onChange={handleChange}
                                                              //  disabled={this.state.isDisabled}
                                                              //  disabled

                                                              onChange={(e) => {
                                                                if (
                                                                  friend == ""
                                                                ) {
                                                                  values.professionalQualification.splice(
                                                                    index,
                                                                    1
                                                                  );
                                                                  let obj = {};
                                                                  obj[
                                                                    "occupation"
                                                                  ] = "";
                                                                  obj[
                                                                    "companyName"
                                                                  ] = "";
                                                                  obj[
                                                                    "designation"
                                                                  ] = "";
                                                                  obj[
                                                                    "fromDate"
                                                                  ] = "";
                                                                  obj[
                                                                    "totalExperience"
                                                                  ] = "";
                                                                  obj[
                                                                    "toDate"
                                                                  ] = "";
                                                                  obj[
                                                                    "annualIncome"
                                                                  ] = "";
                                                                  values.professionalQualification.push(
                                                                    obj
                                                                  );
                                                                  // setFieldValue(
                                                                  //   (values.professionalQualification=obj)    )
                                                                }

                                                                setFieldValue(
                                                                  (values.professionalQualification[
                                                                    index
                                                                  ].toDay =
                                                                    e.target.value)
                                                                );
                                                                var listOfMonth = [];

                                                                if (
                                                                  e.target
                                                                    .value == 31
                                                                ) {
                                                                  listOfMonth = [
                                                                    {
                                                                      key: 1,
                                                                      value:
                                                                        "Jan",
                                                                    },

                                                                    {
                                                                      key: 3,
                                                                      value:
                                                                        "March",
                                                                    },

                                                                    {
                                                                      key: 5,
                                                                      value:
                                                                        "May",
                                                                    },

                                                                    {
                                                                      key: 7,
                                                                      value:
                                                                        "July",
                                                                    },
                                                                    {
                                                                      key: 8,
                                                                      value:
                                                                        "Aug",
                                                                    },

                                                                    {
                                                                      key: 10,
                                                                      value:
                                                                        "Oct",
                                                                    },

                                                                    {
                                                                      key: 12,
                                                                      value:
                                                                        "Dec",
                                                                    },
                                                                  ];
                                                                } else if (
                                                                  e.target
                                                                    .value == 30
                                                                ) {
                                                                  listOfMonth = [
                                                                    {
                                                                      key: 1,
                                                                      value:
                                                                        "Jan",
                                                                    },

                                                                    {
                                                                      key: 3,
                                                                      value:
                                                                        "March",
                                                                    },
                                                                    {
                                                                      key: 4,
                                                                      value:
                                                                        "April",
                                                                    },
                                                                    {
                                                                      key: 5,
                                                                      value:
                                                                        "May",
                                                                    },
                                                                    {
                                                                      key: 6,
                                                                      value:
                                                                        "June",
                                                                    },
                                                                    {
                                                                      key: 7,
                                                                      value:
                                                                        "July",
                                                                    },
                                                                    {
                                                                      key: 8,
                                                                      value:
                                                                        "Aug",
                                                                    },
                                                                    {
                                                                      key: 9,
                                                                      value:
                                                                        "Sept",
                                                                    },
                                                                    {
                                                                      key: 10,
                                                                      value:
                                                                        "Oct",
                                                                    },
                                                                    {
                                                                      key: 11,
                                                                      value:
                                                                        "Nov",
                                                                    },
                                                                    {
                                                                      key: 12,
                                                                      value:
                                                                        "Dec",
                                                                    },
                                                                  ];
                                                                } else if (
                                                                  e.target
                                                                    .value ==
                                                                    28 ||
                                                                  e.target
                                                                    .value == 29
                                                                ) {
                                                                  listOfMonth = [
                                                                    {
                                                                      key: 1,
                                                                      value:
                                                                        "Jan",
                                                                    },
                                                                    {
                                                                      key: 2,
                                                                      value:
                                                                        "Feb",
                                                                    },
                                                                    {
                                                                      key: 3,
                                                                      value:
                                                                        "March",
                                                                    },
                                                                    {
                                                                      key: 4,
                                                                      value:
                                                                        "April",
                                                                    },
                                                                    {
                                                                      key: 5,
                                                                      value:
                                                                        "May",
                                                                    },
                                                                    {
                                                                      key: 6,
                                                                      value:
                                                                        "June",
                                                                    },
                                                                    {
                                                                      key: 7,
                                                                      value:
                                                                        "July",
                                                                    },
                                                                    {
                                                                      key: 8,
                                                                      value:
                                                                        "Aug",
                                                                    },
                                                                    {
                                                                      key: 9,
                                                                      value:
                                                                        "Sept",
                                                                    },
                                                                    {
                                                                      key: 10,
                                                                      value:
                                                                        "Oct",
                                                                    },
                                                                    {
                                                                      key: 11,
                                                                      value:
                                                                        "Nov",
                                                                    },
                                                                    {
                                                                      key: 12,
                                                                      value:
                                                                        "Dec",
                                                                    },
                                                                  ];
                                                                } else {
                                                                  listOfMonth = [
                                                                    {
                                                                      key: 1,
                                                                      value:
                                                                        "Jan",
                                                                    },
                                                                    {
                                                                      key: 2,
                                                                      value:
                                                                        "Feb",
                                                                    },
                                                                    {
                                                                      key: 3,
                                                                      value:
                                                                        "March",
                                                                    },
                                                                    {
                                                                      key: 4,
                                                                      value:
                                                                        "April",
                                                                    },
                                                                    {
                                                                      key: 5,
                                                                      value:
                                                                        "May",
                                                                    },
                                                                    {
                                                                      key: 6,
                                                                      value:
                                                                        "June",
                                                                    },
                                                                    {
                                                                      key: 7,
                                                                      value:
                                                                        "July",
                                                                    },
                                                                    {
                                                                      key: 8,
                                                                      value:
                                                                        "Aug",
                                                                    },
                                                                    {
                                                                      key: 9,
                                                                      value:
                                                                        "Sept",
                                                                    },
                                                                    {
                                                                      key: 10,
                                                                      value:
                                                                        "Oct",
                                                                    },
                                                                    {
                                                                      key: 11,
                                                                      value:
                                                                        "Nov",
                                                                    },
                                                                    {
                                                                      key: 12,
                                                                      value:
                                                                        "Dec",
                                                                    },
                                                                  ];
                                                                }
                                                                // setFieldValue(values.tolistOfMonth= listOfMonth)
                                                                setFieldValue(
                                                                  (values.professionalQualification[
                                                                    index
                                                                  ].tolistOfMonth = listOfMonth)
                                                                );
                                                              }}
                                                              className={
                                                                "form-control" +
                                                                (errors.today &&
                                                                touched.today
                                                                  ? "is-invalid"
                                                                  : "")
                                                              }
                                                              className="form-control"
                                                            >
                                                              <option value="">
                                                                DD
                                                              </option>
                                                              {(friend.tolistOfDay !==
                                                              undefined
                                                                ? friend.tolistOfDay
                                                                : values.tolistOfDay ||
                                                                  []
                                                              ).map((d) => (
                                                                <option
                                                                  value={d}
                                                                >
                                                                  {d}
                                                                </option>
                                                              ))}
                                                            </Field>
                                                          </div>
                                                          <div className="">
                                                            <Field
                                                              as="select"
                                                              name={`professionalQualification.${index}.toMonth`}
                                                              value={parseInt(
                                                                friend.toMonth
                                                                  ? friend.toMonth
                                                                  : friend.toDate ===
                                                                    undefined
                                                                  ? ""
                                                                  : friend.toDate.split(
                                                                      "/"
                                                                    )[1],
                                                                10
                                                              )}
                                                              id="tomonth"
                                                              //   onChange={handleChange}
                                                              //     disabled={this.state.isDisabled}
                                                              //  disabled
                                                              onBlur={(e) => {
                                                                let res = this.calculateExp(
                                                                  friend.fromYear,
                                                                  friend.fromMonth,
                                                                  friend.fromDay,
                                                                  friend.toyear,
                                                                  e.target
                                                                    .value,
                                                                  friend.toDay
                                                                );
                                                                if (
                                                                  res == false
                                                                ) {
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].totalExperience =
                                                                      "")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].fromDay =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].fromMonth =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].fromYear =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].toDay =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].toMonth =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].toyear =
                                                                      "0")
                                                                  );
                                                                } else if (
                                                                  res !==
                                                                  undefined
                                                                ) {
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].totalExperience = res)
                                                                  );
                                                                }
                                                              }}
                                                              onChange={(e) => {
                                                                if (
                                                                  friend == ""
                                                                ) {
                                                                  values.professionalQualification.splice(
                                                                    index,
                                                                    1
                                                                  );
                                                                  let obj = {};
                                                                  obj[
                                                                    "occupation"
                                                                  ] = "";
                                                                  obj[
                                                                    "companyName"
                                                                  ] = "";
                                                                  obj[
                                                                    "designation"
                                                                  ] = "";
                                                                  obj[
                                                                    "fromDate"
                                                                  ] = "";
                                                                  obj[
                                                                    "totalExperience"
                                                                  ] = "";
                                                                  obj[
                                                                    "toDate"
                                                                  ] = "";
                                                                  obj[
                                                                    "annualIncome"
                                                                  ] = "";
                                                                  values.professionalQualification.push(
                                                                    obj
                                                                  );
                                                                  // setFieldValue(
                                                                  //   (values.professionalQualification=obj)    )
                                                                }

                                                                setFieldValue(
                                                                  (values.professionalQualification[
                                                                    index
                                                                  ].toMonth =
                                                                    e.target.value)
                                                                );
                                                                if (
                                                                  e.target
                                                                    .value == 2
                                                                ) {
                                                                  var listOfDay = [];
                                                                  for (
                                                                    var i = 1;
                                                                    i <= 29;
                                                                    i++
                                                                  ) {
                                                                    //    var current = year - i;
                                                                    listOfDay.push(
                                                                      i
                                                                    );
                                                                    // this.setState({ listOfDay: listOfDay });
                                                                  }
                                                                } else if (
                                                                  e.target
                                                                    .value ==
                                                                    1 ||
                                                                  e.target
                                                                    .value ==
                                                                    3 ||
                                                                  e.target
                                                                    .value ==
                                                                    5 ||
                                                                  e.target
                                                                    .value ==
                                                                    7 ||
                                                                  e.target
                                                                    .value ==
                                                                    8 ||
                                                                  e.target
                                                                    .value ==
                                                                    10 ||
                                                                  e.target
                                                                    .value == 12
                                                                ) {
                                                                  var listOfDay = [];
                                                                  for (
                                                                    var i = 1;
                                                                    i <= 31;
                                                                    i++
                                                                  ) {
                                                                    //    var current = year - i;
                                                                    listOfDay.push(
                                                                      i
                                                                    );
                                                                    //  this.setState({ listOfDay: listOfDay });
                                                                  }
                                                                } else {
                                                                  var listOfDay = [];
                                                                  for (
                                                                    var i = 1;
                                                                    i <= 30;
                                                                    i++
                                                                  ) {
                                                                    //    var current = year - i;
                                                                    listOfDay.push(
                                                                      i
                                                                    );
                                                                    //  this.setState({ listOfDay: listOfDay });
                                                                  }
                                                                }
                                                                //setFieldValue(values.tolistOfDay=listOfDay)
                                                                setFieldValue(
                                                                  (values.professionalQualification[
                                                                    index
                                                                  ].tolistOfDay = listOfDay)
                                                                );
                                                              }}
                                                              // value={
                                                              //   parseInt(friend.tomonth?friend.tomonth: friend.toDate.split("/")[1],10)
                                                              //  }
                                                              disabled={
                                                                values.disabledForCielops
                                                                  ? true
                                                                  : friend.occupation ==
                                                                    "1021"
                                                                  ? true
                                                                  : false
                                                              }
                                                              className={
                                                                "form-control" +
                                                                (errors.tomonth &&
                                                                touched.tomonth
                                                                  ? "is-invalid"
                                                                  : "")
                                                              }
                                                              className="form-control"
                                                            >
                                                              <option value="">
                                                                MM
                                                              </option>
                                                              {(friend.tolistOfMonth !==
                                                              undefined
                                                                ? friend.tolistOfMonth
                                                                : values.tolistOfMonth ||
                                                                  []
                                                              ).map((m) => (
                                                                <option
                                                                  value={m.key}
                                                                >
                                                                  {m.value}
                                                                </option>
                                                              ))}
                                                            </Field>
                                                          </div>
                                                          <div className="">
                                                            <Field
                                                              as="select"
                                                              name={`professionalQualification.${index}.toyear`}
                                                              onBlur={(e) => {
                                                                //   this.calculateExp(0,0,0,e.target.value,0,0)
                                                                let res = this.calculateExp(
                                                                  friend.fromYear,
                                                                  friend.fromMonth,
                                                                  friend.fromDay,
                                                                  e.target
                                                                    .value,
                                                                  friend.toMonth,
                                                                  friend.toDay
                                                                );
                                                                if (
                                                                  res == false
                                                                ) {
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].totalExperience =
                                                                      "")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].fromDay =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].fromMonth =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].fromYear =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].toDay =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].toMonth =
                                                                      "0")
                                                                  );
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].toyear =
                                                                      "0")
                                                                  );
                                                                } else if (
                                                                  res !==
                                                                  undefined
                                                                ) {
                                                                  setFieldValue(
                                                                    (values.professionalQualification[
                                                                      index
                                                                    ].totalExperience = res)
                                                                  );
                                                                }
                                                              }}
                                                              id="toyear"
                                                              // onChange={handleChange}
                                                              //    disabled={this.state.isDisabled}
                                                              //  disabled
                                                              disabled={
                                                                values.disabledForCielops
                                                                  ? true
                                                                  : friend.occupation ==
                                                                    "1021"
                                                                  ? true
                                                                  : false
                                                              }
                                                              // value={
                                                              //   friend.toyear?friend.toyear:friend.toDate===undefined?'':friend.toDate===undefined?"":  (friend.toDate.split("/")[2]).split(" ")[0]
                                                              //  }
                                                              value={parseInt(
                                                                friend.toyear
                                                                  ? friend.toyear
                                                                  : friend.toDate ===
                                                                    undefined
                                                                  ? ""
                                                                  : friend.toDate.split(
                                                                      "/"
                                                                    )[2]
                                                              )}
                                                              className={
                                                                "form-control" +
                                                                (errors.toyear &&
                                                                touched.toyear
                                                                  ? "is-invalid"
                                                                  : "")
                                                              }
                                                              className="form-control"
                                                            >
                                                              <option value="">
                                                                YYYY
                                                              </option>
                                                              {(
                                                                this.state
                                                                  .listOfYear2 ||
                                                                []
                                                              ).map((year) => (
                                                                <option
                                                                  value={year}
                                                                >
                                                                  {year}
                                                                </option>
                                                              ))}
                                                            </Field>
                                                          </div>
                                                        </div>
                                                      </td> */}
                                                      <td>
                                                        <Field
                                                          as="select"
                                                          id="totalExperience"
                                                          name={`professionalQualification.${index}.totalExperience`}
                                                          className="form-control"
                                                          disabled={
                                                            values.disabledForCielops
                                                              ? true
                                                              : friend.occupation ==
                                                                "1021"
                                                              ? true
                                                              : false
                                                          }
                                                          onBlur={(e) => {
                                                            this.updateCommonWeightage(
                                                              "professionalQualificationtotalExperience",
                                                              e.target.value,
                                                              values
                                                            );

                                                            // var numbers = /^[0-9.]{1,100}$/;
                                                            // let inputtxt =
                                                            //   e.target.value;
                                                            // if (
                                                            //   inputtxt.match(
                                                            //     numbers
                                                            //   ) == null
                                                            // ) {
                                                            //   warning(
                                                            //     "Total Experience should be numeric and demical value only. eg-1.1",
                                                            //     warningNotification
                                                            //   );
                                                            //   setFieldValue(
                                                            //     (values.professionalQualification[
                                                            //       index
                                                            //     ].totalExperience =
                                                            //       "")
                                                            //   );
                                                            // } else {
                                                            //   values.professionalQualification[
                                                            //     index
                                                            //   ].totalExperience =
                                                            //     friend.totalExperience;
                                                            // }
                                                          }}
                                                        >
                                                          <option value="">---Select----</option>
                                                          {(this.state.getExperienceList)?.map((item) => (
                                                            <option key={item.experienceValue} value={item.experienceValue} >
                                                              {item.experienceDisplay}
                                                            </option>
                                                          ))}
                                                          </Field>
                                                          {/* <ErrorMessage
                                                            name={`professionalQualification.${index}.totalExperience`}
                                                            component="div"
                                                            className="validation"
                                                          /> */}
                                                      </td>

                                                      {/* <td>
                                                        <Field
                                                          as="select"
                                                          name={`professionalQualification.${index}.annualIncome`}
                                                          disabled={
                                                            values.disabledForCielops
                                                              ? true
                                                              : friend.occupation ==
                                                                "1021"
                                                              ? true
                                                              : false
                                                          }
                                                          id="annualIncome"
                                                          className="form-control"
                                                          onBlur={(event) => {
                                                            this.updateCommonWeightage(
                                                              "professionalQualificationannualIncome",
                                                              event.target.value
                                                            );
                                                          }}
                                                        >
                                                          <option value="0">
                                                            ---Select----
                                                          </option>

                                                          {(
                                                            this.state
                                                              .AuualCome || []
                                                          ).map((team) => (
                                                            <option
                                                              key={
                                                                team.codeValueId
                                                              }
                                                              value={
                                                                team.codeValueId
                                                              }
                                                            >
                                                              {team.codeValue}
                                                            </option>
                                                          ))}
                                                        </Field>

                                                        <ErrorMessage
                                                          name={`professionalQualification.${index}.annualIncome`}
                                                          component="div"
                                                          className="validation"
                                                        />
                                                      </td> */}
                                                      <td>
                                                        <button
                                                          type="button"
                                                          className="btn-8 ml-5 mr-5 mt-10"
                                                          disabled={
                                                            values.disabledForCielops
                                                          }
                                                          onClick={() => {
                                                            arrayHelpers1.insert(
                                                              index + 1,
                                                              ""
                                                            );
                                                          }}
                                                        >
                                                          <i
                                                            className="fa fa-plus"
                                                            aria-hidden="true"
                                                          />
                                                        </button>
                                                        <button
                                                          type="button"
                                                          className="btn-8 ml-5 mr-5 mt-10"
                                                          disabled={
                                                            values.disabledForCielops
                                                          }
                                                          onClick={() => {
                                                            arrayHelpers1.remove(
                                                              index
                                                            );
                                                          }}
                                                        >
                                                          <i
                                                            className="fa fa-minus"
                                                            aria-hidden="true"
                                                          />
                                                        </button>
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        ) : (
                                          <button
                                            type="button"
                                            className="btn-4"
                                            onClick={() => {
                                              arrayHelpers1.push("");
                                            }}
                                          >
                                            Add Occupation Details
                                          </button>
                                        )}
                                      </div>
                                    )}
                                  />
                                </div>
                              </div>
                              <p
                                className="hidden-md hidden-lg"
                                style={{ paddingLeft: "15px" }}
                              >
                                Scroll to the right for more fields.
                              </p>
                            </div>
                          </section>
                          {/* banking */}
                          <section
                            className="create-user page-section profile-box"
                            id="banking-details"
                            style={{ display: "none" }}
                          >
                            <div className="row">
                              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                <h2 className="sub-header">Banking Details</h2>
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        IFSC Code
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        type="text"
                                        name="ifscCode"
                                        pattern="[A-Z0-9]{11}"
                                        disabled={true}
                                        //disabled={values.disabledForCielops}
                                        maxlength="11"
                                        autocapitalize="characters"
                                        onChange={(event) => {
                                          let ifscCode = event.target.value;
                                          ifscCode = ifscCode.toUpperCase();
                                          setFieldValue(
                                            (values.ifscCode = ifscCode)
                                          );
                                          //     setFieldValue(
                                          //       (values.ifscCode1 =event.target.value
                                          //         ))
                                        }}
                                        onBlur={(event) => {
                                          fetch(
                                            "https://ifsc.razorpay.com/" +
                                              event.target.value
                                          )
                                            .then((response) => response.json())
                                            .then((records) => {
                                              console.log(records);

                                              let arr = [
                                                "Vijaya Bank",
                                                "Dena Bank",
                                                "Syndicate Bank",
                                                "Allahabad Bank",
                                                "Oriental Bank of Commerce",
                                                "United Bank of India",
                                                "State Bank of Bikaner & Jaipur",
                                                "State Bank of Hyderabad",
                                                "State Bank of Indore",
                                                "State Bank of Mysore",
                                                "State Bank of Patiala",
                                                "State Bank of Saurashtra",
                                                "State Bank of Travancore",
                                                "Bhartiya Mahila Bank",
                                                "Andhra Bank",
                                                "Corporation Bank",
                                              ];
                                              //      mystring.replace(/r/g, '')
                                              console.log(
                                                arr.includes(records.BANK)
                                              );
                                              if (
                                                arr.includes(records.BANK) ===
                                                false
                                              ) {
                                                this.updateCommonWeightage(
                                                  "bankName",
                                                  records.BANK,
                                                  values
                                                );
                                                this.updateCommonWeightage(
                                                  "branch",
                                                  records.BRANCH.replace(
                                                    /r/g,
                                                    ""
                                                  ),
                                                  values
                                                );
                                                this.updateCommonWeightage(
                                                  "branchAddress",
                                                  records.ADDRESS.replace(
                                                    /r/g,
                                                    ""
                                                  ),
                                                  values
                                                );
                                                setFieldValue(
                                                  (values.bankName =
                                                    records.BANK)
                                                );
                                                setFieldValue(
                                                  (values.branch =
                                                    records.BRANCH)
                                                );
                                                setFieldValue(
                                                  (values.branchAddress =
                                                    records.ADDRESS)
                                                );

                                                // setFieldValue(
                                                //   (values.ifscCode =event.target.value
                                                //     )
                                                // );
                                              } else {
                                                warning(
                                                  "IFSC code is Not in Use. This Bank is already merged in to another bank. Please use different IFSC Code",
                                                  warningNotification
                                                );
                                                this.updateCommonWeightage(
                                                  "bankName",
                                                  "",
                                                  values
                                                );
                                                this.updateCommonWeightage(
                                                  "branch",
                                                  "",
                                                  values
                                                );
                                                this.updateCommonWeightage(
                                                  "branchAddress",
                                                  "",
                                                  values
                                                );
                                                this.updateCommonWeightage(
                                                  "accountType",
                                                  "",
                                                  values
                                                );
                                                this.updateCommonWeightage(
                                                  "acccountNumber",
                                                  "",
                                                  values
                                                );

                                                setFieldValue(
                                                  (values.bankName = "")
                                                );
                                                setFieldValue(
                                                  (values.branch = "")
                                                );
                                                setFieldValue(
                                                  (values.branchAddress = "")
                                                );
                                                setFieldValue(
                                                  (values.accountType = "")
                                                );
                                                setFieldValue(
                                                  (values.acccountNumber = "")
                                                );
                                                // setFieldValue(
                                                //   (values.ifscCode =event.target.value
                                                //     )
                                                // );
                                              }

                                              // this.setState({
                                              // records: records
                                              // })
                                            })
                                            .catch((error) => {
                                              this.updateCommonWeightage(
                                                "bankName",
                                                "",
                                                values
                                              );
                                              this.updateCommonWeightage(
                                                "branch",
                                                "",
                                                values
                                              );
                                              this.updateCommonWeightage(
                                                "branchAddress",
                                                "",
                                                values
                                              );
                                              this.updateCommonWeightage(
                                                "accountType",
                                                "",
                                                values
                                              );
                                              this.updateCommonWeightage(
                                                "acccountNumber",
                                                "",
                                                values
                                              );
                                              setFieldValue(
                                                (values.bankName = "")
                                              );
                                              setFieldValue(
                                                (values.branch = "")
                                              );
                                              setFieldValue(
                                                (values.branchAddress = "")
                                              );
                                              setFieldValue(
                                                (values.accountType = "")
                                              );
                                              setFieldValue(
                                                (values.acccountNumber = "")
                                              );
                                            });
                                        }}
                                        className="form-control"
                                      />
                                      {errors.ifscCode && (
                                        <div className="validation">
                                          {errors.ifscCode}
                                        </div>
                                      )}
                                      {/* <ErrorMessage
                                            name="bankName"
                                            className="validation"
                                            component="div"
                                          /> */}
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Name Of The Bank
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        type="text"
                                        name="bankName"
                                        disabled={true}
                                        onBlur={(event) => {
                                          //         this.updateCommonWeightage(event.target.name,event.target.value)
                                        }}
                                        className="form-control"
                                      />
                                      {errors.bankName && (
                                        <div className="validation">
                                          {errors.bankName}
                                        </div>
                                      )}
                                      {/* <ErrorMessage
                                            name="bankName"
                                            className="validation"
                                            component="div"
                                          /> */}
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Branch
                                        <span className="validation"> *</span>
                                      </label>

                                      <Field
                                        type="text"
                                        name="branch"
                                        disabled={true}
                                        onBlur={(event) => {
                                          //  this.updateCommonWeightage(event.target.name,event.target.value)
                                        }}
                                        className="form-control"
                                      />
                                      {errors.branch && (
                                        <div className="validation">
                                          {errors.branch}
                                        </div>
                                      )}
                                      {/* <ErrorMessage
                                            name="branch"
                                            className="validation"
                                            component="div"
                                          /> */}
                                    </div>

                                    <div className="col-md-12 col-sm-12 col-xs-12" />
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Branch Address
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        type="text"
                                        name="branchAddress"
                                        disabled={true}
                                        onBlur={(event) => {
                                          // this.updateCommonWeightage(event.target.name,event.target.value)
                                        }}
                                        className="form-control"
                                      />
                                      {errors.branchAddress && (
                                        <div className="validation">
                                          {errors.branchAddress}
                                        </div>
                                      )}
                                      {/* <ErrorMessage
                                            name="branchAddress"
                                            className="validation"
                                            component="div"
                                          /> */}
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Account Type
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        as="select"
                                        name="accountType"
                                        disabled={true}
                                        //disabled={values.disabledForCielops}
                                        onBlur={(event) => {
                                          this.updateCommonWeightage(
                                            event.target.name,
                                            event.target.value,
                                            values
                                          );
                                        }}
                                        className="form-control"
                                      >
                                        {/* <option value="1">
                                          Select Account Type
                                        </option> */}
                                        <option value="1">Saving</option>
                                        <option value="2">Current</option>
                                      </Field>
                                      {errors.accountType && (
                                        <div className="validation">
                                          {errors.accountType}
                                        </div>
                                      )}
                                      {/* <ErrorMessage
                                            name="accountType"
                                            className="validation"
                                            component="div"
                                          /> */}
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Account Number
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        type="text"
                                        name="acccountNumber"
                                        minLength="9"
                                        maxlength="18"
                                        disabled={true}
                                        // disabled={values.disabledForCielops}
                                        onBlur={(event) => {
                                          this.updateCommonWeightage(
                                            event.target.name,
                                            event.target.value,
                                            values
                                          );
                                        }}
                                        className="form-control"
                                      />
                                      {errors.acccountNumber && (
                                        <div className="validation">
                                          {errors.acccountNumber}
                                        </div>
                                      )}
                                      {/* <ErrorMessage
                                            name="acccountNumber"
                                            className="validation"
                                            component="div"
                                          /> */}
                                    </div>

                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>Upload Cheque Image</label>
                                      <div className="profile-detail">
                                        <div className="circle">
                                          <img
                                            className="profile-pic"
                                            src={values.chequePhotoURL}
                                          />
                                          <div className="profile">
                                            <i
                                              className="fa fa-user"
                                              aria-hidden="true"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="hidden-md hidden-lg hidden-sm col-xs-12">
                                        <div className="profile-add">
                                          <a
                                            className="icon btn-file"
                                            disabled={values.disabledForCielops}
                                          >
                                            {/* <i
                                              className="fa fa-plus-circle"
                                              aria-hidden="true"
                                            /> */}
                                            <FileBase64
                                              multiple={true}
                                              onChange={handleChange}
                                              onDone={(event) => {
                                                if (
                                                  [
                                                    "image/jpg",
                                                    "image/jpeg",
                                                    "image/png",
                                                  ].indexOf(event[0].type) < 0
                                                ) {
                                                  setFieldValue(
                                                    (values.documentTypeCheckForChequeImage = true)
                                                  );
                                                } else if (
                                                  event[0].file.size <= 1000000
                                                ) {
                                                  if (
                                                    this.checkName(
                                                      event[0].name
                                                    )
                                                  ) {
                                                    values.document.push({
                                                      documentHash:
                                                        event[0].base64,
                                                      // documentType: 'ChequePhoto',
                                                      documentType:
                                                        "ChequeImage",
                                                      documentName:
                                                        event[0].name,
                                                    });
                                                    setFieldValue(
                                                      (values.documentTypeCheckForChequeImage = false)
                                                    );
                                                    setFieldValue(
                                                      (values.documentSizeCheckForChequeCheck = false)
                                                    );
                                                    setFieldValue(
                                                      (values.chequeImageDocumentNameValidation = false)
                                                    );

                                                    values.chequePhotoURL =
                                                      event[0].base64;
                                                    setFieldValue(
                                                      (values.chequePhotoURL =
                                                        event[0].base64)
                                                    );
                                                  } else {
                                                    setFieldValue(
                                                      (values.chequeImageDocumentNameValidation = true)
                                                    );
                                                  }
                                                } else {
                                                  setFieldValue(
                                                    (values.chequeImageDocumentNameValidation = false)
                                                  );

                                                  setFieldValue(
                                                    (values.documentSizeCheckForChequeCheck = true)
                                                  );
                                                }
                                              }}
                                            />{" "}
                                            {/* <h4>Add Image Cheue</h4> */}
                                          </a>

                                          <div className="col-md-12 col-sm-12">
                                            {/* <span class="file-size-limit">
                                              Please upload your latest colored passport size photo in jpg/jpeg,png format.
                                              <br />
                                              File Size: Max 1 MB
                                            </span> */}
                                          </div>
                                          {values.documentSizeCheckForChequeCheck ? (
                                            <p style={{ color: "red" }}>
                                              File Size is too large(Maximum
                                              File Size:1MB).
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                          {values.documentTypeCheckForChequeImage ? (
                                            <p style={{ color: "red" }}>
                                              Invalid image format
                                            </p>
                                          ) : (
                                            ""
                                          )}

                                          {values.chequeImageDocumentNameValidation ? (
                                            <p style={{ color: "red" }}>
                                              File/Document name can only
                                              contain number, letter, hyphens,
                                              space.
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                          <div className="profile-add">
                                            <a
                                              className="icon btn-file"
                                              // style={
                                              //   values.disabledForCielops
                                              //     ? {
                                              //         pointerEvents: 'none',
                                              //         cursor: 'default',
                                              //       }
                                              //     : {}
                                              // }
                                              style={{
                                                pointerEvents: "none",
                                                cursor: "default",
                                              }}
                                            >
                                              <i
                                                className="fa fa-plus-circle"
                                                aria-hidden="true"
                                              />
                                              <FileBase64
                                                multiple={true}
                                                onChange={handleChange}
                                                onDone={(event) => {
                                                  if (
                                                    [
                                                      "image/jpg",
                                                      "image/jpeg",
                                                      "image/png",
                                                    ].indexOf(event[0].type) < 0
                                                  ) {
                                                    setFieldValue(
                                                      (values.documentTypeCheckForChequeImage = true)
                                                    );
                                                  } else if (
                                                    event[0].file.size <=
                                                    1000000
                                                  ) {
                                                    if (
                                                      this.checkName(
                                                        event[0].name
                                                      )
                                                    ) {
                                                      values.document.push({
                                                        documentHash:
                                                          event[0].base64,
                                                        // documentType: 'ChequePhoto',
                                                        documentName:
                                                          event[0].name,
                                                        documentType:
                                                          "ChequeImage",
                                                      });

                                                      setFieldValue(
                                                        (values.documentTypeCheckForChequeImage = false)
                                                      );
                                                      setFieldValue(
                                                        (values.documentSizeCheckForChequeCheck = false)
                                                      );
                                                      setFieldValue(
                                                        (values.chequeImageDocumentNameValidation = false)
                                                      );

                                                      values.chequePhotoURL =
                                                        event[0].base64;
                                                      setFieldValue(
                                                        (values.chequePhotoURL =
                                                          event[0].base64)
                                                      );
                                                    } else {
                                                      setFieldValue(
                                                        (values.chequeImageDocumentNameValidation = true)
                                                      );
                                                    }
                                                  } else {
                                                    setFieldValue(
                                                      (values.chequeImageDocumentNameValidation = false)
                                                    );

                                                    setFieldValue(
                                                      (values.documentSizeCheckForChequeCheck = true)
                                                    );
                                                  }
                                                }}
                                              />{" "}
                                              <h4 style={{ color: "black" }}>
                                                Add Image
                                              </h4>
                                            </a>

                                            <div className="col-md-12 col-sm-12">
                                              <span className="file-size-limit">
                                                Please upload your latest
                                                colored cheque image in
                                                jpg/jpeg, png format. <br />
                                                File Size: Max 1 MB
                                              </span>
                                            </div>
                                            {values.documentSizeCheckForChequeCheck ? (
                                              <p style={{ color: "red" }}>
                                                File Size is too large(Maximum
                                                File Size:1MB).
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                            {values.documentTypeCheckForChequeImage ? (
                                              <p style={{ color: "red" }}>
                                                Invalid image format
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                            {values.chequeImageDocumentNameValidation ? (
                                              <p style={{ color: "red" }}>
                                                File/Document name can only
                                                contain number, letter, hyphens,
                                                space.
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          {/* CPE Details */}
                          <section
                            className="create-qualification page-section profile-box"
                            id="CPE-details"
                            style={
                              this.state.processType !== "1003"
                                ? { display: "none" }
                                : { display: "block" }
                            }
                          >
                            <div className="row">
                              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                <h2 className="sub-header">
                                  NISM Certificate Details
                                </h2>

                                <div className="">
                                  <FieldArray
                                    name="CPEDetails"
                                    onChange={handleChange}
                                    render={(arrayHelpers1) => (
                                      <div>
                                        {values.CPEDetails &&
                                        values.CPEDetails.length > 0 ? (
                                          <div className="table-row">
                                            <table
                                              className="table table-bordered table-hover"
                                              id="tab_logic"
                                            >
                                              <thead>
                                                <tr>
                                                  <th className="text-center">
                                                    {" "}
                                                    Passing Certificate No.
                                                    {/* <span className="validation"> *</span> */}
                                                  </th>
                                                  <th className="text-center">
                                                    {" "}
                                                    Date Of Passing Test
                                                  </th>
                                                  <th className="text-center">
                                                    {" "}
                                                    CPE Certificate No.{" "}
                                                  </th>
                                                  <th className="text-center">
                                                    {" "}
                                                    Expiry Date Of CPE{" "}
                                                  </th>

                                                  <th className="text-center">
                                                    {" "}
                                                    Action{" "}
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {values.CPEDetails.map(
                                                  (friend, index) => (
                                                    <tr key={index}>
                                                      <td>
                                                        <Field
                                                          type="text"
                                                          disabled={
                                                            values.disabledForCielops
                                                          }
                                                          // name="PassingCertificateNo"
                                                          name={`CPEDetails.${index}.passingCertificateNo`}
                                                          onBlur={(event) => {
                                                            this.updateCommonWeightage(
                                                              "CPEDetailspassingCertificateNo",
                                                              event.target.value,
                                                              values
                                                            );
                                                          }}
                                                          className="form-control"
                                                        />

                                                        <ErrorMessage
                                                          name={`CPEDetails.${index}.passingCertificateNo`}
                                                          component="div"
                                                          className="validation"
                                                        />
                                                      </td>
                                                      <td>
                                                        <Field
                                                          type="date"
                                                          disabled={
                                                            values.disabledForCielops
                                                          }
                                                          // name="dateOfPassingTest"

                                                          name={`CPEDetails.${index}.dateOfPassingTest`}
                                                          onBlur={(event) => {
                                                            this.updateCommonWeightage(
                                                              "CPEDetailsdateOfPassingTest",
                                                              event.target.value,
                                                              values
                                                            );
                                                          }}
                                                          //   value={moment(friend.dateOfPassingTest,"DD/MM/YYYY HH:mm:ss").format('YYYY-MM-DD[T]HH:mm:ss').split('T')[0]}
                                                          value={
                                                            moment(
                                                              moment(
                                                                friend.dateOfPassingTest,
                                                                "YYYY-MM-DD"
                                                              ).format(
                                                                "DD/MM/YYYY HH:mm:ss"
                                                              ),
                                                              "DD/MM/YYYY HH:mm:ss"
                                                            )
                                                              .format(
                                                                "YYYY-MM-DD[T]HH:mm:ss"
                                                              )
                                                              .split("T")[0]
                                                          }
                                                          className="form-control"
                                                          // onChange={(e) => {
                                                          //   let dateOfPassingTest=   moment(e.target.value,"YYYY-MM-DD").format('DD/MM/YYYY HH:mm:ss')
                                                          //   alert(values.CPEDetails[index].dateOfPassingTest)
                                                          //      setFieldValue(
                                                          //        (values.CPEDetails[index].dateOfPassingTest = dateOfPassingTest)
                                                          //      )
                                                          //    }}
                                                        />
                                                        <ErrorMessage
                                                          name={`CPEDetails.${index}.dateOfPassingTest`}
                                                          component="div"
                                                          className="validation"
                                                        />
                                                      </td>
                                                      <td>
                                                        <Field
                                                          type="text"
                                                          disabled={
                                                            values.disabledForCielops
                                                          }
                                                          //  name="CPECertificateNo"
                                                          name={`CPEDetails.${index}.cpeCertificateNo`}
                                                          className="form-control"
                                                          onBlur={(event) => {
                                                            this.updateCommonWeightage(
                                                              "CPEDetailscpeCertificateNo",
                                                              event.target.value,
                                                              values
                                                            );
                                                          }}
                                                        />
                                                        <ErrorMessage
                                                          name={`CPEDetails.${index}.cpeCertificateNo`}
                                                          component="div"
                                                          className="validation"
                                                        />
                                                      </td>

                                                      <td>
                                                        <Field
                                                          type="date"
                                                          disabled={
                                                            values.disabledForCielops
                                                          }
                                                          //   name="dateOfCPE"
                                                          name={`CPEDetails.${index}.dateOfCpe`}
                                                          // value= {moment(moment(friend.dateOfPassingTest,"YYYY-MM-DD").format('DD/MM/YYYY HH:mm:ss'),"DD/MM/YYYY HH:mm:ss").format('YYYY-MM-DD[T]HH:mm:ss').split('T')[0]}
                                                          onBlur={(event) => {
                                                            this.updateCommonWeightage(
                                                              "PEDetailsdateOfCpe",
                                                              event.target.value,
                                                              values
                                                            );
                                                          }}
                                                          value={
                                                            moment(
                                                              moment(
                                                                friend.dateOfCpe,
                                                                "YYYY-MM-DD"
                                                              ).format(
                                                                "DD/MM/YYYY HH:mm:ss"
                                                              ),
                                                              "DD/MM/YYYY HH:mm:ss"
                                                            )
                                                              .format(
                                                                "YYYY-MM-DD[T]HH:mm:ss"
                                                              )
                                                              .split("T")[0]
                                                          }
                                                          className="form-control"
                                                        />
                                                        <ErrorMessage
                                                          name={`CPEDetails.${index}.dateOfCpe`}
                                                          component="div"
                                                          className="validation"
                                                        />
                                                      </td>

                                                      <td>
                                                        <button
                                                          type="button"
                                                          className="btn-8 ml-5 mr-5 mt-10"
                                                          disabled={
                                                            values.disabledForCielops
                                                          }
                                                          onClick={() => {
                                                            arrayHelpers1.insert(
                                                              index + 1,
                                                              ""
                                                            );
                                                          }}
                                                        >
                                                          <i
                                                            className="fa fa-plus"
                                                            aria-hidden="true"
                                                          />
                                                        </button>
                                                        <button
                                                          type="button"
                                                          className="btn-8 ml-5 mr-5 mt-10"
                                                          disabled={
                                                            values.disabledForCielops
                                                          }
                                                          onClick={() => {
                                                            arrayHelpers1.remove(
                                                              index
                                                            );
                                                          }}
                                                        >
                                                          <i
                                                            className="fa fa-minus"
                                                            aria-hidden="true"
                                                          />
                                                        </button>
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        ) : (
                                          <button
                                            type="button"
                                            className="btn-4"
                                            onClick={() => {
                                              arrayHelpers1.push("");
                                            }}
                                          >
                                            Add CPE Details
                                          </button>
                                        )}
                                      </div>
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </section>

                          {this.state.assignedUserMandateName !== undefined &&
                          this.state.assignedUserMandateName !== "NISM" && 
                          this.state?.newLearnerDetails?.learnerEuinOption && 
                          ![null,undefined,"null","undefined"].includes(this.state?.newLearnerDetails?.learnerEuinOption) &&
                          this.state?.newLearnerDetails?.learnerEuinOption !== "NISM" ? (
                            <section
                              className="create-parent page-section profile-box"
                              id="NISMCertificate"
                              // style={this.state.processType !== "1002" ? { display: "none" } : { display: "block" }}
                            >
                              <div className="row">
                                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                  <h2 className="sub-header">
                                    NISM Certificate Details
                                    <br />
                                    <span
                                      className="validation"
                                      style={{
                                        fontSize: "13px",
                                        textTransform: "capitalize",
                                        color: "#999",
                                      }}
                                    >
                                      THIS SECTION IS TO BE FILLED ONLY IF YOU
                                      ARE APPLYING FOR AN EUIN NUMBER (FRESH OR
                                      RENEWAL).
                                    </span>
                                  </h2>

                                  {/* <span className="validation" style={{fontSize:"13px"}}>This section is to be filled ONLY if you are applying for a FRESH EUIN Number.</span>  */}
                                  <h5>
                                    To apply for a fresh EUIN number, you must
                                    have a VALID NISM CERTIFCATE (valid for more
                                    than 6 months). Please fill VALID NISM
                                    certificate details below. The EUIN tab will
                                    get activated only if NISM certificate
                                    details filled below are VALID and not
                                    expired.
                                  </h5>
                                  <div className="address-border">
                                    <div className="form-group">
                                      <div className="row">
                                        {/* <div className="col-md-4 col-sm-4 col-xs-12">
                                        <label>Certificate </label>
                                        <div>
                                          <Field
                                            type="radio"
                                            name="isCertificate"
                                            value="1"
                                            style={{marginRight: '10px'}}
                                            className="form-check-input"
                                            disabled={
                                            this.state.assignedUserMandateName === 'NISM'
                                              ? true
                                              : false
                                          }
                                            onClick={e => {
                                              setFieldValue (
                                                (values.isCertificate = '1')
                                              );
                                            }}
                                          />
                                          <label
                                            class="form-check-label"
                                            htmlFor="gridCheck"
                                            style={{marginRight: '15px'}}
                                          >
                                            Yes
                                          </label>

                                          <Field
                                            type="radio"
                                            name="isCertificate"
                                            value="0"
                                            style={{marginRight: '10px'}}
                                            className="form-check-input"
                                            disabled={
                                            this.state.assignedUserMandateName === 'NISM'
                                              ? true
                                              : false
                                          }
                                            onClick={e => {
                                              setFieldValue (
                                                (values.isCertificate = '0')
                                              );
                                            }}
                                          />
                                          <label
                                            class="form-check-label"
                                            htmlFor="gridCheck"
                                            style={{marginRight: '15px'}}
                                          >
                                            No
                                          </label>
                                        </div>
                                      </div> */}

                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label>
                                            NISM Certificate Number/ Enrollment
                                            Number{" "}
                                          </label>

                                          <Field
                                            type="text"
                                            name="certificateNumber"
                                            id="certificateNumber"
                                            maxlength="20"
                                            //disabled
                                            // disabled={
                                            //   values.isCertificate === '1'
                                            //     ? false
                                            //     : true
                                            // }

                                            // disabled={
                                            //   this.state.assignedUserMandateName === 'NISM'
                                            //     ? true
                                            //     : false
                                            // }
                                            // disabled={
                                            //          values.disabledForCielops
                                            // }
                                            // disabled={
                                            //   this.state.certificateNumber === "" || this.state.certificateNumber === undefined
                                            //     ? false
                                            //     : true
                                            // }

                                            disabled={
                                              values.disabledForCielops === true
                                                ? false
                                                : this.state
                                                    .certificateNumber === "" ||
                                                  this.state
                                                    .certificateNumber ===
                                                    undefined
                                                ? false
                                                : true
                                            }
                                            // onChange={handleChange}
                                            onChange={(event) => {
                                              handleChange(event);
                                              let certificate =
                                                event.target.value;
                                              setFieldValue(
                                                (values.certificateNumber = certificate)
                                              );
                                            }}
                                            autoComplete="certificateNumber"
                                            className="form-control"
                                            placeholder="NISM Certificate Number"
                                          />
                                          <ErrorMessage
                                            name="certificateNumber"
                                            component="div"
                                            className="validation"
                                          />
                                        </div>
                                        <div
                                          className="col-md-4 col-sm-4 col-xs-12"
                                          style={{ marginTop: "1.3em" }}
                                        >
                                          <label>Valid Upto</label>
                                          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                            <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                              <Field
                                                as="select"
                                                name="nismday"
                                                id="nismday"
                                                // onChange={handleChange}
                                                // disabled={
                                                //   values.isCertificate === '1'
                                                //     ? false
                                                //     : true
                                                // }
                                                // disabled
                                                //     disabled={
                                                //   this.state.assignedUserMandateName === 'NISM'
                                                //     ? true
                                                //     : false
                                                // }
                                                // disabled={
                                                //          values.disabledForCielops
                                                // }
                                                disabled={
                                                  values.disabledForCielops ===
                                                  true
                                                    ? false
                                                    : this.state
                                                        .certificateValidUpto ===
                                                        "" ||
                                                      this.state
                                                        .certificateValidUpto ===
                                                        undefined
                                                    ? false
                                                    : true
                                                }
                                                onChange={(e) => {
                                                  var listOfMonth = [];
                                                  setFieldValue(
                                                    (values.nismday =
                                                      e.target.value)
                                                  );
                                                  if (e.target.value == 31) {
                                                    listOfMonth = [
                                                      { key: 1, value: "Jan" },

                                                      { key: 3, value: "Mar" },

                                                      { key: 5, value: "May" },

                                                      { key: 7, value: "Jul" },
                                                      { key: 8, value: "Aug" },

                                                      { key: 10, value: "Oct" },

                                                      { key: 12, value: "Dec" },
                                                    ];
                                                  } else if (
                                                    e.target.value == 30
                                                  ) {
                                                    listOfMonth = [
                                                      { key: 1, value: "Jan" },

                                                      { key: 3, value: "Mar" },
                                                      { key: 4, value: "Apr" },
                                                      { key: 5, value: "May" },
                                                      { key: 6, value: "Jun" },
                                                      { key: 7, value: "Jul" },
                                                      { key: 8, value: "Aug" },
                                                      { key: 9, value: "Sep" },
                                                      { key: 10, value: "Oct" },
                                                      { key: 11, value: "Nov" },
                                                      { key: 12, value: "Dec" },
                                                    ];
                                                  } else if (
                                                    e.target.value == 28 ||
                                                    e.target.value == 29
                                                  ) {
                                                    listOfMonth = [
                                                      { key: 1, value: "Jan" },
                                                      { key: 2, value: "Feb" },
                                                      { key: 3, value: "Mar" },
                                                      { key: 4, value: "Apr" },
                                                      { key: 5, value: "May" },
                                                      { key: 6, value: "Jun" },
                                                      { key: 7, value: "Jul" },
                                                      { key: 8, value: "Aug" },
                                                      { key: 9, value: "Sep" },
                                                      { key: 10, value: "Oct" },
                                                      { key: 11, value: "Nov" },
                                                      { key: 12, value: "Dec" },
                                                    ];
                                                  } else {
                                                    listOfMonth = [
                                                      { key: 1, value: "Jan" },
                                                      { key: 2, value: "Feb" },
                                                      { key: 3, value: "Mar" },
                                                      { key: 4, value: "Apr" },
                                                      { key: 5, value: "May" },
                                                      { key: 6, value: "Jun" },
                                                      { key: 7, value: "Jul" },
                                                      { key: 8, value: "Aug" },
                                                      { key: 9, value: "Sep" },
                                                      { key: 10, value: "Oct" },
                                                      { key: 11, value: "Nov" },
                                                      { key: 12, value: "Dec" },
                                                    ];
                                                  }
                                                  setFieldValue(
                                                    (values.listOfMonth = listOfMonth)
                                                  );

                                                  // this.setState({
                                                  //   listOfMonth: listOfMonth
                                                  // })
                                                }}
                                                className={
                                                  "form-control" +
                                                  (errors.nismday &&
                                                  touched.nismday
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                // className="form-control"
                                              >
                                                <option value="">DD</option>
                                                {(values.listOfDay || []).map(
                                                  (d) => (
                                                    <option value={d}>
                                                      {d}
                                                    </option>
                                                  )
                                                )}
                                              </Field>
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                              <Field
                                                as="select"
                                                name="nismmonth"
                                                id="nismmonth"
                                                //   onChange={handleChange}
                                                // disabled={
                                                //   values.isCertificate === '1'
                                                //     ? false
                                                //     : true
                                                // }
                                                //     disabled={
                                                //   this.state.assignedUserMandateName === 'NISM'
                                                //     ? true
                                                //     : false
                                                // }
                                                // disabled={
                                                //          values.disabledForCielops
                                                // }
                                                //  disabled
                                                disabled={
                                                  values.disabledForCielops ===
                                                  true
                                                    ? false
                                                    : this.state
                                                        .certificateValidUpto ===
                                                        "" ||
                                                      this.state
                                                        .certificateValidUpto ===
                                                        undefined
                                                    ? false
                                                    : true
                                                }
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    (values.nismmonth =
                                                      e.target.value)
                                                  );
                                                  if (e.target.value == 2) {
                                                    var listOfDay = [];
                                                    for (
                                                      var i = 1;
                                                      i <= 29;
                                                      i++
                                                    ) {
                                                      //    var current = year - i;
                                                      listOfDay.push(i);
                                                      // this.setState({ listOfDay: listOfDay });
                                                    }
                                                  } else if (
                                                    e.target.value == 1 ||
                                                    e.target.value == 3 ||
                                                    e.target.value == 5 ||
                                                    e.target.value == 7 ||
                                                    e.target.value == 8 ||
                                                    e.target.value == 10 ||
                                                    e.target.value == 12
                                                  ) {
                                                    var listOfDay = [];
                                                    for (
                                                      var i = 1;
                                                      i <= 31;
                                                      i++
                                                    ) {
                                                      //    var current = year - i;
                                                      listOfDay.push(i);
                                                      //  this.setState({ listOfDay: listOfDay });
                                                    }
                                                  } else {
                                                    var listOfDay = [];
                                                    for (
                                                      var i = 1;
                                                      i <= 30;
                                                      i++
                                                    ) {
                                                      //    var current = year - i;
                                                      listOfDay.push(i);
                                                      //  this.setState({ listOfDay: listOfDay });
                                                    }
                                                  }
                                                  setFieldValue(
                                                    (values.listOfDay = listOfDay)
                                                  );
                                                }}
                                                className={
                                                  "form-control" +
                                                  (errors.nismmonth &&
                                                  touched.nismmonth
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                // className="form-control"
                                              >
                                                <option value="">MM</option>
                                                {(values.listOfMonth || []).map(
                                                  (m) => (
                                                    <option value={m.key}>
                                                      {m.value}
                                                    </option>
                                                  )
                                                )}
                                              </Field>
                                            </div>
                                            <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                              <Field
                                                as="select"
                                                name="nismyear"
                                                id="nismyear"
                                                className={
                                                  "form-control" +
                                                  (errors.nismyear &&
                                                  touched.nismyear
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                // disabled={
                                                //   values.isCertificate === '1'
                                                //     ? false
                                                //     : true
                                                // }
                                                //     disabled={
                                                //   this.state.assignedUserMandateName === 'NISM'
                                                //     ? true
                                                //     : false
                                                // }
                                                // disabled={
                                                //          values.disabledForCielops
                                                // }
                                                //disabled
                                                disabled={
                                                  values.disabledForCielops ===
                                                  true
                                                    ? false
                                                    : this.state
                                                        .certificateValidUpto ===
                                                        "" ||
                                                      this.state
                                                        .certificateValidUpto ===
                                                        undefined
                                                    ? false
                                                    : true
                                                }
                                                // className="form-control"
                                              >
                                                <option value="">YYYY</option>
                                                {(
                                                  this.state.certificateYear ||
                                                  []
                                                ).map((nismyear) => (
                                                  <option value={nismyear}>
                                                    {nismyear}
                                                  </option>
                                                ))}
                                              </Field>
                                            </div>
                                          </div>

                                          {errors.certificateValidUpto && (
                                            <div className="validation">
                                              {errors.certificateValidUpto}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label style={{ marginBottom: 0 }}>
                                            Enrollment Number{" "}
                                          </label>
                                          <br />
                                          <span
                                            className="validation"
                                            style={{
                                              fontSize: "10px",
                                              color: "#999",
                                            }}
                                          >
                                            For office purpose only
                                          </span>
                                          <Field
                                            type="text"
                                            name="enrollmentNumber"
                                            id="enrollmentNumber"
                                            maxlength="20"
                                            disabled={
                                              values.disabledForCielops === true
                                                ? false
                                                : this.state
                                                    .enrollmentNumber === "" ||
                                                  this.state
                                                    .enrollmentNumber ===
                                                    undefined
                                                ? false ||
                                                  this.state?.newLearnerDetails?.learnerEuinOption ===
                                                    "Fresh EUIN" ||
                                                  this.state?.newLearnerDetails?.learnerEuinOption ===
                                                    "Only EUIN Renewal"
                                                : true
                                            }
                                            // onChange={handleChange}
                                            onChange={(event) => {
                                              handleChange(event);
                                              let certificate =
                                                event.target.value;
                                              setFieldValue(
                                                (values.enrollmentNumber = certificate)
                                              );
                                            }}
                                            autoComplete="enrollmentNumber"
                                            className="form-control"
                                            placeholder="Enrollment Number"
                                          />
                                          <ErrorMessage
                                            name="enrollmentNumber"
                                            component="div"
                                            className="validation"
                                          />
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label style={{ marginBottom: 0 }}>
                                            Enrollment Date
                                          </label>
                                          <br />
                                          <span
                                            className="validation"
                                            style={{
                                              fontSize: "10px",
                                              color: "#999",
                                            }}
                                          >
                                            For office purpose only
                                          </span>
                                          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                            <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                              <Field
                                                as="select"
                                                name="enrollmentday"
                                                id="enrollmentday"
                                                disabled={
                                                  values.disabledForCielops ===
                                                  true
                                                    ? false
                                                    : this.state
                                                        .enrollmentDate ===
                                                        "" ||
                                                      this.state
                                                        .enrollmentDate ===
                                                        undefined
                                                    ? false ||
                                                      this.state
                                                        .newLearnerDetails
                                                        .learnerEuinOption ===
                                                        "Fresh EUIN" ||
                                                      this.state
                                                        .newLearnerDetails
                                                        .learnerEuinOption ===
                                                        "Only EUIN Renewal"
                                                    : true
                                                }
                                                onChange={(e) => {
                                                  var listOfMonth = [];
                                                  setFieldValue(
                                                    (values.enrollmentday =
                                                      e.target.value)
                                                  );
                                                  if (e.target.value == 31) {
                                                    listOfMonth = [
                                                      { key: 1, value: "Jan" },

                                                      { key: 3, value: "Mar" },

                                                      { key: 5, value: "May" },

                                                      { key: 7, value: "Jul" },
                                                      { key: 8, value: "Aug" },

                                                      { key: 10, value: "Oct" },

                                                      { key: 12, value: "Dec" },
                                                    ];
                                                  } else if (
                                                    e.target.value == 30
                                                  ) {
                                                    listOfMonth = [
                                                      { key: 1, value: "Jan" },

                                                      { key: 3, value: "Mar" },
                                                      { key: 4, value: "Apr" },
                                                      { key: 5, value: "May" },
                                                      { key: 6, value: "Jun" },
                                                      { key: 7, value: "Jul" },
                                                      { key: 8, value: "Aug" },
                                                      { key: 9, value: "Sep" },
                                                      { key: 10, value: "Oct" },
                                                      { key: 11, value: "Nov" },
                                                      { key: 12, value: "Dec" },
                                                    ];
                                                  } else if (
                                                    e.target.value == 28 ||
                                                    e.target.value == 29
                                                  ) {
                                                    listOfMonth = [
                                                      { key: 1, value: "Jan" },
                                                      { key: 2, value: "Feb" },
                                                      { key: 3, value: "Mar" },
                                                      { key: 4, value: "Apr" },
                                                      { key: 5, value: "May" },
                                                      { key: 6, value: "Jun" },
                                                      { key: 7, value: "Jul" },
                                                      { key: 8, value: "Aug" },
                                                      { key: 9, value: "Sep" },
                                                      { key: 10, value: "Oct" },
                                                      { key: 11, value: "Nov" },
                                                      { key: 12, value: "Dec" },
                                                    ];
                                                  } else {
                                                    listOfMonth = [
                                                      { key: 1, value: "Jan" },
                                                      { key: 2, value: "Feb" },
                                                      { key: 3, value: "Mar" },
                                                      { key: 4, value: "Apr" },
                                                      { key: 5, value: "May" },
                                                      { key: 6, value: "Jun" },
                                                      { key: 7, value: "Jul" },
                                                      { key: 8, value: "Aug" },
                                                      { key: 9, value: "Sep" },
                                                      { key: 10, value: "Oct" },
                                                      { key: 11, value: "Nov" },
                                                      { key: 12, value: "Dec" },
                                                    ];
                                                  }
                                                  setFieldValue(
                                                    (values.listOfMonth = listOfMonth)
                                                  );
                                                }}
                                                className={
                                                  "form-control" +
                                                  (errors.enrollmentday &&
                                                  touched.enrollmentday
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                // className="form-control"
                                              >
                                                <option value="">DD</option>
                                                {(values.listOfDay || []).map(
                                                  (d) => (
                                                    <option value={d}>
                                                      {d}
                                                    </option>
                                                  )
                                                )}
                                              </Field>
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                              <Field
                                                as="select"
                                                name="enrollmentmonth"
                                                id="enrollmentmonth"
                                                disabled={
                                                  values.disabledForCielops ===
                                                  true
                                                    ? false
                                                    : this.state
                                                        .enrollmentDate ===
                                                        "" ||
                                                      this.state
                                                        .enrollmentDate ===
                                                        undefined
                                                    ? false ||
                                                      this.state
                                                        .newLearnerDetails
                                                        .learnerEuinOption ===
                                                        "Fresh EUIN" ||
                                                      this.state
                                                        .newLearnerDetails
                                                        .learnerEuinOption ===
                                                        "Only EUIN Renewal"
                                                    : true
                                                }
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    (values.enrollmentmonth =
                                                      e.target.value)
                                                  );
                                                  if (e.target.value == 2) {
                                                    var listOfDay = [];
                                                    for (
                                                      var i = 1;
                                                      i <= 29;
                                                      i++
                                                    ) {
                                                      listOfDay.push(i);
                                                    }
                                                  } else if (
                                                    e.target.value == 1 ||
                                                    e.target.value == 3 ||
                                                    e.target.value == 5 ||
                                                    e.target.value == 7 ||
                                                    e.target.value == 8 ||
                                                    e.target.value == 10 ||
                                                    e.target.value == 12
                                                  ) {
                                                    var listOfDay = [];
                                                    for (
                                                      var i = 1;
                                                      i <= 31;
                                                      i++
                                                    ) {
                                                      listOfDay.push(i);
                                                    }
                                                  } else {
                                                    var listOfDay = [];
                                                    for (
                                                      var i = 1;
                                                      i <= 30;
                                                      i++
                                                    ) {
                                                      listOfDay.push(i);
                                                    }
                                                  }
                                                  setFieldValue(
                                                    (values.listOfDay = listOfDay)
                                                  );
                                                }}
                                                className={
                                                  "form-control" +
                                                  (errors.enrollmentmonth &&
                                                  touched.enrollmentmonth
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                // className="form-control"
                                              >
                                                <option value="">MM</option>
                                                {(values.listOfMonth || []).map(
                                                  (m) => (
                                                    <option value={m.key}>
                                                      {m.value}
                                                    </option>
                                                  )
                                                )}
                                              </Field>
                                            </div>
                                            <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                              <Field
                                                as="select"
                                                name="enrollmentyear"
                                                id="enrollmentyear"
                                                className={
                                                  "form-control" +
                                                  (errors.enrollmentyear &&
                                                  touched.enrollmentyear
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                disabled={
                                                  values.disabledForCielops ===
                                                  true
                                                    ? false
                                                    : this.state
                                                        .enrollmentDate ===
                                                        "" ||
                                                      this.state
                                                        .enrollmentDate ===
                                                        undefined
                                                    ? false ||
                                                      this.state
                                                        .newLearnerDetails
                                                        .learnerEuinOption ===
                                                        "Fresh EUIN" ||
                                                      this.state
                                                        .newLearnerDetails
                                                        .learnerEuinOption ===
                                                        "Only EUIN Renewal"
                                                    : true
                                                }
                                                // className="form-control"
                                              >
                                                <option value="">YYYY</option>
                                                {(
                                                  this.state.certificateYear ||
                                                  []
                                                ).map((enrollmentyear) => (
                                                  <option
                                                    value={enrollmentyear}
                                                  >
                                                    {enrollmentyear}
                                                  </option>
                                                ))}
                                              </Field>
                                            </div>
                                          </div>

                                          {errors.enrollmentDate && (
                                            <div className="validation">
                                              {errors.enrollmentDate}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          ) : (
                            ""
                          )}

                          {this.state.assignedUserMandateName !== undefined &&
                          this.state.assignedUserMandateName !== "NISM" && 
                          this.state?.newLearnerDetails?.learnerEuinOption && 
                          ![null,undefined,"null","undefined"].includes(this.state?.newLearnerDetails?.learnerEuinOption) &&
                          this.state?.newLearnerDetails?.learnerEuinOption !== "NISM"? (
                            <section
                              className="create-parent page-section profile-box"
                              id="EUINDetails"
                              // style={this.state.processType !== "1002" ? { display: "none" } : { display: "block" }}
                            >
                              <div className="row">
                                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                  <h2 className="sub-header">
                                    EUIN Details
                                    <br />
                                    <span
                                      className="validation"
                                      style={{
                                        fontSize: "13px",
                                        textTransform: "capitalize",
                                        color: "#999",
                                      }}
                                    >
                                      THIS SECTION IS TO BE FILLED ONLY IF YOU
                                      WANT TO RENEW YOUR EXISTING EUIN NUMBER.
                                    </span>
                                  </h2>
                                  {/* <span className="validation" style={{fontSize:"13px"}}>This section is to be filled ONLY if you want to Renew your EUIN Number.</span>  */}
                                  <h5>
                                    <p>
                                      To renew your existing EUIN, you need to
                                      provide us with your EUIN number. EUIN
                                      number can be renewed only when you have
                                      renewed your NISM certificate.
                                    </p>
                                    <p>
                                      Please note in case your EUIN is not
                                      mapped to your current organisation, you
                                      will need to fill a physical mapping and
                                      renewal form and send it to CIEL office.
                                      You could write to us at
                                      euinsupport@ciel.co.in for any queries.
                                    </p>
                                  </h5>
                                  <div className="address-border">
                                    <div className="form-group">
                                      <div className="row">
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label>EUIN Number </label>
                                          <Field
                                            type="text"
                                            name="euinNumber"
                                            id="euinNumber"
                                            maxlength="20"
                                            //disabled={values.disabledForCielops}
                                            // disabled={
                                            //   this.state.assignedUserMandateName === 'NISM' || this.state.assignedUserMandateName === 'EUIN' || this.state.assignedUserMandateName === 'NISM and EUIN'
                                            //     ? true
                                            //     : false
                                            // }
                                            disabled={
                                              values.disabledForCielops === true
                                                ? false
                                                : this.state.euinNumber ===
                                                    "" ||
                                                  this.state.euinNumber ===
                                                    undefined
                                                ? false
                                                : true
                                            }
                                            onChange={handleChange}
                                            className={
                                              "form-control" +
                                              (errors.euinNumber &&
                                              touched.euinNumber
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="EUIN Number"
                                          />
                                          {/* <ErrorMessage
                                            name="euinNumber"
                                            className="validation"
                                            component="div"
                                          /> */}
                                          {errors.euinNumber && (
                                            <div className="validation">
                                              {errors.euinNumber}
                                            </div>
                                          )}
                                        </div>
                                        <div
                                          className="col-md-4 col-sm-4 col-xs-12"
                                          style={{ visibility: "hidden" }}
                                        >
                                          <label>Valid Upto</label>
                                          {/* <Field
                                                type="date"
                                                onChange={handleChange}
                                                disabled={values.disabledForCielops}
                                                onBlur={event => {
                                                  this.updateCommonWeightage(event.target.name,event.target.value)
                                                }}
                                                max={this.state.today}
                                                name="NameOfARNHolder"
                                                id="NameOfARNHolder"
                                                className="form-control"
                                                placeholder=""
                                              />
                                              <ErrorMessage
                                                name="NameOfARNHolder"
                                                component="div"
                                                className="validation"
                                              /> */}
                                          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                            <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                              <Field
                                                as="select"
                                                name="euinday"
                                                id="euinday"
                                                // onChange={handleChange}
                                                //  disabled={this.state.isDisabled}
                                                //   disabled
                                                // disabled={values.disabledForCielops}
                                                //     disabled={
                                                //   this.state.assignedUserMandateName === 'NISM' || this.state.assignedUserMandateName === 'EUIN' || this.state.assignedUserMandateName === 'NISM and EUIN'
                                                //     ? true
                                                //     : false
                                                // }
                                                // disabled={
                                                //          values.disabledForCielops
                                                // }

                                                disabled={
                                                  values.disabledForCielops ===
                                                  true
                                                    ? false
                                                    : this.props.personnelData
                                                        .euinValidUpto === "" ||
                                                      this.props.personnelData
                                                        .euinValidUpto ===
                                                        undefined
                                                    ? false
                                                    : true
                                                }
                                                onChange={(e) => {
                                                  var listOfMonth = [];
                                                  setFieldValue(
                                                    (values.euinday =
                                                      e.target.value)
                                                  );
                                                  if (e.target.value == 31) {
                                                    listOfMonth = [
                                                      { key: 1, value: "Jan" },

                                                      { key: 3, value: "Mar" },

                                                      { key: 5, value: "May" },

                                                      { key: 7, value: "Jul" },
                                                      { key: 8, value: "Aug" },

                                                      { key: 10, value: "Oct" },

                                                      { key: 12, value: "Dec" },
                                                    ];
                                                  } else if (
                                                    e.target.value == 30
                                                  ) {
                                                    listOfMonth = [
                                                      { key: 1, value: "Jan" },

                                                      { key: 3, value: "Mar" },
                                                      { key: 4, value: "Apr" },
                                                      { key: 5, value: "May" },
                                                      { key: 6, value: "Jun" },
                                                      { key: 7, value: "Jul" },
                                                      { key: 8, value: "Aug" },
                                                      { key: 9, value: "Sep" },
                                                      { key: 10, value: "Oct" },
                                                      { key: 11, value: "Nov" },
                                                      { key: 12, value: "Dec" },
                                                    ];
                                                  } else if (
                                                    e.target.value == 28 ||
                                                    e.target.value == 29
                                                  ) {
                                                    listOfMonth = [
                                                      { key: 1, value: "Jan" },
                                                      { key: 2, value: "Feb" },
                                                      { key: 3, value: "Mar" },
                                                      { key: 4, value: "Apr" },
                                                      { key: 5, value: "May" },
                                                      { key: 6, value: "Jun" },
                                                      { key: 7, value: "Jul" },
                                                      { key: 8, value: "Aug" },
                                                      { key: 9, value: "Sep" },
                                                      { key: 10, value: "Oct" },
                                                      { key: 11, value: "Nov" },
                                                      { key: 12, value: "Dec" },
                                                    ];
                                                  } else {
                                                    listOfMonth = [
                                                      { key: 1, value: "Jan" },
                                                      { key: 2, value: "Feb" },
                                                      { key: 3, value: "Mar" },
                                                      { key: 4, value: "Apr" },
                                                      { key: 5, value: "May" },
                                                      { key: 6, value: "Jun" },
                                                      { key: 7, value: "Jul" },
                                                      { key: 8, value: "Aug" },
                                                      { key: 9, value: "Sep" },
                                                      { key: 10, value: "Oct" },
                                                      { key: 11, value: "Nov" },
                                                      { key: 12, value: "Dec" },
                                                    ];
                                                  }
                                                  setFieldValue(
                                                    (values.listOfMonth = listOfMonth)
                                                  );

                                                  // this.setState({
                                                  //   listOfMonth: listOfMonth
                                                  // })
                                                }}
                                                className={
                                                  "form-control" +
                                                  (errors.euinday &&
                                                  touched.euinday
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                // className="form-control"
                                              >
                                                <option value="">DD</option>
                                                {(values.listOfDay || []).map(
                                                  (d) => (
                                                    <option value={d}>
                                                      {d}
                                                    </option>
                                                  )
                                                )}
                                              </Field>
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                              <Field
                                                as="select"
                                                name="euinmonth"
                                                id="euinmonth"
                                                //   onChange={handleChange}
                                                //     disabled={this.state.isDisabled}
                                                //  disabled
                                                //     disabled={
                                                //   this.state.assignedUserMandateName === 'NISM' || this.state.assignedUserMandateName === 'EUIN' || this.state.assignedUserMandateName === 'NISM and EUIN'
                                                //     ? true
                                                //     : false
                                                // }
                                                // disabled={
                                                //          values.disabledForCielops
                                                // }
                                                disabled={
                                                  values.disabledForCielops ===
                                                  true
                                                    ? false
                                                    : this.props.personnelData
                                                        .euinValidUpto === "" ||
                                                      this.props.personnelData
                                                        .euinValidUpto ===
                                                        undefined
                                                    ? false
                                                    : true
                                                }
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    (values.euinmonth =
                                                      e.target.value)
                                                  );
                                                  if (e.target.value == 2) {
                                                    var listOfDay = [];
                                                    for (
                                                      var i = 1;
                                                      i <= 29;
                                                      i++
                                                    ) {
                                                      //    var current = year - i;
                                                      listOfDay.push(i);
                                                      // this.setState({ listOfDay: listOfDay });
                                                    }
                                                  } else if (
                                                    e.target.value == 1 ||
                                                    e.target.value == 3 ||
                                                    e.target.value == 5 ||
                                                    e.target.value == 7 ||
                                                    e.target.value == 8 ||
                                                    e.target.value == 10 ||
                                                    e.target.value == 12
                                                  ) {
                                                    var listOfDay = [];
                                                    for (
                                                      var i = 1;
                                                      i <= 31;
                                                      i++
                                                    ) {
                                                      //    var current = year - i;
                                                      listOfDay.push(i);
                                                      //  this.setState({ listOfDay: listOfDay });
                                                    }
                                                  } else {
                                                    var listOfDay = [];
                                                    for (
                                                      var i = 1;
                                                      i <= 30;
                                                      i++
                                                    ) {
                                                      //    var current = year - i;
                                                      listOfDay.push(i);
                                                      //  this.setState({ listOfDay: listOfDay });
                                                    }
                                                  }
                                                  setFieldValue(
                                                    (values.listOfDay = listOfDay)
                                                  );
                                                }}
                                                className={
                                                  "form-control" +
                                                  (errors.euinmonth &&
                                                  touched.euinmonth
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                // className="form-control"
                                              >
                                                <option value="">MM</option>
                                                {(values.listOfMonth || []).map(
                                                  (m) => (
                                                    <option value={m.key}>
                                                      {m.value}
                                                    </option>
                                                  )
                                                )}
                                              </Field>
                                            </div>
                                            <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                              <Field
                                                as="select"
                                                name="euinyear"
                                                id="euinyear"
                                                // onChange={handleChange}
                                                //    disabled={this.state.isDisabled}
                                                //  disabled

                                                //     disabled={
                                                //   this.state.assignedUserMandateName === 'NISM' || this.state.assignedUserMandateName === 'EUIN' || this.state.assignedUserMandateName === 'NISM and EUIN'
                                                //     ? true
                                                //     : false
                                                // }
                                                // disabled={
                                                //          values.disabledForCielops
                                                // }
                                                disabled={
                                                  values.disabledForCielops ===
                                                  true
                                                    ? false
                                                    : this.props.personnelData
                                                        .euinValidUpto === "" ||
                                                      this.props.personnelData
                                                        .euinValidUpto ===
                                                        undefined
                                                    ? false
                                                    : true
                                                }
                                                className={
                                                  "form-control" +
                                                  (errors.euinyear &&
                                                  touched.euinyear
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                //disabled={values.disabledForCielops}
                                                //disabled
                                                // className="form-control"
                                              >
                                                <option value="">YYYY</option>
                                                {(
                                                  this.state.certificateYear ||
                                                  []
                                                ).map((euinyear) => (
                                                  <option value={euinyear}>
                                                    {euinyear}
                                                  </option>
                                                ))}
                                              </Field>
                                            </div>
                                          </div>

                                          {errors.dob && (
                                            <div className="validation">
                                              {errors.dob}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          ) : (
                            ""
                          )}
                          {(values.preferredMandateName === "NISM" ||
                          ["", null].includes(values.learnerEuinOption))
                           && 
                          (<section
                            className="create-parent page-section profile-box"
                            id="Preferred-NISM-Exam-Location"
                          >
                            <div className="row">
                              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                <h2 className="sub-header">Preferred NISM Exam Location</h2>
                                <div className="address-border">
                                  <div className="form-group">
                                    <div className="row">
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Preferred Exam Location
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        as="select"
                                        name="preferredExamLocation"
                                        id="preferredExamLocation"
                                        onChange={(e) => {
                                          handleChange(e);
                                          if(e.target.value !== ""){
                                            setFieldValue("preferredExamLocationState",e.target.selectedOptions[0].label);
                                            RegionManagementService.getCenter(
                                              "stateId=" + e.target.value,
                                              (res) => {
                                                if(res.status === "success"){
                                                  setFieldValue("preferredExamCenterList",res.responseListObject);
                                                }else{
                                                  warning(res.reasonText,warningNotification)
                                                }
                                              }
                                            );
                                          }
                                        }}
                                        onBlur={(e) => {
                                          this.updateCommonWeightage(
                                            e.target.name,
                                            e.target.value,
                                            values
                                          );
                                        }}
                                        className={
                                          "form-control" +
                                          (errors.preferredExamLocation &&
                                          touched.preferredExamLocation
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option value="">--- Select ---</option>
                                        {(values.preferredExamLocationList || []).map((location) => (
                                          <option
                                            key={location.stateId}
                                            value={location.stateId}
                                          >
                                            {this.titleCase(location.stateName)}
                                          </option>
                                        ))}
                                      </Field>
                                      {errors.preferredExamLocation && (
                                        <div className="validation">
                                          {errors.preferredExamLocation}
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Preferred Exam Center
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        as="select"
                                        name="preferredExamCenter"
                                        id="preferredExamCenter"
                                        onChange={handleChange}
                                        onBlur={(e) => {
                                          this.updateCommonWeightage(
                                            e.target.name,
                                            e.target.value,
                                            values
                                          );
                                        }}
                                        className={
                                          "form-control" +
                                          (errors.preferredExamCenter &&
                                          touched.preferredExamCenter
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option value="">--- Select ---</option>
                                        {(values.preferredExamCenterList || []).map((team) => (
                                          <option
                                            key={team.centerName}
                                            value={team.centerName}
                                          >
                                            {team.centerName}
                                          </option>
                                        ))}
                                      </Field>
                                      {errors.preferredExamCenter && (
                                        <div className="validation">
                                          {errors.preferredExamCenter}
                                        </div>
                                      )}
                                    </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>)}
                          <section
                            className="create-parent page-section profile-box"
                            id="ARNDetails"
                            // style={this.state.processType !== "1002" ? { display: "none" } : { display: "block" }}
                          >
                            <div className="row">
                              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                <h2 className="sub-header">ARN Details</h2>
                                <div className="address-border">
                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-4 col-sm-4 col-xs-12">
                                        <label>ARN Number </label>
                                        <Field
                                          type="text"
                                          name="ARNNO"
                                          id="ARNNO"
                                          disabled={
                                            this.state.isMandateUser === 1
                                              ? true
                                              : false
                                          }
                                          maxlength="20"
                                          className="form-control"
                                          placeholder="ARN Number"
                                          onChange={handleChange}
                                        />
                                        <ErrorMessage
                                          name="ARNNO"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                      {/* <div className="col-md-4 col-sm-4 col-xs-12">
                                              <label>Select Company Name</label>
                                              <Select
                                                onChange={(value) => this.onChangeParameter(value, setFieldValue)}
                                                name="company"
                                                options={this.state.companyList}
                                                filterOption={createFilter({
                                                  matchFrom: 'start'
                                                  })}
                                                value={values.company}
                                              /> 

                                             
                                             
                                              
                                      
                                            </div>
                                            {this.state.companyNameOther === "other" || this.state.companyNameOther === "Other" || this.state.companyNameOther === "OTHER"? */}
                                      <div className="col-md-4 col-sm-4 col-xs-12">
                                        <label>Company Name </label>
                                        {/* <Field
                                          type="text"
                                          name="otherCompanyName"
                                          //disabled
                                          id="otherCompanyName"
                                          //disabled={values.disabledForCielops}
                                          onChange={handleChange}
                                          autoComplete="otherCompanyName"
                                          className="form-control"
                                          placeholder="Company Name"
                                        /> */}
                                        {/* <ErrorMessage
                                                name="otherCompanyName"
                                                component="div"
                                                className="validation"
                                              /> */}

                                        <Field
                                          type="text"
                                          name="orgCompanyName"
                                          disabled
                                          id="orgCompanyName"
                                          //disabled={values.disabledForCielops}
                                          onChange={handleChange}
                                          autoComplete="otherCompanyName"
                                          className="form-control"
                                          placeholder="Company Name"
                                        />
                                      </div>
                                      {/* ded : ''}dee */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          {/* Uncomment this section for ARN detais */}
                          {/* <section
                                className="create-qualification page-section profile-box"
                                id="ARN-details"
                                // style={this.state.processType !== "1001" ? { display: "none" } : { display: "none" }}

                              >
                                <div className="row">
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                    <h2 className="sub-header">
                                   ARN Details Manoj
                                                                      </h2>

                                    <div className="">
                                      <FieldArray
                                        name="ARNuserFeilds"
                                        onChange={handleChange}
                                        render={arrayHelpers => (
                                          <div>
                                            {values.ARNuserFeilds &&
                                              values.ARNuserFeilds.length >
                                              0 ? (
                                                <div className="table-row">
                                                  <table
                                                    className="table table-bordered table-hover"
                                                    id="tab_logic"
                                                  >
                                                    <thead>
                                                      <tr>
                                                        <th className="text-center">
                                                          {" "}
                                                          Name of AMC
                                                    </th>
                                                        


                                                        <th className="text-center">
                                                          {" "}
                                                          Action{" "}
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {values.ARNuserFeilds.map(
                                                        (friend, index) => (
                                                          <tr key={index}>
                                                           
                                                            <td>
                                                              <Field
                                                                as="select"
                                                                disabled={values.disabledForCielops}
                                                                name={`ARNuserFeilds.${index}.nameOfMutualFundEmpanelled`}
                                                                id="nameOfMutualFundEmpanelled"
                                                                onChange={handleChange}
                                                                onBlur={event => {
                                                                  this.updateCommonWeightage("ARNuserFeildsnameOfMutualFundEmpanelled",event.target.value)
                                                                }}
                                                                className={
                                                                  "form-control" +
                                                                  (errors.NameOfMutualFund && touched.NameOfMutualFund
                                                                    ? " is-invalid"
                                                                    : "")
                                                                }
                                                              >
                                                                <option value="">
                                                                  Select


                                                                  
                                          </option>
                                          {(this.state.nameOfMutual || []).map(
                                            team => (
                                              <option
                                                key={team.mstAmcId}
                                                value={team.mstAmcId}
                                              >
                                                {team.mstAmcName}
                                              </option>
                                            )
                                          )}
                                                              </Field>
                                                            </td>


                                                            <td>
                                                              <button
                                                                type="button"
                                                                className="btn-8 ml-5 mr-5 mt-10"
                                                                disabled={values.disabledForCielops}
                                                                onClick={() => {
                                                                  
                                                                  console.log(values.ARNuserFeilds)
                                                                  // if(values.ARNuserFeilds.length>1){
                                                                    let values1=values.ARNuserFeilds
                                                                    // for(
                                                                    //   let i=0;i<values.ARNuserFeilds.length;i++
                                                                    // ){
                                                                    //   if(values.ARNuserFeilds[i])
                                                                    // }

                                                                    var valueArr = values1.map(function(item){ return item.nameOfMutualFundEmpanelled });
var isDuplicate = valueArr.some(function(item, idx){ 
    return valueArr.indexOf(item) != idx 
});

  if(isDuplicate){
    warning("Please Select unique records",warningNotification)
    values.ARNuserFeilds.splice(values.ARNuserFeilds.length-1,1)
    }

         console.log(isDuplicate)                                                         
      // let data=this.state.nameOfMutual+index
      // let i1=index+1
      // let i=this.state.nameOfMutual+i1
      // data.splice(15,1)
      // this.setState({
      //   i:data
      // })
                                                                  arrayHelpers.insert(
                                                                    index + 1,
                                                                    ""
                                                                  );
                                                                }}
                                                              >
                                                                <i
                                                                  class="fa fa-plus"
                                                                  aria-hidden="true"
                                                                ></i>
                                                              </button>
                                                              <button
                                                                type="button"
                                                                disabled={values.disabledForCielops}
                                                                className="btn-8 ml-5 mr-5 mt-10"
                                                                onClick={() => {
                                                                  

                                                                  arrayHelpers.remove(
                                                                    index
                                                                  );
                                                                }}
                                                              >
                                                                <i
                                                                  class="fa fa-minus"
                                                                  aria-hidden="true"
                                                                ></i>
                                                              </button>
                                                            </td>
                                                          </tr>
                                                        )
                                                      )}
                                                    </tbody>
                                                  </table>
                                                </div>
                                              ) : (
                                                <button
                                                  type="button"
                                                  className="btn-4"
                                                  onClick={() => {
                                                    arrayHelpers.push("");
                                                  }}
                                                >
                                                  Add  ARN Information
                                                </button>
                                              )}
                                          </div>
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </section>
                               */}
                          <section
                            className="create-profile page-section profile-box"
                            id="create-profile"
                          >
                            <h2 className="sub-header">Profile Details</h2>
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-4 col-sm-4 col-xs-12">
                                  <label>Upload Profile Image</label>
                                  <span className="validation">*</span>

                                  <div className="profile-detail">
                                    <div className="circle">
                                      <img
                                        className="profile-pic"
                                        src={values.profilePhotoURL}
                                      />
                                      <div className="profile">
                                        <i
                                          className="fa fa-user"
                                          aria-hidden="true"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="hidden-md hidden-lg hidden-sm col-xs-12">
                                    <div className="profile-add">
                                      {/* {values.disabledForCielops==true?<span ></span>: */}
                                      <a
                                        className="icon btn-file"
                                        disabled={values.disabledForCielops}
                                      >
                                        <i
                                          className="fa fa-plus-circle"
                                          aria-hidden="true"
                                        />
                                        <FileBase64
                                        id="profilePhoto"
                                          multiple={true}
                                          onChange={handleChange}
                                          onDone={(event) => {
                                            if (
                                              [
                                                "image/jpg",
                                                "image/jpeg",
                                                "image/png",
                                              ].indexOf(event[0].type) < 0
                                            ) {
                                              setFieldValue(
                                                (values.documentTypeCheckForProfileImage = true)
                                              );
                                            } else if (
                                              event[0].file.size <= 1000000
                                            ) {
                                              if (
                                                this.checkName(event[0].name)
                                              ) {
                                                values.document.push({
                                                  documentHash: event[0].base64,
                                                  documentType: "ProfilePhoto",
                                                  documentName: event[0].name,
                                                });
                                               
                                                setFieldValue(
                                                  (values.documentTypeCheckForProfileImage = false)
                                                );
                                                setFieldValue(
                                                  (values.documentSizeCheckForProfileCheck = false)
                                                );
                                                setFieldValue(
                                                  (values.profileImageDocumentNameValidation = false)
                                                );

                                                values.profilePhotoURL =
                                                  event[0].base64;
                                                setFieldValue(
                                                  (values.profilePhotoURL =
                                                    event[0].base64)
                                                );
                                                this.updateCommonWeightage(
                                                  "profilePhotoURL",
                                                  event[0].name,
                                                  values
                                                );
                                              } else {
                                                setFieldValue(
                                                  (values.profileImageDocumentNameValidation = true)
                                                );

                                                //   warning("File/Document name can only contain number, letter, hyphens, space. ", warningNotification);
                                              }
                                            } else {
                                              setFieldValue(
                                                (values.profileImageDocumentNameValidation = false)
                                              );

                                              setFieldValue(
                                                (values.documentSizeCheckForProfileCheck = true)
                                              );
                                            }
                                          }}
                                        />{" "}
                                        <h4>Add Image</h4>
                                      </a>
                                      {/* } */}
                                      <div className="col-md-12 col-sm-12">
                                        <span className="file-size-limit">
                                          Please upload your latest colored
                                          passport size photo in jpg/jpeg,png
                                          format.
                                          <br />
                                          File Size: Max 1 MB
                                          <br/>
                                 Photo should be preferably with white background (similar to a passport size photo) 		
                                        </span>
                                      </div>
                                      {values.documentSizeCheckForProfileCheck ? (
                                        <p style={{ color: "red" }}>
                                          File Size is too large(Maximum File
                                          Size:1MB).
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                      {values.documentTypeCheckForProfileImage ? (
                                        <p style={{ color: "red" }}>
                                          Invalid image format
                                        </p>
                                      ) : (
                                        ""
                                      )}

                                      {values.profileImageDocumentNameValidation ? (
                                        <p style={{ color: "red" }}>
                                          File/Document name can only contain
                                          number, letter, hyphens, space.
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="col-md-4 col-sm-4 col-xs-12"
                                  style={{ display: "none" }}
                                >
                                  <label>Upload Text Resume</label>
                                  <div className="text-resume">
                                    <h3>Select File To Upload</h3>
                                  </div>
                                  <div
                                    className="profile-add text-center"
                                    style={{
                                      wordBreak: "break-all",
                                      padding: "11px 25px 11px 6px",
                                    }}
                                  >
                                    {values.resumeDocumentNameDuringEdit !=
                                    "" ? (
                                      <span>
                                        {
                                          values.resumeDocumentNameDuringEditName
                                        }
                                      </span>
                                    ) : values.resumeDocumentName ? (
                                      <span>{values.resumeDocumentName}</span>
                                    ) : (
                                      <span>
                                        <span className="icon">
                                          <i
                                            className="fa fa-times-circle"
                                            aria-hidden="true"
                                          />
                                        </span>
                                        <h4>No File Chosen</h4>
                                      </span>
                                    )}

                                    <div>
                                      <a
                                        className="icon btn-file text-center"
                                        // style={
                                        //   values.disabledForCielops
                                        //     ? {
                                        //         pointerEvents: 'none',
                                        //         cursor: 'default',
                                        //       }
                                        //     : {}
                                        // }
                                      >
                                        <i
                                          className="fa fa-paperclip color_magento"
                                          aria-hidden="true"
                                        />
                                        {values.documentSizeCheckForResume ? (
                                          <p style={{ color: "red" }}>
                                            File Size is too large(Maximum File
                                            Size:2MB).
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                        {values.documentTypeCheckForResume ? (
                                          <p style={{ color: "red" }}>
                                            Invalid Format
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                        <FileBase64
                                          multiple={true}
                                          name="resumeDocumentURL"
                                          onChange={handleChange}
                                          onDone={(event) => {
                                            // const checkType = event[0].name.split(
                                            //   "."
                                            // );
                                            const checkType = event[0].name.split(
                                              /\.(?=[^\.]+$)/
                                            );

                                            var regexp1 = new RegExp(
                                              "^[p{L},-s‘’]+(?!.)$"
                                            );

                                            //    let regexp1=/^[\p{L},\-\s‘’]+(?!\.)$
                                            let result = !regexp1.test(
                                              event[0].name
                                            );
                                            //  alert(ans)
                                            if (result == true) {
                                              if (
                                                [
                                                  "pdf",
                                                  "docx",
                                                  "rtf",
                                                  "doc",
                                                  // "application/pdf",
                                                  // "application/doc",
                                                  // "application/docx",
                                                  // "application/rtf",
                                                  // "application/msword",
                                                  // "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                ].indexOf(checkType[1]) < 0
                                              ) {
                                                setFieldValue(
                                                  (values.documentTypeCheckForResume = true)
                                                );
                                              } else if (
                                                event[0].file.size <= 2000000
                                              ) {
                                                if (
                                                  this.checkName(event[0].name)
                                                ) {
                                                  values.document.push({
                                                    documentHash:
                                                      event[0].base64,
                                                    documentType:
                                                      "ResumeDocument",
                                                    documentName: event[0].name,
                                                  });
                                                  this.updateCommonWeightage(
                                                    "resumeDocumentURL",
                                                    event[0].name,
                                                    values
                                                  );

                                                  setFieldValue(
                                                    (values.documentTypeCheckForResume = false)
                                                  );
                                                  setFieldValue(
                                                    (values.documentSizeCheckForResume = false)
                                                  );
                                                  setFieldValue(
                                                    (values.resumeDocumentNameValidation = false)
                                                  );

                                                  setFieldValue(
                                                    (values.resumeDocumentName =
                                                      event[0].name)
                                                  );
                                                  setFieldValue(
                                                    (values.resumeDocumentNameDuringEdit =
                                                      "")
                                                  );
                                                  setFieldValue(
                                                    (values.downloadResumeAfterSelecting =
                                                      event[0].base64)
                                                  );
                                                } else {
                                                  setFieldValue(
                                                    (values.resumeDocumentNameValidation = true)
                                                  );

                                                  // warning("File/Document name can only contain number, letter, hyphens, space. ", warningNotification);
                                                }
                                              } else {
                                                setFieldValue(
                                                  (values.resumeDocumentNameValidation = false)
                                                );

                                                setFieldValue(
                                                  (values.documentSizeCheckForResume = true)
                                                );
                                              }
                                            } else {
                                              setFieldValue(
                                                (values.resumeDocumentNameValidation = true)
                                              );
                                            }
                                          }}
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <div className="hidden-md hidden-lg hidden-sm col-xs-12">
                                    <div className="profile-add">
                                      <a className="icon btn-file">
                                        {values.downloadResumeAfterSelecting ? (
                                          <a
                                            href={
                                              values.downloadResumeAfterSelecting
                                            }
                                            className="download"
                                            download={values.resumeDocumentName}
                                          >
                                            <i
                                              className="fa fa-download"
                                              aria-hidden="true"
                                            />
                                            <h4> Download Resume </h4>
                                          </a>
                                        ) : (
                                            values.resumeDocumentURL || ""
                                          ).includes("pdf") ? (
                                          <a
                                            href={values.resumeDocumentURL}
                                            className="download"
                                            target="_blank"
                                          >
                                            <i
                                              className="fa fa-download"
                                              aria-hidden="true"
                                            />
                                            <h4> Download Resume </h4>
                                          </a>
                                        ) : values.resumeDocumentURL ? (
                                          <a
                                            href={values.resumeDocumentURL}
                                            className="download"
                                            // disabled={true}
                                          >
                                            <i
                                              className="fa fa-download"
                                              aria-hidden="true"
                                            />
                                            <h4> Download Resume </h4>
                                          </a>
                                        ) : (
                                          <span>
                                            <i
                                              className="fa fa-download"
                                              aria-hidden="true"
                                            />
                                            <h4> Download Resume </h4>
                                          </span>
                                        )}
                                      </a>
                                      <div className="col-md-12 col-sm-12">
                                        <span className="file-size-limit">
                                          Supported Formats: .doc,.docx, .pdf,
                                          .rtf only. File Size: Max 2 MB
                                        </span>

                                        {values.resumeDocumentNameValidation ? (
                                          <p style={{ color: "red" }}>
                                            File/Document name can only contain
                                            number, letter, hyphens, space.
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="col-md-4 col-sm-4 col-xs-12"
                                  style={{ display: "none" }}
                                >
                                  <label>Upload Video Resume</label>
                                  <div className="profile-detail">
                                    <div className="circle">
                                      <div className="profile-pic">
                                        <div className="profile">
                                          <ReactPlayer
                                            width="100%"
                                            height="90"
                                            url={values.resumeVideoURL}
                                            playing={false}
                                            controls
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="hidden-md hidden-lg hidden-sm col-xs-12">
                                    <div className="profile-add">
                                      {/* {values.disabledForCielops==true?<span ></span>: */}

                                      <a
                                      // style={
                                      //   values.disabledForCielops
                                      //     ? {
                                      //         pointerEvents: 'none',
                                      //         cursor: 'default',
                                      //       }
                                      //     : {}
                                      // }
                                      >
                                        <span
                                          className="icon btn-file text-center"
                                          disabled={values.disabledForCielops}
                                        >
                                          <i
                                            className="fa fa-plus-circle"
                                            aria-hidden="true"
                                          />
                                          <FileBase64
                                            multiple={true}
                                            name="resumeVideoURL"
                                            onChange={handleChange}
                                            onDone={(event) => {
                                              if (
                                                [
                                                  "video/mp4",
                                                  "video/avi",
                                                  "video/mpeg",
                                                ].indexOf(event[0].type) < 0
                                              ) {
                                                setFieldValue(
                                                  (values.documentTypeCheckForVideo = true)
                                                );
                                              } else if (
                                                event[0].file.size <= 3000000
                                              ) {
                                                if (
                                                  this.checkName(event[0].name)
                                                ) {
                                                  values.document.push({
                                                    documentHash:
                                                      event[0].base64,
                                                    documentType: "VideoResume",
                                                    documentName: event[0].name,
                                                  });
                                                  this.updateCommonWeightage(
                                                    "resumeVideoURL",
                                                    event[0].name,
                                                    values
                                                  );

                                                  setFieldValue(
                                                    (values.documentTypeCheckForVideo = false)
                                                  );
                                                  setFieldValue(
                                                    (values.documentSizeCheckForVideoCheck = false)
                                                  );
                                                  setFieldValue(
                                                    (values.VideodocumentNameValidation = false)
                                                  );
                                                  setFieldValue(
                                                    (values.resumeVideoURL =
                                                      event[0].base64)
                                                  );
                                                } else {
                                                  setFieldValue(
                                                    (values.VideodocumentNameValidation = true)
                                                  );
                                                  //   warning("File/Document name can only contain number, letter, hyphens, space.", warningNotification);
                                                }
                                              } else {
                                                setFieldValue(
                                                  (values.VideodocumentNameValidation = false)
                                                );
                                                setFieldValue(
                                                  (values.documentSizeCheckForVideoCheck = true)
                                                );
                                              }
                                            }}
                                          />

                                          <h4>Add Video</h4>
                                        </span>
                                      </a>
                                      {/* } */}
                                      {/* <span className="icon">
                                            <i
                                              className="fa fa-times-circle"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                          <h4>Remove Video</h4> */}
                                      {values.documentSizeCheckForVideoCheck ? (
                                        <p style={{ color: "red" }}>
                                          File Size is too large(Maximum File
                                          Size:3MB).
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                      {values.documentTypeCheckForVideo ? (
                                        <p style={{ color: "red" }}>
                                          Invalid Format
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                      {values.VideodocumentNameValidation ? (
                                        <p style={{ color: "red" }}>
                                          Video name should contains numbers,
                                          letters, hypens, spaces.
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                      <div className="col-md-12 col-sm-12">
                                        <span className="file-size-limit">
                                          Supported Formats: .mp4, .avi, .mpeg
                                          only. <br />
                                          File Size: Max 3 MB
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-4 col-sm-4 hidden-xs">
                                  <div className="profile-add">
                                    <a
                                      className="icon btn-file"
                                      // style={
                                      //   values.disabledForCielops
                                      //     ? {
                                      //         pointerEvents: 'none',
                                      //         cursor: 'default',
                                      //       }
                                      //     : {}
                                      // }
                                    >
                                      <i
                                        className="fa fa-plus-circle"
                                        aria-hidden="true"
                                      />
                                      <FileBase64
                                        multiple={true}
                                        onChange={handleChange}
                                        onDone={(event) => {
                                          if (
                                            [
                                              "image/jpg",
                                              "image/jpeg",
                                              "image/png",
                                            ].indexOf(event[0].type) < 0
                                          ) {
                                            setFieldValue(
                                              (values.documentTypeCheckForProfileImage = true)
                                            );
                                          } else if (
                                            event[0].file.size <= 1000000
                                          ) {
                                            if (this.checkName(event[0].name)) {
                                              values.document.push({
                                                documentHash: event[0].base64,
                                                documentType: "ProfilePhoto",
                                                documentName: event[0].name,
                                              });
                                              this.updateCommonWeightage(
                                                "profilePhotoURL",
                                                event[0].name,
                                                values
                                              );
                                              setFieldValue(
                                                (values.documentTypeCheckForProfileImage = false)
                                              );
                                              setFieldValue(
                                                (values.documentSizeCheckForProfileCheck = false)
                                              );
                                              setFieldValue(
                                                (values.profileImageDocumentNameValidation = false)
                                              );

                                              values.profilePhotoURL =
                                                event[0].base64;
                                              setFieldValue(
                                                (values.profilePhotoURL =
                                                  event[0].base64)
                                              );
                                            } else {
                                              setFieldValue(
                                                (values.profileImageDocumentNameValidation = true)
                                              );
                                            }
                                          } else {
                                            setFieldValue(
                                              (values.profileImageDocumentNameValidation = false)
                                            );

                                            setFieldValue(
                                              (values.documentSizeCheckForProfileCheck = true)
                                            );
                                          }
                                        }}
                                      />{" "}
                                      <h4>Add Image</h4>
                                    </a>

                                    <div className="col-md-12 col-sm-12">
                                      <span className="file-size-limit">
                                        Please upload your latest colored
                                        passport size photo in jpg/jpeg, png
                                        format. <br />
                                        File Size: Max 1 MB
                                        <br/>
                                 Photo should be preferably with white background (similar to a passport size photo) 	
                                      </span>
                                    </div>
                                    {values.documentSizeCheckForProfileCheck ? (
                                      <p style={{ color: "red" }}>
                                        File Size is too large(Maximum File
                                        Size:1MB).
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    {values.documentTypeCheckForProfileImage ? (
                                      <p style={{ color: "red" }}>
                                        Invalid image format
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    {values.profileImageDocumentNameValidation ? (
                                      <p style={{ color: "red" }}>
                                        File/Document name can only contain
                                        number, letter, hyphens, space.
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>

                                <div
                                  className="col-md-4 col-sm-4 hidden-xs"
                                  style={{ display: "none" }}
                                >
                                  <div className="profile-add">
                                    <a className="icon btn-file">
                                      {values.downloadResumeAfterSelecting ? (
                                        <a
                                          href={
                                            values.downloadResumeAfterSelecting
                                          }
                                          className="download"
                                          download={values.resumeDocumentName}
                                        >
                                          <i
                                            className="fa fa-download"
                                            aria-hidden="true"
                                          />
                                          <h4> Download Resume </h4>
                                        </a>
                                      ) : (
                                          values.resumeDocumentURL || ""
                                        ).includes("pdf") ? (
                                        <a
                                          href={values.resumeDocumentURL}
                                          className="download"
                                          target="_blank"
                                        >
                                          <i
                                            className="fa fa-download"
                                            aria-hidden="true"
                                          />
                                          <h4> Download Resume </h4>
                                        </a>
                                      ) : values.resumeDocumentURL ? (
                                        <a
                                          href={values.resumeDocumentURL}
                                          className="download"
                                        >
                                          <i
                                            className="fa fa-download"
                                            aria-hidden="true"
                                          />
                                          <h4> Download Resume </h4>
                                        </a>
                                      ) : (
                                        <span>
                                          <i
                                            className="fa fa-download"
                                            aria-hidden="true"
                                          />
                                          <h4> Download Resume </h4>
                                        </span>
                                      )}
                                    </a>
                                    <div className="col-md-12 col-sm-12">
                                      <span className="file-size-limit">
                                        Supported Formats: .doc,.docx, .pdf,
                                        .rtf only. File Size: Max 2 MB
                                      </span>
                                      {values.resumeDocumentNameValidation ? (
                                        <p style={{ color: "red" }}>
                                          File/Document name can only contain
                                          number, letter, hyphens, space.
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div
                                  style={{ display: "none" }}
                                  className="col-md-4 col-sm-4 hidden-xs"
                                >
                                  <div className="profile-add">
                                    <a
                                      style={
                                        values.disabledForCielops
                                          ? {
                                              pointerEvents: "none",
                                              cursor: "default",
                                            }
                                          : {}
                                      }
                                    >
                                      <span className="icon btn-file text-center">
                                        <i
                                          className="fa fa-plus-circle"
                                          aria-hidden="true"
                                        />
                                        <FileBase64
                                          multiple={true}
                                          name="resumeVideoURL"
                                          onChange={handleChange}
                                          onDone={(event) => {
                                            if (
                                              [
                                                "video/mp4",
                                                "video/avi",
                                                "video/mpeg",
                                              ].indexOf(event[0].type) < 0
                                            ) {
                                              setFieldValue(
                                                (values.documentTypeCheckForVideo = true)
                                              );
                                            } else if (
                                              event[0].file.size <= 3000000
                                            ) {
                                              if (
                                                this.checkName(event[0].name)
                                              ) {
                                                values.document.push({
                                                  documentHash: event[0].base64,
                                                  documentType: "VideoResume",
                                                  documentName: event[0].name,
                                                });
                                                this.updateCommonWeightage(
                                                  "resumeVideoURL",
                                                  event[0].name,
                                                  values
                                                );
                                                setFieldValue(
                                                  (values.documentTypeCheckForVideo = false)
                                                );
                                                setFieldValue(
                                                  (values.documentSizeCheckForVideoCheck = false)
                                                );
                                                setFieldValue(
                                                  (values.VideodocumentNameValidation = false)
                                                );

                                                setFieldValue(
                                                  (values.resumeVideoURL =
                                                    event[0].base64)
                                                );
                                              } else {
                                                // warning("File/Document name can only contain number, letter, hyphens, space.", warningNotification);
                                                setFieldValue(
                                                  (values.VideodocumentNameValidation = true)
                                                );
                                              }
                                            } else {
                                              setFieldValue(
                                                (values.VideodocumentNameValidation = false)
                                              );

                                              setFieldValue(
                                                (values.documentSizeCheckForVideoCheck = true)
                                              );
                                            }
                                          }}
                                        />

                                        <h4>Add Video</h4>
                                      </span>
                                    </a>
                                    {/* <span className="icon">
                                            <i
                                              className="fa fa-times-circle"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                          <h4>Remove Video</h4> */}
                                    {values.documentSizeCheckForVideoCheck ? (
                                      <p style={{ color: "red" }}>
                                        File Size is too large(Maximum File
                                        Size:3MB).
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    {values.documentTypeCheckForVideo ? (
                                      <p style={{ color: "red" }}>
                                        Invalid Format
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    {values.VideodocumentNameValidation ? (
                                      <p style={{ color: "red" }}>
                                        Video name should contains numbers,
                                        letters, hypens, spaces.
                                      </p>
                                    ) : (
                                      ""
                                    )}

                                    <div className="col-md-12 col-sm-12">
                                      <span className="file-size-limit">
                                        Supported Formats: .mp4, .avi, .mpeg
                                        only. <br />
                                        File Size: Max 3 MB
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-20 pl pr profile-footer">
                            {roledata.map((item, key) =>
                              // alert("item.description"+ item.description)
                              item.description === "Update User Profile" ? (
                                item.objectActionId
                                  .applicationObjectActionName ===
                                "My User Profile" ? (
                                  item.objectActionId.applicationObjectId
                                    .applicationObjectName === "Save" ? (
                                    <div>
                                      <button
                                        onClick={async () => {
                                          debugger;
                                          if(values.orgCompanyName==="HDFC Bank Limited"){
                                            
                                            window.$('#Hdfc-Mandate').modal('show')
                                          }        
                                        else{
                                          setFieldValue(
                                            (values.submitStatus = "SUBMITTED")
                                          );
                                          console.log(values);

                                          const curErrors = await formikProps.validateForm();
                                          console.log(curErrors);
                                          let errorData = [];
                                          errorData.push(curErrors);

                                          const curErrorsKeys = Object.keys(
                                            curErrors
                                          );

                                          if (curErrorsKeys.length) {
                                            if (errorData.length > 0) {
                                              warning(
                                                "You have either not filled all mandatory fields or not uploaded all document for NISM. In case you don't have your documents ready, please save your profile as 'Save Draft'. Add the documents to move to the next section",
                                                warningNotification
                                              );
                                            }
                                          console.log("eeee",curErrorsKeys);
                                            const el = document.getElementById(
                                              curErrorsKeys[0]
                                            );

                                            if (el) {
                                              el.focus();
                                           }
                                          }else{
                                            handleSubmit()
                                             }
                                        }
                                        
                                        }}
                                        className="btn-4 pull-right"
                                        name="submitForm"
                                        // type="submit"
                                        type="button"
                                        disabled={this.state.submitBtnDisabled}

                                  >
                                        Submit
                                      </button>
                                      {values.applicationStatus ==
                                      "SUBMITTED" ? (
                                        ""
                                      ) : (
                                        <button
                                          onClick={async (event) => {

                                            if(values.orgCompanyName==="HDFC Bank Limited"){
                                            
                                              window.$('#Hdfc-Mandate').modal('show')
                                            }
                                            else{
                                            console.log(values);
                                            
                                            setFieldValue(
                                              (values.submitStatus = "DRAFTED")
                                              );
                                              console.log(values);
                                              
                                              const curErrors = await formikProps.validateForm();
                                              const curErrorsKeys = Object.keys(
                                                curErrors
                                                );
                                                
                                                if (curErrorsKeys.length) {
                                                  const el = document.getElementById(
                                                    curErrorsKeys[0]
                                                    );
                                                    
                                                    if (el) {
                                                      el.focus();
                                                    }
                                                  }else{
                                                    handleSubmit()
                                                     }
                                            }
                                          }}
                                          className="btn-4 pull-right"
                                          name="submitForm"
                                          // type="submit"
                                          type="button"
                                          disabled={this.state.saveDraftBtnDisabled}
                                        >
                                          Save Draft
                                        </button>
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )
                            )}
                            <button
                              onClick={async () => {
                                if (localStorage.getItem("roleId") == 5) {
                                  this.props.history.push("/learnerDashboard");
                                } else {
                                  this.props.history.push("/dashboard");
                                }
                              }}
                              className="btn-4 pull-right"
                              name="submitForm"
                              type="button"
                            >
                              Cancel
                            </button>

                            {localStorage.getItem("roleId") == 6 ? (
                              <button
                                onClick={async () => {
                                  debugger;
                                  if(values.orgCompanyName==="HDFC Bank Limited"){        
                                    window.$('#Hdfc-Mandate').modal('show')
                                  }
                                  else{
                                  setFieldValue(
                                    (values.submitStatus = "SUBMITTED")
                                  );
                                  console.log(values);

                                  const curErrors = await formikProps.validateForm();
                                  console.log(curErrors);
                                  let errorData = [];
                                  errorData.push(curErrors);

                                  const curErrorsKeys = Object.keys(curErrors);

                                  if (curErrorsKeys.length) {
                                    if (errorData.length > 0) {
                                      warning(
                                        "You have either not filled all mandatory fields or not uploaded all document for NISM. In case you don't have your documents ready, please save your profile as 'Save Draft'. Add the documents to move to the next section",
                                        warningNotification
                                      );
                                    }
                                    const el = document.getElementById(
                                      curErrorsKeys[0]
                                    );

                                    if (el) {
                                      el.focus();
                                    }
                                  }else{
                                    handleSubmit()
                                     }
                                }
                                }}
                                className="btn-4 pull-right"
                                name="submitForm"
                                // type="submit"
                                type="button"
                              >
                                Submit
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                          {/* <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-20">
                                <button
                                  onClick={async () => {
                                    const curErrors = await formikProps.validateForm();
                                    const curErrorsKeys = Object.keys(curErrors);

                                    if (curErrorsKeys.length) {
                                      const el = document.getElementById(
                                        curErrorsKeys[0]
                                      );

                                      if (el) el.focus();
                                    }
                                  }}
                                  className="btn-4 pull-right"
                                  name="submitForm"
                                  type="submit"
                                >
                                  Submit
                              </button>
                              </div> */}
                        </div>
                      </Form>
                    </main>
                  </div>
                </div>
              </div>

{/* HDFC MANDATE POPUP STARTS */}

                    <div
                      className="modal fade"
                      id="Hdfc-Mandate"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="myModalLabel"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={this.handleClose}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
            
                            {/* <strong><h4 className="modal-title text-center" style={{fontWeight:"bold",marginBottom:20}} id="myModalLabel">
                                How was your overall experience with RCP?
                            </h4>
                            </strong> */}
                          </div>
                          <div className="modal-body">
                            <span style={{fontSize:"17px"}}>
                            In case you experience difficulty in submitting the profile due to your corporate machine, please use your personal laptop/desktop OR your mobile device and submit profile again.
                          </span>
                          </div>
                          <div className="modal-footer text-center">
                            {roledata.map((item, key) =>
                              item.description === "Update User Profile" ? (
                                item.objectActionId
                                  .applicationObjectActionName ===
                                "My User Profile" ? (
                                  item.objectActionId.applicationObjectId
                                    .applicationObjectName === "Save" ? (
                                    <div>
                                      <button
                                        onClick={async () => {
                                          debugger;
                                          window.$('#Hdfc-Mandate').modal('hide')
                                          setFieldValue(
                                            (values.submitStatus = "SUBMITTED")
                                          );
                                          console.log(values);

                                          const curErrors = await formikProps.validateForm();
                                          console.log(curErrors);
                                          let errorData = [];
                                          errorData.push(curErrors);

                                          const curErrorsKeys = Object.keys(
                                            curErrors
                                          );

                                          if (curErrorsKeys.length) {
                                            if (errorData.length > 0) {
                                              warning(
                                                "You have either not filled all mandatory fields or not uploaded all document for NISM. In case you don't have your documents ready, please save your profile as 'Save Draft'. Add the documents to move to the next section",
                                                warningNotification
                                              );
                                            }
                                          console.log("eeee",curErrorsKeys);
                                            const el = document.getElementById(
                                              curErrorsKeys[0]
                                            );
                                            if (el) {
                                              el.focus();
                                           }
                                          }
                                          else{
                                           handleSubmit()
                                          }
                                        
                                        }}
                                        className="btn-4 pull-right"
                                        name="submitForm"
                                        type="submit"
                                        disabled={this.state.submitHDFCBtnDisabled}

                                  >
                                        Submit
                                      </button>
                                      {values.applicationStatus ==
                                      "SUBMITTED" ? (
                                        ""
                                      ) : (
                                        <button
                                          onClick={async (event) => {

                                            console.log(values);
                                            window.$('#Hdfc-Mandate').modal('hide')
                                            setFieldValue(
                                              (values.submitStatus = "DRAFTED")
                                              );
                                              console.log(values);
                                              
                                              const curErrors = await formikProps.validateForm();
                                              const curErrorsKeys = Object.keys(
                                                curErrors
                                                );
                                                
                                                if (curErrorsKeys.length) {
                                                  const el = document.getElementById(
                                                    curErrorsKeys[0]
                                                    );
                                                    if (el) {
                                                      el.focus();
                                                    }
                                                  }
                                                  else{
                                                handleSubmit()
                                                  }
                                          }}
                                          className="btn-4 pull-right"
                                          name="submitForm"
                                          type="submit"
                                          disabled={this.state.saveDraftHDFCBtnDisabled}
                                        >
                                          Save Draft
                                        </button>
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )
                            )}
                            {localStorage.getItem("roleId") == 6 ? (
                              <button
                                onClick={async () => {
                                  debugger;
                                  window.$('#Hdfc-Mandate').modal('hide')
                                  setFieldValue(
                                    (values.submitStatus = "SUBMITTED")
                                  );
                                  console.log(values);

                                  const curErrors = await formikProps.validateForm();
                                  console.log(curErrors);
                                  let errorData = [];
                                  errorData.push(curErrors);

                                  const curErrorsKeys = Object.keys(curErrors);

                                  if (curErrorsKeys.length) {
                                    if (errorData.length > 0) {
                                      warning(
                                        "You have either not filled all mandatory fields or not uploaded all document for NISM. In case you don't have your documents ready, please save your profile as 'Save Draft'. Add the documents to move to the next section",
                                        warningNotification
                                      );
                                    }
                                    const el = document.getElementById(
                                      curErrorsKeys[0]
                                    );

                                    if (el) {
                                      el.focus();
                                    }
                                  }
                                  else{
                                    handleSubmit()
                                   }
                                }}
                                className="btn-4 pull-right"
                                name="submitForm"
                                type="submit"
                              >
                                Submit
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                          </div>
                        </div>
                      </div>
{/* HDFC MANDATE POPUP ENDS */}
              <Modal
                open={this.state.showWhyTheFillForm}
                onClose={this.onWhyTheFillFormCloseModal}
                center
              >
                <div
                  className=""
                  id="roadmap"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="modalLabel"
                  data-keyboard="true"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <div className="scr-board">
                          <h2>Why Fill The Form? </h2>
                          <div className="bottom-line" />
                        </div>
                        {/* <h4 className="modal-title">FinX Core Login</h4> */}
                      </div>
                      <div className="modal-body">
                        The Profile Form is a means of gathering your
                        information which would help us in serving you more
                        efficiently through your journey towards becoming a
                        Mutual Fund Advisor.
                      </div>
                      <div className="modal-footer">
                        {/* <button
                      type="button"
                      className="btn-5 mt-40 mr-20"
                      name="btnYes"
                    onClick={this.getPaymentLink}
                    >
                      Yes
                    </button> */}
                        <button
                          type="button"
                          className="btn-5 mt-40"
                          name="btnNo"
                          onClick={this.onWhyTheFillFormCloseModal}
                        >
                          Ok
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </section>
          </React.Fragment>
        )}
      />
    );
  }
}

export default UserProfile;
