import React from "react";

const FirstMessage = () => {
  return (
    <div className="notify">
      <br />
      <span
        style={{
          float: "right",
          fontSize: "30px",
          marginTop: "-27px",
          marginRight: "6px",
        }}
      >
        &times;
      </span>
      <b className="notify">Success!</b>
      <p className="notify">
        1. Thank you, we have recorded your details. NISM will be sending
        you a verification link from noreply22-certification@nism.ac.in on your
        personal or official email ID. Please click on the link to validate your
        profile.
        <br />
        2. Online self-paced study material has been assigned to you. There are
        400 questions to prepare from. CIEL will shortly contact you to block
        your NISM exam date. Prepare well!
      </p>
    </div>
  );
};
const SecondMessage = () => {
  return (
    <div className="notify">
      <br />
      <span
        style={{
          float: "right",
          fontSize: "30px",
          marginTop: "-27px",
          marginRight: "6px",
        }}
      >
        &times;
      </span>
      <b className="notify">Success!</b>
      <p className="notify">
        1. Thank you, we have recorded your details. NISM will be sending
        you a verification link from noreply22-certification@nism.ac.in on your
        personal or official email ID. Please click on the link to validate your
        profile.
        <br /> 2. CIEL will contact you shortly to block your NISM exam date.
      </p>
    </div>
  );
};

const ThirdMessage = () => {
  return (
    <div className="notify">
      <br />
      <span
        style={{
          float: "right",
          fontSize: "30px",
          marginTop: "-27px",
          marginRight: "6px",
        }}
      >
        &times;
      </span>
      <b className="notify">Success!</b>
      <p className="notify">
        1. Thank you, we have recorded your details. NISM will be sending
        you a verification link from noreply22-certification@nism.ac.in on your
        personal or official email ID. Please click on the link to validate your
        profile.
        <br /> 2. Please make the payment towards the exam fees to complete the
        registration.
      </p>
    </div>
  );
};

export { FirstMessage, SecondMessage, ThirdMessage };
